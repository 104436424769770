import React from 'react'
import './Insurance.css';

function Insurance() {
  return (
    <div>
      <div className='insurance-heading'>INSURANCE API
      </div>
      <p className='insurance-para'>Offer holistic insurance services including policy comparison, policy customization and policy purchase</p>
      <img src='https://img.freepik.com/premium-vector/hospital-medical-billing-services-illustration_2175-4537.jpg?w=2000'className="imagcustomer"alt='customerimages'></img>
      <div className="feature-table">
  <div className="feature-box">
    <div className="box">
    <i className="fas fa-chart-bar"></i>

      <p>Compare Insurance Products</p>
    </div>
  </div>
  <div className="feature-box">
    <div className="box">
    <i className="fas fa-shopping-cart"></i>

      <p>Purchase Insurance Policy Real-time</p>
    </div>
  </div>
  <div className="feature-box">
    <div className="box">
    <i className="fas fa-home"></i>

      <p>Tailor-made Offerings for Rural Market</p>
    </div>
  </div>

  <div className="feature-box">
  <div className="box">
    <i className="fas fa-file-invoice"></i>
    <p>Claims Management</p>
  </div>
</div>

 
  </div>
     </div>
  )
}

export default Insurance
