import React from 'react'
import { Link } from 'react-router-dom';
import './PlatformAndBenefits.css';
import 'font-awesome/css/font-awesome.min.css';

function PlatformandBenefits() {
  return (
    <div>
    <div >
      <div className='platformh1'>Platform And Benefits</div>
      <div className="popup-container">
  <div className="popup-item">
    <Link to="/service/BankingPlatform/AI/ML-Benefits">
      <h4>AI/ML BENEFITS</h4>
    </Link>
  </div>

  <div className="popup-item">
    <Link to="/service/BankingPlatform/Finance-Vertical">
      <h4>FINANCE VERTICALS</h4>
    </Link>
  </div>

  <div className="popup-item">
    <Link to="/service/BankingPlatform/NeoBankingPlatform">
      <h4>NEO BANKING PLATFORM</h4>
    </Link>
  </div>

  <div className="popup-item">
    <Link to="/service/BankingPlatform/Platform-Variants">
      <h4>BANKING PLATFORM VARIANTS</h4>
    </Link>
  </div>

  <div className="popup-item">
    <Link to="/service/BankingPlatform/Neo-Banking-API-BAsedPlatform">
      <h4>API-BASED PLATFORM</h4>
    </Link>
  </div>
</div>

  </div>
  </div>
  
  )
}

export default PlatformandBenefits
