import React from "react";

import  "./product_common.css";




export default function VM() {

    return (

        <>

       {/* <div><NavBar/></div>  */}

        <div className="banner parbase industries-gradient aem-GridColumn aem-GridColumn--default--12">





        <section id="sml_ht_home_banner">

            <article className="container">

            <div className="row">

              <div className="col-md-9 col-sm-12 col-xs-12 ">

                    <h1 className="h2-heading white-color l-top">

                    Project Management

                    </h1>

                  </div>

            </div>

          </article>

        </section>
        <section className="pt75">
  <article className="container">
    <div className="row">
      <div className="col-md-6 col-sm-12 col-xs-12 text-center-xs wow fadeInLeft" data-wow-delay="0.6s"> 
        {/* <!-- <img className="img-responsive" src="/content/dam/infosys-web/en/navigate-your-next/research/Images/future-occupations.gif" alt="Future Occupations and Industries" /> -->
        <video id="herovideo" className="fillWidth" width="100%" height="100%" preload="auto" autoplay muted loop playsinline preload="metadata" poster="../../content/dam/infosys-web/en/navigate-your-next/research/Images/vendor.jpg">
        <!-- <source src="../../content/dam/infosys-web/en/navigate-your-next/research/Images/skills-training.mp4" type="video/mp4"> -->
        Your browser does not support HTML5 video.
        </video> */}
              <img src="/Images/login1.jpg"></img>

      </div>
      
       <div className="col-md-6 col-sm-12 col-xs-12 mb-sm-20 wow fadeInRight" data-wow-delay="0.6s">
        <h3 className="mb10 mt0">Login and User Management–</h3>
        <ul>
        <li>Create user</li>
         <li>Edit</li>
         <li>deactivate user</li>
         <li>Login</li>
         <li>User Log</li>
         <li>License management</li>
        </ul>
        <p>This module enables user to aces the software resources which they are allowed to access</p>
       </div>
    </div>
  </article>
</section>
        {/* <section className="pt75">
  <article className="container">
    <div className="row">
       <div className="col-md-6 col-sm-12 col-xs-12 text-center-xs wow fadeInLeft" data-wow-delay="0.6s"> 
       <!-- <img className="img-responsive" src="/content/dam/infosys-web/en/navigate-your-next/research/Images/future-occupations.gif" alt="Future Occupations and Industries" /> -->
        <video id="herovideo" className="fillWidth" width="100%" height="100%" preload="auto" autoplay muted loop playsinline preload="metadata" poster="../../content/dam/infosys-web/en/navigate-your-next/research/Images/login1.jpg">
        <!-- <source src="../../content/dam/infosys-web/en/navigate-your-next/research/Images/skills-training.mp4" type="video/mp4"> -->
        Your browser does not support HTML5 video.
        </video> 
        
      </div>
      <div className="col-md-6 col-sm-12 col-xs-12 mb-sm-20 wow fadeInRight" data-wow-delay="0.6s">
        <h3 className="mb10 mt0">Web Application Modules Phase 1:</h3> 
        <h4>Login and User Management–</h4>
        <ul>
         <li>Create user</li>
         <li>Edit</li>
         <li>deactivate user</li>
         <li>Login</li>
         <li>User Log</li>
         <li>License management</li>
        </ul>
        <p>This module enables user to aces the software resources which they are allowed to access</p>
       </div>

  </article>
</section> */}
<section className="pt75">
  <article className="container">
    <div className="row">
      <div className="col-md-6 col-sm-12 col-xs-12 mb-sm-20 wow fadeInLeft" data-wow-delay="0.6s">
        <h3 className="mb10 mt0">Access Control Management</h3>
        <ul>
          <li>edit profile</li>
          <li>update profile</li>
          <li>License key generation</li>
          <li>Action Registry and Approval Dashboards</li>
          <li>Access Control and management of access profiles</li>
          
        </ul>
        <p>Creating an access control for registered user, by which company will hide certain sensitive data from users/employee which they are not supposed to know, like commercials, cost tracking etc kind of sensitive information</p>

      </div>
      <div className="col-md-6 col-sm-12 col-xs-12 text-center-xs wow fadeInRight" data-wow-delay="0.6s"> 
       
              <img src="/Images/access.jpg"></img>

      </div>
    </div>
  </article>
</section>
<section className="pt75">
  <article className="container">
    <div className="row">
      <div className="col-md-6 col-sm-12 col-xs-12 text-center-xs wow fadeInLeft" data-wow-delay="0.6s"> 
       
              <img src="/Images/vendor.jpg"></img>

      </div>
      
       <div className="col-md-6 col-sm-12 col-xs-12 mb-sm-20 wow fadeInRight" data-wow-delay="0.6s">
        <h3 className="mb10 mt0">Vendor List</h3>
        <ul>
          <li>Add new</li>
          <li>Edit</li>
          <li>Delete/Defaulter list</li>
          <li>Import Excel(with given format)</li>
          <li>Export Excel</li>
          <li>Vendor Profile and Track records</li>
        </ul>
        <p>Vendors are third party service providers, they either work for company or provide certain resources for company, and they are also important assets to track, monitor and control</p>
       </div>
    </div>
  </article>
</section>
<section className="pt75">
  <article className="container">
    <div className="row">
      <div className="col-md-6 col-sm-12 col-xs-12 mb-sm-20 wow fadeInLeft" data-wow-delay="0.6s">
        <h3 className="mb10 mt0">Customer/Client and Third Parties Management</h3>
        <ul>
          <li>Add Customer Info</li>
          <li>Add Customer's Company Information like Credit Limit, payment Schedules dates, Province</li>
          <li>Edit Customer Profile</li>
          <li>View Customer Profile and current Status</li>
          <li>Delete</li>
          <li>Print</li>
          <li>Export/Import data to Excel</li>
          <li>Access Control of module</li>
        </ul>
        <p>Customers & third parties management, Company have to do transactions in various level, PO, Material Transfer, Payments all transactional information will be locked in the Client Management Module</p>
      </div>
      <div className="col-md-6 col-sm-12 col-xs-12 text-center-xs wow fadeInRight" data-wow-delay="0.6s"> 
        
              <img src="/Images/customer.jpg"></img>

      </div>
    </div>
  </article>
</section>
<section className="pt75">
    <article className="container">
      <div className="row">
        <div className="col-md-6 col-sm-12 col-xs-12 text-center-xs wow fadeInLeft" data-wow-delay="0.6s"> 
          
                <img src="/Images/quo.jpg"></img>

        </div>
        
         <div className="col-md-6 col-sm-12 col-xs-12 mb-sm-20 wow fadeInRight" data-wow-delay="0.6s">
          <h3 className="mb10 mt0">Quotation Management</h3>
          <ul>
            <li>Create Quotation</li>
            <li>Category Masters for Terms, Banks, etc so Entry could be easy</li>
            <li>Customer details with drop down list for easy access on key press - list automatically come</li>
            <li>Edit Quotation</li>
            <li>Print Quotation</li>
            <li>Quotation History</li>
            <li>Access Controls related to Quotation</li>
            <li>Reports of Quotation from date to date</li>
            
          </ul>
          <p>Creating a commercial Against the Project is not a easy task, customer/clients ask for in detail information, about planning, execution, phases, services, terms, etc. some time it is material, some time it is for service, we identified 2-3 unique ways of creating Quotations, it will help you to manage and create quotation easily</p>
          
         </div>
      </div>
    </article>
  </section>
  <section className="pt75">
    <article className="container">
      <div className="row">
        <div className="col-md-6 col-sm-12 col-xs-12 mb-sm-20 wow fadeInLeft" data-wow-delay="0.6s">
          <h3 className="mb10 mt0">DATA MANAGEMENT AGAINST PO- For Project Milestones</h3>
          <ul>
            <li>PO Entry<p>-It will define the Project Milestone and Payment Milestones</p></li>
            
            <li>Invoice number and PO number as Key</li>
            <li>Billing Schedules Manager- A perfect scheduler alerts for payment reminder
                <p>- Works automatically once PO Scheduled dates added.</p>
            </li>
            <li>Transactions
                <p>- Against PO like milestone completed, payment invoice raised against milestone, payment done etc</p>
            </li>
            <li>Category Masters for Terms, Banks, etc so Entry could be easy</li>
            <li>Customer details with drop down list for easy access on key press,list automatically come</li>
            </ul>
        </div>
  
    
        <div className="col-md-6 col-sm-12 col-xs-12 text-center-xs wow fadeInRight" data-wow-delay="0.6s"> 
         
                <img src="/Images/data.jpg"></img>

        </div>
      </div>
    </article>
  </section>
  <section className="pt75">
    <article className="container">
      <div className="row">
        <div className="col-md-6 col-sm-12 col-xs-12 text-center-xs wow fadeInLeft" data-wow-delay="0.6s"> 
          
                <img src="/Images/pro-plan.jpg"></img>

        </div>
        
         <div className="col-md-6 col-sm-12 col-xs-12 mb-sm-20 wow fadeInRight" data-wow-delay="0.6s">
          <h3 className="mb10 mt0">Project Manager</h3>
          <h4>Project Planning</h4>
          <ul>
            <li>This Module is core of everything, any company uses any number and any type of project (i.e. one time delivery, service based , execution, production based, AMC’s based, human resource based or any kind)</li>
            <li>We have to register the project here,by Create project option</li>
            <li>we can even dynamically Add the project categories as Project category master, as basic theme behind every project is same</li>
        </ul>
          
         </div>
      </div>
    </article>
  </section>
  <section className="pt75">
    <article className="container">
      <div className="row">
        <div className="col-md-6 col-sm-12 col-xs-12 mb-sm-20 wow fadeInLeft" data-wow-delay="0.6s">
          <h3 className="mb10 mt0">Phase Planning</h3>
          <p>Every project has phases, mostly calculated by means of physical/visible outcome, Project need to separate broadly in Phases, like</p>
          <ul>
            <li>Enquiry, Quotation, Negotiation and PO phase</li>
            <li>Planning and resource management Phase</li>
            <li>Execution Phase</li>
            <li>Delivery phase</li>
            <li>Testing</li>
            <li>Service and Maintenance Phase</li>
            <li>Export/Import data to Excel</li>
            <li>Access Control of module</li>
          </ul>
          <p>In real Phases could be different and we can add phases after project created</p>
        </div>
        <div className="col-md-6 col-sm-12 col-xs-12 text-center-xs wow fadeInRight" data-wow-delay="0.6s"> 
          
                <img src="/Images/phase.jpg"></img>

        </div>
      </div>
    </article>
  </section>
  <section className="pt75">
    <article className="container">
      <div className="row row-margin">
        <div className="col-md-8 col-md-offset-2 col-sm-12 col-xs-12 wow fadeInDown" data-wow-delay="0.3s">
          <h3 id="page-innertitle" className=" mb30 text-center">Milestone Planning </h3>
        </div>
        <div className="col-md-6 col-sm-6 col-xs-12 wow fadeInLeft" data-wow-delay="0.3s">
          <ul className="list-items mb0">
            <li>Milestones are the certain checkpoints for project tracking</li>
            <li>Managers feels very comfortable with Milestones</li>
            <li>Every phase have set of milestones</li>
            <li>Milestones are set of activities
            </li>
          </ul>
          <p>E.g. above mentioned phase 1 have Enquiry, Quotation, Negotiation, PO phase available, we have to separate each in every milestone, like if client is interested in enquiry after certain follow-uphe will provide specification/requirements/demands something, so milestone 1 of phase 1 is Customer requirements in details.,</p>
        </div>
        <div className="col-md-6 col-sm-6 col-xs-12 wow fadeInRight" data-wow-delay="0.3s">
          <p> (if customer didn’t share requirement, there is nothing to process, we have to disable the enquiry and put the reason that).</p>
          <p>Then we propose a quotation, if requirements are in our area or scope we create a quotation so if quotation is ready from authorized person with run away price, further milestone of negotiation happens, in negotiation like wise in production, or in commissioning also, we can put the milestones with dates and tracks the manager’s performance</p>
        </div>
      </div>
    </article>
  </section> 
  <section className="pt75">
    <article className="container">
      <div className="row row-margin">
        <div className="col-md-8 col-md-offset-2 col-sm-12 col-xs-12 wow fadeInDown" data-wow-delay="0.3s">
          <h3 id="page-innertitle" className=" mb30 text-center">Activity planning</h3>
        </div>
        <div className="col-md-6 col-sm-6 col-xs-12 wow fadeInLeft" data-wow-delay="0.3s">
          <ul>
            <li>Core action where cost management is require</li>
            <li>Activity is action to do</li>
            <li>Requires Humans, assets, time, we define activity as execution of actions with management of above 3 factors</li>
            <li>Activities may or may not have dependency over phase or another activity</li>
            <li>We need to assign the resources on activities, and in our project, we manage the reusable assets very efficiently, it saves the cost</li>
          </ul>
        </div>
        <div className="col-md-6 col-sm-6 col-xs-12 wow fadeInRight" data-wow-delay="0.3s">
          <ul>
            <li>Cost tracking and management is not a separate process module, it is the micro level management of resources in the activity level itself</li>
            <li>We define the cost of every factor from human per hour cost to assets, materials, backup plans etc. and as we are in project planning, while real time scheduling we can track each and every aspect of the activity and as we architected group of activities forms the milestone, group of milestones are Quotations</li>
          </ul>
        </div>
      </div>
    </article>
  </section> 
  <section className="pt75">
    <article className="container">
      <div className="row row-margin">
    
        <div className="col-md-6 col-sm-6 col-xs-12 wow fadeInLeft" data-wow-delay="0.3s">
            <h3 className="mb10 mt0">Assigning resources to activities</h3>
          <ul>
            <li>Resources can be easily added against activity and person </li>
            <li>Once resource assigned with time, it will get locked and booked, if same required the planner needs to schedule the time settings by considering priority</li>
            <li>Resources selection are of two categories Human, and material</li>
            <li>Activities may or may not have dependency over phase or another activity</li>
            <li>We need to assign the resources on activities, and in our project, we manage the reusable assets very efficiently, it saves the cost</li>
          </ul>
        </div>
        <div className="col-md-6 col-sm-6 col-xs-12 wow fadeInRight" data-wow-delay="0.3s">
          <ul>
            <li>Material Resources are again classified in 2 categories of consumable and re usable materials</li>
            <li>Time allocation (time in hours and minutes, yet no real project execution started) is again important when we submit the project for real time execution and for human cost tracking it is very important</li>
          </ul>
        </div>
      </div>
    </article>
  </section> 
  <section className="pt75">
    <article className="container">
      <div className="row">
        <div className="col-md-6 col-sm-12 col-xs-12 text-center-xs wow fadeInLeft" data-wow-delay="0.6s"> 
         
                          <img src="/Images/pro-report.jpg"></img>

        </div>
        
         <div className="col-md-6 col-sm-12 col-xs-12 mb-sm-20 wow fadeInRight" data-wow-delay="0.6s">
          <h3 className="mb10 mt0">Project reports </h3>
          <ul>
           <li>Giant chart on table(Whole Project control on single admin display) </li>
           <li>Expected budget vs Actual Expenses </li>
           <li>Alert on loss making scenario</li>
        </ul>
          
         </div>
      </div>
    </article>
  </section>
  <section className="pt75">
    <article className="container">
      <div className="row">
        <div className="col-md-6 col-sm-12 col-xs-12 mb-sm-20 wow fadeInLeft" data-wow-delay="0.6s">
          <h3 className="mb10 mt0">Project Scheduler</h3>
          <ul>
            <li>Once we marked project as start execution on date, it will give the project expected completion time in days
            </li>
            <li>Holiday management and leave request provision could be managed to get more accurate deadline</li>
            <li>Every asset and asset owner get notified with his actions
            </li>
            <li>Everyone has his/her own responsibility
            </li>
            <li>Scheduler works for every user
            </li>
            <li>Time extension request and approval available with proper reason verification by higher level
            </li>
            
          </ul>
        </div>
        <div className="col-md-6 col-sm-12 col-xs-12 text-center-xs wow fadeInRight" data-wow-delay="0.6s"> 
         
                          <img src="/Images/pro-sch.jpg"></img>

        </div>
      </div>
    </article>
  </section>
  <section className="pt75">
    <article className="container">
      <div className="row">
        <div className="col-md-6 col-sm-12 col-xs-12 text-center-xs wow fadeInLeft" data-wow-delay="0.6s"> 
          
                          <img src="/Images/note.jpg"></img>

        </div>
        
         <div className="col-md-6 col-sm-12 col-xs-12 mb-sm-20 wow fadeInRight" data-wow-delay="0.6s">
          <h3 className="mb10 mt0">Notification Scheduler</h3>
          <ul>
            <ul>
                <li>Algorithm responsible for sending the notification to every account, we can set the notification time requirements like 80% time over, please update the activity status etc</li>
                <li>It is a continuously running thread of system, helps to take a follow-up from people by itself, if they are using mobile application then it’s performance and notifications are highly effective
             </li>
             </ul>
        </ul>
          
         </div>
      </div>
    </article>
  </section>
  <section className="pt75">
    <article className="container">
      <div className="row row-margin">
    
        <div className="col-md-6 col-sm-6 col-xs-12 wow fadeInLeft" data-wow-delay="0.3s">
            <h3 className="mb10 mt0">Team Building
            </h3>
            <ul>
             <li>While addition of resources and managing the plan of the project, assign activity to the available person then he will automatically being the member of the team</li>
            </ul>
        </div>
        <div className="col-md-6 col-sm-6 col-xs-12 wow fadeInRight" data-wow-delay="0.3s">
            <h2 className="mb10 mt0">Team Building</h2>
          <ul>
          <li>Putting alternative option to everyone, by calculation of risk factor percentage, that much of additional resources need to be on bench, or on some another useful activity which have lesser priority than this project. If required we can provide the backup management module
        </li>
        </ul>
        </div>
      </div>
    </article>
  </section> 
  <section className="pt75">
    <article className="container">
      <div className="row">
        <div className="col-md-6 col-sm-12 col-xs-12 mb-sm-20 wow fadeInLeft" data-wow-delay="0.6s">
          <h3 className="mb10 mt0">Activity Profile Page for user </h3>
          <ul>
           <li>Most important page for every user, the assigned and defined activities and update against the activities gets reflected here in the single page, this page looks like as follows this page called timeline page</li>
            
          </ul>
        </div>
        <div className="col-md-6 col-sm-12 col-xs-12 text-center-xs wow fadeInRight" data-wow-delay="0.6s"> 
          
                          <img src="/Images/activity-profile.jpg"></img>

        </div>
      </div>
    </article>
  </section>




       

       

       

        </div>

       
        </>
        

    )

  }
