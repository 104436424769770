import React from "react";
import "./product_common.css";

export default function Warehouse() {
  return (
    <>
      <div className="banner parbase industries-gradient aem-GridColumn aem-GridColumn--default--12">
        <section id="sml_ht_home_banner">
          <article className="container">
            <div className="row">
              <div className="col-md-9 col-sm-12 col-xs-12 ">
                <h1 id="heading" className="h2-heading white-color l-top">Warehouse</h1>
              </div>
            </div>
          </article>
        </section>
        </div>
        <section class="pt75">
    <article class="container">
        <div class="row row-margin">
            <div class="col-md-8 col-md-offset-2 col-sm-12 col-xs-12 wow fadeInDown" data-wow-delay="0.3s">
              <h2 id="page-innertitle">Warehouse Features</h2>
            </div>
      
        <div class="col-md-6 col-sm-6 col-xs-12 wow fadeInLeft" data-wow-delay="0.3s">
            <h3 class="mb10 mt0">Warehouse Features</h3>
        <ul>
            <li>Item Registry<ul>
                <li>Item Registry</li>
                </ul>
            </li>
        </ul>
        <ul>
            <li>Item Catalogue<ul>
                <li>Important to have the item classification</li>
                </ul>
            </li>
        </ul>
        <ul>
            <li>MAX/MIN STOCK<ul>
                <li>To manage the threshold and save cost and time</li>
                </ul>
            </li>
        </ul>
          
        </div>
        <div class="col-md-6 col-sm-6 col-xs-12 wow fadeInRight" data-wow-delay="0.3s">
        <ul>
            <li>Item Service/Audit<ul>
                <li>Few Item requires regular audit or service while adding item in warehouse we are going to add the dates of service and audit</li>
                </ul>
            </li>
        </ul>
        <ul>
            <li>Item Reports<ul>
                <li>Availability</li>
                <li>Cost etc</li>
                </ul>
            </li>
        </ul><ul>
            <li>Graph /Charts
            </li>
        </ul>
        </div>
      </div>
    </article>
  </section>
  <section class="pt75">
  <article class="container">
    <div class="row">
      <div class="col-md-6 col-sm-12 col-xs-12 mb-sm-20 wow fadeInLeft" data-wow-delay="0.6s">
        <h3 class="mb10 mt0">Warehouse Manager</h3>
        <ul>
          <li>Warehouse Dashboard
            <ul>
                <li>Gives overall information about the warehouse
                </li>
                <li>Space,number of items, shelves etc on broad or bird eye view
                </li>
            </ul>
        </li>
          <li>Warehouse reports
            <ul>
                <li>Recent Transactions, Material Inventory, service audits</li>
            </ul>
          </li>
          <li>Warehouse Charts</li>
          <li>Space management
            <ul>
                <li>To utilize the warehouse with maximum available space
                </li>
                <li>Shelf Creating Scenario, need few on location skillsets to organize
                </li>
            </ul>
        </li>
          
          
        </ul>

      </div>
      <div class="col-md-6 col-sm-12 col-xs-12 text-center-xs wow fadeInRight" data-wow-delay="0.6s"> 
       <img src="/Images/w-profile.jpg"></img>
      </div>
    </div>
  </article>
</section>
<section class="pt75">
    <article class="container">
        <div class="row row-margin">
           
      
        <div class="col-md-6 col-sm-6 col-xs-12 wow fadeInLeft" data-wow-delay="0.3s">
            <h3 class="mb10 mt0">Material Request</h3>
        <ul>
            <li>Request an Item<ul>
                <li>Here item request may came from a project planning phase, provided the case that PO entry available against the project</li>
                </ul>
            </li>
        </ul>
        <ul>
            <li>Requested material Sort listing and checking availability<ul>
                <li>Material Request dashboard shows the material Required for the project, its responsibility of warehouse manager to provide the same Service</li>
                <li>Along with vendor or store check, warehouse user need to validate the prices online, need to put screenshot of the same material, along with link,so quotation and cost approval becomes transparent</li>
                </ul>
            </li>
        </ul>
        
          
        </div>
        <div class="col-md-6 col-sm-6 col-xs-12 wow fadeInRight" data-wow-delay="0.3s">
        <ul>
            <ul>
                <li>Material available in the warehouse would marked as available, Quick search option provided to know the availability of material</li>
               <li>Material not marked as present need to purchase from sources, warehouse manager need to ask the quotation from several sources</li>
                </ul>
            
        </ul>
        <ul>
            <li>Purchase Requested<ul>
                <li>Item Not available in store marked as Purchase requested.</li>
                </ul>
            </li>
        </ul><ul>
            <li>Available Items
            </li>
            <ul>
                <li>Item available are notified to the planner of the project, same shall reflect on the dashboard</li>
            </ul>
        </ul>
        <ul>
            <li>Create MRR
            </li>
            <ul>
                <li>Material Requirement Receipt, a list of material required and requested to the vendors/online market</li>
            </ul>
        </ul>
        </div>
      </div>
    </article>
  </section>
  <section class="pt75">
  <article class="container">
    <div class="row">
      <div class="col-md-6 col-sm-12 col-xs-12 text-center-xs wow fadeInLeft" data-wow-delay="0.6s"> 
       <img src="/Images/material-man.jpg"></img>
      </div>
      
       <div class="col-md-6 col-sm-12 col-xs-12 mb-sm-20 wow fadeInRight" data-wow-delay="0.6s">
        <h3 class="mb10 mt0">Material Manager</h3>
        <ul>
          <li>Quotations module Above
            <ul>
                <li>Quotations module Above
                </li>
            </ul>
        </li>
          <li>Quotation Approvals
            <ul>
                <li>Approval phases and hierarchy of approvals
                </li>
            </ul>
        </li>
          <li>PO Dashboard
            <ul>
                <li>Once Quotation approved from certain vendor on online reseller/E commerce portal Purchase orders gets created
                </li>
            </ul>
        </li>
        
          
        </ul>
       </div>
    </div>
  </article>
</section>
<section class="pt75">
    <article class="container">
        <div class="row row-margin">
           
      
        <div class="col-md-6 col-sm-6 col-xs-12 wow fadeInLeft" data-wow-delay="0.3s">
            <h3 class="mb10 mt0">Material Manager</h3>
        <ul>
            <li>Delivery Against PO<ul>
                <li>After PO , delivery tracking against PO required to check, along with the terms and conditions mentioned in the PO document</li>
                <li>Material Arrival checkpoint required PO and LR to cross examine with system generated requirements</li>
                
                </ul>
            </li>
        </ul>
        <ul>
            <li>Assign to warehouse<ul>
                <li>Material arrived, or material delivery need to assign in warehouse, certain parameter check we are performing to do the same</li>
                <li>Material needs to cross verify</li>
                <li>Specifications need to be cross verify</li>
                <li>Check for the size in the warehouse, location assignment policy needs to define</li>
                <li>Tagging the material</li>
                </ul>
            </li>
        </ul>
        
          
        </div>
        <div class="col-md-6 col-sm-6 col-xs-12 wow fadeInRight" data-wow-delay="0.3s">
        <ul>
            <li>Material Return and policy making</li>
            <ul>
                <li>On delivery against PO, if material is not meeting up the criteria which planner defined material need to return against PO</li>
                </ul>
            
        </ul>
        <ul>
            <li>Material Shift<ul>
                <li>Material migration case and reason</li>
                <li>Material migration case and reason</li>
                <li>Material migration case and reason</li>
                <li>Material receipt of delivery on location</li>
                </ul>
            </li>
        </ul><ul>
            <li>Material Dispatch
            </li>
            <ul>
                <li>Dispatch for consumption or usage,</li>
            </ul>
        </ul>
        
        </div>
      </div>
    </article>
  </section>
  <section class="pt75">
  <article class="container">
    <div class="row">
      <div class="col-md-6 col-sm-12 col-xs-12 mb-sm-20 wow fadeInLeft" data-wow-delay="0.6s">
        <h3 class="mb10 mt0">Assembly Manager</h3>
        <ul>
          <li>Product Definition
            <ul>
                <li>Product definition attributes
                </li>
                <li>Product definition attributes
                </li>
                <li>Product definition attributes
                </li>
                <li>Activity definition for assembly by considering new project as new source of income
                </li>
            </ul>
        </li>
          
          
        </ul>

      </div>
      <div class="col-md-6 col-sm-12 col-xs-12 text-center-xs wow fadeInRight" data-wow-delay="0.6s"> 
        <img src="/Images/a-manager.jpg"></img>
      </div>
    </div>
  </article>
</section>
<section class="pt75">
    <article class="container">
      <div class="row">
        <div class="col-md-6 col-sm-12 col-xs-12 text-center-xs wow fadeInLeft" data-wow-delay="0.6s"> 
       <img src="/Images/a-product.jpg"></img>
        </div>
        
         <div class="col-md-6 col-sm-12 col-xs-12 mb-sm-20 wow fadeInRight" data-wow-delay="0.6s">
          <h3 class="mb10 mt0">Assembly Manager
        </h3>
          <ul>
            <li>Item Requirements and Status of availability
                <ul>
                    <li>Check with the MRR status and schedule accordingly
                    </li>
                </ul>
            </li>
            <li>Finished Product Labeling
                <ul>
                    <li>Naming, Lableing,tagging,Coding
                    </li>
                    <li>Packaging of material
                    </li>
                    <li>Packaging of material
                    </li>
                </ul>
            </li>
           
            
          </ul>
         </div>
      </div>
    </article>
  </section>
  <section class="pt75">
    <article class="container">
      <div class="row">
        <div class="col-md-6 col-sm-12 col-xs-12 mb-sm-20 wow fadeInLeft" data-wow-delay="0.6s">
          <h3 class="mb10 mt0">My Work/Performance Reports</h3>
          <ul>
            <li>My Work/Performance Reports</li>
            <li>Calendar Manager, Email Manager
            </li>
          </ul>
  
        </div>
        <div class="col-md-6 col-sm-12 col-xs-12 text-center-xs wow fadeInRight" data-wow-delay="0.6s"> 
          <img src="/Images/w-summary.jpg"></img>
        </div>
      </div>
    </article>
  </section>
  <section class="pt75">
    <article class="container">
      <div class="row">
        <div class="col-md-6 col-sm-12 col-xs-12 text-center-xs wow fadeInLeft" data-wow-delay="0.6s"> 
          <img src="/Images/w-dashboard.jpg"></img>
        </div>
        
         <div class="col-md-6 col-sm-12 col-xs-12 mb-sm-20 wow fadeInRight" data-wow-delay="0.6s">
        
          <ul>
            <li>Master Management

                <ul>
                    <li>Information or Meta information which is fixed or set which frequently going to check

                    </li>
                </ul>
            </li>
            <li>Settings

                <ul>
                    <li>Settings

                    </li>
                    <li>Settings

                    </li>
                    <li>Server Settings,logs, backup provisions etc

                    </li>
                </ul>
            </li>
           
            
          </ul>
         </div>
      </div>
    </article>
  </section>



  
      
    </>
  );
}
