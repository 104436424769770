import "./template.css";
 
 import { Link } from "react-router-dom";
import ReadModal2 from "./readMore.js";
 import React, { useState, useRef, useEffect} from 'react';
 import Slider from 'react-slick';
 import 'slick-carousel/slick/slick.css';
 import 'slick-carousel/slick/slick-theme.css';
import CarouselSlider from "./Carousel.js";
import CaseStudyCard from "./CaseStudyCard.js";

function DigitalTransformation() {



    const [activeModal, setActiveModal] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);
  const sliderRef = useRef(null);
  const modalRef = useRef(null);
  const [selectedSlide, setSelectedSlide] = useState(null);
  const settings = {
    // dots: true,
    // infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },

      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },

      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 3000,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
     
    ],
  };
  const slideData = [
    {
      id: 1,
      heading: 'Customized Approach',
      text: 'We understand that each business is unique, and we tailor our digital transformation strategies to fit your specific needs and goals.',
      buttonLabel: 'Read More',
      modalContent: {
        // modalHeading: 'Modal Heading 1',
        modalText: 'CloudCipher seamlessly integrates with existing workflows and software ecosystems, minimizing disruption and streamlining the adoption process. Whether migrating from another platform or implementing new security measures, CloudCipher ensures a smooth transition with minimal downtime.',
      },
    },
    {
      id: 2,
      heading: 'Expert Team',
      text: 'Our team consists of specialists in digital technologies, ensuring that you have access to the latest innovations and expertise.',
      buttonLabel: 'Read More',
      modalContent: {
        modalHeading: 'Modal Heading 2',
        modalText: 'Modal Text 2',
      },
    },
 
    {
      id: 3,
      heading: 'End-to-End Support',
      text: 'From initial assessment to implementation and beyond, we offer comprehensive support throughout your digital transformation journey.',
      buttonLabel: 'Read More',
      modalContent: {
        modalHeading: 'Modal Heading 3',
        modalText: 'Modal Text 3',
      },
    },
 
    {
      id: 4,
      heading: 'Focus on ROI',
      text: 'Our services are designed not just to transform your business digitally but also to deliver tangible returns on investment.',
      buttonLabel: 'Read More',
      modalContent: {
        modalHeading: 'Modal Heading 4',
        modalText: 'Modal Text 4',
      },
    },
 
    // {
    //   id: 5,
    //   heading: '24/7 Monitoring and Support',
    //   text: 'To ensuring prompt resolution of security incidents and technical issues, with dedicated experts maintaining data integrity and availability."',
    //   buttonLabel: 'Read More',
    //   modalContent: {
    //     modalHeading: 'Modal Heading 5',
    //     modalText: 'Modal Text 5',
    //   },
    // },
 
    // {
    //   id: 6,
    //   heading: 'Sixth Slide',
    //   text: 'A different text for the second slide.',
    //   buttonLabel: 'Read More',
    //   modalContent: {
    //     modalHeading: 'Modal Heading 6',
    //     modalText: 'Modal Text 6',
    //   },
    // },
 
    // {
    //   id: 7,
    //   heading: 'Seventh Slide',
    //   text: 'A different text for the second slide.',
    //   buttonLabel: 'Read More',
    //   modalContent: {
    //     modalHeading: 'Modal Heading 7',
    //     modalText: 'Modal Text 7',
    //   },
    // },
 
    // {
    //   id: 8,
    //   heading: 'Eighth Slide',
    //   text: 'A different text for the second slide.',
    //   buttonLabel: 'Read More',
    //   modalContent: {
    //     modalHeading: 'Modal Heading 8',
    //     modalText: 'Modal Text 8',
    //   },
    // },
    // ... (more slides)
  ];
 
 
 
  const openModal = (slide) => {
    setSelectedSlide(slide);
    setModalOpen(true);
  };
 
  const closeModal = () => {
    setModalOpen(false);
  };
 
  // Handle clicks outside the modal to close it
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        closeModal();
      }
    };
 
    window.addEventListener('mousedown', handleOutsideClick);
 
    return () => {
      window.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);



  const modalContent = (
    <>
      <p className="modal-np" >The Purpose Behind Our Digital Transformation Service </p>
      
         In the era of digital revolution, staying ahead means embracing technological advancements. Our Digital Transformation Services are designed to help businesses navigate and thrive in this digital age. By integrating digital technology into all areas of your business, we aim to fundamentally change how you operate and deliver value to customers. It's not just about upgrading technology; it's about revolutionizing your entire business approach and mindset.

         <hr/>
            <br/> <strong>Operational Efficiency</strong> 
 
            Digital transformation services aim to streamline and automate internal processes, workflows, and operations using technologies such as cloud computing, robotic process automation (RPA), and enterprise resource planning (ERP) systems. This leads to increased productivity, reduced costs, and faster time-to-market for products and services.
         <br/><br/>
        <strong> Data-Driven Decision-Making</strong>
        Digital transformation services help organizations harness the power of data by collecting, analyzing, and leveraging data insights to make informed decisions, identify trends, and uncover new opportunities. This includes implementing data analytics tools, predictive modeling, and business intelligence solutions to drive strategic initiatives and optimize performance.
 
          <br/><br/><strong>Employee Empowerment and Collaboration</strong> 
          Digital transformation services focus on empowering employees with the tools, skills, and mindset needed to thrive in a digital workplace. This includes providing training in digital literacy, fostering collaboration through digital platforms, and enabling remote work and flexible arrangements to attract and retain top talent.
 
 
          
  
 
 
      {/* Your dynamic content here */}
      {/* You can pass any JSX content you want */}
    </>
  );


  return (

    <div>

<div id="maindivs">
        <div id="carouselExampleCaptions" className="carousel slide">
          <div className="carousel-inner">
            <div className="carousel-item active">
              <img
                src="/Images/firstcomponant.jpg"
                className="d-block w-100"
                style={{ height: "700px" }}
                alt="slider first"
              />
              <div className="carousel-caption  d-md-block">
                <div className="component-1">Digital Transformation Services</div>
              
                <div
                  className="component-paras"
                  style={{ color: "white", margin: "50px" }}
                >
                 "The Ultimate Secure Collaboration Platform" 
                </div>
              </div>
            </div>
    
        </div>
        </div>
        
    
        <div className="container-np2">
          {/* <!-- section2 title --> */}
          <div className="cont2-titles">
            <div class="cont2-titletext">What is Digital Transformation Services?</div>
            
          </div>
    
          {/* <!-- section part --> */}
          <div class="container21">
            {/* <!-- left part --> */}
    
            <div className="cont2-left cont2-text">
              <div
                className="left-text"
                style={{
                  // fontFamily: "arial",
    
                  fontFamily: "system-ui",
                  fontSize:"25px"
                }}
              >
               Digital Transformation Services encompass a comprehensive approach to leveraging digital technologies to radically improve the performance and reach of enterprises. This involves reimagining existing business processes, culture, and customer experiences to meet changing business and market requirements. Our services include digital strategy formulation, process automation, digital tool implementation, data analytics, and digital culture cultivation. 
              </div>
            </div>
    
            {/* right part */}
            <div className="cont2-right cont2-text">
              <div className="right-text">
                <img
                  class="content2img"
                  src="/Images/digital-transformation.jpg"
                  alt="Description of the image"
                />
              </div>
            </div>
          </div>
          <div class="readmore">
            <ReadModal2 buttonText="Read More" modalContent={modalContent} />

            
          </div>
        </div>
    
        {/* section3 */}
        <section class="section3">
          <div class="cont2-title title ">Our Features</div>
    
          <div class="section3parts">
            <div class="content-np item31">
              <div>
                <strong style={{
                  paddingLeft:'15px'
                }}> Digital Strategy Development
    </strong>
                <p class="aligned-paragraph item3text" style={{ marginBottom: "30px" }}>
                Crafting tailored strategies that align with your business objectives and digital goals. 
 
                </p>
              </div>
            </div>
    
            <div class="content-np item322">
              <strong class="item32-heading item31-conent"style={{
                  paddingLeft:'15px'
                }} >
              Customer Experience Transformation
    
              </strong>
              <div class=" item31-conent-img  "></div>
              <p class="aligned-paragraph item3text" style={{color:'black'}}>
              Enhancing customer interaction and engagement through digital platforms. 
              </p>
            </div>
    
            <div class="content-np item33">
              <strong class="item32-headings"style={{
                  paddingLeft:'15px'
                }} > Process Automation and Optimization
    </strong>
              <p class="aligned-paragraph item3text" >
              Streamlining operations to improve efficiency and reduce costs using the latest digital tools. 

              </p>
              {/* <div class="item32-button"><button class="item32-button">Read more</button></div> */}
            </div>
    
            <div class="content-np item34" >
              <strong style={{
                 paddingLeft:'15px'
                }} >Data Analytics and Insights
     </strong>
              <p class="aligned-paragraph item3text" style={{color:'black'}}>
              Utilizing data analytics to drive decision-making and create personalized customer experiences.

              </p>
            </div>
            <div class="content-np item35">
              <strong style={{
                  paddingLeft:'15px'
                }}> Technology Implementation
     </strong>
              <p class="aligned-paragraph item3text">
              Introducing cutting-edge technologies like AI, IoT, and cloud computing to modernize your business operations. 

              </p>
            </div>
    
            <div class="content-np item36">
              <strong style={{
                  paddingLeft:'15px'
                }}>Digital Training and Change Management
    </strong>
              <p class="aligned-paragraph item3text">
              Equipping your team with the necessary skills and mindset for a smooth digital transition.
              </p>
            </div>
    
            <div class="content-np item37">
              <strong style={{
                 paddingLeft:'15px'
                }}>Customizable & Extensible 
    </strong>
              <p class="aligned-paragraph item3text" style={{color:'black'}}>
              Enhance SmartCloud's capabilities with a myriad of extensions and plugins.
Automate tasks and optimize data workflows.

              </p>
            </div>
          </div>
          {/* <div class="readmore">
            <button class="read1">Read More</button>
          </div> */}
        </section>

        
    
        <div class="section4">


        
<div className='sliders'>
  {/* Previous and Next buttons */}
  <button className="nav-button-slider prev" onClick={() => sliderRef.current?.slickPrev()}>
    &lt;
  </button>
  <button className="nav-button-slider next" onClick={() => sliderRef.current?.slickNext()}>
    &gt;
  </button>
  {/* Modal */}
  {/* {isModalOpen && selectedSlide && (
    <div className="modal-overlay" ref={modalRef}>
      <div className="modal-content-m" style={{ position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
       
        <div id="close-m" onClick={closeModal}>
          <i className="fas fa-times closeicon"></i>
        </div>
        <h2>{selectedSlide. modalContent.modalHeading}</h2>
        <p>{selectedSlide. modalContent.modalText}</p>
      </div>
    </div>
  )} */}
 
  <div className="slidercontainer">
    <Slider ref={sliderRef} {...settings}>
      {slideData.map((slide) => (
        <div key={slide.id} className="slider-item">
          <div className="slidecontents">
            <h2 className='slideheading h2-np slidecontent'>{slide.heading}</h2>
            <p className='slidetext p-np slidecontent'>{slide.text}</p>
            {/* <button className='slidebutton read1' onClick={() => openModal(slide)}>
              {slide.buttonLabel}
            </button> */}
          </div>
        </div>
      ))}
    </Slider>
  </div>
</div>
 
     
          {/* <CarouselSlider/> */}
         
        </div>
    
        <div className="section5">
    <h1 className="section5heading">How We are Handling</h1>
      <div className="section5jsxcard">
      <CaseStudyCard
        imageUrl="/Images/casestudyimg1.jpg"
        title="Assessment & Planning"
        description="Understanding your current digital maturity and defining clear objectives. "
        // linkUrl="/platform/E-cart/casestudy-vitualwallet"
      />
      <CaseStudyCard
        imageUrl="/Images/casestudyimg2.jpg"
        title="Strategy Formulation"
        description="Developing a detailed transformation roadmap tailored to your business needs. "
        linkUrl=""
      />
      <CaseStudyCard
        imageUrl="/Images/casestudyimg3.jpg"
        title="Execution"
        description="Implementing the transformation strategy, focusing on technology integration, process automation, and digital skill enhancement. "
        linkUrl=""
      />
     
      <CaseStudyCard
        imageUrl="/Images/casestudyimg4.jpg"
        title="Continuous Improvement"
        description="Post-implementation, we focus on refining and optimizing digital processes, ensuring they evolve with your business and the digital landscape. "
        linkUrl=""
      />
      
     
    {/* <div class="readmore">
  <button class="read">show More</button>
  </div> */}


{/* section 6 */}


  
    </div>
    </div>  
    </div>
      
    </div>
  )
}

export default DigitalTransformation
