import React from "react";
import "./Footer.css";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faInstagram,
  faLinkedin,
  faTwitter,
  faYoutube,
  faGoogle,
  faWhatsapp
} from "@fortawesome/free-brands-svg-icons";

export default function Footer() {
  return (
    <>
      <div>
        {/* <hr
          className="mb-4 mt-0 d-inline-block mx-auto"
          style={{ width: "100%", backgroundColor: "#000", height: "10px" }}
        /> */}
      </div>
      <div id="footercontainer" className="footercontainer pt50 pb50">
        <footer className="">
          {/* <hr
            className="mb-4 mt-0 d-inline-block mx-auto"
            style={{ width: "100%", backgroundColor: "#000", height: "10px" }}
          /> */}
          <section className="d-flex justify-content-between ">
            <div className="d-flex flex-row"></div>
          </section>

          <section className="">
            <div className="container text-center text-md-start mt-3">
              <div className="row mt-3">
                <div className="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4">
                  {/* <h6
                    id="footer-title"
                    className="mobile-hidden text-uppercase fw-bold"
                  >
                   
                  </h6> */}
                  {/* <hr
                    className="mb-4 mt-0 d-inline-block mx-auto"
                    style={{
                      width: "200px",
                      backgroundColor: "#000",
                      height: "4px",
                    }}
                  /> */}
                  {/* <p>DigiTechHub</p> */}
                  <Link to="/" className=" ">
                  <img className="img-footer" src="/Images/Digitechub_SVG_file.svg" id="" />
                </Link>
                <h6 className="text-white mobile-only"></h6>
                <h6 className="text-white"></h6>
                </div>

                <div className="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4">
                  <h6 id="footer-title" className="text-uppercase fw-bold">
                    Link
                  </h6>
                  <hr
                    className="mb-4 mt-0 d-inline-block mx-auto"
                    style={{
                      width: "60px",
                      backgroundColor: "#000",
                      height: "4px",
                    }}
                  />
                  {/* <p onClick={() => window.scrollTo(0, 0)}>
                    <Link to="/" id="text" className="text-black">
                      Navigate Your Next
                    </Link>
                  </p> */}
                  <p onClick={() => window.scrollTo(0, 0)}>
                    <Link  to="/about-us" id="text" className="text-black">
                      About Us
                    </Link>
                  </p>
                  {/* <p>
                    <Link href="#!" id="text" className="text-black">
                      Careers
                    </Link>
                  </p> */}
                  <p>
                    <Link href="#!" id="text" className="text-black">
                      Corporate
                    </Link>
                  </p>
                  {/* <p>
  <Link href="#!" id='text' className="text-black">Responsibility</Link>
  </p>
  <p>
  <Link href="#!" id='text' className="text-black">Investors</Link>
  </p>
  <p>
  <Link href="#!" id='text' className="text-black">Newsroom</Link>
  </p> */}
                </div>

                <div className="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4">
                  <h6 id="footer-title" className="text-uppercase fw-bold">
                    policy
                  </h6>
                  <hr
                    className="mb-4 mt-0 d-inline-block mx-auto"
                    style={{
                      width: "90px",
                      backgroundColor: "#000",
                      height: "4px",
                    }}
                  />
                  <p onClick={() => window.scrollTo(0, 0)}>
                    <Link to="/Terms" id="text" className="text-black">
                      Terms of Use
                    </Link>
                  </p>
                  <p onClick={() => window.scrollTo(0, 0)}>
                    <Link to="/Privacy" id="text" className="text-black">
                      Privacy Statement
                    </Link>
                  </p>
                  <p onClick={() => window.scrollTo(0, 0)}>
                    <Link to="/Cookie" id="text" className="text-black">
                      Cookie Policy
                    </Link>
                  </p>
                  {/* <p onClick={() => window.scrollTo(0, 0)}>
                    <Link to="/Safe" id="text" className="text-black">
                      Safe Harbour Provision
                    </Link>
                  </p> */}
                </div>

                <div className="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">
                  <h6 id="footer-title" className="text-uppercase fw-bold">
                    Contact
                  </h6>
                  <hr
                    className="mb-4 mt-0 d-inline-block mx-auto"
                    style={{
                      width: "110px",
                      backgroundColor: "#000",
                      height: "4px",
                    }}
                  />
                  
                  <p id="contact" className="  mr-3">
                   
                  info@digitechub.com
                  </p>
                 
            
                {/* <hr
                  className="mb-4 mt-0 d-inline-block mx-auto"
                  style={{
                    width: "200px",
                    backgroundColor: "#000",
                    height: "4px",
                  }}
                /> */}
                {/* <p>DigiTechHub</p> */}
                {/* <Link to="/" className=" ">
                  <img className="img-footer" src="/Images/Digitechub_SVG_file.svg" id="" />
                </Link>
                <h6 className="text-white mobile-only"></h6>
                <h6 className="text-white"></h6> */}
              </div>

                  {/* <p id="contactsocialmedia"> Social networks</p> */}

                  <div className="social-links">
                    <a href="https://www.youtube.com/@Digitec-hub" className="youtube social">
                      <FontAwesomeIcon icon={faYoutube} size="1x" />
                    </a>

                    <a href="https://www.facebook.com/digitechubinc" className="facebook social">
                      <FontAwesomeIcon icon={faFacebook} size="1x" />
                    </a>
                    <a href="https://www.twitter.com" className="twitter social">
                      <FontAwesomeIcon icon={faTwitter} size="1x" />
                    </a>

                    <a href="https://www.instagram.com/digitechubinc/" className="instagram social">
                      <FontAwesomeIcon icon={faInstagram} size="1x" />
                    </a>

                    <a
                      href="https://www.linkedin.com/company/digitechub-inc/?viewAsMember=true"
                      className="Linkedin social"
                    >
                      <FontAwesomeIcon icon={faLinkedin} size="1x" />
                    </a>

                    <a href="https://api.whatsapp.com/send?phone=YOUR_PHONE_NUMBER" className="whatsapp social">
                    <FontAwesomeIcon icon={faWhatsapp} size="1x" />
                    </a>
                  </div>
                </div>
              </div>
          </section>
         

          <div className="text-center ">
            <Link id="footer-line" className=" text-black">
              Copyright © 2024 DigiTecHub
            </Link>
          </div>
        </footer>
        </div>
     
    </>
  );
}
