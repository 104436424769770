import React from 'react'
import './CaseStudy.css';
import CsForm from './CsForm.js';

function CsVirtualWallet() {
  return (
    <div>
      <div className="main-casestudydiv">
      <section className="caseStudysection3">
        <div className="casestudytitle">
          <div className="casestudylable">CASE STUDY</div>
          <h1 className="case-headind1">
            {" "}
            Virtual Wallet for E-Cart
          </h1>
        </div>
      </section>
 
      <div className="part2 col">
        <section className="para-in case-pt75">
          <article className="case-container">
            <div className="row-k">
              <div
                className="row-in case-mb20 fadeInUp animated"
                data-wow-delay="0.3s"
                style={{
                  visibility: "visible",
                  WebkitAnimationDelay: "0.3s",
                  MozAnimationDelay: "0.3s",
                  animationDelay: "0.3s",
                }}
              >
                <p className="part2-para">
                The Virtual Wallet for E-Cart is an innovative E-commerce solution designed to streamline the checkout process and enhance security for online transactions. Acting as a digital payment and storage platform, the Virtual Wallet provides users with a convenient and secure way to store payment methods, manage transactions, and expedite the checkout process across various E-commerce platforms.
                </p>
                {/* <p className="part2-para">
                The goal was to provide a seamless experience for customers purchasing antivirus software keys while ensuring a secure, efficient back-end operation.
                </p> */}
              </div>
            </div>
            <div className="row-k case-mt45">
              <div className="row-in2 ">
                <div
                  className="yellow-bg pad-all wow fadeInUp animated"
                  data-wow-delay="1.3s"
                  style={{
                    visibility: "visible",
                    WebkitAnimationDelay: "1.3s",
                    MozAnimationDelay: "1.3s",
                    animationDelay: "1.3s",
                  }}
                >
                  <div className="row1 row-k">
                    <div className="col1">
                      <p>
                        {" "}
                        <strong>Key Challenges</strong>
                      </p>
                      <ul className="case-list-items">
                        <p>
                        Traditional E-commerce transactions often involve repetitive input of payment information for each purchase, leading to friction during the checkout process. Additionally, concerns about data security and privacy can deter users from making online transactions, particularly on unfamiliar platforms.
                        </p>
                        {/* <li>
                        Security: Ensuring secure transactions and protecting sensitive customer data.
                        </li>
                        <li>
                        User Experience: Providing a seamless, intuitive shopping experience on both web and mobile platforms.
                        </li>
                        <li>
                        Digital Key Management: Efficiently distributing and managing a vast inventory of digital keys.
                        </li> */}
                      </ul>
                    </div>
                    <div className="col2">
                      <p>
                        {" "}
                        <strong>Ready to experience?</strong>
                      </p>
                      <a
                        href="#rfs"
                        className="scrollto-target pdf"
                        title="Talk To Experts"
                      >
                        TALK TO EXPERTS
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </article>
        </section>
      </div>
      <div className="part3">
        <section  className="case-pt0 case-pb75">
          <article className="case-container">
            <div className="row-in2">
              <div
                className="row-in2 wow fadeInUp animated"
                data-wow-delay="0.3s"
                style={{
                  visibility: "visible",
                  WebkitAnimationDelay: "0.3s",
                  MozAnimationDelay: "0.3s",
                  animationDelay: "0.3s",
                }}
              >
                <h2 className="h2-heading-cs text-center">The Solution</h2>
                <h3 className=" para-txt col-md-part-2 pd-lr light-gray text-center mb50">
               The Vitualwallet- Secure payment hub
                </h3>
              </div>
              <div
                className="col-part1 wow fadeInLeft animated"
                data-wow-delay="0.6s"
                style={{
                  visibility: "visible",
                  WebkitAnimationDelay: "0.6s",
                  MozAnimationDelay: "0.6s",
                  animationDelay: "0.6s",
                }}
              >
                <p>
                  <strong>Secure Payment Storage</strong>
                </p>
                <ul className="case-list-items">
                  <li>
                  Utilizing robust encryption.
                  </li>
                  <li>
                  Securely storing users' payment information.
                  </li>
                  <li>
                  Including credit/debit card details, bank account information, and digital wallets.
                  </li>
                  
                </ul>
                <p>
                  <strong>One-Click Checkout</strong>
                </p>
                <ul className="case-list-items">
                  <li>
                  Allowing users to complete purchases with a single click.
                  </li>
                  <li>
                  Selecting their preferred payment method from the Virtual Wallet.
                  </li>
                  <li>
                  Eliminating the need to manually input payment details for each transaction.
                  </li>
                </ul>
                <p>
                  <strong>Transaction History</strong>
                </p>
                <ul className="case-list-items">
                  <li>
                  Providing users with a comprehensive transaction history.
                  </li>
                  <li>
                  Offering a receipt management system.
                  </li>
                  <li>
                  Allowing users to track their spending and manage E-commerce purchases effectively.
                  </li>
                </ul>
              </div>
            </div>
            <div className="row case-mt45">
              <div className="">
                <div
                  className="yellow-bg pad-all wow fadeInUp animated"
                  data-wow-delay="1.3s"
                  style={{
                    visibility: "visible",
                    WebkitAnimationDelay: "1.3s",
                    MozAnimationDelay: "1.3s",
                    animationDelay: "1.3s",
                  }}
                >
                  <h2 className="head-case">
                  Implementation
                  </h2>
                  <div className="row case-txt-para">
                    <div className="col1">
                      <ul className="case-list-items">
                        <li>
                        Platform Development – The Virtual Wallet for E-Cart platform is built with user-centric design, stringent security measures, and seamless integration with third-party E-commerce platforms.
                        </li>
                        <li>
                        User Onboarding – Users securely link various payment methods to their Virtual Wallet accounts, including credit/debit cards, bank accounts, and digital wallets, with each method undergoing verification and encryption for heightened security.
                        </li>
                        <li>One-Click Checkout Integration – Collaborating with E-commerce platforms and online retailers to seamlessly incorporate the Virtual Wallet's one-click checkout feature, ensuring swift and secure purchase completion for users.</li>
                        <li>Security Measures Implementation – Implementing advanced security measures such as encryption, authentication, and fraud detection algorithms to protect users' payment information and ensure secure transactions.</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
              
          </article>
        </section>
      </div>
      <div className="part4 col">
        <section className="para-in ">
          <article className="case-container">
            
            <div className="row-k ">
              <div className="row-in2 ">
                <div
                  className=" pad-all wow fadeInUp animated">
                  <div className="part4-row1 row-k">
                    <div className="col1">
                    <p>
                  <strong>Results</strong>
                </p>
                <ul className="case-list-items">
                  <li>
                  Improved Checkout Experience: The Virtual Wallet for E-Cart streamlines the checkout process, reducing friction and making it easier for users to complete purchases online.
                  </li>
                  <li>
                  Enhanced Security: By securely storing payment information and implementing advanced security measures, the Virtual Wallet protects users' sensitive data and reduces the risk of unauthorized access or fraudulent transactions.
                  </li>
                  <li>
                  Increased Convenience: Users benefit from the convenience of having all their payment methods stored in one place, simplifying the online shopping experience and saving time during checkout.
                  </li>
                </ul>
                    </div>
                    <div className="col2">
                    <p>
                  <strong>Conclusion</strong>
                </p>
                <ul className="-list-items">
                  <li>
                  The Virtual Wallet for E-Cart represents an innovative solution to the challenges of online payment processing and checkout friction in the E-commerce industry. By offering a secure and convenient platform for storing payment methods and expediting transactions, the Virtual Wallet enhances the online shopping experience for both users and E-commerce businesses, driving increased conversion rates and customer satisfaction.
                  </li>
                  
                </ul>
                    </div>
                    

                  </div>
                </div>
              </div>
            </div>
          </article>
        </section>
      </div>
        
      <CsForm />
    </div>
    </div>
  )
}

export default CsVirtualWallet
