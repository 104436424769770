import React, { useEffect, useState } from "react";
import "./Accordion.css"; // Import your CSS file
import AOS from "aos";
import "aos/dist/aos.css";
 
function Accordion({items }) {
  const [activeIndex, setActiveIndex] = useState(null);
  const [isContentVisible, setIsContentVisible] = useState(false);
 
  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, []);
 
  const onItemClick = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
   
  };
 
  const onTitleMouseEnter = (index) => {
    setIsContentVisible(true);
    setActiveIndex(index);
  };
 
  const onTitleMouseLeave = () => {
    setIsContentVisible(false);
  };
 
  return (
    <div className="accordion">
      {items.map((item, index) => (
        <div key={index} className="accordion-item">
          <div
            className={`accordion-title ${
              index === activeIndex ? "active" : ""
            }`}
            onClick={() => onItemClick(index)}
            onMouseEnter={() => onTitleMouseEnter(index)}
            onMouseLeave={onTitleMouseLeave}
          >
            {item.title}
            <button className="accordion-toggle">
              {index === activeIndex ? "-" : "+"}
            </button>
          </div>
          <div
            className={`accordion-content ${
              index === activeIndex && isContentVisible ? "active" : ""
            }`}
          >
            {item.content}
          </div>
        </div>
      ))}
    </div>
  );
}
 
export default Accordion;