import "./template.css";

import { Link } from "react-router-dom";
import ReadModal2 from "./readMore.js";
import React, { useState, useRef, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CarouselSlider from "./Carousel.js";
import CaseStudyCard from "./CaseStudyCard.js";

import Accordion from "./Accordion";
import "./Accordion.css";


import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

function DroneSolution() {
  const [activeModal, setActiveModal] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);
  const sliderRef = useRef(null);
  const modalRef = useRef(null);
  const [selectedSlide, setSelectedSlide] = useState(null);
  const settings = {
    // dots: true,
    // infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },

      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },

      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 3000,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const slideData = [
    {
      id: 1,
      heading: "State-of-the-Art Technology",
      text: "Utilizing the latest advancements in drone and AI technology.",
      buttonLabel: "Read More",
      modalContent: {
        // modalHeading: 'Modal Heading 1',
        modalText:
          "CloudCipher seamlessly integrates with existing workflows and software ecosystems, minimizing disruption and streamlining the adoption process. Whether migrating from another platform or implementing new security measures, CloudCipher ensures a smooth transition with minimal downtime.",
      },
    },
    {
      id: 2,
      heading: "Customized Solutions",
      text: "Tailoring services to meet the unique demands of different industries and projects.",
      buttonLabel: "Read More",
      modalContent: {
        modalHeading: "Modal Heading 2",
        modalText: "Modal Text 2",
      },
    },

    {
      id: 3,
      heading: "Data Accuracy and Security",
      text: "Emphasizing on precision data collection and secure data handling.",
      buttonLabel: "Read More",
      modalContent: {
        modalHeading: "Modal Heading 3",
        modalText: "Modal Text 3",
      },
    },

    {
      id: 4,
      heading: "Expert Team",
      text: "Our team includes experienced pilots, data analysts, and technology experts.",
      buttonLabel: "Read More",
      modalContent: {
        modalHeading: "Modal Heading 4",
        modalText: "Modal Text 4",
      },
    },

    {
      id: 5,
      heading: "Compliance and Reliability",
      text: "Ensuring full compliance with regulations and providing reliable, efficient solutions.",
      buttonLabel: "Read More",
      modalContent: {
        modalHeading: "Modal Heading 5",
        modalText: "Modal Text 5",
      },
    },

    // {
    //   id: 6,
    //   heading: 'Sixth Slide',
    //   text: 'A different text for the second slide.',
    //   buttonLabel: 'Read More',
    //   modalContent: {
    //     modalHeading: 'Modal Heading 6',
    //     modalText: 'Modal Text 6',
    //   },
    // },

    // {
    //   id: 7,
    //   heading: 'Seventh Slide',
    //   text: 'A different text for the second slide.',
    //   buttonLabel: 'Read More',
    //   modalContent: {
    //     modalHeading: 'Modal Heading 7',
    //     modalText: 'Modal Text 7',
    //   },
    // },

    // {
    //   id: 8,
    //   heading: 'Eighth Slide',
    //   text: 'A different text for the second slide.',
    //   buttonLabel: 'Read More',
    //   modalContent: {
    //     modalHeading: 'Modal Heading 8',
    //     modalText: 'Modal Text 8',
    //   },
    // },
    // ... (more slides)
  ];


  let items = [
    {
      title: "Q: How do drone solutions enhance traditional survey methods?  ",
      content:
        "A: Drone solutions offer higher precision, faster data collection, and access to difficult terrains, making them superior to traditional methods.  ",
    },
    {
      title: "Q: Can your drone technology be customized for specific project needs?  ",
      content:
        " A: Yes, we tailor our drone solutions based on the specific requirements and objectives of your project.  ",
    },
    {
      title: "Q: How do you ensure the security and privacy of data collected by drones? ",
      content:
        "A: We employ advanced encryption and blockchain technology to ensure the highest levels of data security and privacy.  ",
    },
   
    
   
  ];


  const openModal = (slide) => {
    setSelectedSlide(slide);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  // Handle clicks outside the modal to close it
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        closeModal();
      }
    };

    window.addEventListener("mousedown", handleOutsideClick);

    return () => {
      window.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const modalContent = (
    <>
      <p className="modal-np">The Purpose Behind Our Managed Drone Solutions</p>
      At Digitec Hub, our Drone Solutions are at the forefront of technological
      innovation, designed to revolutionize aerial surveys, surveillance, and
      data collection. By harnessing the power of advanced drones, we bring
      unparalleled precision, efficiency, and insight to your projects and
      operations.
      <hr />
      <br /> <strong>Addressing Diverse Industry Needs</strong>
      Different industries have unique requirements when it comes to drone-based
      solutions. For instance, environmental organizations may be interested in
      carbon credit surveys to assess carbon sequestration in forests, while
      government agencies may require surveillance capabilities for law
      enforcement or border security. By offering a diverse range of services,
      the company can cater to a broad spectrum of industry needs.
      <br />
      <br />
      <strong> Highlighting Expertise and Capabilities</strong>
      By showcasing a range of specialized solutions, the company demonstrates
      its expertise and capabilities in leveraging drone technology for diverse
      applications. This helps attract clients who have specific needs in areas
      such as environmental monitoring, spatial analysis, or security.
      <br />
      <br />
      <strong>Stressing Intelligence and Efficiency</strong>
      By stating that their services are not merely about aerial data collection
      but also about providing intelligent, secure, and highly efficient
      solutions, the company emphasizes its commitment to delivering value
      beyond basic data acquisition. This implies that the company's offerings
      are designed to provide actionable insights, streamline processes, and
      maximize efficiency for its clients.
      {/* Your dynamic content here */}
      {/* You can pass any JSX content you want */}
    </>
  );

  return (
    <div>
      <div id="maindivs">
        <div id="carouselExampleCaptions" className="carousel slide">
          <div className="carousel-inner">
            <div className="carousel-item active">
              <img
                src="/Images/dronebgfinal.png"
                className="d-block w-100"
                style={{ height: "auto",opacity:"0.6" }}
                alt="slider first"
              />
              <div className="carousel-caption d-md-block">
                <div className="component-1 text-dark">Drone Solutions </div>
                <div
                  className="component-paras"
                  style={{
                    color: "black",
                    marginTop: "-20px",
                    fontStyle: "italic",
                    fontWeight:"700"
                  }}
                >
                  "Redefining Perspectives – Advanced Drone Solutions for a New
                  Era"
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container-np2">
          {/* <!-- section2 title --> */}
          <div className="cont2-titles">
            {/* <div class="cont2-titletext">What is Drone Solutions?</div> */}
          </div>

          {/* <!-- section part --> */}
          <div class="container21">
            {/* <!-- left part --> */}

            <div className="cont2-left cont2-text">
              <div
                className="left-text"
                style={{
                  // fontFamily: "arial",

                  fontFamily: "system-ui",
                }}
              >
                Drone Solutions encompass a comprehensive suite of services
                utilizing cutting-edge drone technology. We offer specialized
                solutions ranging from carbon credit surveys and geospatial
                analysis to infrared surveys and government surveillance.
                Integrating technologies like Generative AI and Blockchain, our
                services are not just about aerial data collection but about
                providing intelligent, secure, and highly efficient drone-based
                solutions.
              </div>
            </div>

            {/* right part */}
            <div className="cont2-right cont2-text">
              <div className="right-text">
                <img
                  class="content2img"
                  src="/Images/drone.gif"
                  alt="Description of the image"
                />
              </div>
            </div>
          </div>
          <div class="readmore">
            <ReadModal2 buttonText="Read More" modalContent={modalContent} />
          </div>
        </div>

        {/* section3 */}
        <section class="section3">
          <div class="cont2-title title ">Our Features</div>

          <div class="section3parts">
            <div class="content-np item31">
              <div>
                <strong
                  style={{
                    paddingLeft: "15px",
                  }}
                >
                  {" "}
                  Drone-Based Carbon Credit Survey
                </strong>
                <p class="aligned-paragraph item3text">
                  Utilizing drones for precise carbon stock assessment in
                  forestry and agriculture.
                </p>
              </div>
            </div>

            <div class="content-np item322">
              <strong class="item32-heading item31-conent"style={{
                 paddingLeft:'15px',fontSize:'18px'
                }} >
              Geospatial Surveys
    
              </strong>
              <div class=" item31-conent-img  "></div>
              <p class="aligned-paragraph item3text" style={{ color: "black" }}>
                Advanced drones equipped for detailed geospatial mapping and
                land surveying.
              </p>
            </div>

            <div class="content-np item33">
              <strong class=""style={{
                 paddingLeft:'15px'
                }} > Infrared (IR) Surveys with Drones
    </strong>
              <p class="aligned-paragraph item3text">
                Implementing IR technology for inspections, search and rescue
                operations, and more.
              </p>
              {/* <div class="item32-button"><button class="item32-button">Read more</button></div> */}
            </div>

            <div class="content-np item34">
              <strong
                style={{
                  paddingLeft: "15px",
                }}
              >
                Generative AI-Based Drone Technology
              </strong>
              <p class="aligned-paragraph item3text" style={{ color: "black" }}>
                Leveraging AI for enhanced data analysis, predictive
                maintenance, and automated flight patterns.
              </p>
            </div>
            <div class="content-np item35">
              <strong
                style={{
                  paddingLeft: "15px",
                }}
              >
                {" "}
                Blockchain Integrated Drone Technology
              </strong>
              <p class="aligned-paragraph item3text">
                Ensuring secure and transparent data collection for carbon
                credits and survey data.
              </p>
            </div>

            <div class="content-np item36">
              <strong
                style={{
                  paddingLeft: "15px",
                }}
              >
                Government and Military Surveillance Systems
              </strong>
              <p class="aligned-paragraph item3text">
                Providing high-security drone solutions for government and
                military surveillance needs.
              </p>
            </div>

            <div class="content-np item37">
              <strong
                style={{
                  paddingLeft: "15px",
                }}
              >
                Collective Drone Work
              </strong>
              <p class="aligned-paragraph item3text" style={{ color: "black" }}>
                Implementing swarm technology for coordinated drone operations
                in various applications.
              </p>
            </div>
          </div>
          {/* <div class="readmore">
            <button class="read1">Read More</button>
          </div> */}
        </section>

        <div class="section4">
          {/* <div className='sliders'> */}
          {/* Previous and Next buttons */}
          {/* <button className="nav-button-slider prev" onClick={() => sliderRef.current?.slickPrev()}>
    &lt;
  </button>
  <button className="nav-button-slider next" onClick={() => sliderRef.current?.slickNext()}>
    &gt;
  </button> */}
          {/* Modal */}
          {/* {isModalOpen && selectedSlide && (
    <div className="modal-overlay" ref={modalRef}>
      <div className="modal-content-m" style={{ position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
       
        <div id="close-m" onClick={closeModal}>
          <i className="fas fa-times closeicon"></i>
        </div>
        <h2>{selectedSlide. modalContent.modalHeading}</h2>
        <p>{selectedSlide. modalContent.modalText}</p>
      </div>
    </div>
  )} */}

          {/* <div className="slidercontainer">
    <Slider ref={sliderRef} {...settings}>
      {slideData.map((slide) => (
        <div key={slide.id} className="slider-item">
          <div className="slidecontents">
            <h2 className='slideheading h2-np slidecontent'>{slide.heading}</h2>
            <p className='slidetext p-np slidecontent'>{slide.text}</p> */}
          {/* <button className='slidebutton read1' onClick={() => openModal(slide)}>
              {slide.buttonLabel}
            </button> */}
          {/* </div>
        </div>
      ))}
    </Slider>
  </div>
</div> */}
          <div className="main-cont">
            <div className="Cardcomp">
              <div className="card-container">
                <div className="row-ca">
                  <div className="col-ca">
                    <div className="card-head text-center">
                      <h2 className="head-t ">USP of Drone Services</h2>
                      {/* <p className="t-color">
                    Delivering domain expertise and technology-driven offerings
                    to help you turn digital challenges into opportunities.
                  </p> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card-container">
              <div className="row-ck">
                <div className="col-ck">
                  <div className="card-wrapper">
                    <div className="main-card">
                      <div className="innercon">
                        <div className="card-content1">
                          <h2>
                            State-of-the-Art Technology{" "}
                            <FontAwesomeIcon icon={faArrowRight} />
                          </h2>
                          {/* <p>vbhf</p> */}
                        </div>
                        <img src="/Images/drone.gif" alt="" />
                      </div>
                    </div>
                    <div className="hover-card">
                      <div className="hover-content">
                        <h2>State-of-the-Art Technology </h2>
                        <p>
                          Utilizing the latest advancements in drone and AI
                          technology.{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-ck">
                  <div className="card-wrapper">
                    <div className="main-card">
                      <div className="innercon">
                        <div className="card-content1">
                          <h2>
                            Customized Solutions
                            <FontAwesomeIcon icon={faArrowRight} />{" "}
                          </h2>
                          {/* <p>vbhf</p> */}
                        </div>
                        <img src="/Images/hand.gif" alt="" />
                      </div>
                    </div>
                    <div className="hover-card">
                      <div className="hover-content">
                        <h2>Customized Solutions</h2>
                        <p>
                          {" "}
                          Tailoring services to meet the unique demands of
                          different industries and projects.{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-ck">
                  <div className="card-wrapper">
                    <div className="main-card">
                      <div className="innercon">
                        <div className="card-content1">
                          <h2>
                            Data Accuracy and Security{" "}
                            <FontAwesomeIcon icon={faArrowRight} />
                          </h2>
                          {/* <p>vbhf</p> */}
                        </div>
                        <img src="/Images/shield.gif" alt="" />
                      </div>
                    </div>
                    <div className="hover-card">
                      <div className="hover-content">
                        <h2>Data Accuracy and Security</h2>
                        <p>
                          Emphasizing on precision data collection and secure
                          data handling.{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-ck">
                  <div className="card-wrapper">
                    <div className="main-card">
                      <div className="innercon">
                        <div className="card-content1">
                          <h2>
                            Expert Team <FontAwesomeIcon icon={faArrowRight} />
                          </h2>
                          {/* <p>vbhf</p> */}
                        </div>
                        <img src="/Images/diagram.png" alt="" />
                      </div>
                    </div>
                    <div className="hover-card">
                      <div className="hover-content">
                        <h2>Expert Team</h2>
                        <p>
                          Our team includes experienced pilots, data analysts,
                          and technology experts.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-ck">
                  <div className="card-wrapper">
                    <div className="main-card">
                      <div className="innercon">
                        <div className="card-content1">
                          <h2>
                            Compliance and Reliability
                            <FontAwesomeIcon icon={faArrowRight} />
                          </h2>
                          {/* <p>vbhf</p> */}
                        </div>
                        <img src="/Images/steps.gif" alt="" />
                      </div>
                    </div>
                    <div className="hover-card">
                      <div className="hover-content">
                        <h2>Compliance and Reliability</h2>
                        <p>
                          Ensuring full compliance with regulations and
                          providing reliable, efficient solutions.{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-ck">
                  <div className="card-wrapper">
                    <div className="main-card">
                      <div className="innercon">
                        <div className="card-content1">
                          <h2>
                            Comprehensive Risk Management{" "}
                            <FontAwesomeIcon icon={faArrowRight} />
                          </h2>
                          {/* <p>vbhf</p> */}
                        </div>
                        <img src="/Images/social-care.gif" alt="" />
                      </div>
                    </div>
                    <div className="hover-card">
                      <div className="hover-content">
                        <h2>Comprehensive Risk Management </h2>
                        <p>
                          {" "}
                          Our team emphasizes data accuracy and security,
                          ensuring that sensitive information is collected,
                          processed, and stored securely.{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <div className='col-ck'>
        <div className="card-wrapper">
            <div className="main-card">
            <div className="innercon">
                  <div className="card-content1">
                    <h2>cvgd <FontAwesomeIcon icon={faArrowRight} /></h2>
                    
                  </div>
                  <img src="/Images/cybercardico.svg" alt="" />
                </div>
            </div>
            <div className="hover-card">
                <div className="hover-content">
                <h2>bhhgjfx</h2>
                    <p>vndxhdmnhit bnxilhk cvbnrildh</p>
                    
                </div>
            </div>
        </div>
        </div>
        <div className='col-ck'>
        <div className="card-wrapper">
        <div className="main-card">
        <div className="innercon">
                  <div className="card-content1">
                    <h2>cvgd <FontAwesomeIcon icon={faArrowRight} /></h2>
                    
                  </div>
                  <img src="/Images/cybercardico.svg" alt="" />
                </div>
        </div>
        <div className="hover-card">
            <div className="hover-content">
            <h2>bhhgjfx</h2>
            <p>vndxhdmnhit bnxilhk cvbnrildh</p>
                    
            </div>
        </div>
    </div>
    </div> */}
                {/* <div className='col-ck'>
    <div className="card-wrapper">
        <div className="main-card">
        <div className="innercon">
                  <div className="card-content1">
                    <h2>cvgd <FontAwesomeIcon icon={faArrowRight} /></h2>
                    
                  </div>
                  <img src="/Images/cybercardico.svg" alt="" />
                </div>
        </div>
        <div className="hover-card">
            <div className="hover-content">
              <h2>bhhgjfx</h2>
                   
              <p>vndxhdmnhit bnxilhk cvbnrildh</p>
                    
            </div>
        </div>
    </div>
    </div> */}
              </div>
            </div>
          </div>




          {/* <CarouselSlider/> */}
        </div>


        

        <div className="section5">
          <h1 className="section5heading">How We are Handling</h1>
          <div className="section5jsxcard">
            <CaseStudyCard
              imageUrl="/Images/casestudyimg1.jpg"
              title="Thorough Requirement Analysis"
              description="Conducting in-depth analyses to understand your project requirements and objectives. "
              // linkUrl="/platform/E-cart/casestudy-vitualwallet"
            />
            <CaseStudyCard
              imageUrl="/Images/casestudyimg2.jpg"
              title="Strategic Planning and Execution"
              description="Developing and executing a strategic plan tailored to your specific drone solution needs. "
              linkUrl=""
            />
            <CaseStudyCard
              imageUrl="/Images/casestudyimg3.jpg"
              title="Ongoing Support and Maintenance"
              description="Providing continuous support and maintenance for our drone technologies. "
              linkUrl=""
            />

            <CaseStudyCard
              imageUrl="/Images/casestudyimg4.jpg"
              title="Data Processing and Analysis"
              description="Offering comprehensive data processing and analytical services post-flight. "
              linkUrl=""
            />

            {/* <div class="readmore">
  <button class="read">show More</button>
  </div> */}

            {/* section 6 */}
          </div>
        </div>


        <section className="FAQ">
        <div className="FAQbody">
          <h1 className="section5heading text-dark" >FAQ's for this service</h1>
          <Accordion items={items} />
        </div>
      </section>


      </div>
    </div>
  );
}

export default DroneSolution;
