import "./template.css";

import { Link } from "react-router-dom";
import ReadModal2 from "./readMore.js";
import React, { useState, useRef, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CarouselSlider from "./Carousel.js";
import CaseStudyCard from "./CaseStudyCard.js";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

function OnlineAnalytics() {
  const [activeModal, setActiveModal] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);
  const sliderRef = useRef(null);
  const modalRef = useRef(null);
  const [selectedSlide, setSelectedSlide] = useState(null);
  const settings = {
    // dots: true,
    // infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },

      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },

      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 3000,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const slideData = [
    {
      id: 1,
      heading: "Enhanced SQL Editor with AI-Optimized Queries ",
      text: "Advanced SQL editor equipped with AI capabilities for optimizing queries and enhancing data exploration,AI suggestions for query improvement and error minimization. ",
      buttonLabel: "Read More",
      modalContent: {
        // modalHeading: 'Modal Heading 1',
        modalText:
          "CloudCipher seamlessly integrates with existing workflows and software ecosystems, minimizing disruption and streamlining the adoption process. Whether migrating from another platform or implementing new security measures, CloudCipher ensures a smooth transition with minimal downtime.",
      },
    },
    {
      id: 2,
      heading: "Enterprise-Level Scalability and Cloud Optimization ",
      text: "Scalable infrastructure capable of handling large datasets and high user concurrency,Cloud-optimized architecture for flexibility in deployment and scaling. ",
      buttonLabel: "Read More",
      modalContent: {
        modalHeading: "Modal Heading 2",
        modalText: "Modal Text 2",
      },
    },

    {
      id: 3,
      heading: "Security and Data Protection ",
      text: "Robust security framework with encryption, access controls, and comprehensive audit trails,Ensures data integrity and protection, particularly critical for sensitive CCTV and IoT data. ",
      buttonLabel: "Read More",
      modalContent: {
        modalHeading: "Modal Heading 3",
        modalText: "Modal Text 3",
      },
    },

    {
      id: 4,
      heading: "Extensive Database and Storage System Support ",
      text: "Supports a wide range of SQL and NoSQL databases and data warehouses,Facilitates a unified approach to data management across different storage systems. ",
      buttonLabel: "Read More",
      modalContent: {
        modalHeading: "Modal Heading 4",
        modalText: "Modal Text 4",
      },
    },

    {
      id: 5,
      heading: "Intuitive User Interface for All User Levels ",
      text: "User-friendly interface designed for simplicity and ease of use across all platform functionalities,Streamlines complex operations, making the platform accessible to a diverse user base. ",
      buttonLabel: "Read More",
      modalContent: {
        modalHeading: "Modal Heading 5",
        modalText: "Modal Text 5",
      },
    },

    // {
    //   id: 6,
    //   heading: 'Sixth Slide',
    //   text: 'A different text for the second slide.',
    //   buttonLabel: 'Read More',
    //   modalContent: {
    //     modalHeading: 'Modal Heading 6',
    //     modalText: 'Modal Text 6',
    //   },
    // },

    // {
    //   id: 7,
    //   heading: 'Seventh Slide',
    //   text: 'A different text for the second slide.',
    //   buttonLabel: 'Read More',
    //   modalContent: {
    //     modalHeading: 'Modal Heading 7',
    //     modalText: 'Modal Text 7',
    //   },
    // },

    // {
    //   id: 8,
    //   heading: 'Eighth Slide',
    //   text: 'A different text for the second slide.',
    //   buttonLabel: 'Read More',
    //   modalContent: {
    //     modalHeading: 'Modal Heading 8',
    //     modalText: 'Modal Text 8',
    //   },
    // },
    // ... (more slides)
  ];

  const openModal = (slide) => {
    setSelectedSlide(slide);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  // Handle clicks outside the modal to close it
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        closeModal();
      }
    };

    window.addEventListener("mousedown", handleOutsideClick);

    return () => {
      window.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const modalContent = (
    <>
      <p className="modal-np">The Purpose Behind Our Online Analytics </p>
      At Online Analytics, our vision is to transform the way businesses
      leverage data — driving innovation, efficiency, and growth through an
      integrated, AI-powered platform.
      <hr />
      <br /> <strong>Scalability</strong>
      Outsourcing enables rapid adjustment of operations to meet fluctuating
      demands without the burden of hiring and training internal staff.
      <br />
      <br />
      <strong> Access to Global Talent</strong>
      Outsourcing grants access to diverse talent worldwide, leveraging
      specialized skills and knowledge not easily found locally.
      <br />
      <br />
      <strong>Focus on Core Business Activities</strong>
      Delegating non-core functions allows organizations to dedicate resources
      to core activities, enhancing competitiveness and facilitating effective
      pursuit of growth opportunities.
      {/* Your dynamic content here */}
      {/* You can pass any JSX content you want */}
    </>
  );

  return (
    <div id="maindivs">
      <div id="carouselExampleCaptions" className="carousel slide">
        <div className="carousel-inner">
          <div className="carousel-item active">
            <img
              src="/Images/image (1).png"
              className="d-block w-100"
              style={{opacity:'0.5',height: "auto" }}
              alt="slider first"
            />
            <div className="carousel-caption  d-md-block">
              <div className="component-1 text-dark">Online Analytics</div>

              <div
                className="component-paras"
                style={{fontWeight:"700", color: "black", marginTop: "-20px" ,fontStyle:'italic'}}
              >
                "Empowering Data-Driven Decisions"
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-np2">
        {/* <!-- section2 title --> */}
        <div className="cont2-titles">
          {/* <div class="cont2-titletext">What is Online Analytics?</div> */}
        </div>

        {/* <!-- section part --> */}
        <div class="container21">
          {/* <!-- left part --> */}

          <div className="cont2-left cont2-text">
            <div
              className="left-text"
              style={{
                // fontFamily: "arial",

                fontFamily: "system-ui",
              }}
            >
              Online Analytics offers a unified platform for advanced data
              management, analysis, and security, designed to streamline
              operations across various industries.
            </div>
          </div>

          {/* right part */}
          <div className="cont2-right cont2-text">
            <div className="right-text">
              <img
                class="content2img"
                src="/Images/pie-chart (1).gif"
                alt="Description of the image"
              />
            </div>
          </div>
        </div>
        <div class="readmore">
          <ReadModal2 buttonText="Read More" modalContent={modalContent} />
        </div>
      </div>

      {/* section3 */}
      <section class="section3">
        <div class="cont2-title title ">Our Features</div>

        <div class="section3parts">
          <div class="content-np item31">
            <div>
              <strong style={{ paddingLeft: "20px" }}>
                {" "}
                Real-Time IoT and CCTV Integration
              </strong>
              <p
                class="aligned-paragraph item3text"
                style={{ marginBottom: "30px" }}
              >
                Seamlessly integrates with a wide range of IoT devices and CCTV
                systems for real-time data collection, monitoring, and visual
                analytics,Advanced object recognition technology in CCTV feeds,
                enabling detailed analytics based on the platform and domain
                selection.
              </p>
            </div>
          </div>

          <div class="content-np item322">
            <strong
              class="item32-heading item31-conent"
              style={{
                paddingLeft: "15px",
                color: "black",
              }}
            >
              Industrial and Automation System
            </strong>
            <div class=" item31-conent-img  "></div>
            <p
              class="aligned-paragraph item3text mt-1"
              style={{ color: "black" }}
            >
              Direct connections for efficient data acquisition.
            </p>
          </div>

          <div class="content-np item33">
            <strong
              class=""
              style={{
                paddingLeft: "15px",
              }}
            >
              {" "}
              Network Protocol Support
            </strong>
            <p class="aligned-paragraph item3text">
              Adaptability to multiple network protocols, guaranteeing seamless
              integration of various data sources. Enables reliable data
              transfer between various devices and systems.
            </p>
            {/* <div class="item32-button"><button class="item32-button">Read more</button></div> */}
          </div>

          <div class="content-np item34">
            <strong
              style={{
                paddingLeft: "15px",
              }}
            >
              Analytics and Monitoring Tools
            </strong>
            <p class="aligned-paragraph item3text" style={{ color: "black" }}>
              The system features advanced monitoring and analytical tools for
              comprehensive data analysis and visualization, along with
              customizable dashboards for tracking key performance indicators
              and operational metrics.
            </p>
          </div>
          <div class="content-np item35">
            <strong
              style={{
                paddingLeft: "15px",
              }}
            >
              {" "}
              Intelligent Alert and Notification System
            </strong>
            <p class="aligned-paragraph item3text">
              Configurable alerting mechanisms based on specific data events,
              patterns, or recognition events from CCTV analysis,Instant
              notification system for timely and appropriate responses to
              critical events.
            </p>
          </div>

          <div class="content-np item36">
            <strong
              style={{
                paddingLeft: "15px",
              }}
            >
              Automated Batch Processing and Task Scheduling
            </strong>
            <p class="aligned-paragraph item3text">
              Facilities for scheduling and automating data processing and
              analytical tasks,Efficient management of batch operations for
              optimized workflow.
            </p>
          </div>

          <div class="content-np item37">
            <strong
              style={{
                paddingLeft: "15px",
              }}
            >
              Dynamic Reminder and Task Management System
            </strong>
            <p class="aligned-paragraph item3text" style={{ color: "black" }}>
              Customizable reminder system integrated with task management
              functionalities,Seamlessly links with calendars and organizational
              tools for comprehensive task tracking.
            </p>
          </div>
        </div>
        {/* <div class="readmore">
          <button class="read1">Read More</button>
        </div> */}
      </section>

      <div class="section4">
          
          <div className="main-cont">
            <div className="Cardcomp">
              <div className="card-container">
                <div className="row-ca">
                  <div className="col-ca">
                    <div className="card-head text-center">
                      <h2 className="head-t ">USP of Online Analytics Platforms</h2>
                     
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card-container">
              <div className="row-ck">
                <div className="col-ck">
                  <div className="card-wrapper">
                    <div className="main-card">
                      <div className="innercon">
                        <div className="card-content1">
                          <h2>
                          Enhanced SQL Editor with AI-Optimized Queries{" "}
                            <FontAwesomeIcon icon={faArrowRight} />
                          </h2>
                          {/* <p>vbhf</p> */}
                        </div>
                        <img src="/Images/Seamless_Multi-Platform_Integration.gif" alt="" />
                      </div>
                    </div>
                    <div className="hover-card">
                      <div className="hover-content">
                        <h2> Enhanced SQL Editor with AI-Optimized Queries</h2>
                        <p>
                        Advanced SQL editor equipped with AI capabilities for optimizing queries and enhancing data exploration,AI suggestions for query improvement and error minimization.{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-ck">
                  <div className="card-wrapper">
                    <div className="main-card">
                      <div className="innercon">
                        <div className="card-content1">
                          <h2>
                          Enterprise-Level Scalability and Cloud Optimization
                            <FontAwesomeIcon icon={faArrowRight} />{" "}
                          </h2>
                          {/* <p>vbhf</p> */}
                        </div>

                        <img src="/Images/scalability.gif" alt="" />

                      </div>
                    </div>
                    <div className="hover-card">
                      <div className="hover-content">
                        <h2>Enterprise-Level Scalability and Cloud Optimization</h2>
                        <p>
                          {" "}
                          Scalable infrastructure capable of handling large datasets and high user concurrency,Cloud-optimized architecture for flexibility in deployment and scaling. {" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-ck">
                  <div className="card-wrapper">
                    <div className="main-card">
                      <div className="innercon">
                        <div className="card-content1">
                          <h2>
                          Security and Data Protection{" "}
                            <FontAwesomeIcon icon={faArrowRight} />
                          </h2>
                          {/* <p>vbhf</p> */}
                        </div>
                        <img src="/Images/Secure_Payment_Processing.gif" alt="" />
                      </div>
                    </div>
                    <div className="hover-card">
                      <div className="hover-content">
                        <h2>Security and Data Protection</h2>
                        <p>
                        Robust security framework with encryption, access controls, and comprehensive audit trails,Ensures data integrity and protection, particularly critical for sensitive CCTV and IoT data.{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-ck">
                  <div className="card-wrapper">
                    <div className="main-card">
                      <div className="innercon">
                        <div className="card-content1">
                          <h2>
                          Extensive Database and Storage System Support<FontAwesomeIcon icon={faArrowRight} />
                          </h2>
                          {/* <p>vbhf</p> */}
                        </div>
                        <img src="/Images/Engagement_and_Interaction.gif" alt="" />
                      </div>
                    </div>
                    <div className="hover-card">
                      <div className="hover-content">
                        <h2>Extensive Database and Storage System Support</h2>
                        <p>
                        Supports a wide range of SQL and NoSQL databases and data warehouses,Facilitates a unified approach to data management across different storage systems.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-ck">
                  <div className="card-wrapper">
                    <div className="main-card">
                      <div className="innercon">
                        <div className="card-content1">
                          <h2>
                          Intuitive User Interface for All User Levels
                            <FontAwesomeIcon icon={faArrowRight} />
                          </h2>
                          {/* <p>vbhf</p> */}
                        </div>
                        <img src="/Images/Performance_Optimization.gif" alt="" />
                      </div>
                    </div>
                    <div className="hover-card">
                      <div className="hover-content">
                        <h2> Intuitive User Interface for All User Levels</h2>
                        <p>
                        User-friendly interface designed for simplicity and ease of use across all platform functionalities,Streamlines complex operations, making the platform accessible to a diverse user base.{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-ck">
                  <div className="card-wrapper">
                    <div className="main-card">
                      <div className="innercon">
                        <div className="card-content1">
                          <h2>
                          Cost-effective Solutions{" "}
                            <FontAwesomeIcon icon={faArrowRight} />
                          </h2>
                          {/* <p>vbhf</p> */}
                        </div>
                        <img src="/Images/hand.gif" alt="" />
                      </div>
                    </div>
                    <div className="hover-card">
                      <div className="hover-content">
                        <h2>Cost-effective Solutions</h2>
                        <p>
                          {" "}
                          We offer cost-effective analytics solutions that deliver high ROI, ensuring that businesses get the most value from their investment in analytics.{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

               
                
              </div>
            </div>
          </div>




          {/* <CarouselSlider/> */}
        </div>


      <div className="section5">
        <h1 className="section5heading"> How we are Handling</h1>
        <div className="section5jsxcard">
          <CaseStudyCard
            imageUrl="/Images/casestudyimg1.jpg"
            title="Enhanced Security and Compliance"
            description="Strengthened data protection and streamlined compliance processes. "
           
          />
          <CaseStudyCard
            imageUrl="/Images/casestudyimg2.jpg"
            title="Personalized Customer Service"
            description="Improved customer satisfaction through personalized banking experiences. "
            linkUrl=""
          />
          <CaseStudyCard
            imageUrl="/Images/casestudyimg3.jpg"
            title="Reduced Fraud Risks"
            description="Effective real-time monitoring and detection of fraudulent activities. "
            linkUrl=""
          />

          <CaseStudyCard
            imageUrl="/Images/casestudyimg4.jpg"
            title="Strategic Business Insights"
            description="Data-driven insights leading to informed decision-making and competitive advantage. "
            linkUrl=""
          />

          {/* <div class="readmore">
  <button class="read">show More</button>
  </div> */}

          {/* section 6 */}
        </div>
      </div>
    </div>
  );
}

export default OnlineAnalytics;
