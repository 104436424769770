import React from "react";
import "./Smartcloud.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function Smartcloud() {
  return (
    <div>
      <div className="Smartcloud-container">SmartCloud</div>

      {/* card effect */}

      <div class="Smart-card-grid">
        {/* <!-- Card 1 --> */}
        <div class="Smart-card ">
          <div class="Smart-card-content">
            <h4 class="Smart-card-title">File Access and Syncing:</h4>
            <p class="Smart-card-description">
              1.Securely store your files, folders, contacts, photo galleries,
              and more.{" "}
            </p>
            <p class="Smart-card-description">
              2.Access your data from desktop machines, laptops, and mobile
              devices.{" "}
            </p>
            <p class="Smart-card-description">
              3.Synchronization between all devices ensures you always have the
              latest version of your files.{" "}
            </p>
          </div>
        </div>

        {/* <!-- Card 2 (Copy and paste more cards as needed) --> */}
        <div class="Smart-card ">
          <div class="Smart-card-content">
            <h4 class="Smart-card-title">Collaborative Editing:</h4>
            <p class="Smart-card-description">
              SmartCloudintegrates with Collabora Online or ONLYOFFICE to
              provide collaborative editing of Word, Excel, and PowerPoint
              files.{" "}
            </p>
          </div>
        </div>

        {/* <!-- Card 3 (Copy and paste more cards as needed) --> */}
        <div class="Smart-card ">
          <div class="Smart-card-content">
            <h4 class="Smart-card-title">Calendar:</h4>
            <p class="Smart-card-description">
              Share calendars with other users or groups and sync them with your
              devices.{" "}
            </p>
          </div>
        </div>

        {/* <!-- Card 4 (Copy and paste more cards as needed) --> */}
        <div class="Smart-card ">
          <div class="Smart-card-content">
            <h4 class="Smart-card-title">Contacts:</h4>
            <p class="Smart-card-description">Manage and sync your contacts. </p>
          </div>
        </div>

        {/* <!-- Card 5 (Copy and paste more cards as needed) --> */}
        <div class="Smart-card ">
          <div class="Smart-card-content">
            <h4 class="Smart-card-title">Tasks & Notes:</h4>
            <p class="Smart-card-description">
              Create, manage, and sync tasks and notes.{" "}
            </p>
          </div>
        </div>

        {/* <!-- Card 6 (Copy and paste more cards as needed) --> */}
        <div class="Smart-card ">
          <div class="Smart-card-content">
            <h4 class="Smart-card-title">SmartCloudTalk</h4>
            <p class="Smart-card-description">
              Offers video conferencing, screen sharing, chat, and more.{" "}
            </p>
          </div>
        </div>

        {/* <!-- Card 7 (Copy and paste more cards as needed) --> */}
        <div class="Smart-card ">
          <div class="Smart-card-content">
            <h4 class="Smart-card-title">Email:</h4>
            <p class="Smart-card-description">
              Integrated mail client for handling personal and professional
              emails.{" "}
            </p>
          </div>
        </div>

        {/* <!-- Card 8 (Copy and paste more cards as needed) --> */}
        <div class="Smart-card ">
          <div class="Smart-card-content">
            <h4 class="Smart-card-title">Security: </h4>
            <p class="Smart-card-description">1.End-to-end encryption. </p>
            <p class="Smart-card-description">2.Two-factor authentication. </p>
            <p class="Smart-card-description">3.Built-in ransomware protection. </p>
            <p class="Smart-card-description">
              4.Token-based authentication for shared links.{" "}
            </p>
            <p class="Smart-card-description">
              5.Machine learning-based suspicious login detection.{" "}
            </p>
          </div>
        </div>

        {/* <!-- Card 9 (Copy and paste more cards as needed) --> */}
        <div class="Smart-card ">
          <div class="Smart-card-content">
            <h4 class="Smart-card-title">Privacy: </h4>
            <p class="Smart-card-description">
              1.On-premise hosting ensures data remains under your control.{" "}
            </p>
            <p class="Smart-card-description">
              2.Privacy settings for shared files and folders.{" "}
            </p>
            <p class="Smart-card-description">3.GDPR compliance tools. </p>
          </div>
        </div>

        {/* <!-- Card 10 (Copy and paste more cards as needed) --> */}
        <div class="Smart-card ">
          <div class="Smart-card-content">
            <h4 class="Smart-card-title">Apps:</h4>
            <p class="Smart-card-description">
              A large library of apps to enhance functionality, from note-taking
              to music streaming. The SmartCloudApp Store offers various apps to
              extend the platform's capabilities.{" "}
            </p>
          </div>
        </div>

        {/* <!-- Card 11 (Copy and paste more cards as needed) --> */}
        <div class="Smart-card ">
          <div class="Smart-card-content">
            <h4 class="Smart-card-title">Workflow Management:</h4>
            <p class="Smart-card-description">
              Automate repetitive tasks and optimize the flow of data. .
            </p>
          </div>
        </div>

        {/* <!-- Card 12 (Copy and paste more cards as needed) --> */}
        <div class="Smart-card ">
          <div class="Smart-card-content">
            <h4 class="Smart-card-title">Data Backup and Versioning: </h4>
            <p class="Smart-card-description">
              1.Automatically backup and version files, allowing users to revert
              to previous states.{" "}
            </p>
            <p class="Smart-card-description">
              2.Deleted files are moved to trash, giving users an opportunity to
              restore accidentally deleted data.{" "}
            </p>
          </div>
        </div>

        {/* <!-- Card 13 (Copy and paste more cards as needed) --> */}
        <div class="Smart-card ">
          <div class="Smart-card-content">
            <h4 class="Smart-card-title">Federation: </h4>
            <p class="Smart-card-description">
              Connect with other SmartCloudservers, sharing files and folders
              across different installations.{" "}
            </p>
          </div>
        </div>

        {/* <!-- Card 14 (Copy and paste more cards as needed) --> */}
        <div class="Smart-card ">
          <div class="Smart-card-content">
            <h4 class="Smart-card-title">User and Group Management: </h4>
            <p class="Smart-card-description">
              1.Create different user accounts and groups.{" "}
            </p>
            <p class="Smart-card-description">
              2.Assign storage quotas, set group permissions, and more.{" "}
            </p>
          </div>
        </div>

        {/* <!-- Card 15 (Copy and paste more cards as needed) --> */}
        <div class="Smart-card ">
          <div class="Smart-card-content">
            <h4 class="Smart-card-title">Integration: </h4>
            <p class="Smart-card-description">
              1.Connect and integrate with third-party storage providers like
              Dropbox, Google Drive, and Amazon S3.{" "}
            </p>
            <p class="Smart-card-description">
              2.Integration with external user directories like LDAP or Active
              Directory.
            </p>
          </div>
        </div>

        {/* <!-- Card 16 (Copy and paste more cards as needed) --> */}
        <div class="Smart-card ">
          <div class="Smart-card-content">
            <h4 class="Smart-card-title">Mobile & Desktop Clients: </h4>
            <p class="Smart-card-description">
              Clients available for Android, iOS, Windows, macOS, and Linux.{" "}
            </p>
          </div>
        </div>

        {/* <!-- Card 17 (Copy and paste more cards as needed) --> */}
        <div class="Smart-card ">
          <div class="Smart-card-content">
            <h4 class="Smart-card-title">Photos and Media: </h4>
            <p class="Smart-card-description">
              View and organize your media collection, including photos and
              videos.{" "}
            </p>
          </div>
        </div>

        {/* <!-- Card 18 (Copy and paste more cards as needed) --> */}
        <div class="Smart-card ">
          <div class="Smart-card-content">
            <h4 class="Smart-card-title"></h4>
            <p class="Smart-card-description">
              Smart Cloud's, with the community and developers adding new
              features and improving existing ones.{" "}
            </p>
          </div>
        </div>

        {/* <!-- Add more cards here --> */}
      </div>

      {/* card effect end */}

      <div className="Smartcloud-main-description transperant">
        <div className="SMV">Introducing SmartCloud</div>

        <section className="SMV1">
          <div className="container SMV1_container">
            <div className="SVM2">
              <h4 className="SVM2head">
                The Ultimate Secure Collaboration Platform{" "}
              </h4>
              <p className="SVM2para">
                In a world where data breaches and privacy concerns dominate
                headlines, SmartCloud emerges as a beacon of security and
                functionality. We've brought together the best features of cloud
                storage and communication, offering a cohesive, secure, and
                user-friendly platform tailored for businesses and individuals
                who value their privacy.{" "}
              </p>
            </div>
          </div>
        </section>

        <div className="SMV">Why Choose SmartCloud? </div>

        <section class="smart-faqs">
          <div class="smart-container smart-faqs_container">
            <article className="smart-faq">
              <div className="smart-question_answer">
                <h4>1. Comprehensive File Management: </h4>
                <p>
                  -Seamless access, synchronization, and sharing of your files
                  across devices.{" "}
                </p>
                <p>
                  -Advanced backup and versioning ensure you never lose vital
                  data.{" "}
                </p>
              </div>
            </article>

            <article className="smart-faq">
              <div className="smart-question_answer">
                <h4>2. Enhanced Collaboration Tools: </h4>
                <p>-Collaborative document editing. </p>
                <p>
                  -Organization-level chat for seamless internal communication.{" "}
                </p>
                <p>
                  -Personal and group calendars, contacts management, and task
                  tracking.{" "}
                </p>
              </div>
            </article>

            <article className="smart-faq">
              <div className="smart-question_answer">
                <h4>3.SmartChat & Bot Integration:</h4>
                <p>
                  -Engage with advanced chatbots for swift support and queries.
                </p>
                <p>
                  -Personal and group chats, ensuring efficient communication.{" "}
                </p>
                <p>-Enhanced with end-to-end encryption for utmost privacy. </p>
              </div>
            </article>

            <article className="smart-faq">
              <div className="smart-question_answer">
                <h4>4. Ultra-Secure Communication:</h4>
                <p>
                  -Private VoIP calling: Connect without the fear of
                  eavesdropping.{" "}
                </p>
                <p>
                  -Organization-level chats: Keep internal conversations
                  guarded.
                </p>
                <p>
                  -Encrypted chat options: Every word you type is for your eyes
                  and your intended recipient only.{" "}
                </p>
              </div>
            </article>

            <article className="smart-faq">
              <div className="smart-question_answer">
                <h4>5. Advanced Security & Privacy: </h4>
                <p>-Ransomware protection and suspicious login detection. </p>
                <p>
                  -GDPR compliant with added privacy settings for data control.{" "}
                </p>
                <p>-Token-based authentication for added security layers. </p>
              </div>
            </article>

            <article className="smart-faq">
              <div className="smart-question_answer">
                <h4>6. Customizable & Extensible: </h4>
                <p>
                  -Enhance SmartCloud's capabilities with a myriad of extensions
                  and plugins.{" "}
                </p>
                <p>-Automate tasks and optimize data workflows.</p>
              </div>
            </article>

            <article className="smart-faq">
              <div className="smart-question_answer">
                <h4>7. On-the-Go Access: </h4>
                <p>
                  -Mobile applications ensure you stay connected and productive,
                  wherever you are.{" "}
                </p>
              </div>
            </article>
          </div>
        </section>

        {/* <!-- end smart-faqs --> */}

        <div className="SMV">
          Elevate Your Online Collaboration with SmartCloud{" "}
        </div>

        <section className="SM1">
          <div className="container SM1_container">
            <div className="SM2">
              <p>
                With SmartCloud, you're not just adopting a platform; you're
                embracing a secure future where collaboration is boundless, and
                privacy is a guarantee. Whether you're an enterprise seeking to
                enhance internal communication or an individual looking for a
                safe space online, SmartCloud is tailored to fit every need.{" "}
              </p>
            </div>
          </div>
        </section>

        <section className="SM1">
          <div className="container SM1_container">
            <p className="SM2para">
              Experience Modern Collaboration with Uncompromised Security Dive
              into the world of SmartCloud today and discover the new standard
              in online communication and collaboration.{" "}
            </p>
          </div>
        </section>
      </div>
    </div>
  );
}

export default Smartcloud;
