import React from 'react'
import './Gaming.css';

function Gaming() {
 

  return (
    <div>
      <div className='Ecommerece-headingss'> Gaming and VR</div>
      <h2 style={{paddingLeft:'7%',paddingTop:'20px'}}>Gaming for Learning - Interactive Solutions</h2>
   
     <p style={{paddingLeft:'7%'}}>At DigitechHub, we believe that learning can be both fun and effective. Our "Gaming for Learning" approach combines entertainment with knowledge, creating immersive experiences that engage and educate users across various industries.</p>
    
    <div className="grid-container" >
      
  <div className="left-column">
    <h3 style={{paddingLeft:'11%'}} >Gaming Development</h3>
    <p style={{paddingLeft:'11%'}}>We develop educational games that make learning enjoyable:</p>
    <ul style={{paddingLeft:'9%'}}>
      <li><strong>Interactive Storytelling:</strong> Engage users in educational narratives and adventures.</li>
      <li><strong>STEM Learning Games:</strong> Foster science, technology, engineering, and math education through games.</li>
      <li><strong>Language Learning:</strong> Enhance language skills through fun and interactive gameplay.</li>
      <li><strong>History and Culture:</strong> Explore historical periods and cultures through immersive experiences.</li>
    </ul>
  </div>
  <div className="right-column">
    <h3>Virtual Reality Models</h3>
    <p>We create virtual reality models for various industries:</p>
    <ul>
      <li><strong>Defense:</strong> Develop realistic VR models for military training and simulations.</li>
      <li><strong>Education and Training:</strong> Provide immersive learning experiences for schools, universities, and corporate training programs.</li>
      <li><strong>Interactive Engineering and Maintenance:</strong> Enable engineers and technicians to practice maintenance procedures in a risk-free virtual environment.</li>
    </ul>
  </div>
</div>



<div class="containerr" style={{paddingLeft:'7%'}}>
  <section class="team">
    <h2>Our Expert Team</h2>
    <p>Our team comprises passionate educators, skilled developers, and creative artists who understand the power of interactive learning. We are committed to creating engaging and informative experiences that empower learners across all ages.</p>
  </section>
  <section class="portfolio">
    <h2>Our Portfolio</h2>
    <p>Discover our portfolio showcasing how "Gaming for Learning" has transformed industries. From virtual reality simulations for defense to interactive education solutions, our projects demonstrate the fusion of entertainment and knowledge.</p>
   
  </section>
  <section class="contact">
    <h2>Contact Us</h2>
    <p>Ready to revolutionize learning through interactive gaming and virtual reality? Connect with us today, and let's embark on a journey where entertainment and knowledge come together for a brighter future!</p>
    {/* <p>&copy; 2023 [Your Company Name]. All rights reserved.</p> */}
  </section>
  
</div>
<p className="G"style={{paddingLeft:'39%'}}>&copy; 2023 DigitechHub. All rights reserved.</p>


</div>

       
   
   
  )
}

export default Gaming
