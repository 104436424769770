import React from 'react'
import './CreateSavingAccount.css';

function CreateSavingAccount() {
  return (
    <div>
              <div className='createsavingacc'> Create Accounts</div>
              <div style={{ position: 'relative', height: '650px' }}>
  <img
    src="https://media.istockphoto.com/id/1472818295/photo/striped-white-background-grunge-brush-stroke-light-gray-cracked-texture-dye-sparse-grayscale.webp?b=1&s=170667a&w=0&k=20&c=4LFBnSEF3Pmt6TreX0E3Vk1fdhfSg9j-pvlN3ptskYo="
    className="createimagess"
    alt="createimagess"
    style={{ height: '100%', width: '100%', objectFit: 'cover' }}
  />
  <div
    style={{
      position: 'absolute',
      marginTop: '-600px',
      marginLeft:'13.4%',
    
      fontSize: '35px',
      backgroundColor: 'rgba(255, 255, 255, 0.7)',
      borderRadius: '5px',
      animation: 'fadeInDown 2s ease forwards',
      fontFamily: 'tungstenw05-medium, Oswald, sans-serif',
    }}
  >
    Create Saving Account
  </div>
  <ul
    style={{
      position: 'absolute',
     marginTop:'-500px',
     marginLeft:'13.4%',
      transform: 'translate(-50%, -50%)',
      backgroundColor: 'rgba(255, 255, 255, 0.7)',
      padding: '20px',
      borderRadius: '5px',
      
      animation: 'fadeInDown 2s ease forwards',
    }}
  >
    <li>
      <i className="fas fa-file-alt" style={{ marginRight: '10px' }}></i>
      Start process to open Savings account
    </li>
    <li>
      <i className="fas fa-list" style={{ marginRight: '10px' }}></i>
      Select type of Savings account
    </li>
    <li>
      <i className="fas fa-upload" style={{ marginRight: '10px' }}></i>
      Upload national ID docs for KYC
    </li>
    <li>
      <i className="fas fa-user" style={{ marginRight: '10px' }}></i>
      Type in personal information
    </li>
    <li>
      <i className="fas fa-user-plus" style={{ marginRight: '10px' }}></i>
      Give details of nominee
    </li>
    <li>
      <i className="fas fa-video" style={{ marginRight: '10px' }}></i>
      Initiate video KYC process
    </li>
    <li>
      <i className="fas fa-check-circle" style={{ marginRight: '10px' }}></i>
      Upon successful completion, account is created
    </li>
  </ul>
</div>
<div
    style={{
      position: 'absolute',
      marginTop: '-600px',
      marginLeft:'60.4%',
      fontSize: '35px',
      backgroundColor: 'rgba(255, 255, 255, 0.7)',
      borderRadius: '5px',
      animation: 'fadeInDown 2s ease forwards',
      fontFamily: 'tungstenw05-medium, Oswald, sans-serif',
    }}
  >
    Create Personal Account
  </div>

  <ul
    style={{
      position: 'absolute',
      marginTop:'-500px',
      marginLeft:'54%',
      marginLeft:'60.4%',
       transform: 'translate(-50%, -50%)',
       backgroundColor: 'rgba(255, 255, 255, 0.7)',
       padding: '20px',
       borderRadius: '5px',
       animation: 'fadeInDown 2s ease forwards',
       marginBottom:'-20px'
    }}
  >
    <li>
    <i className="fas fa-file-alt" style={{ marginRight: '10px' }}></i>
      Select type of personal loan
    </li>
    <li>
    <i className="fas fa-list" style={{ marginRight: '10px' }}></i>
      Give details to check eligibility criteria for loan
    </li>
    <li>
    <i className="fas fa-calculator" style={{ marginRight: '10px' }}></i>
     Get eligible loan amount, tenure & EMI options.
    </li>
    <li>
    <i className="fas fa-video" style={{ marginRight: '10px' }}></i>
      Complete video KYC
    </li>
    <li>
    <i className="fas fa-pencil-alt" style={{ marginRight: '10px' }}></i>
     Fill in loan agreements & e-sign
    </li>
    <li>
    <i className="fas fa-check-circle" style={{ marginRight: '10px' }}></i>
      Upon successful completion, loan account is created
    </li>
    <li>
    <i className="fas fa-arrow-right" style={{ marginRight: '10px' }}></i>
     Initiate loan application process
    </li>
  </ul>
</div>
 


    
    
  )
}

export default CreateSavingAccount
