import "./template.css";
 
 import { Link } from "react-router-dom";
import ReadModal2 from "./readMore.js";
 import React, { useState, useRef, useEffect} from 'react';
 import Slider from 'react-slick';
 import 'slick-carousel/slick/slick.css';
 import 'slick-carousel/slick/slick-theme.css';
import CarouselSlider from "./Carousel.js";
import CaseStudyCard from "./CaseStudyCard.js";

function ConsultingServices() {



    const [activeModal, setActiveModal] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);
  const sliderRef = useRef(null);
  const modalRef = useRef(null);
  const [selectedSlide, setSelectedSlide] = useState(null);
  const settings = {
    // dots: true,
    // infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },

      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },

      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 3000,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
     
    ],
  };
  const slideData = [
    {
      id: 1,
      heading: 'Expert Team',
      text: 'Our consultants are industry veterans with extensive experience and knowledge. ',
      buttonLabel: 'Read More',
      modalContent: {
        // modalHeading: 'Modal Heading 1',
        modalText: 'CloudCipher seamlessly integrates with existing workflows and software ecosystems, minimizing disruption and streamlining the adoption process. Whether migrating from another platform or implementing new security measures, CloudCipher ensures a smooth transition with minimal downtime.',
      },
    },
    {
      id: 2,
      heading: 'Customized and Actionable Strategies',
      text: 'We focus on providing tailor-made, actionable strategies that can be implemented effectively.',
      buttonLabel: 'Read More',
      modalContent: {
        modalHeading: 'Modal Heading 2',
        modalText: 'Modal Text 2',
      },
    },
 
    {
      id: 3,
      heading: 'Holistic Approach',
      text: 'Our services cover a wide range of business aspects, offering a comprehensive consulting solution.',
      buttonLabel: 'Read More',
      modalContent: {
        modalHeading: 'Modal Heading 3',
        modalText: 'Modal Text 3',
      },
    },
 
    {
      id: 4,
      heading: 'Commitment to Results',
      text: 'We are dedicated to delivering results that drive growth and improve performance.',
      buttonLabel: 'Read More',
      modalContent: {
        modalHeading: 'Modal Heading 4',
        modalText: 'Modal Text 4',
      },
    },
 
    {
      id: 5,
      heading: 'Ongoing Support and Guidance',
      text: 'Beyond initial consulting, we offer ongoing support to ensure successful strategy implementation.',
      buttonLabel: 'Read More',
      modalContent: {
        modalHeading: 'Modal Heading 5',
        modalText: 'Modal Text 5',
      },
    },
 
    // {
    //   id: 6,
    //   heading: 'Sixth Slide',
    //   text: 'A different text for the second slide.',
    //   buttonLabel: 'Read More',
    //   modalContent: {
    //     modalHeading: 'Modal Heading 6',
    //     modalText: 'Modal Text 6',
    //   },
    // },
 
    // {
    //   id: 7,
    //   heading: 'Seventh Slide',
    //   text: 'A different text for the second slide.',
    //   buttonLabel: 'Read More',
    //   modalContent: {
    //     modalHeading: 'Modal Heading 7',
    //     modalText: 'Modal Text 7',
    //   },
    // },
 
    // {
    //   id: 8,
    //   heading: 'Eighth Slide',
    //   text: 'A different text for the second slide.',
    //   buttonLabel: 'Read More',
    //   modalContent: {
    //     modalHeading: 'Modal Heading 8',
    //     modalText: 'Modal Text 8',
    //   },
    // },
    // ... (more slides)
  ];
 
 
 
  const openModal = (slide) => {
    setSelectedSlide(slide);
    setModalOpen(true);
  };
 
  const closeModal = () => {
    setModalOpen(false);
  };
 
  // Handle clicks outside the modal to close it
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        closeModal();
      }
    };
 
    window.addEventListener('mousedown', handleOutsideClick);
 
    return () => {
      window.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);



  const modalContent = (
    <>
      <p className="modal-np" >The Purpose Behind Our Consulting Services</p>
      At Digitec Hub, our Consulting Services are aimed at empowering businesses to navigate complex challenges and seize opportunities for growth. We bring a wealth of expertise and insights to help you make informed decisions and implement effective strategies. 
          <hr/>
           <br/> <strong>Leveraging Technology</strong> 
 
           Another purpose is to assist businesses in leveraging technology to achieve their strategic objectives. Consultants assess the organization's technology infrastructure, capabilities, and needs, and provide recommendations on adopting and integrating new technologies to drive innovation, improve customer experiences, and gain a competitive edge.
         <br/><br/>
        <strong> Optimizing Operations</strong>
         The primary purpose is to help businesses optimize their operations by identifying inefficiencies, streamlining processes, and improving productivity. Consultants analyze current workflows, systems, and practices to identify areas for improvement and implement solutions that enhance efficiency and effectiveness.
 
          <br/><br/><strong>Innovating Processes</strong> 
          Digitec Hub's Consulting Services aim to foster innovation by helping businesses innovate their processes, products, and services. Consultants facilitate ideation sessions, conduct market research, and develop strategies to drive innovation and create new revenue streams. This includes implementing agile methodologies, design thinking principles, and lean practices to foster a culture of innovation within the organization.
 
 
          
  
 
      {/* Your dynamic content here */}
      {/* You can pass any JSX content you want */}
    </>
 );


  return (

    <div>

<div id="maindivs">
        <div id="carouselExampleCaptions" className="carousel slide">
          <div className="carousel-inner">
            <div className="carousel-item active">
              <img
                src="/Images/firstcomponant.jpg"
                className="d-block w-100"
                style={{ height: "700px" }}
                alt="slider first"
              />
            <div className="carousel-caption d-md-block">
  <div className="component-1">Consulting Services</div>
  <div className="component-paras" style={{ color: "white", marginLeft: '20px', fontStyle: 'italic' }}>
    "Empowering Your Business Vision – Expert Consulting for Strategic Growth"
    
  </div>
</div>

            </div>
    
        </div>
        </div>
        
    
        <div className="container-np2">
          {/* <!-- section2 title --> */}
          <div className="cont2-titles">
            <div class="cont2-titletext">What is Consulting Services?</div>
            
          </div>
    
          {/* <!-- section part --> */}
          <div class="container21">
            {/* <!-- left part --> */}
    
            <div className="cont2-left cont2-text">
              <div
                className="left-text"
                style={{
                  // fontFamily: "arial",
                fontSize:"25px",
                  fontFamily: "system-ui",
                }}
              >
           Digitec Hub's Consulting Services encompass a comprehensive range of advisory and implementation solutions. Our focus is on helping businesses optimize operations, leverage technology, and innovate processes. From initial strategy development to execution, we provide expert guidance in various domains, including technology, management, marketing, and more, to ensure your business stays ahead in a competitive landscape. 
              </div>
            </div>
    
            {/* right part */}
            <div className="cont2-right cont2-text">
              <div className="right-text">
                <img
                  class="content2img"
                  src="\Images\consulting-def-.png"
                  alt="Description of the image"
                />
              </div>
            </div>
          </div>
          <div class="readmore">
            <ReadModal2 buttonText="Read More" modalContent={modalContent} />

            
          </div>
        </div>
    
        {/* section3 */}
        <section class="section3">
          <div class="cont2-title title ">Our Features</div>
    
          <div class="section3parts">
            <div class="content-np item31">
              <div>
                <strong style={{
                   paddingLeft:'15px'
                }}> Strategic Planning and Analysis
    </strong>
                <p class="aligned-paragraph item3text" style={{ marginBottom: "30px" }}>
                Offering in-depth market analysis, strategic planning, and business modeling to align your operations with long-term objectives. 
                </p>
              </div>
            </div>
    
            <div class="content-np item322">
              <strong class="item32-heading item31-conent"style={{
                  paddingLeft:'15px'
                }} >
              Operational Optimization
    
              </strong>
              <div class=" item31-conent-img  "></div>
              <p class="aligned-paragraph item3text"style={{color:'black'}}>
              Providing insights and strategies to enhance efficiency and streamline business processes. 
              </p>
            </div>
    
            <div class="content-np item33">
              <strong class="item32-headings"style={{
                 paddingLeft:'15px'
                }} > Technology Consulting
    </strong>
              <p class="aligned-paragraph item3text">
              Guiding businesses in the effective use of technology, from software selection to digital transformation strategies.
              </p>
              {/* <div class="item32-button"><button class="item32-button">Read more</button></div> */}
            </div>
    
            <div class="content-np item34" >
              <strong style={{
                  paddingLeft:'15px'
                }} >Marketing and Branding Strategy
     </strong>
              <p class="aligned-paragraph item3text" style={{color:'black'}}>
              Developing comprehensive marketing and branding strategies to strengthen market presence and customer engagement. 
              </p>
            </div>
            <div class="content-np item35">
              <strong style={{
                  paddingLeft:'15px'
                }}> Risk Management
     </strong>
              <p class="aligned-paragraph item3text">
              Assisting in identifying, assessing, and managing business risks to ensure sustainable growth

              </p>
            </div>
    
            <div class="content-np item36">
              <strong style={{
                  paddingLeft:'15px'
                }}>Customized Solutions
    </strong>
              <p class="aligned-paragraph item3text">
              Tailoring our consulting services to meet the unique needs and challenges of your business.
              </p>
            </div>
    
            <div class="content-np item37">
              <strong style={{
                   paddingLeft:'15px'
                }}>
                    Innovating Processes
    </strong>
              <p class="aligned-paragraph item3text" style={{color:'black'}}>
              Services aim to foster innovation by helping businesses innovate their processes, products, and services.
              </p>
            </div>
          </div>
          {/* <div class="readmore">
            <button class="read1">Read More</button>
          </div> */}
        </section>

        
    
        <div class="section4">


        
<div className='sliders'>
  {/* Previous and Next buttons */}
  <button className="nav-button-slider prev" onClick={() => sliderRef.current?.slickPrev()}>
    &lt;
  </button>
  <button className="nav-button-slider next" onClick={() => sliderRef.current?.slickNext()}>
    &gt;
  </button>
  {/* Modal */}
  {/* {isModalOpen && selectedSlide && (
    <div className="modal-overlay" ref={modalRef}>
      <div className="modal-content-m" style={{ position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
       
        <div id="close-m" onClick={closeModal}>
          <i className="fas fa-times closeicon"></i>
        </div>
        <h2>{selectedSlide. modalContent.modalHeading}</h2>
        <p>{selectedSlide. modalContent.modalText}</p>
      </div>
    </div>
  )} */}
 
  <div className="slidercontainer">
    <Slider ref={sliderRef} {...settings}>
      {slideData.map((slide) => (
        <div key={slide.id} className="slider-item">
          <div className="slidecontents">
            <h2 className='slideheading h2-np slidecontent'>{slide.heading}</h2>
            <p className='slidetext p-np slidecontent'>{slide.text}</p>
            {/* <button className='slidebutton read1' onClick={() => openModal(slide)}>
              {slide.buttonLabel}
            </button> */}
          </div>
        </div>
      ))}
    </Slider>
  </div>
</div>
 
     
          {/* <CarouselSlider/> */}
         
        </div>
    
        <div className="section5" >
    <h1 className="section5heading">How We are Handling</h1>
      <div className="section5jsxcard">
      <CaseStudyCard
        imageUrl="/Images/casestudyimg1.jpg"
        title="Initial Consultation and Discovery"
        description="Engaging with your team to understand your business, goals, and challenges.  "
        // linkUrl="/platform/E-cart/casestudy-vitualwallet"
      />
      <CaseStudyCard
        imageUrl="/Images/casestudyimg2.jpg"
        title="Implementation Support"
        description="Assisting in the implementation of strategies with continuous support and guidance. "
        linkUrl=""
      />
      <CaseStudyCard
        imageUrl="/Images/casestudyimg3.jpg"
        title="In-Depth Analysis and Strategy Development"
        description="Conducting thorough research and analysis to develop a robust strategy. "
        linkUrl=""
      />
     
      <CaseStudyCard
        imageUrl="/Images/casestudyimg4.jpg"
        title="Performance Monitoring and Adjustment"
        description="Monitoring the effectiveness of strategies and making necessary adjustments."
        linkUrl=""
      />
      
     
    {/* <div class="readmore">
  <button class="read">show More</button>
  </div> */}


{/* section 6 */}


  
    </div>
    </div> 
    
        
      </div>
      
    </div>
  )
}

export default ConsultingServices
