import React from 'react';
import './SaaSDevelopment.css'; // Import your CSS file
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUniversity, faHospital, faShoppingCart, faIndustry, faLaptop,faTruck } from "@fortawesome/free-solid-svg-icons";


function SaasDevelopment() {
    return (
      <div className="saas-development-container">
        <div className='saas-heading'>SaaS Development Support</div>
        <h2 className="sectionss-heading" style={{paddingLeft:'6.8%'}}> Empowering Your Business with Innovative Solutions</h2>
        <p className="section-description" style={{paddingLeft:'6.8%'}}>
          Welcome to DigitechHub, a leader in providing Software as a Service (SaaS) development and support. With a diverse portfolio of SaaS products, we are committed to transforming your business operations and driving growth through cutting-edge solutions.
        </p>
        <div className='subpoint'>Our SaaS Product Offerings </div>

        <div className="saas-products-container ">
       
      <div
        className="saas-card e-commerce-card transparent">
        <h3 style={{paddingLeft:'8.4%'}}>E-Commerce Platforms</h3>
        <p style={{paddingLeft:'8.4%'}}>Experience seamless online retail with our SaaS-based e-commerce platforms that facilitate easy product management, secure transactions, and engaging customer experiences.</p>
       
      </div>

      <div className="saas-card shopify-card transparent" style={{paddingLeft:'11.4%'}}>
        <h3>Shopify Application and Development Support</h3>
        <p>Elevate your Shopify store with customized applications that enhance functionality, user experience, and sales. We provide end-to-end development and support services.</p>
      </div>

      <div className="saas-card sap-card transparent"style={{paddingLeft:'10.4%'}} >
        <h3>SAP and MS Dynamics Integration</h3>
        <p>Seamlessly integrate your systems with SAP and Microsoft Dynamics to streamline processes, optimize data flow, and enhance overall business efficiency. </p>
      </div>

      <div className="saas-card cloud-card transparent" style={{paddingLeft:'11.4%'}}>
        <h3>Cloud Platform Developments</h3>
        <p>Leverage the power of cloud computing with our SaaS solutions built on industry-leading cloud platforms, ensuring scalability, security, and accessibility.</p>
      </div>
      <div className="saas-card googole-card transparent" style={{paddingLeft:'10.4%'}}>
        <h3> Google API Platform and Azure Development</h3>
        <p>Harness the capabilities of Google APIs and Microsoft Azure with our development expertise, creating solutions that leverage data, AI, and more. </p>
      </div>

      <div className="saas-card paltform-card transparent"style={{paddingLeft:'11.4%'}} >
        <h3>Platform API Integration and Development</h3>
        <p>We specialize in creating SaaS solutions that integrate with various APIs, enhancing functionality and providing seamless experiences. </p>
      </div>
      <div className="saas-card custom-card transparent" style={{paddingLeft:'10.4%'}}>
        <h3> Custom-Made ERP Solutions</h3>
        <p>Address your unique business needs with our custom ERP solutions, designed to streamline processes, improve data accuracy, and drive productivity. </p>
      </div>

      <div className="saas-card networking-card transparent" style={{paddingLeft:'11.4%'}}>
        <h3>Networking Software and Service Platforms</h3>
        <p>Our SaaS products in networking empower businesses with software and service solutions that optimize communication, collaboration, and connectivity. </p>
      </div>
        </div>
      <div className="why-choose-us">
      <h2>Why Choose DigitechHub for SaaS Development Support?</h2>
      <ul style={{paddingLeft:'13%',marginTop:'40px'}}>
        <li>
          <strong>Expertise:</strong> Our experienced team combines technical knowledge with industry insights to deliver solutions that meet your specific requirements.
        </li>
        <li>
          <strong>Innovation:</strong> We stay at the forefront of technology trends, ensuring that your SaaS products are equipped with the latest advancements.
        </li>
        <li>
          <strong>Customization:</strong> We understand that no two businesses are alike. Our solutions are tailor-made to match your unique needs and goals.
        </li>
        <li>
          <strong>Collaborative Approach:</strong> We believe in working closely with our clients to ensure that our solutions align with their vision and business objectives.
        </li>
        <li>
          <strong>End-to-End Support:</strong> From development and integration to ongoing support and updates, we provide comprehensive SaaS solutions.
        </li>
      </ul>
    </div>

    
    <div className="industries-we-serve">
        <h4 className='r1'>Industries We Use</h4>
        <div className="section-content">
  <ul className="simple-list" style={{paddingLeft:'13%'}}>
    <li>
      <FontAwesomeIcon icon={faUniversity} className="section-icons" />
      Finance and Banking
    </li>
    <li>
      <FontAwesomeIcon icon={faHospital} className="section-icons" />
      Healthcare
    </li>
    <li>
      <FontAwesomeIcon icon={faShoppingCart} className="section-icons" />
      E-commerce and Retail
    </li>
    <li>
      <FontAwesomeIcon icon={faIndustry} className="section-icons" />
      Manufacturing
    </li>
    <li>
      <FontAwesomeIcon icon={faLaptop} className="section-icons" />
      Technology and IT Services
    </li>
    <li>
      <FontAwesomeIcon icon={faTruck} className="section-icons" />
      Logistics and Supply Chain
    </li>
  </ul>
</div>





<div >
      <p className='digital-transformation' style={{paddingLeft:'13%'}}>
        Join us on a journey of digital transformation with our comprehensive range of SaaS products and development support.
        Contact us today to explore how our solutions can revolutionize your business operations and drive success.
      </p>
    </div>
   
        {/* Add the rest of your content here */}
        
      </div>
      
</div>
    );
  }
  
  export default SaasDevelopment;
  