import "./template.css";
 
 import { Link } from "react-router-dom";
import ReadModal2 from "./readMore.js";
 import React, { useState, useRef, useEffect} from 'react';
 import Slider from 'react-slick';
 import 'slick-carousel/slick/slick.css';
 import 'slick-carousel/slick/slick-theme.css';
import CarouselSlider from "./Carousel.js";

import Accordion from "./Accordion";
import "./Accordion.css";


import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

function AdmServices() {

  const [activeModal, setActiveModal] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);
  const sliderRef = useRef(null);
  const modalRef = useRef(null);
  const [selectedSlide, setSelectedSlide] = useState(null);
  const settings = {
    // dots: true,
    // infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },

      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },

      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 3000,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
     
    ],
  };
  const slideData = [
    {
      id: 1,
      heading: 'Expertise and Experience',
      text: 'Our team comprises highly skilled professionals with extensive experience in software development and management.',
      buttonLabel: 'Read More',
      modalContent: {
        // modalHeading: 'Modal Heading 1',
        modalText: 'CloudCipher seamlessly integrates with existing workflows and software ecosystems, minimizing disruption and streamlining the adoption process. Whether migrating from another platform or implementing new security measures, CloudCipher ensures a smooth transition with minimal downtime.',
      },
    },
    {
      id: 2,
      heading: 'Customized Solutions ',
      text: 'We prioritize understanding your business needs to deliver tailor-made solutions that drive value. ',
      buttonLabel: 'Read More',
      modalContent: {
        modalHeading: 'Modal Heading 2',
        modalText: 'Modal Text 2',
      },
    },
 
    {
      id: 3,
      heading: 'Quality and Reliability',
      text: 'Our commitment to quality and reliability ensures that we deliver high-performing, secure applications. ',
      buttonLabel: 'Read More',
      modalContent: {
        modalHeading: 'Modal Heading 3',
        modalText: 'Modal Text 3',
      },
    },
 
    {
      id: 4,
      heading: 'Agile and Flexible Approach',
      text: 'Our agile methodology allows us to adapt quickly to changes and deliver solutions in a timely manner. ',
      buttonLabel: 'Read More',
      modalContent: {
        modalHeading: 'Modal Heading 4',
        modalText: 'Modal Text 4',
      },
    },
 
    {
      id: 5,
      heading: 'Comprehensive Support',
      text: ' From initial consultation to post-launch support, we offer end-to-end services to ensure your complete satisfaction. "',
      buttonLabel: 'Read More',
      modalContent: {
        modalHeading: 'Modal Heading 5',
        modalText: 'Modal Text 5',
      },
    },
 
    // {
    //   id: 6,
    //   heading: 'Sixth Slide',
    //   text: 'A different text for the second slide.',
    //   buttonLabel: 'Read More',
    //   modalContent: {
    //     modalHeading: 'Modal Heading 6',
    //     modalText: 'Modal Text 6',
    //   },
    // },
 
    // {
    //   id: 7,
    //   heading: 'Seventh Slide',
    //   text: 'A different text for the second slide.',
    //   buttonLabel: 'Read More',
    //   modalContent: {
    //     modalHeading: 'Modal Heading 7',
    //     modalText: 'Modal Text 7',
    //   },
    // },
 
    // {
    //   id: 8,
    //   heading: 'Eighth Slide',
    //   text: 'A different text for the second slide.',
    //   buttonLabel: 'Read More',
    //   modalContent: {
    //     modalHeading: 'Modal Heading 8',
    //     modalText: 'Modal Text 8',
    //   },
    // },
    // ... (more slides)
  ];
 

  let items = [
    {
      title: "Q: What types of businesses can benefit from ADM Services?  ",
      content:
        "A: Businesses of all sizes and across various sectors can benefit from our ADM Services, especially those looking to leverage technology for growth and operational efficiency.  ",
    },
    {
      title: "Q: How do you ensure the security of the applications developed?",
      content:
        "A: We adhere to the best industry practices and standards for security, including regular audits, compliance checks, and implementing robust security protocols in our development process.  ",
    },
    {
      title: "Q: Can you handle high-volume, complex application development projects? ",
      content:
        "A: Yes, our team is equipped to manage large-scale, complex application development projects, ensuring timely delivery without compromising on quality.  ",
    },
   
    
   
  ];
 
 
  const openModal = (slide) => {
    setSelectedSlide(slide);
    setModalOpen(true);
  };
 
  const closeModal = () => {
    setModalOpen(false);
  };
 
  // Handle clicks outside the modal to close it
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        closeModal();
      }
    };
 
    window.addEventListener('mousedown', handleOutsideClick);
 
    return () => {
      window.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);



  const modalContent = (
    <>
      <p className="modal-np" >The Purpose Behind Our ADM Service </p>
      The purpose of Application Development & Managed Services is to provide clients with tailored software solutions to meet their specific business needs, while also offering ongoing support and maintenance to ensure the smooth operation and evolution of their applications.

         <hr/>
           <br/> <strong>Design, Development, and Implementation</strong> 
 
           Custom software solutions tailored to organizational needs enhance operational efficiency and competitiveness through ADM Services.
         <br/><br/>
        <strong>Application Maintenance</strong>
        Ongoing maintenance ensures software functionality, security, and performance, meeting evolving business demands.
 
          <br/><br/><strong>System Integration</strong> 
          Seamless communication between systems enhances operational efficiency and decision-making through ADM Services' system integration.
 
          <br/><br/><strong>Platform Upgrades</strong> 
          Keeping applications up-to-date with the latest technologies and standards improves features, security, and performance.
          
        
 
      {/* Your dynamic content here */}
      {/* You can pass any JSX content you want */}
    </>
  );



  return (
   
        <div id="maindivs">
        <div id="carouselExampleCaptions" className="carousel slide">
          <div className="carousel-inner">
            <div className="carousel-item active">
              <img
                src="/Images/Admservicesbgimages.jpg"
                className="d-block w-100"
                style={{ height: "auto",opacity:"0.35"  }}
                alt="slider first"
              />
              <div className="carousel-caption  d-md-block">
                <div className="component-1 text-dark">ADM Services </div>
              
                <div
                  className="component-paras"
                  style={{ color: "white", margin: "50px" }}
                >
                
                </div>
              </div>
            </div>
    
        </div>
        </div>
        
    
        <div className="container-np2">
          {/* <!-- section2 title --> */}
          <div className="cont2-titles">
            {/* <div class="cont2-titletext">What is ADM Service ? </div> */}
            
          </div>
    
          {/* <!-- section part --> */}
          <div class="container21">
            {/* <!-- left part --> */}
    
            <div className="cont2-left cont2-text">
              <div
                className="left-text"
                style={{
                  // fontFamily: "arial",
    
                  fontFamily: "system-ui",
                  fontSize:"22px"
                }}
              >
            Application development and managed services (ADM) encompass the process of designing, building, testing, deploying, and maintaining software applications for businesses. This comprehensive service includes the development of custom applications tailored to the client's requirements, as well as the ongoing management, monitoring, and support of these applications to ensure optimal performance, security, and scalability.

              </div>
            </div>
    
            {/* right part */}
            <div className="cont2-right cont2-text">
              <div className="right-text">
                <img
                  class="content2img"
                  src="/Images/applications.gif"
                  alt="Description of the image"
                />
              </div>
            </div>
          </div>
          <div class="readmore">
            <ReadModal2 buttonText="Read More" modalContent={modalContent} />

            
          </div>
        </div>
    
        {/* section3 */}
        <section class="section3">
          <div class="cont2-title title ">Our Features</div>
    
          <div class="section3parts">
            <div class="content-np item31" style={{ display: "flex", flexDirection: "column", justifyContent: "flex-end" }}>
              <div>
                <strong style={{ paddingLeft: '20px' }}> 
                 Custom Application Development
               </strong>
                <p class="aligned-paragraph item3text" style={{ marginBottom: "30px" }}>
                We design and develop software applications from scratch, ensuring they align perfectly with your business objectives and requirements.

 
                </p>
              </div>
            </div>
    
            <div class="content-np item322">
              <strong class="item32-heading item31-conent"style={{
                paddingLeft:'15px',color:'black'
              }} >
           Full Lifecycle Management
              </strong>
              <div class=" item31-conent-img  "></div>
              <p class="aligned-paragraph item3text mt-2" style={{color:'black'}}>
              Our service covers the entire software development lifecycle, from initial concept and design to deployment, maintenance, and ongoing support.


              </p>
            </div>
    
            <div class="content-np item33">
              <strong class="item32-heading"
                style={{
                paddingLeft:'15px',color:'white'
              }}> 
       Expertise Across Technologies
    </strong>
              <p class="aligned-paragraph item3text">
              Our team comprises skilled professionals with expertise across various technologies, ensuring that we can deliver solutions using the most suitable platforms and frameworks for your project.


              </p>
              {/* <div class="item32-button"><button class="item32-button">Read more</button></div> */}
            </div>
    
            <div class="content-np item34" >
              <strong style={{
                paddingLeft:'15px'
               }}>Scalability and Flexibility
     </strong>
              <p class="aligned-paragraph item3text" style={{color:'black'}}>
              We build applications that are scalable and flexible, capable of growing and adapting to meet your evolving business needs over time.

              </p>
            </div>
            <div class="content-np item35">
              <strong style={{
                paddingLeft:'15px'
             }}> Proactive Monitoring and Maintenance
     </strong>
              <p class="aligned-paragraph item3text">
              We provide proactive monitoring and maintenance services to ensure the continued smooth operation of your applications, identifying and addressing any issues before they impact your business.


              </p>
            </div>
    
            <div class="content-np item36">
              <strong style={{
                paddingLeft:'15px'
               }}>Security and Compliance
    </strong>
              <p class="aligned-paragraph item3text">
              Security is a top priority, and we implement robust security measures to protect your applications and data, ensuring compliance with relevant regulations and standards.

              </p>
            </div>
    
            <div class="content-np item37">
              <strong style={{
                paddingLeft:'15px'
               }}>Cost-Effective Solutions
    </strong>
              <p class="aligned-paragraph item3text" style={{color:'black'}}>
              Our goal is to deliver cost-effective solutions that provide maximum value for your investment, helping you achieve your business goals efficiently and within budget

              </p>
            </div>
          </div>
          {/* <div class="readmore">
            <button class="read1">Read More</button>
          </div> */}
        </section>

        
    
        <div class="section4">
          
          <div className="main-cont">
            <div className="Cardcomp">
              <div className="card-container">
                <div className="row-ca">
                  <div className="col-ca">
                    <div className="card-head text-center">
                      <h2 className="head-t ">USP of ADM Services</h2>
                     
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card-container">
              <div className="row-ck">
                <div className="col-ck">
                  <div className="card-wrapper">
                    <div className="main-card">
                      <div className="innercon">
                        <div className="card-content1">
                          <h2>
                          Tailored Solutions{" "}
                            <FontAwesomeIcon icon={faArrowRight} />
                          </h2>
                          {/* <p>vbhf</p> */}
                        </div>
                        <img src="/Images/Tailored_for_Small_and_Midsize_Tutors.gif" alt="" />
                      </div>
                    </div>
                    <div className="hover-card">
                      <div className="hover-content">
                        <h2>Tailored Solutions</h2>
                        <p>
                        We understand that every business is unique, which is why we focus on delivering tailored solutions that precisely meet your requirements and objectives. Whether you need a custom web application, mobile app, or enterprise software, we ensure that our solutions align perfectly with your business needs.
{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-ck">
                  <div className="card-wrapper">
                    <div className="main-card">
                      <div className="innercon">
                        <div className="card-content1">
                          <h2>
                          End-to-End Service
                            <FontAwesomeIcon icon={faArrowRight} />{" "}
                          </h2>
                          {/* <p>vbhf</p> */}
                        </div>
                        <img src="/Images/route.gif" alt="" />
                      </div>
                    </div>
                    <div className="hover-card">
                      <div className="hover-content">
                        <h2>End-to-End Service </h2>
                        <p>
                          {" "}
                          : From initial concept to deployment and ongoing maintenance, we provide comprehensive end-to-end services to streamline the entire development lifecycle. This holistic approach ensures continuity, efficiency, and accountability at every stage of your project.
{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-ck">
                  <div className="card-wrapper">
                    <div className="main-card">
                      <div className="innercon">
                        <div className="card-content1">
                          <h2>
                          Expertise Across Technologies{" "}
                            <FontAwesomeIcon icon={faArrowRight} />
                          </h2>
                          {/* <p>vbhf</p> */}
                        </div>
                        <img
                        className="img-ico"
                        src="/Images/organigram.gif"
                        alt=""
                      />
                      </div>
                    </div>
                    <div className="hover-card">
                      <div className="hover-content">
                        <h2>Expertise Across Technologies</h2>
                        <p>
                        Our team comprises seasoned professionals with expertise across a wide range of technologies, platforms, and frameworks. Whether you require expertise in Java, Python, .NET, or cloud technologies like AWS, Azure, or Google Cloud Platform, we have the skills and experience to deliver top-notch solutions.
 {" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-ck">
                  <div className="card-wrapper">
                    <div className="main-card">
                      <div className="innercon">
                        <div className="card-content1">
                          <h2>
                          Proactive Support and Maintenance<FontAwesomeIcon icon={faArrowRight} />
                          </h2>
                          {/* <p>vbhf</p> */}
                        </div>
                        <img src="/Images/diagram.gif" alt="" />
                      </div>
                    </div>
                    <div className="hover-card">
                      <div className="hover-content">
                        <h2>Proactive Support and Maintenance</h2>
                        <p>
                        We go beyond just development by offering proactive support and maintenance services to ensure the continued performance, security, and scalability of your applications. Our dedicated team monitors your systems, identifies potential issues, and implements timely updates and enhancements to keep your applications running smoothly.

                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-ck">
                  <div className="card-wrapper">
                    <div className="main-card">
                      <div className="innercon">
                        <div className="card-content1">
                          <h2>
                          Focus on Innovation
                            <FontAwesomeIcon icon={faArrowRight} />
                          </h2>
                          {/* <p>vbhf</p> */}
                        </div>
                        <img src="/Images/implementation.gif" alt="" />
                      </div>
                    </div>
                    <div className="hover-card">
                      <div className="hover-content">
                        <h2> Focus on Innovation</h2>
                        <p>
                        Innovation is at the heart of what we do. We continuously explore emerging technologies, trends, and best practices to incorporate the latest advancements into our solutions. By staying ahead of the curve, we help future-proof your applications and keep your business competitive in today's rapidly evolving digital landscape.


                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-ck">
                  <div className="card-wrapper">
                    <div className="main-card">
                      <div className="innercon">
                        <div className="card-content1">
                          <h2>
                           Transparent Communication and Collaboration{" "}
                            <FontAwesomeIcon icon={faArrowRight} />
                          </h2>
                          {/* <p>vbhf</p> */}
                        </div>
                        <img src="/Images/copywriting.gif" alt="" />
                      </div>
                    </div>
                    <div className="hover-card">
                      <div className="hover-content">
                        <h2> Transparent Communication and Collaboration</h2>
                        <p>
                          {" "}
                           We believe in fostering transparent communication and collaboration with our clients throughout the entire engagement. From project kickoff meetings to regular progress updates and feedback sessions, we keep you informed and involved every step of the way, ensuring alignment with your vision and expectations.




{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

               
                
              </div>
            </div>
          </div>




          {/* <CarouselSlider/> */}
        </div>
    
        {/* <div class="section5">
          <div class="section5heading">
            <div class="section5heading1">Our Case Studies</div>
          
          </div>
    
          <div class="section5cards">
            <div class="item51 item55">
              <div class="item5content">
                <div class="item51img">Expert Team</div>
    
                <div class="item51text item5text">
                  <a href="" class="full-width-link a-np">
                    Our team comprises experienced web designers, developers, and
                    UX/UI experts who excel at translating ideas into exceptional
                    websites
                  </a>
                </div>
              </div>
            </div>
    
            <div class="item52 item55">
              <div class="item5content">
                <div class="item52img">Scalability</div>
    
                <div class="item52text item5text">
                  <a href="" class="full-width-link a-np">
                    We build websites that are scalable and adaptable to
                    accommodate your business growth and changing requirements.
                  </a>
                </div>
              </div>
            </div>
    
            <div class="item53 item55">
              <div class="item5content">
                <div class="item53img">Responsive Design</div>
    
                <div class="item53text item5text">
                  <a href="" class="full-width-link a-np">
                    We ensure that your website looks and functions flawlessly on
                    desktops, tablets, and smartphones, providing a consistent
                    experience to users.
                  </a>
                </div>
              </div>
            </div>
    
            <div class="item54 item55">
              <div class="item5content">
                <div class="item54img">User-Centric Approach</div>
    
                <div class="item54text item5text">
                  <a href="" class="full-width-link a-np">
                    Our design and development process centers around the needs
                    and preferences of your target audience, ensuring a
                    user-focused website.
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div> */}


        {/* <div class="readmore">
      <button class="read">show More</button>
      </div> */}
    
    
    {/* section 6 */}
    
    <section className="FAQ">
        <div className="FAQbody">
          <h1 className="section5heading text-dark" >FAQ's for this service</h1>
          <Accordion items={items} />
        </div>
      </section>


      </div>
  )
}

export default  AdmServices
