import "./template.css";
import { Link } from "react-router-dom";
import CarouselSlider from "./Carousel.js";
import ReadModal2 from "./readMore.js";
import React, { useState, useRef, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CaseStudyCard1 from "./CaseStudyCard1.js";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

function Ecart() {
  const [activeModal, setActiveModal] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);
  const sliderRef = useRef(null);
  const modalRef = useRef(null);
  const [selectedSlide, setSelectedSlide] = useState(null);
  const settings = {
    // dots: true,
    // infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },

      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },

      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 3000,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const slideData = [
    {
      id: 1,
      heading: "Personalized Shopping Experience",
      text: "Tailoring product recommendations and offers based on user preferences and browsing history to enhance engagement and satisfaction.",
      buttonLabel: "Read More",
      modalContent: {
        modalHeading: "Modal Heading 1",
        modalText: "Modal Text 1",
      },
    },
    {
      id: 2,
      heading: "Seamless Multi-Platform Integration",
      text: "Providing a unified shopping experience across various devices and channels, including desktop, mobile, and social media platforms.",
      buttonLabel: "Read More",
      modalContent: {
        modalHeading: "Modal Heading 2",
        modalText: "Modal Text 2",
      },
    },

    {
      id: 3,
      heading: "Instant Checkout",
      text: "Offering a streamlined checkout process with one-click purchasing options, reducing cart abandonment rates and improving conversion rates",
      buttonLabel: "Read More",
      modalContent: {
        modalHeading: "Modal Heading 3",
        modalText: "Modal Text 3",
      },
    },

    {
      id: 4,
      heading: "24/7 Customer Support",
      text: "Providing round-the-clock customer service through live chat, email, or phone support to address inquiries and resolve issues promptly.",
      buttonLabel: "Read More",
      modalContent: {
        modalHeading: "Modal Heading 4",
        modalText: "Modal Text 4",
      },
    },

    {
      id: 5,
      heading: "Social Shopping Features",
      text: "Integrating social sharing, user-generated content, and influencer collaborations to create a vibrant community and enhance the shopping experience.",
      buttonLabel: "Read More",
      modalContent: {
        modalHeading: "Modal Heading 5",
        modalText: "Modal Text 5",
      },
    },

    // {
    //   id: 6,
    //   heading: 'Sixth Slide',
    //   text: 'A different text for the second slide.',
    //   buttonLabel: 'Read More',
    //   modalContent: {
    //     modalHeading: 'Modal Heading 6',
    //     modalText: 'Modal Text 6',
    //   },
    // },

    // {
    //   id: 7,
    //   heading: 'Seventh Slide',
    //   text: 'A different text for the second slide.',
    //   buttonLabel: 'Read More',
    //   modalContent: {
    //     modalHeading: 'Modal Heading 7',
    //     modalText: 'Modal Text 7',
    //   },
    // },

    // {
    //   id: 8,
    //   heading: 'Eighth Slide',
    //   text: 'A different text for the second slide.',
    //   buttonLabel: 'Read More',
    //   modalContent: {
    //     modalHeading: 'Modal Heading 8',
    //     modalText: 'Modal Text 8',
    //   },
    // },
    // ... (more slides)
  ];

  const openModal = (slide) => {
    setSelectedSlide(slide);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  // Handle clicks outside the modal to close it
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        closeModal();
      }
    };

    window.addEventListener("mousedown", handleOutsideClick);

    return () => {
      window.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const modalContent = (
    <>
      <p className="modal-np">The Purpose Behind Our Ecart Services </p>
      {/* At Digitec, we prioritize secure, efficient, and collaborative work environments. CloudCipher offers a full suite of cloud services tailored to your business needs, ensuring data sovereignty and fostering teamwork. */}
      {/* <hr/>Collaboration & Productivity: */}
      <hr />
      <br />
      <strong>All-In-One Platform </strong>
      From analytics to affiliate marketing, every feature is integrated
      seamlessly, allowing you to focus on what you do best - sell.
      <br />
      <br />
      <strong>Proactive User Management</strong>
      Monitor user activities, keep track of your best customers, and understand
      buying behavior – all in real-time.
      <br />
      <br />
      <strong> Data-Driven Insights</strong>
      With advanced reporting, analytics, and abandoned cart analysis, make
      informed decisions to propel your business forward
      <br />
      <br />
      <strong> Unparalleled Support</strong>
      Stuck somewhere? Chat with our support team anytime, anywhere. We're here
      to assist you every step of the way.
      {/* Your dynamic content here */}
      {/* You can pass any JSX content you want */}
    </>
  );

  return (
    <div id="maindivs">
      <div id="carouselExampleCaptions" className="carousel slide">
        <div className="carousel-inner">
          <div className="carousel-item active">
            <img
              src="/Images/ecartfinal.png"
              className="d-block w-100"
              style={{ height: "auto",opacity:"0.5" }}
              alt="slider first"
            />
            <div className="carousel-caption  d-md-block">
              <div className="component-1" style={{ color: "black" }}>E-cart</div>
              <div
                className="component-paras"
                style={{ color: "black", marginTop: "-20px", fontStyle: "italic",fontWeight:"700" }}
              >
                "The Ultimate eCommerce Solution for Modern Businesses"
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-np2">
        {/* <!-- section2 title --> */}
        <div className="cont2-titles">
          {/* <div class="cont2-titletext">What is E-cart?</div> */}
        </div>

        {/* <!-- section part --> */}
        <div class="container21">
          {/* <!-- left part --> */}

          <div className="cont2-left cont2-text">
            <div
              className="left-text"
              style={{
                // fontFamily: "arial",

                fontFamily: "system-ui",
              }}
            >
              Discover a world where cutting-edge technology meets eCart,
              introducing the Expert eCart Platform. Built for entrepreneurs by
              entrepreneurs, our platform encapsulates everything you'd ever
              need to run a successful online store and more.
            </div>
          </div>

          {/* right part */}
          <div className="cont2-right cont2-text">
            <div className="right-text">
              <img
                class="content2img"
                src="/Images/online-shopping.gif"
                alt="Description of the image"
              />
            </div>
          </div>
        </div>

        <div class="readmore">
          <ReadModal2 buttonText="Read More" modalContent={modalContent} />
          {/* <ReadModal1 /> */}

          <div>{/* Trigger/Open The Modal */}</div>
        </div>
      </div>

      {/* section3 */}
      <section class="section3">
        <div class="cont2-title title ">Our Features</div>

        <div class="section3parts">
          <div class="content-np item31">
            <div>
              <strong
                style={{
                  paddingLeft: "15px", fontSize:"22px",
                }}
              >
                Warehouse & Inventory Control
              </strong>
              <p class="aligned-paragraph item3text">
                Complete management of your warehouse, ensuring that you’re
                stocked up and ready to meet customer demands.
              </p>
            </div>
          </div>

          <div class="content-np item322">
            <strong
              class="item32-heading item31-conent"
              style={{
                paddingLeft: "15px",fontSize:"22px",
                color: "black",
              }}
            >
              Supply Chain Mastery
            </strong>
            <div class=" item31-conent-img  "></div>
            <p
              class="aligned-paragraph item3text mt-1"
              style={{ color: "black" }}
            >
              Integrate seamlessly with supply chain companies. Streamline
              processes, reduce costs, and enhance efficiency.
            </p>
          </div>

          <div class="content-np item33">
            <strong
              class=""
              style={{
                paddingLeft: "15px",
                fontSize:"22px",
              }}
            >
              {" "}
              Unified Communication
            </strong>
            <p class="aligned-paragraph item3text">
              From WhatsApp-like chat options to chatbots, stay connected with
              your customers like never before.
            </p>
            {/* <div class="item32-button"><button class="item32-button">Read more</button></div> */}
          </div>

          <div class="content-np item34">
            <strong
              style={{
                paddingLeft: "15px",
                fontSize:"22px",
              }}
            >
              Simplified Accounting
            </strong>
            <p class="aligned-paragraph item3text" style={{ color: "black" }}>
              Automatic sales and accounting integration ensures that you're
              always on top of your finances.
            </p>
          </div>
          <div class="content-np item35">
            <strong
              style={{
                paddingLeft: "15px",
                fontSize:"22px",
              }}
            >
              {" "}
              Marketing Tools
            </strong>
            <p class="aligned-paragraph item3text">
              Harness the power of email marketing, social media sign-ins, and
              bot-driven bookings to drive traffic and conversions.
            </p>
          </div>

          <div class="content-np item36">
            <strong
              style={{
                paddingLeft: "15px",
                fontSize:"22px",
              }}
            >
              Affiliate Opportunities
            </strong>
            <p class="aligned-paragraph item3text">
              Expand your reach with our in-built affiliate marketing tools,
              allowing you to collaborate with influencers and bloggers with
              ease.
            </p>
          </div>

          <div class="content-np item37">
            <strong
              style={{
                paddingLeft: "15px",
                fontSize:"22px",
              }}
            >
              Automated Reporting
            </strong>
            <p class="aligned-paragraph item3text" style={{ color: "black" }}>
              Scheduled broadcasting ensures that your team stays updated.
              Receive critical business reports right in your inbox.
            </p>
          </div>
        </div>
        {/* <div class="readmore">
          <button class="read1">Read More</button>
        </div> */}
      </section>



      <div class="section4">
          
          <div className="main-cont">
            <div className="Cardcomp">
              <div className="card-container">
                <div className="row-ca">
                  <div className="col-ca">
                    <div className="card-head text-center">
                      <h2 className="head-t ">USP of Ecart Platforms</h2>
                     
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card-container">
              <div className="row-ck">
                <div className="col-ck">
                  <div className="card-wrapper">
                    <div className="main-card">
                      <div className="innercon">
                        <div className="card-content1">
                          <h2>
                          Personalized Shopping Experience{" "}
                            <FontAwesomeIcon icon={faArrowRight} />
                          </h2>
                          {/* <p>vbhf</p> */}
                        </div>
                        <img src="/Images/Personalized_Shopping_Experience.gif" alt="" />
                      </div>
                    </div>
                    <div className="hover-card">
                      <div className="hover-content">
                        <h2> Personalized Shopping Experience </h2>
                        <p>
                        Tailoring product recommendations and offers based on user preferences and browsing history to enhance engagement and satisfaction.{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-ck">
                  <div className="card-wrapper">
                    <div className="main-card">
                      <div className="innercon">
                        <div className="card-content1">
                          <h2>
                          Seamless Multi-Platform Integration
                            <FontAwesomeIcon icon={faArrowRight} />{" "}
                          </h2>
                          {/* <p>vbhf</p> */}
                        </div>
                        <img src="/Images/Seamless_Multi-Platform_Integration.gif" alt="" />
                      </div>
                    </div>
                    <div className="hover-card">
                      <div className="hover-content">
                        <h2>Seamless Multi-Platform Integration</h2>
                        <p>
                          {" "}
                          Providing a unified shopping experience across various devices and channels, including desktop, mobile, and social media platforms.{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-ck">
                  <div className="card-wrapper">
                    <div className="main-card">
                      <div className="innercon">
                        <div className="card-content1">
                          <h2>
                          Instant Checkout{" "}
                            <FontAwesomeIcon icon={faArrowRight} />
                          </h2>
                          {/* <p>vbhf</p> */}
                        </div>
                        <img src="/Images/Instant_Checkout.gif" alt="" />
                      </div>
                    </div>
                    <div className="hover-card">
                      <div className="hover-content">
                        <h2>Instant Checkout</h2>
                        <p>
                        Offering a streamlined checkout process with one-click purchasing options, reducing cart abandonment rates and improving conversion rates.{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-ck">
                  <div className="card-wrapper">
                    <div className="main-card">
                      <div className="innercon">
                        <div className="card-content1">
                          <h2>
                          24/7 Customer Support<FontAwesomeIcon icon={faArrowRight} />
                          </h2>
                          {/* <p>vbhf</p> */}
                        </div>
                        <img src="/Images/24_Customer_Support.gif" alt="" />
                      </div>
                    </div>
                    <div className="hover-card">
                      <div className="hover-content">
                        <h2>24/7 Customer Support</h2>
                        <p>
                        Providing round-the-clock customer service through live chat, email, or phone support to address inquiries and resolve issues promptly.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-ck">
                  <div className="card-wrapper">
                    <div className="main-card">
                      <div className="innercon">
                        <div className="card-content1">
                          <h2>
                          Social Shopping Features
                            <FontAwesomeIcon icon={faArrowRight} />
                          </h2>
                          {/* <p>vbhf</p> */}
                        </div>
                        <img src="/Images/Social_Shopping_Features.gif" alt="" />
                      </div>
                    </div>
                    <div className="hover-card">
                      <div className="hover-content">
                        <h2>Social Shopping Features</h2>
                        <p>
                        Integrating social sharing, user-generated content, and influencer collaborations to create a vibrant community and enhance the shopping experience.{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-ck">
                  <div className="card-wrapper">
                    <div className="main-card">
                      <div className="innercon">
                        <div className="card-content1">
                          <h2>
                          Secure Payment Processing{" "}
                            <FontAwesomeIcon icon={faArrowRight} />
                          </h2>
                          {/* <p>vbhf</p> */}
                        </div>
                        <img src="/Images/Secure_Payment_Processing.gif" alt="" />
                      </div>
                    </div>
                    <div className="hover-card">
                      <div className="hover-content">
                        <h2>Secure Payment Processing </h2>
                        <p>
                          {" "}
                          Rest easy knowing that your customers' payment information is safe and secure, thanks to our state-of-the-art encryption technology.{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

               
                
              </div>
            </div>
          </div>




          {/* <CarouselSlider/> */}
        </div>






      <div className="section5">
        <h1 className="section5heading">Case Studies </h1>
        <div className="section5jsxcard-3">
          <CaseStudyCard1
            imageUrl="/Images/casestudyimg1.jpg"
            title="BharatMake.com"
            description="Revolutionizing E-commerce with E-cart Platform."
            linkUrl="/platform/E-cart/casestudt-bharatmake"
          />
          <CaseStudyCard1
            imageUrl="/Images/casestudyimg2.jpg"
            title="Digital Key Store"
            description="Revolutionizing Digital Software Distribution."
            linkUrl="/platform/E-cart/casestudy-digitalkeystore"
          />
          <CaseStudyCard1
            imageUrl="/Images/casestudyimg3.jpg"
            title=" Virtual Wallet for E-Cart"
            description=" The Virtual Wallet for E-Cart simplifies online shopping by securely storing payment methods and expediting transactions across different E-commerce platforms, enhancing convenience and security."
            linkUrl="/platform/E-cart/casestudy-vitualwallet"
          />
        </div>
      </div>
    </div>
  );
}

export default Ecart;
