import React from 'react'
import { Helmet } from 'react-helmet'
import Slider from './Slider'

export default function Investors() {
  return (
    <div>
      <Helmet>
        <title>Investors</title>
        <meta name='description' content='Investors page'></meta>
      </Helmet>
      <Slider/>
      <section className='section'>
        <div className='container'>
            <div className='row'>

            </div>

        </div>

      </section>
    </div>
  )
}
