import React, { useState } from 'react';
import './DropdownMenu.css'; // Import the CSS file for styling
import { Link } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  faArrowRight
} from '@fortawesome/free-solid-svg-icons';

const DropdownMenu2 = () => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);

  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const handleLinkClick = () => {
    setIsHovered(false);
  };


  return (
    <div
      className="dropdown"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <a className="ok">Services</a>
      {isHovered && (
        <div className="dropdown-content-2">

<h3 className='allplatforms'>
  All Services <FontAwesomeIcon icon={faArrowRight} />
</h3>

<hr className='linehr' />
           <li className='dard'>
            {/* <Link className='link-aj' to="/services/AdmServices">Application Development &  Managed(ADM) Services</Link>
             */}
            
            {/* <Link className='link-aj' to="/service/Cloudservices">Cloud Services</Link> */}

            <Link className='link-aj' to="/service/StafAugmentation"  onClick={handleLinkClick}>Staff Augumentation</Link>

            <Link className='link-aj' to="/service/IOTServices" onClick={handleLinkClick}>IOT </Link>

            {/* <Link className='link-aj' to="/service/GenAI">GenAI</Link> */}

           
            






<Link className='link-aj' to="/service/ManagedServices"  onClick={handleLinkClick}>Managed Services </Link>
<Link className='link-aj' to="/service/AI"  onClick={handleLinkClick}>Artificial Intelligence </Link>
{/* <Link className='link-aj' to="/solution/DroneSolution"  onClick={handleLinkClick}>Drones</Link> */}
<Link className='link-aj' to="/service/blockchain"  onClick={handleLinkClick}>Blockchain </Link>
<Link className='link-aj' to="/service/CyberSecurity1"  onClick={handleLinkClick}>Cyber Security </Link>


           

            {/* <Link className='link-aj' to="/service/OCRservice">OCR/Object Recognition </Link> */}

            {/* <Link className='link-aj' to="/service/ERPservice">ERP Services </Link> */}

           

           

            {/* <Link className='link-aj' to="/service/TestingandQA">Testing & QA</Link> */}
          
      
          
          </li>
          
        </div>
      )}
    </div>
  );
};

export default DropdownMenu2;