import "./template.css";

import { Link } from "react-router-dom";
import ReadModal2 from "./readMore.js";
import React, { useState, useRef, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CarouselSlider from "./Carousel.js";
import CaseStudyCard from "./CaseStudyCard.js";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faArrowRight } from "@fortawesome/free-solid-svg-icons";



function TestingandQA() {
  const [activeModal, setActiveModal] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);
  const sliderRef = useRef(null);
  const modalRef = useRef(null);
  const [selectedSlide, setSelectedSlide] = useState(null);
  const settings = {
    // dots: true,
    // infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 3000,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const slideData = [
    {
      id: 1,
      heading: "Expert Testing Team",
      text: "Our team comprises experienced and skilled QA professionals adept in the latest testing methodologies. ",
      buttonLabel: "Read More",
      modalContent: {
        // modalHeading: 'Modal Heading 1',
        // modalText: 'CloudCipher seamlessly integrates with existing workflows and software ecosystems, minimizing disruption and streamlining the adoption process. Whether migrating from another platform or implementing new security measures, CloudCipher ensures a smooth transition with minimal downtime.',
      },
    },
    {
      id: 2,
      heading: "Comprehensive Testing Approach",
      text: "Offering a thorough and holistic approach to testing, covering all aspects of software quality. ",
      buttonLabel: "Read More",
      //   modalContent: {
      //     modalHeading: 'Modal Heading 2',
      //     modalText: 'Modal Text 2',
      //   },
    },

    {
      id: 3,
      heading: "Cutting-Edge Tools and Technologies",
      text: "Utilizing the latest tools and technologies for efficient and effective testing. ",
      buttonLabel: "Read More",
      modalContent: {
        modalHeading: "Modal Heading 3",
        modalText: "Modal Text 3",
      },
    },

    {
      id: 4,
      heading: "Customized Service Delivery",
      text: "Tailoring our services to meet the unique needs and timelines of your projects. ",
      buttonLabel: "Read More",
      modalContent: {
        modalHeading: "Modal Heading 4",
        modalText: "Modal Text 4",
      },
    },

    {
      id: 5,
      heading: "Focus on Continuous Improvement",
      text: "Not just identifying issues, but also providing insights for continuous improvement of the software. ",
      buttonLabel: "Read More",
      modalContent: {
        modalHeading: "Modal Heading 5",
        modalText: "Modal Text 5",
      },
    },

    // {
    //   id: 6,
    //   heading: 'Sixth Slide',
    //   text: 'A different text for the second slide.',
    //   buttonLabel: 'Read More',
    //   modalContent: {
    //     modalHeading: 'Modal Heading 6',
    //     modalText: 'Modal Text 6',
    //   },
    // },

    // {
    //   id: 7,
    //   heading: 'Seventh Slide',
    //   text: 'A different text for the second slide.',
    //   buttonLabel: 'Read More',
    //   modalContent: {
    //     modalHeading: 'Modal Heading 7',
    //     modalText: 'Modal Text 7',
    //   },
    // },

    // {
    //   id: 8,
    //   heading: 'Eighth Slide',
    //   text: 'A different text for the second slide.',
    //   buttonLabel: 'Read More',
    //   modalContent: {
    //     modalHeading: 'Modal Heading 8',
    //     modalText: 'Modal Text 8',
    //   },
    // },
    // ... (more slides)
  ];

  const openModal = (slide) => {
    setSelectedSlide(slide);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  // Handle clicks outside the modal to close it
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        closeModal();
      }
    };

    window.addEventListener("mousedown", handleOutsideClick);

    return () => {
      window.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const modalContent = (
    <>
      <p className="modal-np">The Purpose Behind Our Managed Testing and QA</p>
      At Digitec Hub, our Testing and QA Services are dedicated to ensuring that
      every software solution meets the highest standards of quality and
      performance. We believe in delivering excellence in every project,
      ensuring your applications are reliable, efficient, and ready for the
      demands of the modern digital landscape.
      <hr />
      <br />
      1.Identifying and Preventing Defects <br />
      Managed testing and QA aim to identify defects, bugs, or issues within the
      software early in the development lifecycle. By catching these issues
      early, teams can prevent them from becoming more costly and time-consuming
      to fix later on in the development process or after deployment.
      <br />
      <br />
      2. Ensuring Functional Requirements <br />
      Testing verifies that the software meets its functional requirements,
      which are the intended behaviors and features specified by stakeholders.
      This ensures that the software behaves as expected and fulfills its
      intended purpose.
      <br />
      <br />
      3. Maintaining Software Quality <br /> Managed testing and QA processes
      establish and maintain a high level of software quality. This involves
      adherence to coding standards, best practices, and quality metrics to
      ensure that the software is reliable, robust, and scalable.
      <br />
      <br />
      4. Optimizing Resources and Costs:
      <br />
      Effective managed testing practices optimize resource utilization and
      reduce overall development costs. By automating repetitive testing tasks,
      prioritizing testing efforts, and streamlining processes, organizations
      can achieve faster time-to-market and improved return on investment.
      {/* Your dynamic content here */}
      {/* You can pass any JSX content you want */}
    </>
  );

  return (
    <div id="maindivs">
      <div id="carouselExampleCaptions" className="carousel slide">
        <div className="carousel-inner">
          <div className="carousel-item active">
            <img
              src="/Images/testingmainbg.png"
              className="d-block w-100"
              style={{ height: "auto" }}
              alt="slider first"
            />
            <div className="carousel-caption  d-md-block">
              <div className="component-1 text-white">Testing and QA</div>

              <div
                className="component-paras"
                style={{
                  color: "white",
                  marginTop: "-20px" ,
                  fontStyle: "italic",
                  fontWeight:"700"
                }}
              >
                "Ensuring Excellence at Every Step – Quality Delivered with
                Precision"
              </div>
            </div>
    </div>          
        </div>
      </div>

      <div className="container-np2">
        {/* <!-- section2 title --> */}
        <div className="cont2-titles">
          {/* <div class="cont2-titletext">What is Testing and QA?</div> */}
        </div>

        {/* <!-- section part --> */}
        <div class="container21">
          {/* <!-- left part --> */}

          <div className="cont2-left cont2-text">
            <div
              className="left-text"
              style={{
                // fontFamily: "arial",
               
                fontFamily: "system-ui",
              }}
            >
              Testing and Quality Assurance (QA) Services involve a
              comprehensive process of evaluating the functionality,
              performance, and security of software applications to ensure they
              meet specified requirements. Our services encompass a range of
              testing methodologies and practices, from automated testing to
              manual testing, and from performance testing to security audits.
              The goal is to identify and fix bugs, enhance performance, and
              ensure the software is of the highest quality before deployment.
            </div>
          </div>

          {/* right part */}
          <div className="cont2-right cont2-text">
            <div className="right-text">
            <img
                  class="content2img"
                  src="/Images/optometrist.gif"
                  alt="Description of the image"
                  
                />
            </div>
          </div>
        </div>
        <div class="readmore">
          <ReadModal2 buttonText="Read More" modalContent={modalContent} />
        </div>
      </div>

      {/* section3 */}
      <section class="section3">
        <div class="cont2-title title ">Our Features</div>

        <div class="section3parts">
          <div class="content-np item31" >
            <div>
              <strong
                style={{ paddingLeft: '20px' }}
              >
                Automated Testing Solutions
              </strong>
              <p class="aligned-paragraph item3text" style={{ marginBottom: "30px" }}>
                Implementing automated testing for efficient and accurate test
                execution.
              </p>
            </div>
          </div>

          <div class="content-np item322">
            <strong
              class="item32-heading item31-conent"
              style={{
                paddingLeft:'15px',color:'black',fontSize:'18px'
              }}
            >
              Manual Testing Expertise
            </strong>
            <div class=" item31-conent-img  "></div>
            <p class="aligned-paragraph item3text mt-1" style={{color:'black'}}>
              Providing meticulous manual testing to cover aspects automation
              can't.
            </p>
          </div>

          <div class="content-np item33">
            <strong
              class=""
              style={{
                paddingLeft:'15px'
              }}
            >
              {" "}
              Performance Testing
            </strong>
            <p class="aligned-paragraph item3text">
              Assessing software performance under various conditions to ensure
              robustness and scalability.
            </p>
            {/* <div class="item32-button"><button class="item32-button">Read more</button></div> */}
          </div>

          <div class="content-np item34">
            <strong
              style={{
                paddingLeft:'15px'
               }}
            >
              Security Audits and Testing
            </strong>
            <p class="aligned-paragraph item3text" style={{color:'black'}}>
              Conducting thorough security audits to identify vulnerabilities
              and enhance protection.
            </p>
          </div>
          <div class="content-np item35">
            <strong
              style={{
                paddingLeft:'15px'
             }}
            >
              {" "}
              Usability and Compatibility Testing
            </strong>
            <p class="aligned-paragraph item3text">
              Ensuring the software is user-friendly and compatible across
              multiple devices and platforms.
            </p>
          </div>

          <div class="content-np item36">
            <strong
              style={{
                paddingLeft:'15px'
             }}
            >
              Continuous Integration/Continuous Deployment (CI/CD) Integration
            </strong>
            <p class="aligned-paragraph item3text">
              Integrating testing into your CI/CD pipeline for ongoing quality
              assurance.
            </p>
          </div>

          <div class="content-np item37">
            <strong
              style={{
                paddingLeft:'15px'
             }}
            >
              Customized Testing Strategies
            </strong>
            <p class="aligned-paragraph item3text" style={{color:'black'}}>
              Developing tailor-made testing strategies that align with your
              specific project requirements.
            </p>
          </div>
        </div>
        {/* <div class="readmore">
            <button class="read1">Read More</button>
          </div> */}
      </section>

      <div class="section4">
          
          <div className="main-cont">
            <div className="Cardcomp">
              <div className="card-container">
                <div className="row-ca">
                  <div className="col-ca">
                    <div className="card-head text-center">
                      <h2 className="head-t ">USP of Testing & QA Services</h2>
                     
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card-container">
              <div className="row-ck">
                <div className="col-ck">
                  <div className="card-wrapper">
                    <div className="main-card">
                      <div className="innercon">
                        <div className="card-content1">
                          <h2>
                          Expert Testing Team{" "}
                            <FontAwesomeIcon icon={faArrowRight} />
                          </h2>
                          {/* <p>vbhf</p> */}
                        </div>
                        <img
                        className="img-ico"
                        src="/Images/organigram.gif"
                        alt=""
                      />
                      </div>
                    </div>
                    <div className="hover-card">
                      <div className="hover-content">
                        <h2> Expert Testing Team </h2>
                        <p>
                        Our team comprises experienced and skilled QA professionals adept in the latest testing methodologies. {" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-ck">
                  <div className="card-wrapper">
                    <div className="main-card">
                      <div className="innercon">
                        <div className="card-content1">
                          <h2>
                          Comprehensive Testing Approach
                            <FontAwesomeIcon icon={faArrowRight} />{" "}
                          </h2>
                          {/* <p>vbhf</p> */}
                        </div>
                        <img src="/Images/Seamless_Multi-Platform_Integration.gif" alt="" />
                      </div>
                    </div>
                    <div className="hover-card">
                      <div className="hover-content">
                        <h2>Comprehensive Testing Approach</h2>
                        <p>
                          {" "}
                          Offering a thorough and holistic approach to testing, covering all aspects of software quality. {" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-ck">
                  <div className="card-wrapper">
                    <div className="main-card">
                      <div className="innercon">
                        <div className="card-content1">
                          <h2>
                          Cutting-Edge Tools and Technologies{" "}
                            <FontAwesomeIcon icon={faArrowRight} />
                          </h2>
                          {/* <p>vbhf</p> */}
                        </div>
                        <img src="/Images/cutting-edge.gif" alt="" />
                      </div>
                    </div>
                    <div className="hover-card">
                      <div className="hover-content">
                        <h2>Cutting-Edge Tools and Technologies</h2>
                        <p>
                        Utilizing the latest tools and technologies for efficient and effective testing.{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-ck">
                  <div className="card-wrapper">
                    <div className="main-card">
                      <div className="innercon">
                        <div className="card-content1">
                          <h2>
                          Customized Service Delivery<FontAwesomeIcon icon={faArrowRight} />
                          </h2>
                          {/* <p>vbhf</p> */}
                        </div>
                        <img src="/Images/hand.gif" alt="" />
                      </div>
                    </div>
                    <div className="hover-card">
                      <div className="hover-content">
                        <h2>Customized Service Delivery</h2>
                        <p>
                        Tailoring our services to meet the unique needs and timelines of your projects.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-ck">
                  <div className="card-wrapper">
                    <div className="main-card">
                      <div className="innercon">
                        <div className="card-content1">
                          <h2>
                          Focus on Continuous Improvement
                            <FontAwesomeIcon icon={faArrowRight} />
                          </h2>
                          {/* <p>vbhf</p> */}
                        </div>
                        <img src="/Images/implementation.gif" alt="" />
                      </div>
                    </div>
                    <div className="hover-card">
                      <div className="hover-content">
                        <h2> Focus on Continuous Improvement</h2>
                        <p>
                        Not just identifying issues, but also providing insights for continuous improvement of the software.{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-ck">
                  <div className="card-wrapper">
                    <div className="main-card">
                      <div className="innercon">
                        <div className="card-content1">
                          <h2>
                          Automation{" "}
                            <FontAwesomeIcon icon={faArrowRight} />
                          </h2>
                          {/* <p>vbhf</p> */}
                        </div>
                        <img src="/Images/automation.gif" alt="" />
                      </div>
                    </div>
                    <div className="hover-card">
                      <div className="hover-content">
                        <h2>Automation</h2>
                        <p>
                          {" "}
                          We use the latest automation tools and technologies to streamline the testing process and improve efficiency, saving you time and resources.{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

               
                
              </div>
            </div>
          </div>




          {/* <CarouselSlider/> */}
        </div>

      <div className="section5">
        <h1 className="section5heading">How We are Handling</h1>
        <div className="section5jsxcard">
          <CaseStudyCard
            imageUrl="/Images/casestudyimg1.jpg"
            title="Initial Assessment and Planning"
            description=" Conducting a detailed analysis of your software to plan an effective testing strategy. "
            // linkUrl="/platform/E-cart/casestudy-vitualwallet"
          />
          <CaseStudyCard
            imageUrl="/Images/casestudyimg2.jpg"
            title="Execution of Testing Plan"
            description="Implementing the testing plan using a combination of automated and manual testing methodologies. "
            linkUrl=""
          />
          <CaseStudyCard
            imageUrl="/Images/casestudyimg3.jpg"
            title="Regular Reporting and Feedback"
            description=" Providing regular updates and comprehensive reports on testing progress and findings. "
            linkUrl=""
          />

          <CaseStudyCard
            imageUrl="/Images/casestudyimg4.jpg"
            title="Collaborative Bug Fixing and Optimization"
            description="Working closely with your development team to address any issues and optimize the software."
            linkUrl=""
          />

          {/* <div class="readmore">
  <button class="read">show More</button>
  </div> */}

          {/* section 6 */}
        </div>
      </div>
      {/* <div class="readmore">
      <button class="read">show More</button>
      </div> */}

      {/* section 6 */}
    </div>
  );
}

export default TestingandQA;
