import React from "react";
// import "./HospitalERP.css";

export default function Hospital() {
  return (       
    
    // new changes in code in hospitalerp



<div>

<div className="OnlineTP-container">Hospital ERP</div>





{/* automated health care management start */}


<div className="OTP">Automated Health Care Management</div>

    <section className="OTP1">
      <div className="container OTP1_container">
        <div className="OTP2">

          <p className="OTP2para">
          1. At Hindustan Informatics,we aspire in delivering the world class
                 automated health care management system.
          </p>
          <p className="OTP2para">
          2. Our HCMS is design with in-depth understanding of health care
                 industry which makes the system more compatible, unique and easy
                 to use.
          </p>
          <p className="OTP2para">
          3. We also ensure our system meets every individual needs to
                maintain their daily health reports.
          </p>
          <p className="OTP2para">
          4.There is nothing like we missed; if anybody needs a special
                 feature we are there to assist you.
          </p>

        </div>
      </div>
    </section>

    {/* automated health care management end */}





    {/* left right conetent for grid left side image right side content start */}

{/* conent image 1 */}
<div class="OnlineTP-grid-leftright">
        <div class="OnlineTP-image-left">
        <img src="/Images/doctor.jpg"></img>
        </div>

        <div class="OnlineTP-content-right">

        <h4 className="OTPh3">What it Includes ?</h4>
              <p className="OTPp"> Clinical Management-</p>
              <p className="OTPp"> 1.Easiest patient registration.</p>
              <p className="OTPp"> 2.Doctors registration and schedule definition</p>
              <p className="OTPp"> 3.Reception Manager</p>
              <p className="OTPp"> 4.Appointment Taking</p>
              <p className="OTPp"> 5.Queue Management</p>
              <p className="OTPp"> 6. Most Effective observation notes and consultation note taking
                   approach</p>
              <p className="OTPp"> 7. Complete Case management-observation, diagnose, treatment,
                   report, prescription & case status</p>

                  
        </div>
    </div>


{/* conent image 2 */}
<div class="OnlineTP-grid-leftright">
        <div class="OnlineTP-image-left">
        <img src="/Images/clinic-inventory.jpg"></img>
        </div>

        <div class="OnlineTP-content-right">

        <h4 className="OTPh3"> Small and limited clinical inventory and accounting</h4>
              <p className="OTPp"> 1.Visitor Doctor registration</p>
              <p className="OTPp"> 2. Medicine Stock and usage</p>
              <p className="OTPp"> 3.Fees Management</p>
              <p className="OTPp"> 4.Daily, Weekly and Monthly collection</p>
              <p className="OTPp"> 5. Performance reports</p>
              <p className="OTPp"> “Free and Direct payment gateway for you. Online debit and
                   credit card payment facility-If required....!”</p>
              
              
        </div>
    </div>


    
    {/* conent image 3 */}

    <div class="OnlineTP-grid-leftright">
        <div class="OnlineTP-image-left">
        <img src="/Images/clinic-m2.jpg"></img>
        </div>

        <div class="OnlineTP-content-right">

        <h4 className="OTPh3">Key Offerings</h4>
              <p className="OTPp"> We are offering unique design and process which is very easy to
                use and guides you for appropriate decision taking</p>
              
              <h4 className="OTPh3">Product Includes</h4>
              <p className="OTPp">1.Special Machine Learning algorithm that helps for easy data
                   entry techniques</p>
                   <p  className="OTPp">2.Medicine Database for easy access and use</p>

                   <p className="OTPp"> 3.Bluetooth / Wi-Fi printer for bill and prescription printing</p>
              
              
        </div>
    </div>


 {/* conent image 4 */}

 <div class="OnlineTP-grid-leftright">
        <div class="OnlineTP-image-left">
        <img src="/Images/hms.jpg"></img>
        <img src="/Images/static-management.jpg"></img>
       
              
        </div>

        
   
        <div class="OnlineTP-content-right">

        <h4 className="OTPh3">Product Specifications</h4>
              <p className="OTPp"> Healthcare Information System designed with special attention on
                organization and Services particular organization provides</p>
            
              <p className="OTPp"> Product available in 3 different versions and few special
               add-ons</p>
                   <p  className="OTPp">Products -</p>

                   <p className="OTPp"> 1.Clinical Management</p>
                   <p className="OTPp"> 2.Hospital Management</p>
                   <p className="OTPp"> 3.Multi-Specialty</p>
              
        </div>
    </div>



 {/* conent image 5 */}

 <div class="OnlineTP-grid-leftright">
        <div class="OnlineTP-image-left">
        <img src="/Images/management.jpg"></img>
        <img src="/Images/staff-management.jpg"></img>
        
        </div>


        <div class="OnlineTP-content-right">

       
              <p className="OTPp">Add-ons –</p>
            
              <p className="OTPp">1.In-house Medical Store Management</p>
                   <p  className="OTPp">2.Operation Theater Manager</p>
                   <p className="OTPp"> 3.Hardware Integration/Reporting</p>
                   <p className="OTPp"> 4.Payroll Management foe staff</p>
                   <p className="OTPp"> 5.Ambulances/Transport Manager</p>
                   <p className="OTPp"> Available on-</p>

<p className="OTPp">1.Android, iOS for Mobile/Tablet</p>
<p  className="OTPp">2.Internet Browsers via web links</p>
<p className="OTPp"> 3.Windows, Mac OS, Linux</p>
                   
              
              
        </div>
    </div>




     {/* conent image 6 */}

     
     <div class="OnlineTP-grid-leftright">
        <div class="OnlineTP-image-left">
        <img src="/Images/homepage.jpg"></img>
        </div>


        
    
              

        <div class="OnlineTP-content-right">

        <img src="/Images/homepage1.jpg"></img>
        </div>
    </div>

{/* left right conetent for grid left side image right side content end */}





</div>
    





  );
}
