import React from "react";
import "./CaseStudy.css";
import CsForm from './CsForm.js';
 
 

 
 
 
const CloudCaseStudy2 = () => {
  return (
    <div className="main-casestudydiv">
      <section className="caseStudysection5">
        <div className="casestudytitle">
          <div className="casestudylable">CASE STUDY</div>
          <h1 className="case-headind1 text-dark">
            {" "}
            CloudCipher Enhancing Data Integrity in PharmaCo's R&D Division{" "}
          </h1>
        </div>
      </section>
 
      <div className="part2 col">
        <section className="para-in case-pt75">
          <article className="case-container">
            <div className="row-k">
              <div
                className="row-in case-mb20 fadeInUp animated"
                data-wow-delay="0.3s"
                style={{
                  visibility: "visible",
                  WebkitAnimationDelay: "0.3s",
                  MozAnimationDelay: "0.3s",
                  animationDelay: "0.3s",
                }}
              >
                <p className="part2-para">
                PharmaCo, an innovative pharmaceutical company, grappled with the management of sensitive clinical trial data, intellectual property protection, and the need for a collaborative environment conducive to high-stakes research. The implementation of CloudCipher significantly improved their data handling, security, and collaborative efforts.
                </p>
                <p className="part2-para">
                PharmaCo's R&D division operates in a highly regulated industry where data integrity and security are paramount. The division was hindered by outdated systems that struggled with the demands of large-scale data analysis, secure communication, and collaborative drug design processes.
                </p>
              </div>
            </div>
            <div className="row-k case-mt45">
              <div className="row-in2 ">
                <div
                  className="yellow-bg pad-all wow fadeInUp animated"
                  data-wow-delay="1.3s"
                  style={{
                    visibility: "visible",
                    WebkitAnimationDelay: "1.3s",
                    MozAnimationDelay: "1.3s",
                    animationDelay: "1.3s",
                  }}
                >
                  <div className="row1 row-k">
                    <div className="col1">
                      <p>
                        {" "}
                        <strong>Key Challenges</strong>
                      </p>
                      <ul className="case-list-items">
                        <li>
                          Data Security: Protecting patient data and proprietary formulas was of utmost concern, given the confidentiality and compliance requirements of the pharmaceutical industry.
                        </li>
                        <li>
                        Collaboration on Drug Design: Coordinating efforts between chemists, biologists, and data analysts required a platform that could handle complex data sets and facilitate real-time collaboration.
                        </li>
                        <li>
                        Communication Safety: Securely discussing sensitive information and research findings with internal and external stakeholders was a necessity.
                        </li>
                        <li>
                        Data Synchronization and Accessibility: Ensuring the latest research findings were immediately available to all relevant parties, across global locations.
                        </li>
                      </ul>
                    </div>
                    <div className="col2">
                      <p>
                        {" "}
                        <strong>Ready to experience?</strong>
                      </p>
                      <a
                        href="#rfs"
                        className="scrollto-target pdf"
                        title="Talk To Experts"
                      >
                        TALK TO EXPERTS
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </article>
        </section>
      </div>
      <div className="part3">
        <section  className="case-pt0 case-pb75">
          <article className="case-container">
            <div className="row-in2">
              <div
                className="row-in2 wow fadeInUp animated"
                data-wow-delay="0.3s"
                style={{
                  visibility: "visible",
                  WebkitAnimationDelay: "0.3s",
                  MozAnimationDelay: "0.3s",
                  animationDelay: "0.3s",
                }}
              >
                <h2 className="h2-heading-cs text-center">Phased Implementation Approach</h2>
                <h3 className=" para-txt col-md-part-2 pd-lr light-gray text-center mb50">
                  {/* E-cart was deployed to overcome these obstacles. Key
                  features included */}
                </h3>
              </div>
              <div
                className="col-part1 wow fadeInLeft animated"
                data-wow-delay="0.6s"
                style={{
                  visibility: "visible",
                  WebkitAnimationDelay: "0.6s",
                  MozAnimationDelay: "0.6s",
                  animationDelay: "0.6s",
                }}
              >
                <p>
                  <strong>Needs Assessment</strong>
                </p>
                <ul className="case-list-items">
                  <li>
                  Detailed analysis of PharmaCo's data infrastructure and security needs.
                  </li>
                </ul>
                <p>
                  <strong>Collaborative Workspace</strong>
                </p>
                <ul className="case-list-items">
                  <li>
                  A virtual collaborative platform was established, enabling simultaneous drug design and research documentation.
                  </li>
                </ul>
                <p>
                  <strong>Secure Communications</strong>
                </p>
                <ul className="case-list-items">
                  <li>
                  A secure messaging system was implemented for confidential discussions regarding clinical trials and research.
                  </li>
                </ul>
              </div>
            </div>
            <div className="row case-mt45">
              <div className="">
                <div
                  className="yellow-bg pad-all wow fadeInUp animated"
                  data-wow-delay="1.3s"
                  style={{
                    visibility: "visible",
                    WebkitAnimationDelay: "1.3s",
                    MozAnimationDelay: "1.3s",
                    animationDelay: "1.3s",
                  }}
                >
                  <h2 className="head-case">
                  Implementation{" "}
                  </h2>
                  <div className="row case-txt-para">
                    <div className="col1">
                      <ul className="case-list-items">
                        <li>
                          Advanced Encryption: All data, including patient records and drug formulas, were encrypted both at rest and in transit.
                        </li>
                        <li>
                        Collaborative Workspace: A virtual collaborative platform was established, enabling simultaneous drug design and research documentation.
                        </li>
                        <li>  Secure Communications: A secure messaging system was implemented for confidential discussions regarding clinical trials and research.</li>
                        <li>
                        Real-time Data Synchronization: CloudCipher's synchronization tools ensured consistent data availability across research centers worldwide.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </article>
        </section>
      </div>
      <div className="part4 col">
        <section className="para-in ">
          <article className="case-container">
           
            <div className="row-k ">
              <div className="row-in2 ">
                <div
                  className=" pad-all wow fadeInUp animated">
                  <div className="part4-row1 row-k">
                    <div className="col1">
                    <p>
                  <strong>Results</strong>
                </p>
                <ul className="case-list-items">
                  <li>
                  Strengthened Data Security: Post-CloudCipher implementation, there were zero data breaches or compliance infractions.
                  </li>
                  <li>
                    Streamlined Collaboration: Drug development timelines were reduced by 30% due to more efficient collaborative processes.
                  </li>
                  <li>
                    Guaranteed Communication Safety: Secure channels facilitated safe sharing of research, resulting in enhanced stakeholder trust.
                  </li>
                  <li>
                  Improved Data Handling: Researchers accessed real-time data updates, accelerating the pace of innovation and decision-making.
                  </li>
                </ul>
                    </div>
                    <div className="col2">
                    <p>
                  <strong>Concluding Thoughts</strong>
                </p>
                <ul className="-list-items">
                  <li>
                  For PharmaCo, CloudCipher was a game-changer. The integration of CloudCipher’s secure cloud services enabled PharmaCo to uphold the highest standards of data security and integrity, vital in the pharmaceutical industry.  
                  </li>
                  <li>
                  With CloudCipher, PharmaCo’s R&D division achieved a new level of efficiency and security in their quest to develop life-saving drugs.
                  </li>
                </ul>
                    </div>
                   
               
                 
                  </div>
                </div>
              </div>
            </div>
          </article>
        </section>
      </div>
       
      <CsForm />
    </div>
  );
};
 
export default CloudCaseStudy2;
 