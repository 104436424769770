import React from 'react'
import './VoIP.css';

const benefitsData = [
    {
      title: "Virtual Phone Numbers and Sim Cards",
      description: "Virtual phone numbers are telephone numbers that are not directly associated with a physical phone line. They enable you to have a local presence in different geographical regions without needing a physical location. Virtual sim cards work similarly, allowing users to have multiple numbers on a single device.",
      benefits: [
        "Establish local presence in multiple regions.",
        "Manage calls from different numbers on a single device.",
        "Ideal for businesses targeting diverse markets.",
      ],
    },
    {
        title: "VoIP Calling Apps:",
        description: "  VoIP calling apps enable users to make voice and video calls over the internet, bypassing traditional phone networks. These apps often offer additional features such as messaging, video conferencing, and file sharing. ",
        benefits: [
            "Cost-effective international calling.", 

            "Enhanced communication features beyond voice calls.",
            
            "Seamless connectivity over Wi-Fi and mobile data.",
        ],
      },
      {
        title: "Direct Phone Calls on SIM Number: ",
        description: "VoIP providers also offer the ability to make direct phone calls using traditional phone numbers, just like a regular phone call. The difference is that the call is routed over the internet rather than through a cellular network. ",
        benefits: [
            "Savings on long-distance and international calls.", 

            "Better call quality over stable internet connections.", 
            
            "Access to advanced features like call recording and voicemail.", 
        ],
      },
      {
        title: "Business VoIP Services: ",
        description: "Business VoIP services are tailored for organizations and offer advanced features suitable for professional use. These may include call routing, IVR (Interactive Voice Response), conference calling, and integration with business software. ",
        benefits: [
            "Streamlined communication within the organization.", 

            "Scalability to accommodate business growth.",
            
            "Integration with CRM and other software for enhanced customer service. ",
        ],
      },
      {
        title: "VoIP Hardware: ",
        description: "VoIP hardware includes devices like IP phones and ATA (Analog Telephone Adapters). IP phones connect directly to the internet and offer a range of features. ATA allows traditional analog phones to connect to VoIP networks. ",
        benefits: [
            "Dedicated devices for VoIP calls. ",

            "Improved voice quality and compatibility.", 
            
            "Variety of hardware options to meet different needs. ",
        ],
      },
      {
        title: "VoIP for International Calling: ",
        description: "One of the most popular uses of VoIP is for international calling. VoIP services can significantly reduce the cost of making international calls compared to traditional phone services. ",
        benefits: [
            "Substantial cost savings on international calls.", 

            "Access to clear voice quality and stable connections.", 
            
            "Various pricing plans and options available.", 
        ],
      },

    {
        title: "VoIP for Business Process Outsourcing: ",
        description: "Business Process Outsourcing (BPO) companies use VoIP to provide customer support, telemarketing, and other services. VoIP enables seamless communication between agents and customers. ",
        benefits: [
            "Cost-effective communication for BPO operations.", 

            "Integration with CRM and support software.", 
            
            "Flexibility to handle high call volumes.", 
        ],
      },
      {
        title: "VoIP Security and Encryption: ",
        description: "Many VoIP services offer encryption and security features to protect calls and data. This is crucial for maintaining the privacy and confidentiality of conversations. ",
        benefits: [
            "Secure communication to prevent eavesdropping.", 

            "Protection against data breaches and cyber threats.", 
            
            "Compliance with data protection regulations.", 
        ],
      },
    // Repeat this structure for other benefit sections
  ];

function VoIP() {
  return (
    <div>
      <div className='VoIP-heading'>VoIP Platform Service</div>
      <div className="voip-benefits">
            <h2 className='voip'>VoIP Benefits</h2>

            {/* Repeat the structure for other benefits */}
            <div className="benefits-container">
      {benefitsData.map((benefit, index) => (
        <div className="benefit" key={index}>
          <h3>{index + 1}. {benefit.title}</h3>
          <p>{benefit.description}</p>
          <h4>Benefits:</h4>
          <ul>
            {benefit.benefits.map((item, subIndex) => (
              <li key={subIndex}>{item}</li>
            ))}
          </ul>
        </div>
      ))}
    </div>
    In summary, VoIP services offer a wide range of options for voice communication over the internet, catering to personal, business, and international calling needs. The flexibility, cost savings, and advanced features make VoIP an increasingly popular choice for modern communication.
        </div>
    </div>
  )
}

export default VoIP
