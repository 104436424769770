import React from 'react'
import './Customer.css'

function CUSTOMER() {
  return (
    <div className='api'>
      <div className='customer-heading'>CUSTOMER API</div>
      <p className='customer-parag'>Create and manage customer profiles with easy to plug-in solution</p>
      <img src='https://6062099.fs1.hubspotusercontent-na1.net/hubfs/6062099/20945431.jpg'className="imagcustomer"alt='customerimages'></img>
      <div className="feature-table">
  <div className="feature-box">
    <div className="box">
    <i className="fas fa-user-plus"></i>

      <p>Create Customer</p>
    </div>
  </div>
  <div className="feature-box">
    <div className="box">
    <i className="fas fa-user-check"></i>

      <p>Get Customer</p>
    </div>
  </div>
  <div className="feature-box">
    <div className="box">
    <i className="fas fa-user-edit"></i>

      <p>Update Customer</p>
    </div>
  </div>

  <div className="feature-box">
    <div className="box">
    
    <i className="fas fa-user-cog"></i>

      <p>Personalization with User Data</p>
    </div>
  </div>
 
</div>
    
    </div>
  )
}

export default CUSTOMER
