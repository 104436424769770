import React from "react";

import "./product_common.css";

export default function VM() {
  return (
    <>
      {/* <div><NavBar/></div>  */}

      <div className="banner parbase industries-gradient aem-GridColumn aem-GridColumn--default--12">
        <section id="sml_ht_home_banner">
          <article className="container">
            <div className="row">
              <div className="col-md-9 col-sm-12 col-xs-12 ">
                <h1 className="h2-heading white-color l-top">Chat-Bot's</h1>
              </div>
            </div>
          </article>
        </section>
      </div>

      <section id="pt75" className="pt75">
        <article className="container">
          <div className="row">
            <div
              className="col-md-6 col-sm-12 col-xs-12 text-center-xs wow fadeInLeft"
              data-wow-delay="0.6s"
            >
              {/* <video id="herovideo" className="fillWidth" width="100%" height="100%" preload="auto" autoplay muted loop playsinline preload="metadata" poster="../../content/dam/infosys-web/en/navigate-your-next/research/Images/chatboat.jpg"> */}

              {/* Your browser does not support HTML5 video. */}

              {/* </video> */}

              <img src="/Images/chatboat.jpg"></img>
            </div>

            <div id="col2"
              className="col-md-6 col-sm-12 col-xs-12 mb-sm-20 wow fadeInRight"
              data-wow-delay="0.6s"
            >
              <h3 className="mb10 mt0">
                Chat Bot Architecture- Professional communication channel for
                professional use “to the point at a click”
              </h3>

              <p>
                The above diagram illustrates the general concepts of how a Chat
                server communicates with a chat bot framework or platform. These
                concepts are applicable to external bots/Applications also.
              </p>

              <p>
                Bots architecture consists of two main parts: Chat host and bot
                host. These two parts are connected with each other via Chat SDK
                methods. Bot host runs externally to the Chat host and consists
                of the bot infrastructure and, typically, external services. The
                bot infrastructure, in turn, consists of SDK, adapter, and
                framework.
              </p>
            </div>
          </div>
        </article>
      </section>

      <section id="pt75" className="pt75">
        <article className="container">
          <div className="row">
            <div
              className="col-md-6 col-sm-12 col-xs-12 mb-sm-20 wow fadeInLeft"
              data-wow-delay="0.6s"
            >
              <p>
                With the ability to listen to and respond to messages, the bot
                can be integrated into the Chat host/s(Own, What's App,
                Messenger, Skype etc) so that it can engage with users in a
                conversational manner.
              </p>

              <p>
                To perform this connection, bot creators use adapters that can
                translate the logic defined via the framework’s powers to the
                format that the Chat host understands.
                <br></br>
                Adapters use Chat SDK (provided by service providers in some
                cases) methods to communicate with the Chat hosts.
              </p>
            </div>

            <div id="col2"
              className="col-md-6 col-sm-12 col-xs-12 text-center-xs wow fadeInRight"
              data-wow-delay="0.6s"
            >
              <img src="/Images/chat-bot-arc.jpg"></img>
            </div>
          </div>
        </article>
      </section>
      <section id="pt75" className="pt75">
        <article className="container">
          <div className="row">
            <div
              className="col-md-6 col-sm-12 col-xs-12 text-center-xs wow fadeInLeft"
              data-wow-delay="0.6s"
            >
              {/* <video id="herovideo" className="fillWidth" width="100%" height="100%" preload="auto" autoplay muted loop playsinline preload="metadata" poster="../../content/dam/infosys-web/en/navigate-your-next/research/Images/chatboat.jpg"> */}

              {/* Your browser does not support HTML5 video. */}

              {/* </video> */}

              <img src="/Images/double-helix-right.jpg"></img>
            </div>

            <div id="col2"
              className="col-md-6 col-sm-12 col-xs-12 mb-sm-20 wow fadeInRight"
              data-wow-delay="0.6s"
            >
              <h3 className="mb10 mt0">How Bots Architecture works</h3>

              <p>
                Bot creators design the bot’s logic with the help of their
                favorite framework. This logic defines behavioral interaction
                with users, giving the bot ability to recognize the context of
                the conversation.
              </p>

              <p>
                In this way, the bot can focus on what users want to achieve and
                even take a proactive approach in finding the right solution and
                creating better user experience.
              </p>
            </div>
          </div>
        </article>
      </section>
      <section id="pt75" className="pt75">
        <article className="container">
          <div className="row">
            <div
              className="col-md-6 col-sm-12 col-xs-12 mb-sm-20 wow fadeInLeft"
              data-wow-delay="0.6s"
            >
              <p>
                With the ability to listen to and respond to messages, the bot
                can be integrated into the Chat host/s(Own, What's App,
                Messenger, Skype etc) so that it can engage with users in a
                conversational manner.
              </p>

              <p>
                To perform this connection, bot creators use adapters that can
                translate the logic defined via the framework’s powers to the
                format that the Chat host understands.
                <br></br>
                Adapters use Chat SDK (provided by service providers in some
                cases) methods to communicate with the Chat hosts.
              </p>
            </div>

            <div id="col2"
              className="col-md-6 col-sm-12 col-xs-12 text-center-xs wow fadeInRight"
              data-wow-delay="0.6s"
            >
              <img src="/Images/banner_img.jpg"></img>
            </div>
          </div>
        </article>
      </section>
      <section id="pt75" className="pt75">
        <article className="container">
          <div className="row">
            <div
              className="col-md-6 col-sm-12 col-xs-12 text-center-xs wow fadeInLeft"
              data-wow-delay="0.6s"
            >
              {/* <video id="herovideo" className="fillWidth" width="100%" height="100%" preload="auto" autoplay muted loop playsinline preload="metadata" poster="../../content/dam/infosys-web/en/navigate-your-next/research/Images/chatboat.jpg"> */}

              {/* Your browser does not support HTML5 video. */}

              {/* </video> */}

              <img src="/Images/bot-user.jpg"></img>
            </div>

            <div id="col2"
              className="col-md-6 col-sm-12 col-xs-12 mb-sm-20 wow fadeInRight"
              data-wow-delay="0.6s"
            >
              <h3 className="mb10 mt0">Users and Bots</h3>

              <p>
                Bots in Chat require user accounts with the bot role. Other than
                having a specific set of permissions, bot accounts are treated
                like regular user accounts within the Chat host.
              </p>

              <p>
                However, to prevent possible user confusion, messages from
                online bots are marked with a default “Bot” label.
              </p>
            </div>
          </div>
        </article>
      </section>

      <section id="pt75" className="pt75">
        <article className="container">
          <div className="row">
            <div
              className="col-md-6 col-sm-12 col-xs-12 mb-sm-20 wow fadeInLeft"
              data-wow-delay="0.6s"
            >
              <h3 className="mb10 mt0">Bot Admin</h3>

              <p>
                Admins (and only admins) can create the bot account and
                configure role permissions.
              </p>
              <p>
                This includes setting the credentials a bot uses to connect as
                well as which user fields the bots have access to for privacy
                and security.
              </p>
              <p>
                Chat(OFFICE) team works on improving management views for bots
                and enhancing the future potential of user-activated bots.
              </p>
            </div>

            <div id="col2"
              className="col-md-6 col-sm-12 col-xs-12 text-center-xs wow fadeInRight"
              data-wow-delay="0.6s"
            >
              <img src="/Images/admin.jpg"></img>
            </div>
          </div>
        </article>
      </section>
      <section id="pt75" className="pt75">
        <article className="container">
          <div className="row">
            <div
              className="col-md-6 col-sm-12 col-xs-12 text-center-xs wow fadeInLeft"
              data-wow-delay="0.6s"
            >
              {/* <video id="herovideo" className="fillWidth" width="100%" height="100%" preload="auto" autoplay muted loop playsinline preload="metadata" poster="../../content/dam/infosys-web/en/navigate-your-next/research/Images/chatboat.jpg"> */}

              {/* Your browser does not support HTML5 video. */}

              {/* </video> */}

              <img src="/Images/stream.jpg"></img>
            </div>

            <div id="col2"
              className="col-md-6 col-sm-12 col-xs-12 mb-sm-20 wow fadeInRight"
              data-wow-delay="0.6s"
            >
              <h3 className="mb10 mt0">Message Streams</h3>

              <p>
                Every user in Chat(Application) has a collection for their
                messages that can be emitted by the server’s streamer.
              </p>

              <p>
                Bots subscribe to the collection of messages for their own user
                account, creating a stream that is updated every time a message
                is sent either directly to them or any room they are joined in.
              </p>

              <h2 className="mb10 mt0">Chat SDK</h2>
              <p>
                The SDK is a low-level software module that offers an interface
                for external consumers to subscribe to message streams,
              </p>
              <p>
                send messages, and call methods on the Chat server via Web -
                Sockets / DDP. Bot framework adapter are written using the SDK.
              </p>
            </div>
          </div>
        </article>
      </section>
      {/* <section className="pt75">
    <article className="container">
      <div className="row">
        <div className="col-md-6 col-sm-12 col-xs-12 mb-sm-20 wow fadeInLeft" data-wow-delay="0.6s">
          <h3 className="mb10 mt0">Framework Adapter</h3>
          <p>An adapter (also referred as connector or middleware) is a software component that is used to link the framework with the Chat host.</p>
  
           <p> An adapter allows bot creators to design and build bot interactions according to the framework’s rules and practices without dealing with the low-level programming details and methods of the communication with the Chat host.</p>
            
           <p> Adapters use Chat SDK to communicate with the Chat host.</p>
        </div>
        <h3 className="mb10 mt0">Bot Framework</h3>
        <p>Bot frameworks provide unique approaches to create conversational interfaces and allow developers to focus on the design and management of complex bot logic.</p>
  
          <p>Most of frameworks can be run on your own bot host. There are also frameworks that operate as SaaS (Software as a Service), so they work in the cloud.</p>
        </div>
        
        
    
    </article>
  </section> */}
      <section id="pt75" className="pt75">
        <article className="container">
          <div className="row">
            <div
              className="col-md-6 col-sm-12 col-xs-12 text-center-xs wow fadeInLeft"
              data-wow-delay="0.6s"
            >
              <h3 className="mb10 mt0">Framework Adapter</h3>
              <p>
                An adapter (also referred as connector or middleware) is a
                software component that is used to link the framework with the
                Chat host.
              </p>

              <p>
                {" "}
                An adapter allows bot creators to design and build bot
                interactions according to the framework’s rules and practices
                without dealing with the low-level programming details and
                methods of the communication with the Chat host.
              </p>

              <p> Adapters use Chat SDK to communicate with the Chat host.</p>
            </div>

            <div id="col2"
              className="col-md-6 col-sm-12 col-xs-12 mb-sm-20 wow fadeInRight"
              data-wow-delay="0.6s"
            >
              <h3 className="mb10 mt0">Bot Framework</h3>
              <p>
                Bot frameworks provide unique approaches to create
                conversational interfaces and allow developers to focus on the
                design and management of complex bot logic.
              </p>

              <p>
                Most of frameworks can be run on your own bot host. There are
                also frameworks that operate as SaaS (Software as a Service), so
                they work in the cloud.
              </p>
            </div>
          </div>
        </article>
      </section>
      <section id="pt75" className="pt75">
        <article className="container">
          <div className="row">
            <div
              className="col-md-6 col-sm-12 col-xs-12 text-center-xs wow fadeInLeft"
              data-wow-delay="0.6s"
            >
              <h3 className="mb10 mt0">Bot Scaling</h3>
              <p>
                In production, hundreds or thousands of users can be carrying
                out conversations with a bot at the same time. The ability to
                scale a bot from one conversation to many concurrent ones is
                essential.
              </p>
              <p>
                Bot frameworks/platforms support varying degrees of
                scalability.To ensure that growing number of users will not
                cause architectura and performance problems, you should choose
                your bot’s framework wisely
              </p>
              <p>
                We are dealing with node JS platform which is now a days best
                and effective framework which gave an outstanding performances
                on proven factors of load testing.
              </p>
            </div>

            <div id="col2"
              className="col-md-6 col-sm-12 col-xs-12 mb-sm-20 wow fadeInRight"
              data-wow-delay="0.6s"
            >
              <h3 className="mb10 mt0">External Services</h3>
              <p>
                The value of most production bots relies on their ability to
                access and manipulate external data and systems (services).
              </p>
              <p>
                External services, like databases, scheduling systems, CRM, NLP,
                REST AND SOAP API can be used to provide data or conversational
                processing.
              </p>
              <p>
                The framework simplifies connection of a bot with these services
                and ensures ease of access to them through the bot host.
              </p>
              <p>
                Channel communicates and bridges the bot and users with each
                others hence channels can be defined as; Channels, which are a
                special kind of module, receive messages fromthe different chat
                platforms.
              </p>
            </div>
          </div>
        </article>
      </section>

      <section id="pt75" className="pt75">
        <article className="container">
          <div className="row">
            <div
              className="col-md-6 col-sm-12 col-xs-12 mb-sm-20 wow fadeInLeft"
              data-wow-delay="0.6s"
            >
              <h3 className="mb10 mt0">Mobile Application Interface</h3>
              <h3>Connection settings with Server</h3>
              <p>
                We have approached to put connection setting and create secure
                channel which communicate with private company server and pulls
                the data we have requested in chat query
              </p>
              <h3>Communication Features</h3>
              <ul>
                <li>Chatting</li>
                <li>Video call</li>
                <li>Audio call</li>
                <li>
                  All types file sharing with filter and security with upload
                  file option
                </li>
              </ul>
            </div>

            <div id="col2"
              className="col-md-6 col-sm-12 col-xs-12 text-center-xs wow fadeInRight"
              data-wow-delay="0.6s"
            >
              <img src="/Images/channel.jpg"></img>
            </div>
          </div>
        </article>
      </section>
      <section id="pt75" className="pt75">
        <article className="container">
          <div className="row">
            <div
              className="col-md-6 col-sm-12 col-xs-12 text-center-xs wow fadeInLeft"
              data-wow-delay="0.6s"
            >
              {/* <video id="herovideo" className="fillWidth" width="100%" height="100%" preload="auto" autoplay muted loop playsinline preload="metadata" poster="../../content/dam/infosys-web/en/navigate-your-next/research/Images/chatboat.jpg"> */}

              {/* Your browser does not support HTML5 video. */}

              {/* </video> */}

              <img src="/Images/botsc.jpg"></img>
            </div>

            <div id="col2"
              className="col-md-6 col-sm-12 col-xs-12 mb-sm-20 wow fadeInRight"
              data-wow-delay="0.6s"
            >
              <h3 className="mb10 mt0">Communication Feature</h3>
              <ul>
                <li>
                  Chat bot channel integration with provided security at channel
                  level
                </li>
                <li>Team work and collaboration and grouping</li>
                <li>Message level security makes us unique</li>
                <li>Search the contents inside application</li>
                <li>
                  Can available in all platforms on all mobile phones, all pc
                  OS, web and always in synch
                </li>
              </ul>
            </div>
          </div>
        </article>
      </section>
    </>
  );
}
