import React from 'react'
import './Account.css';

function Account() {
  return (
    <div>
      <div className='account-heading'>ACCOUNT API</div>
      <p className='account-para'>Offer excellent mobile-first banking experience with targeted  services with flexible API infrastructure</p>
      <img src='https://diro.io/wp-content/uploads/2021/05/bank-account-verification-methods.jpg'className="imagcustomer"alt='customerimages'></img>
      <div className="feature-table">
  <div className="feature-box">
    <div className="box">
    <i className="fas fa-plus"></i>
      <p>Create Savings Account</p>
    </div>
  </div>
  <div className="feature-box">
    <div className="box">
    <i className="fas fa-user-check"></i>


      <p>Get Account Details</p>
    </div>
  </div>
  <div className="feature-box">
    <div className="box">
    <i className="fas fa-receipt"></i>


      <p>Get Mini Statement</p>
    </div>
  </div>
  <div className="feature-box">
    <div className="box">
    <i className="fas fa-list"></i>


      <p>Get Detailed Transactions</p>
    </div>
  </div>
   </div>
   </div>
  )
}

export default Account
