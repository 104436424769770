import "./App.css";
import Home from "./components/Home";
import NavBar from "./Layouts/NavBar";

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import "@fortawesome/fontawesome-svg-core/styles.css";
import "aos/dist/aos.css";
import NavigateYourNext from "./components/NavigateYourNext";
import About from "./components/About";

import Investors from "./components/Investors";
import Vision from "./components/Vision";
import React, { useEffect } from "react";
import AOS from "aos";
import BlogDetail from "./components/BlogDetail";

import CookiesBanner from './components/CookiesBanner';


import Products from "./components/Products";
import Contact from "./components/Contact";
import Chat from "./components/Chat";
import Gps from "./components/Gps";
import OnlineTutor from "./components/OnlineTutor";
import Project from "./components/Project";
import Advanced from "./components/Advanced";
import Terms from "./components/Terms";
import Privacy from "./components/Privacy";
import Cookie from "./components/Cookie";
import Safe from "./components/Safe";
import Foundar from "./components/Foundar";
import Reporting from "./components/Reporting";
import Gaming from "./components/Gaming";
import HospitalErp from "./components/HospitalErp";
import Cane_ERP_And_Automation from "./components/Cane_ERP_And_Automation";
import Hotel_Management from "./components/Hotel_Management";
import School_ERP from "./components/School_ERP";
import Warehouse from "./components/Warehouse";
import Footer from "./components/Footer";

import SaaSDevelopment from "./components/SaaSDevelopment";
import BusinessProcessManagement from "./components/BusinessProcessManagement";
import GISApplication from "./components/GISApplication";
import TechicalDocument from "./components/TechicalDocument";
import DigitalMarketing from "./components/DigitalMarketing";
import RPAImplementaion from "./components/ERP/RPAImplementaion";
import BusinessIntelligence from "./components/BusinessIntelligence";
import MobileApplication from "./components/MobileApplication";
import SocialMediaDevelopment from "./components/SocialMediaDevelopment";
import WebsiteDevelopment from "./components/WebsiteDevelopment";
import WebsiteTesting from "./components/WebsiteTesting";
import ECommerce from "./components/ECommerce";
import PageNotFound from "./components/PageNotFound";
import Blockchain from "./components/Blockchain";
import VoIP from "./components/VoIP";
import BankingPlatform from "./components/BankingPlatform";
import Smartcloud from "./components/Smartcloud";
import AIBenefits from "./components/AIBenefits";
import FinanceVertical from "./components/FinanceVertical";
import NeoBankingPlatform from "./components/NeoBankingPlatform";
import PlatformVariants from "./components/PlatformVariants";
import API_BasedPlatform from "./components/API_BasedPlatform";
import PlatformandBenefits from "./components/PlatformandBenefits";
import AITransformerCustomerJourney from "./components/AITransformerCustomerJourney";
import CustomerOnboarding from "./components/CustomerOnboarding";
import CustomerServices from "./components/CustomerServices"; // Adjust the path based on your file structure
import CustomerNurturing from "./components/CustomerNurturing";

import Ecart from "./components/Ecart";



import AdmServices from "./components/AdmServices";

import OnlineAnalytics from "./components/OnlineAnalytics";

import Smartvision from "./components/Smartvision";
import Kyc from "./components/Kyc";
import Customer from "./components/Customer";
import Account from "./components/Account";
import Loan from "./components/Loan";
import Cards from "./components/Cards";
import Deposite from "./components/Deposite";
import Payment from "./components/Payment";
import Insurance from "./components/Insurance";
import PayrollWithIot from "./components/PayrollWithIot";

import CreateSavingAccount from "./components/CreateSavingAccount";
import StaffingOutsource from "./components/StaffingOutsource";
import BharatMake from "./components/BharatMake";
import KpoAndBpo from "./components/KpoAndBpo";
import ServiceModal from "./Layouts/ServiceModal";
import SmartAccounting from "./components/SmartAccounting";
import TechnologyModal from "./Layouts/TechnologyModal";

// import Why from "./components/why";
// import What from "./components/what";
// import How from "./components/How";
import Clientlist from "./components/Clientlist";
import Solution from "./Layouts/Solution";
import NavbarCaseStudy from "./Layouts/NavbarCaseStudy";

import Cloudeciper from "./components/Cloudeciper";

import CsCloudeCipher1 from "./components/CsCloudCipher1";
import CsCloudCipher2 from "./components/CsCloudCipher2";
import CsCloudCipher3 from "./components/CsCloudCipher3";
import CsBharatMake from "./components/CsBharatMake";
import CsDigitalKeyStore from "./components/CsDigitalKeyStore";
import CsVirtualWallet from "./components/CsVirtualWallet";

import ISPMonitoring from "./components/ISPMonitoring";
import StafAugmentation from "./components/StafAugmentation";

import DigitalTransformation from "./components/DigitalTransformation";
import CloudServices from "./components/CloudServices";
import ERPservice from "./components/ERPservice";
import TestingandQA from "./components/TestingandQA";

import IOTServices from "./components/IOTServices";
import ConsultingServices from "./components/ConsultingServices";
import IndustrialAutomationService from "./components/IndustrialAtomationService";

import OCRservice from "./components/OCRservice";
import GenAI from "./components/GenAI";
import SuplyCL from "./components/SuplyCL";
import FinancialS from "./components/FinancialS";
import DroneSolution from "./components/DroneSolution";
import HealthcareAndPharma from "./components/HealthcareAndPharma";
import Automative from "./components/Automative";
import SolECommerce from "./components/SolEcommerce";
import CyberSecurity1 from "./components/CyberSecurity1";
import ManagedServices from "./components/ManagedServices";

import AI from "./components/AI";
import ML from "./components/ML";
import Accordion from "./components/Accordion";
import Blogs from "./components/Blogs";
import BlogDetails from "./components/BlogDetails";

// import BlogContents from "./components/BlogContents";
import TitlePreview from "./components/TitlePreview";
import DemoBlog from "./components/DemoBlog";
import Category from "./components/Category";

// import useGoogleAnalytics from "./useGoogleAnalytics";


document.addEventListener("DOMContentLoaded", function () {
  var element = document.getElementById("style");
  if (element !== null) {
    element.style.property = "value";
  }
});

function App() {

  // useGoogleAnalytics('G-LVHZ7BMJX3');
 

  useEffect(() => {
    AOS.init();
    
    //useGoogleAnalytics('G-LVHZ7BMJX3');
    
    // Add the scroll event listener
    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

 
  // Your existing code for Routes and components...

  // Define the handleScroll function
  function handleScroll() {
    // Check if the element with ID 'yourElementId' exists
    var element = document.getElementById("main-div");

    if (element !== null) {
      // Now you can safely access the 'style' property
      var style = element.style;

      // Rest of your code here
    } else {
      console.error("Element with ID 'main-div' not found.");
    }
  }

  return (

    
     
      
    

    <Router>
      <NavBar />

      <Routes>
        <Route path="/category/:title" element={<Category />} />

        <Route path="/" element={<Home />} />
        <Route path="/platforms/PayrollWithIot" element={<PayrollWithIot />} />
        <Route path="/Solution" element={<Solution />} />
        <Route path="/NavbarCaseStudy" element={<NavbarCaseStudy />} />
        <Route path="/Technologies" element={<TechnologyModal />} />
        <Route path="/investors" element={<Investors />} />
        {/* <Route path="/about-us" element={<About />} />  */}
        <Route path="/vision" element={<Vision />} />
        <Route path="/product" element={<Products />} />
        <Route path="/Services" element={<ServiceModal />} />
        <Route path="/platforms/Chat" element={<Chat />} />
        <Route path="/product/Gps" element={<Gps />} />
        <Route path="/platforms/OnlineTutor" element={<OnlineTutor />} />
        <Route path="/product/Project" element={<Project />} />
        <Route path="/service/advanced-toll-survey" element={<Advanced />} />
        <Route path="/Terms" element={<Terms />} />
        <Route path="/Privacy" element={<Privacy />} />
        <Route path="/Cookie" element={<Cookie />} />
        <Route path="/Safe" element={<Safe />} />
        <Route path="/anout-us/founder" element={<Foundar />} />
        <Route path="/platforms/reporting" element={<Reporting />} />
        <Route path="/service/CyberSecurity1" element={<CyberSecurity1 />} />
        <Route
          path="/platforms/SaasDevelopment"
          element={<SaaSDevelopment />}
        />
        <Route path="/service/gis-app" element={<GISApplication />} />
        <Route
          path="/service/technical-documents-or-content"
          element={<TechicalDocument />}
        />
        <Route
          path="/service/digital-marketing"
          element={<DigitalMarketing />}
        />
        <Route path="/platforms/ecart" element={<Ecart />} />
        <Route path="/platforms/cloudcipher" element={<cloudCipher />} />
        <Route
          path="/platforms/erp-rpa-implementaion"
          element={<RPAImplementaion />}
        />
        <Route
          path="/services/business-managment-process"
          element={<BusinessProcessManagement />}
        />
        <Route
          path="/service/BusinessIntelligence"
          element={<BusinessIntelligence />}
        />
        <Route
          path="/service/MobileApplication"
          element={<MobileApplication />}
        />
        <Route
          path="/service/SocialMediaDevelopment"
          element={<SocialMediaDevelopment />}
        />
        <Route path="/service/blockchain" element={<Blockchain />} />
        <Route path="/service/ManagedServices" element={<ManagedServices />} />
        <Route path="/service/AI" element={<AI />} />
        
        
        
        <Route path="/platform/SmartAccounting" element={<SmartAccounting />} />
        <Route path="/Service/AI_ML" element={<ML/>}/>
        <Route path="/Technologies" element={<TechnologyModal />} />
        <Route path="/investors" element={<Investors />} />
        {/* <Route path="/about-us" element={<About />} /> */}
        <Route path="/vision" element={<Vision />} />
        <Route path="/product" element={<Products />} />
        <Route path="/Services" element={<ServiceModal />} />
        <Route path="/platforms/Chat" element={<Chat />} />
        <Route path="/product/Gps" element={<Gps />} />
        <Route path="/platforms/OnlineAnalytics" element={<OnlineAnalytics />} />
        <Route path="/product/Project" element={<Project />} />
        <Route path="/service/advanced-toll-survey" element={<Advanced />} />
        <Route path="/Terms" element={<Terms />} />
        <Route path="/Privacy" element={<Privacy />} />
        <Route path="/Cookie" element={<Cookie />} />
        <Route path="/Safe" element={<Safe />} />
        <Route path="/anout-us/founder" element={<Foundar />} />
        <Route path="/platforms/reporting" element={<Reporting />} />
        <Route path="/service/CyberSecurity1" element={<CyberSecurity1 />} />
        <Route
          path="/platforms/SaasDevelopment"
          element={<SaaSDevelopment />}
        />
        <Route path="/service/gis-app" element={<GISApplication />} />
        <Route
          path="/service/technical-documents-or-content"
          element={<TechicalDocument />}
        />
        <Route
          path="/service/digital-marketing"
          element={<DigitalMarketing />}
        />
        <Route path="/platforms/ecart" element={<Ecart />} />
        <Route path="/platforms/Accordion" element={< Accordion />} />
       
        <Route path="/platforms/cloudcipher" element={<cloudCipher />} />
        <Route
          path="/platforms/erp-rpa-implementaion"
          element={<RPAImplementaion />}
        />
        <Route
          path="/services/business-managment-process"
          element={<BusinessProcessManagement />}
        />
        <Route
          path="/service/BusinessIntelligence"
          element={<BusinessIntelligence />}
        />
        <Route
          path="/service/MobileApplication"
          element={<MobileApplication />}
        />
        <Route
          path="/service/SocialMediaDevelopment"
          element={<SocialMediaDevelopment />}
        />
        <Route path="/service/blockchain" element={<Blockchain />} />
        <Route path="/platform/SmartAccounting" element={<SmartAccounting />} />

        <Route path="/platforms/VoIP" element={<VoIP />} />
        <Route
          path="/service/WebsiteDevelopment"
          element={<WebsiteDevelopment />}
        />
        <Route path="/service/WebsiteTesting" element={<WebsiteTesting />} />
        <Route path="/platforms/ECommerce" element={<ECommerce />} />
        <Route
          path="/platforms/BankingPlatform"
          element={<BankingPlatform />}
        />

        <Route path="/platforms/hospital-ERP" element={<HospitalErp />} />
        <Route
          path="/platforms/cane-ERP-automation"
          element={<Cane_ERP_And_Automation />}
        />
        <Route
          path="/platforms/hotel-management"
          element={<Hotel_Management />}
        />
        <Route path="/products/school-ERP" element={<School_ERP />} />
        <Route path="/products/warehouse" element={<Warehouse />} />
        <Route path="/platforms/smartcloud" element={<Smartcloud />} />

        <Route
          path="/platforms/OnlineAnalytics"
          element={<OnlineAnalytics />}
        />

        <Route path="/platforms/Smartvision" element={<Smartvision />} />

        <Route path="/Contact" element={<Contact />} />

        <Route path="/service/blockchain" element={<Blockchain />} />

        <Route path="/service/GamingAndVR" element={<Gaming />} />
        <Route path="/service/KpoAndBpo" element={<KpoAndBpo />} />

        <Route
          path="/service/Staffing/Consulting"
          element={<StaffingOutsource />}
        />

        <Route path="/navigate-your-next" element={<NavigateYourNext />} />
        {/* <Route path="/services/business-managment-process" element={<BusinessProcessManagement/>}/> */}

        <Route
          path="/service/BankingPlatform/AI/ML-Benefits"
          element={<AIBenefits />}
        />
        <Route
          path="/service/BankingPlatform/Finance-Vertical"
          element={<FinanceVertical />}
        />
        <Route
          path="/service/BankingPlatform/NeoBankingPlatform"
          element={<NeoBankingPlatform />}
        />
        <Route
          path="/service/BankingPlatform/Platform-Variants"
          element={<PlatformVariants />}
        />
        <Route
          path="/service/BankingPlatform/Neo-Banking-API-BAsedPlatform"
          element={<API_BasedPlatform />}
        />

        <Route
          path="/srevice/BankingPlatform/CustomerOnboarding"
          element={<CustomerOnboarding />}
        />
        <Route
          path="/service/BankingPlatform/CustomerService"
          element={<CustomerServices />}
        />
        <Route
          path="/service/BankingPlatform/CustomerNurturing"
          element={<CustomerNurturing />}
        />

        <Route path="/service/BankingPlatform/KYC" element={<Kyc />} />
        <Route
          path="/service/BankingPlatform/Customer"
          element={<Customer />}
        />
        <Route path="/service/Bankingplatform/Account" element={<Account />} />
        <Route path="/service/BankingPlatform/Loan" element={<Loan />} />
        <Route path="/service/BankingPlatform/Cards" element={<Cards />} />
        <Route
          path="/service/Bankingplatform/Deposite"
          element={<Deposite />}
        />
        <Route path="/service/BankingPlatform/Payment" element={<Payment />} />
        <Route
          path="/service/BankingPlatform/Insurance"
          element={<Insurance />}
        />
        <Route path="/service/ERPservice" element={<ERPservice />} />

        <Route
          path="/service/BankingPlatform/CreatingSavingAccount"
          element={<CreateSavingAccount />}
        />

        <Route
          path="/service/BankingPlatform/Platform-And-Benefits"
          element={<PlatformandBenefits />}
        />
        <Route
          path="/service/BankingPlatform/AI-Transformed-Customer-Journey"
          element={<AITransformerCustomerJourney />}
        />
        <Route path="/bharatmake" element={<BharatMake />} />
        {/* <Route path="/why" element={<Why/>}/> */}
        {/* <Route path="/what" element={<What/>}/> */}
        {/* <Route path="/How" element={<How/>}/> */}
        <Route path="/Clientlist" element={<Clientlist />} />
        <Route path="*" element={<PageNotFound />} />

        <Route path="/service/BankingPlatform/KYC" element={<Kyc />} />
        <Route
          path="/service/BankingPlatform/Customer"
          element={<Customer />}
        />
        <Route path="/service/Bankingplatform/Account" element={<Account />} />
        <Route path="/service/BankingPlatform/Loan" element={<Loan />} />
        <Route path="/service/BankingPlatform/Cards" element={<Cards />} />
        <Route
          path="/service/Bankingplatform/Deposite"
          element={<Deposite />}
        />
        <Route path="/service/BankingPlatform/Payment" element={<Payment />} />
        <Route
          path="/service/BankingPlatform/Insurance"
          element={<Insurance />}
        />

        <Route
          path="/service/BankingPlatform/CreatingSavingAccount"
          element={<CreateSavingAccount />}
        />

        <Route
          path="/service/BankingPlatform/Platform-And-Benefits"
          element={<PlatformandBenefits />}
        />
        <Route
          path="/service/BankingPlatform/AI-Transformed-Customer-Journey"
          element={<AITransformerCustomerJourney />}
        />
        <Route path="/bharatmake" element={<BharatMake />} />

        <Route path="/platfom/e-cart" element={<Ecart />} />

        
        
        
        <Route path="/platform/cloudecipher" element={<Cloudeciper />} />
        <Route path="/platform/cloudecipher" element={<Cloudeciper />} />
        <Route
          path="/platform/cloudecipher/casestudy-Empowering-taxcollection"
          element={<CsCloudeCipher1 />}
        />
        <Route
          path="/plaform/cloudcipher/casestudy-Enhancing-Data-Integrity"
          element={<CsCloudCipher2 />}
        />
        <Route
          path="/platform/cloudcipher/casestudy-Securing-Operations-Bank"
          element={<CsCloudCipher3 />}
        />
        <Route
          path="/platform/E-cart/casestudt-bharatmake"
          element={<CsBharatMake />}
        />
        <Route
          path="/platform/E-cart/casestudy-digitalkeystore"
          element={<CsDigitalKeyStore />}
        />
        <Route
          path="/platform/E-cart/casestudy-vitualwallet"
          element={<CsVirtualWallet />}
        />

        <Route path="/services/AdmServices" element={<AdmServices />} />

        <Route
          path="/service/Digitaltransformation"
          element={<DigitalTransformation />}
        />
        <Route path="/service/Cloudservices" element={<CloudServices />} />
        <Route path="/service/IOTServices" element={<IOTServices />} />

        <Route path="/platform/ISPMonitoring" element={<ISPMonitoring />} />
        <Route
          path="/service/StafAugmentation"
          element={<StafAugmentation />}
        />
        <Route path="/service/TestingandQA" element={<TestingandQA />} />
        <Route
          path="/service/Consulting-services"
          element={<ConsultingServices />}
        />
        <Route
          path="/service/Industrial-automation-service"
          element={<IndustrialAutomationService />}
        />
        <Route path="/solution/DroneSolution" element={<DroneSolution />} />

        <Route path="/NavbarCaseStudy/CsBharatMake" element={<CsBharatMake />} />
        <Route path="/NavbarCaseStudy/CsDigitalKeyStore" element={<CsDigitalKeyStore />} />
        <Route path="/NavbarCaseStudy/CsVirtualWallet" element={<CsVirtualWallet />} />
        <Route path="/NavbarCaseStudy/CsModeration" element={<CsCloudeCipher1 />} />
        <Route path="/NavbarCaseStudy/CsDataSecurity" element={<CsCloudCipher2 />} />
        <Route path="/NavbarCaseStudy/CsBankSecurity" element={<CsCloudCipher3 />} />
        <Route
          path="/solution/HealthcareAndPharma"
          element={<HealthcareAndPharma />}
        />
        <Route path="/solution/E-commerce" element={<SolECommerce />} />

        <Route path="/service/OCRservice" element={<OCRservice />} />
        <Route path="/service/GenAI" element={<GenAI />} />
        <Route path="/solution/SuplyCL" element={<SuplyCL />} />
        <Route path="/solution/FinancialS" element={<FinancialS />} />
        <Route path="/solution/Automative" element={<Automative />} />
        <Route path="/casestudy/Automative" element={<Automative />} />
        <Route path="/blog" element={<Blogs/>}/>
        <Route path="/BlogDetails/:title" element={<BlogDetails />} />
        {/* <Route path="/BlogContents" element={<BlogContents/>} /> */}
        <Route path="/TitlePreview"element={<TitlePreview/>}/>
        {/* <Route path="/" exact component={PopularPosts} /> */}
        {/* Route for individual blog post detail */}
        <Route path="/BlogDetail" component={BlogDetail} />

         <Route path="*" element={<PageNotFound />} /> 
      </Routes>
      <CookiesBanner />
      <Footer />
    </Router>
     
  );
}

export default App;
