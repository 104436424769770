import React from "react";
import "./product_common.css";

export default function School_ERP() {
  return (
    <>
      <div className="banner parbase industries-gradient aem-GridColumn aem-GridColumn--default--12">
        <section id="sml_ht_home_banner">
          <article className="container">
            <div className="row">
              <div className="col-md-9 col-sm-12 col-xs-12 ">
                <h1 id="heading" className="h2-heading white-color l-top"> School Erp</h1>
              </div>
            </div>
          </article>
        </section>
        <section class="pt75">
  <article class="container">
    <div class="row">
      <div class="col-md-6 col-sm-12 col-xs-12 text-center-xs wow fadeInLeft" data-wow-delay="0.6s"> 
      <img src="/Images/facethumb.jpg"></img>
      </div>
      <div class="col-md-6 col-sm-12 col-xs-12 mb-sm-20 wow fadeInRight" data-wow-delay="0.6s">
        <h3 class="mb10 mt0">Adminstration</h3> 
        <h3>1. Gate Automation and Management System
        </h3>
        <ul>
          <li>RFID based Gate Automation with Flip barrier biometric
        </li>
          <li>Tripod Turnstyle and RFID card based with single entry on   single authentication biometrics.
        </li>
        </ul>
        <h3>2. Attendance and authorization Mechanism</h3>
        <ul>
          <li>Biometrics - thumb + face
        </li>
          <li>RFID card and antenna
        </li>
          <li>Mobile application tab based for teachers   to put class / lecture attendance
        </li>
          
        </ul>
       </div>
    </div>
  </article>
</section>
<section class="pt75">
  <article class="container">
    <div class="row">
      <div class="col-md-6 col-sm-12 col-xs-12 mb-sm-20 wow fadeInLeft" data-wow-delay="0.6s">
        <h3 class="mb10 mt0">3. Authentication and Authorizations
        </h3>
        <ul>
          <li>In case of biometric - reading thumb readings and verifying same
        </li>
          <li>In case of biometric - reading thumb readings and verifying same
        </li>
          <li>RFID ID card assignment and mapping of students for enrolled students
        </li>
          <li>printing ID cards and Issue ID cards for enrolled students
        </li>
          <li>School Uniform based RFID identification
          </li>
          </ul>
          <h3 class="mb10 mt0">4. Auto Backup of database</h3>
          <ul>
          <li>Daily backup of database automatically on secure computer
        </li>
        <li>Project available with cloud integration, mobile application for Staff, student and can design for customized users as well
        </li>
        <li>System Architecture is Distributed loosely coupled system
        </li>
          </ul>

      </div>
      <div class="col-md-6 col-sm-12 col-xs-12 text-center-xs wow fadeInRight" data-wow-delay="0.6s"> 
      <img src="/Images/user.jpg"></img>
      </div>
    </div>
  </article>
</section>
<section class="pt75">
  <article class="container">
    <div class="row">
      <div class="col-md-6 col-sm-12 col-xs-12 text-center-xs wow fadeInLeft" data-wow-delay="0.6s"> 
      <img src="/Images/event.jpg"></img>
      </div>
      
       <div class="col-md-6 col-sm-12 col-xs-12 mb-sm-20 wow fadeInRight" data-wow-delay="0.6s">
        <h3 class="mb10 mt0">5. SMS and Email
        </h3>
        <ul>
          <li>Setting up SMS API configuration
        </li>
          <li>Setting up communication Email SMTP details
        </li>
          <li>Email and SMS Dashboard and logs for 30 days
        </li>
        </ul>
        <h3 class="mb10 mt0">6. School Event Management
        </h3>
        <ul>
            <li>Create Event
            </li>
            <li>Request a Budget for Event and approval procedure
            </li>
            <li>Event Notice, Broadcast & Schedule
            </li>
            <li>Event Participants & involvement
            </li>
        </ul>
    </div>
    </div>
  </article>
</section>
<section class="pt75">
  <article class="container">
    <div class="row">
      <div class="col-md-6 col-sm-12 col-xs-12 mb-sm-20 wow fadeInLeft" data-wow-delay="0.6s">
        <h3 class="mb10 mt0">School Account
        </h3>
        <h3>1. Student Accounts tracking
        </h3>
        <ul>
          <li>View Transaction History, Impose fees and fine, Impose Class Fine
        </li>
          <li>Fees and Fine Detail Reports, Payment History, Discount to student
        </li>
        </ul>
        <h3>2. Fee Structure and management
        </h3>
        <ul>
            <li>
                Fee Structure definition & its breakups ,Imposing & as signing fees, Category definition for fees

            </li>
        </ul>

      </div>
      <div class="col-md-6 col-sm-12 col-xs-12 text-center-xs wow fadeInRight" data-wow-delay="0.6s"> 
      <img src="/Images/fee.jpg"></img>
      </div>
    </div>
  </article>
</section>
<section class="pt75">
    <article class="container">
      <div class="row">
        <div class="col-md-6 col-sm-12 col-xs-12 text-center-xs wow fadeInLeft" data-wow-delay="0.6s"> 
        <img src="/Images/payroll.jpg"></img>
        </div>
        
         <div class="col-md-6 col-sm-12 col-xs-12 mb-sm-20 wow fadeInRight" data-wow-delay="0.6s">
          <h3 class="mb10 mt0">3. Human Resource</h3>
          <ul>
            <li>Appointment letter, Offer Letter, Releasing letter, Appraisal creation, Training need Identification, Annual training plan
            </li>
          </ul>
          <h3>4. Payroll
        </h3>
        <ul>
            <li>Define Payroll, Salary deductions, salary slips, Payroll Processing, Salary Structure approved from HR
            </li>
        </ul>
         </div>
      </div>
    </article>
  </section>
  <section class="pt75">
    <article class="container">
      <div class="row">
        <div class="col-md-6 col-sm-12 col-xs-12 mb-sm-20 wow fadeInLeft" data-wow-delay="0.6s">
          <h3 class="mb10 mt0">5. Daybook Management</h3>
          <ul>
            <li>Expenses/Order category,New Entry, Edit Entry, View Entry
            </li>
            
          </ul>
          <h3>6. Ledger
        </h3>
        <ul>
            <li>Create/Edit Ledger, Issue Cheque, Cheque Deposite management, Liquid Cash Management, Generate Trail Balance Statement between the dates
            </li>
        </ul>
  
        </div>
        <div class="col-md-6 col-sm-12 col-xs-12 text-center-xs wow fadeInRight" data-wow-delay="0.6s"> 
        <img src="/Images/ledger.jpg"></img>
        </div>
      </div>
    </article>
  </section>
  <section class="pt75">
    <article class="container">
      <div class="row">
        <div class="col-md-6 col-sm-12 col-xs-12 text-center-xs wow fadeInLeft" data-wow-delay="0.6s"> 
        <img src="/Images/inventory1.jpg"></img>
        </div>
        
         <div class="col-md-6 col-sm-12 col-xs-12 mb-sm-20 wow fadeInRight" data-wow-delay="0.6s">
          <h3 class="mb10 mt0">7. Inventory</h3>
          <ul>
            <li>Assets against requisition, new Assets, labeling & identification, location or moving of assets</li>
            <li>Assets against requisition, new Assets, labeling & identification, location or moving of assets
            </li>

          </ul>
          <h3>8. Requisition Management & PO</h3>
        <ul>
            <li>New Requisition Category, Apply for order, Approval Process, Accountant approval - principal approval</li>
             <li>Purchase Order, Reject Order, Return of material Rejected
            </li>
        </ul>
         </div>
      </div>
    </article>
  </section>
  <section class="pt75">
    <article class="container">
      <div class="row">
        <div class="col-md-6 col-sm-12 col-xs-12 mb-sm-20 wow fadeInLeft" data-wow-delay="0.6s">
          <h3 class="mb10 mt0">9. Discount & concession</h3>
          <ul>
            <li>Add Discount Category, students wise discount dashboard, Discount & concession dashboard

            </li>
            
          </ul>
          <h3>10. Classroom Assets

        </h3>
        <ul>
            <li>View Classroom Assets, Show Fixed assets, assign assets to classroom, Create and manage new classroom

            </li>
        </ul>
  
        </div>
        <div class="col-md-6 col-sm-12 col-xs-12 text-center-xs wow fadeInRight" data-wow-delay="0.6s"> 
        <img src="/Images/student.jpg"></img>
        </div>
      </div>
    </article>
  </section>

  <section class="pt75">
    <article class="container">
      <div class="row">
        <div class="col-md-6 col-sm-12 col-xs-12 text-center-xs wow fadeInLeft" data-wow-delay="0.6s"> 
        <img src="/Images/hostel-management.jpg"></img>
        </div>
        
         <div class="col-md-6 col-sm-12 col-xs-12 mb-sm-20 wow fadeInRight" data-wow-delay="0.6s">
          <h3 class="mb10 mt0">School Management</h3>
          <h3>1. Hostel Management and Automation
        </h3>
          <ul>
            <li>Registration, creation of multiple hostel</li>
            <li>Floor management,Attendance and tracking on mobile Applications of parents , Available Seats & bed Allocation
            </li>
            <li>RFID or biometric Integration for attendance & zone tracking,Entrance restrictions & in out using flap barriers with bioemetrics
            </li>
          </ul>
          <h3>2. Feedback Module</h3>
        <ul>
            <li>Feedback to Teachers, student & parents integration over the feedback </li>
            <li>Web account or mobile application enabled</li>
        </ul>
         </div>
      </div>
    </article>
  </section>
  <section class="pt75">
    <article class="container">
      <div class="row">
        <div class="col-md-6 col-sm-12 col-xs-12 mb-sm-20 wow fadeInLeft" data-wow-delay="0.6s">
          <h3 class="mb10 mt0">3. Configuration and Settings of Project
        </h3>
          <ul>
            <li>User Configuration,Fine Category Definition,class plan</li>
            <li>Add New Class, Section or Division,Subject
            </li>
            <li>SMS configuration and settings,Email Settings
            </li>
          </ul>
          <h3>4. MIS and Reporting Dashboard</h3>
        <ul>
            <li>Academy, Library, Accounts, Hostel, Staff List, Transport reports, Recruitment ,Health Reports</li>
            <li>Event & Feedback support report ,Assets & PO reports</li>
            <li>Daybook reports & auto email, Student admission reports, Discount Report</li>
        </ul>
  
        </div>
        <div class="col-md-6 col-sm-12 col-xs-12 text-center-xs wow fadeInRight" data-wow-delay="0.6s"> 
        <img src="/Images/mis-stud.jpg"></img>
        </div>
      </div>
    </article>
  </section>
  <section class="pt75">
    <article class="container">
      <div class="row">
        <div class="col-md-6 col-sm-12 col-xs-12 text-center-xs wow fadeInLeft" data-wow-delay="0.6s"> 
        <img src="/Images/online.jpg"></img>
        </div>
        
         <div class="col-md-6 col-sm-12 col-xs-12 mb-sm-20 wow fadeInRight" data-wow-delay="0.6s">
          <h3 class="mb10 mt0"> Staff Management
        </h3>
        <h3>1. Online Examinations</h3>
          <ul>
            <li>Create Online Exam for Class,Time setting for online Exam
            </li>
            <li>Create Online Exam for Class,Time setting for online Exam</li>
            
          </ul>
          <h3>2. Notice Board
        </h3>
        <ul>
            <li>Create notice, Approval Hierarchy and approval process
            </li>
            <li>Show notice to whom it concern, Mobile Application Notice Broadcast</li>
        </ul>
        <h3>3. School Leaving Certificate</h3>
        <ul>
            <li>Create Leaving Certificate, Issue LC criteria and permissions to allow</li>
        </ul>
         </div>
      </div>
    </article>
  </section>
  <section class="pt75">
    <article class="container">
      <div class="row">
        <div class="col-md-6 col-sm-12 col-xs-12 mb-sm-20 wow fadeInLeft" data-wow-delay="0.6s">
          <h3 class="mb10 mt0">4. Leave Management</h3>
          <ul>
            <li>Apply for leave from staff login, Apply for leave from mobile Application</li>
            <li>Apply for leave from staff login, Apply for leave from mobile Application
            </li>
            
          </ul>
          <h3>5. Syllabus Management
        </h3>
        <ul>
            <li>Class Definition & Subject definition Selection, Syllabus Against the Subject</li>
            <li>Class Definition & Subject definition Selection, Syllabus Against the Subject
            </li>
            <li>Month update to students, point covered marked from student mobile applications
            </li>
            <li>Month update to students, point covered marked from student mobile applications
            </li>
        </ul>
  
        </div>
        <div class="col-md-6 col-sm-12 col-xs-12 text-center-xs wow fadeInRight" data-wow-delay="0.6s"> 
        <img src="/Images/leave.jpg"></img>
        </div>
      </div>
    </article>
  </section>
  <section class="pt75">
    <article class="container">
      <div class="row">
        <div class="col-md-6 col-sm-12 col-xs-12 text-center-xs wow fadeInLeft" data-wow-delay="0.6s"> 
        <img src="/Images/blog.jpg"></img>
        </div>
        
         <div class="col-md-6 col-sm-12 col-xs-12 mb-sm-20 wow fadeInRight" data-wow-delay="0.6s">
          <h3 class="mb10 mt0">6. Blogs
        </h3>
          <ul>
            <li>Queries, Technology & innovation, Discussion, Buy & Sale

            </li>
          </ul>
          <h3>7. Time Table

        </h3>
        <ul>
            <li>Synchronize with syllabus, mark as completed, Attendance against syllabus</li>
            <li>Calendar visuals & notification to staff</li>
        </ul>
         </div>
      </div>
    </article>
  </section>
  <section class="pt75">
    <article class="container">
      <div class="row">
        <div class="col-md-6 col-sm-12 col-xs-12 mb-sm-20 wow fadeInLeft" data-wow-delay="0.6s">
          <h3 class="mb10 mt0">Parent</h3>
          <h3>1. Student Admission process
        </h3>
          <ul>
            <li>Student Registration form, Student Short list process</li>
            <li>Student Enrollment, Student Promotion</li>
            
          </ul>
          <h3>2. Bus Tracking and Automation </h3>
        <ul>
            <li>GPS tracker Unit for real time location tracking</li>
            <li>School BUS-STOP REGISTRATION & School Bus fuel consumption</li>
            <li>Update to parents on location of bus</li>
            <li>Bus nearby arrival reminder for the parents or authorized mobile user</li>
            <li>Bus with CCTV & RFID card base authentication</li>
            <li>Route definition & tracking for particular vehicle</li>
        </ul>
        <h3>3. Birthday
        </h3>
         <ul>
            <li>Notification, SMS and E-Mail on Birthday</li>
         </ul>
  
        </div>
        <div class="col-md-6 col-sm-12 col-xs-12 text-center-xs wow fadeInRight" data-wow-delay="0.6s"> 
        <img src="/Images/admission.jpg"></img>
        </div>
      </div>
    </article>
  </section>
  <section class="pt75">
    <article class="container">
      <div class="row">
        <div class="col-md-6 col-sm-12 col-xs-12 text-center-xs wow fadeInLeft" data-wow-delay="0.6s"> 
        <img src="/Images/library2.jpg"></img>
        </div>
        
         <div class="col-md-6 col-sm-12 col-xs-12 mb-sm-20 wow fadeInRight" data-wow-delay="0.6s">
          <h3 class="mb10 mt0">Linbrary And Resources</h3>
          <h3>Library management and Automation</h3>
          <ul>
            <li>Library management and Automation</li>
            <li>Book Inventory</li>
            <li>Rack Management and location </li>
            <li>Barcode Integration with all books
            </li>
            <li>RFID tag integration with book
            </li>
            <li>Book Allocation/Issue
            </li>
            <li>Fine against book assigned
            </li>
            <li>book requisition
            </li>
            <li>Library Zone tracking and book out of network without issuing alert
            </li>
          </ul>
         </div>
      </div>
    </article>
  </section>
  <section class="pt75">
    <article class="container">
      <div class="row">
        <div class="col-md-6 col-sm-12 col-xs-12 mb-sm-20 wow fadeInLeft" data-wow-delay="0.6s">
          <h3 class="mb10 mt0">Student
        </h3>
          <h3>Homework and Assignments</h3>
          <ul>
            <li>Define Homework
            </li>
            <li>Show HomeWork
            </li>
            <li>Student Homework and assignment completion status from staff</li>
            
          </ul>
        </div>
        <div class="col-md-6 col-sm-12 col-xs-12 text-center-xs wow fadeInRight" data-wow-delay="0.6s"> 
         <img src="/Images/homework.jpg"></img>
        </div>
      </div>
    </article>
  </section>
              </div>
           
    </>
  );
}
