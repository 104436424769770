
import { useEffect, useRef, useState } from "react";

import "./Home.css";

import { Link } from "react-router-dom";

import { useParams } from 'react-router-dom';





const Home = () => {

  


  
  const [isWhiteBackground, setIsWhiteBackground] = useState("");



  const [activeSection, setActiveSection] = useState(null);

  // const [showText, setShowText] = useState(false);

  const [currentDiv, setCurrentDiv] = useState("");


 

  const Hexagon = ({ text, url }) => (

    <Link to={url} className="hexagon-link">

      <div className="hexagon">

        {/* <img src={imageUrl}  /> */}



        <p  className="hex-text">{text}</p>

      </div>

    </Link>

  );

  const honeycombData = [

    // Row 1 (6 hexagons)

    [

      { url: "/service/digital-marketing", text: "Digital Marketing" },

      {

        text: "Social Media Development and Management",

        url: "/service/SocialMediaDevelopment",

      },

      {

        text: "Technical Document / Content Writing",

        url: "/service/technical-documents-or-content",

      },

      { text: "Website Development", url: "/service/WebsiteDevelopment" },

      { text: "GIS App Development", url: "/service/gis-app" },

      { text: "Mobile App Development", url: "/service/MobileApplication" },

    ],

    // Row 2 (4 hexagons)

    [

      { text: "Gaming Development", url: "/service/Gaming" },

      { text: "Blockchain", url: "/service/blockchain" },

      { text: "Advanced Toll Survey", url: "/service/advanced-toll-survey" },

      { text: "Internet of Things", url: "/service/IoT" },

      { text: "Business Intelligence", url: "/service/BusinessIntelligence" },

    ],

    // Row 3 (6 hexagons)

    [

      { text: "ERP/RPA Implementation", url: "/service/erp-rpa-implementaion" },

      { text: "SAAS Development support", url: "/service/SaasDevelopment" },

      { text: "Reporting and Analytics", url: "/services/reporting" },

      { text: "Banking planforms", url: "/service/BankingPlatform" },

      { text: "VOIP Platforms", url: "/service/VOIP" },

      { text: "E commerce platform", url: "/service/Ecommerce" },

    ],

    // Row 4 (4 hexagons)

    [

      { text: "Website Testing", url: "/service/WebsiteTesting" },

      { text: "Mobile App Testing", url: "/service/MobileApplication" },

      { text: "Cyber Security", url: "/service/CyberSecurity" },

      {

        text: "Business Process Management",

        url: "/services/business-managment-process",

      },

      { text: "Staffing and consulting Services", url: "/service/Staffing" },

    ],

  ];



  const divRefs = useRef([]);

  const handleScroll = () => {
    const windowHeight = window.innerHeight;
    const scrollTop = window.scrollY;
    const divHeight = windowHeight;
    const currentDivIndex = Math.floor(scrollTop / divHeight);
    const currentDivId = `item-${currentDivIndex + 1}`;
    const currentDiv = document.getElementById(currentDivId);

   

    // Check if currentDiv is not null before accessing its properties
    if (currentDiv) {
       
        
        currentDiv.style.backgroundColor === "" ? setIsWhiteBackground("black") : setIsWhiteBackground("white");

        const navLinks = document.querySelectorAll('.nav-link span');
        const navLines = document.querySelectorAll('.nav-link .line');
        const sideNav = document.querySelectorAll('.nav-link');

        for (let i = 0; i < navLinks.length; i++) {
            navLinks[i].style.color = currentDiv.style.backgroundColor === "" || currentDiv.style.backgroundColor === "white" || currentDiv.style.backgroundColor === "rgb(150, 53, 150)" || currentDiv.style.backgroundColor === "rgb(255, 255, 255)" ? "black" : "white";
        }

        for (let j = 0; j < navLines.length; j++) {
            navLines[j].style.backgroundColor = currentDiv.style.backgroundColor === "" || currentDiv.style.backgroundColor === "white" || currentDiv.style.backgroundColor === "rgb(150, 53, 150)" || currentDiv.style.backgroundColor === "rgb(255, 255, 255)" ? "black" : "white";
        }

        setActiveSection(currentDivId);
        setCurrentDiv(currentDivId);
    } else {
        
    }
};



  const scrollToSection = (id) => {

    const section = document.getElementById(id);

    if (section) {

      section.scrollIntoView({ behavior: "smooth" });

    }

  };



  useEffect(() => {

    window.scrollTo(0, 0);

    window.addEventListener("scroll", handleScroll);



    return () => window.removeEventListener("scroll", handleScroll);

  }, []);

  const Home = () => {
    // Extract the sectionId from the URL parameters
    const { sectionId } = useParams();

    // Scroll to the section with the specified id when the component mounts
    useEffect(() => {
      const section = document.getElementById(sectionId);
      if (section) {
        section.scrollIntoView({ behavior: 'smooth' });
      }
    }, [sectionId]);
  }

  return (

    <div className="main-div">

      <div className="desktop-nav">

        <nav

          style={{

            zIndex: 10,

            top: 0,

            bottom: 0,

            margin: "auto",

            color: isWhiteBackground,



          }}

          className="h-50  position-fixed align-middle pe-4 "

        >

          {/* <li

            onClick={() => scrollToSection("item-1")}

            className={`nav-link p-3 ${activeSection === "item-1" ? "active" : ""

              }`}

          >

            <div className="line" ></div>

            <span className="" >Welcome to <br></br> DigiTechub</span>

          </li> */}

          <li

            onClick={() => scrollToSection("item-2")}

            className={`nav-link p-3 ${activeSection === "item-2" ? "active" : ""

              }`}

          >

            <div className="line"></div>

            <span className="">Your <br></br> Gateway</span>

          </li>

          <li

            onClick={() => scrollToSection("item-3")}

            className={`nav-link p-3 ${activeSection === "item-3" ? "active" : ""

              }`}

          >

            <div className="line"></div>

            <span className="">Vission</span>

          </li>

          <li

            onClick={() => scrollToSection("item-4")}

            className={`nav-link p-3 ${activeSection === "item-4" ? "active" : ""

              }`}

          >

            <div className="line"></div>

            <span className="">Mission</span>

          </li>

          <li

            onClick={() => scrollToSection("item-5")}

            className={`nav-link p-3 ${activeSection === "item-5" ? "active" : ""

              }`}

          >

            <div className="line"></div>

            <span className="">Goal</span>

          </li>

          <li

            onClick={() => scrollToSection("item-6")}

            className={`nav-link p-3 ${activeSection === "item-6" ? "active" : ""

              }`}

          >

            <div className="line"></div>

            <span className="">Approach</span>

          </li>

          <li

            onClick={() => scrollToSection("item-7")}

            className={`nav-link p-3 ${activeSection === "item-7" ? "active" : ""

              }`}

          >

            <div className="line"></div>

            <span className="">Innovations</span>

          </li>

          <li

            onClick={() => scrollToSection("item-8")}

            className={`nav-link p-3 ${activeSection === "item-8" ? "active" : ""

              }`}

          >

            <div className="line"></div>

            <span className="">Clients & <br></br> Partners</span>

          </li>

          <li

            onClick={() => scrollToSection("item-99")}

            className={`nav-link p-3 ${activeSection === "item-99" ? "active" : ""

              }`}

          >

            <div className="line"></div>

            <span className="">Strategic</span>

          </li>

        </nav>

      </div>



      {/* {showText && <p className="scroll-text">Scrolling text</p>} */}

      <div className="image-container" id="main-div">

        <div

          ref={(el) => (divRefs.current[0] = el)}

          id="items-1"

          className="screen col-md-12" 
          // style={{ maxHeight: '100vh' }}



        >
              <div
                id="item-1"
                data-aos="slide-down"
                data-aos-duration="700"
                className="container w-60 float-start"
              >
                <h1 id="heading" className="heading" >
                Welcome to DigiTecHub Inc
                </h1>

                <div className=" mt-3 item-1-align" >
                  <p className="fullpage-desc white-color">
                    <em></em>
                  </p>

                  <p className="mb20 mb-xs-10 mt50 mt-xs-0 animateThird">
                    {/* <a href="navigate-your-next/digital-capabilities.html" title="Explore" className="btn btn-shutter-more text-uppercase fontweight600">Explore</a> */}
                  </p>

                  

                  <div className="row hidden-xs">
                    <div
                      className="col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-xs-20"
                      style={{ paddingLeft: '0' }}
                    >
                      <h3
                        className="fullpage-second-desc white-color"
                        // style={{ textAlign:}}
                      >
                       We help businesses change using technology.<br></br>We make businesses better with our products & services.<br></br>Let's make your business succeed in the digital world.

                      </h3>
                    </div>
                  </div>
                </div>
              </div>

              </div>



        <div

          ref={(el) => (divRefs.current[1] = el)}

          id="item-2"

          className="screen col-md-12"

          style={{

            position: "relative",

            backgroundColor: "#AB6604",

            // backgroundImage:

            //   'url("https://images.unsplash.com/photo-1527380992061-b126c88cbb41?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1965&q=80")',

            backgroundRepeat: "no-repeat",

            backgroundSize: "cover",

          }}

        >



          <div

            id="item-2"



            className="col-md-9 float-center pt-3 pb-3 d-flex justify-content-center flex-wrap "



          >

            <div className="freeflowhtml aem-GridColumn aem-GridColumn--default--12">

              <section id="core_capabilities" className="scroll-section relative bg-topaz-medium home_promo_banner">

                <article className="container">

                  <div className="row">

                    <div className="content home-second-heading-top wow fadeInUp" data-wow-delay="0.3s">

                      <div className="col-lg-6 col-md-6 col-sm-6 col-md-offset-6 col-xs-12">

                        <div id="why">

                          <h2 className="home-second-heading white-color mt-2 mb-xs-10" >Your Gateway to Comprehensive Digital Excellence! </h2>

                          <p className="fullpage-desc white-color">

                            <em >Welcome to the World, where you can get really good at everything digitally
 </em>

                          </p>

                          <p className="mb20 mb-xs-10 mt50 mt-xs-0 animateThird">
 
                  {/* <a href="navigate-your-next/digital-capabilities.html" title="Explore" className="btn btn-shutter-more text-uppercase fontweight600">Explore</a> */}
 
                </p>

                          <div className="row hidden-xs">

                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-xs-20" style={{ paddingLeft: "0" }}>

                              <h3 className="fullpage-second-desc white-color" >We're DigiTechub Inc. We're in Florida, USA. We're offering various Business & IT Offerings. We are really good at mixing money, tech, financial, manpower, & online E-commerce selling solutions to make businesses better. Let's use tech to make your business awesome worldwide.
</h3>



                            </div>

                          </div>

                        </div>

                      </div>

                    </div>

                  </div>

                  <div className="background fullCoverImg ai-powered-core-img dnf-digital-capabilities-lead" id="whyimg" style={{ backgroundImage: 'url("/Images/digital-capabilities-lead-tab.png")' }}></div>

                </article>

              </section>

            </div>



          </div>



        </div>












        <div

          ref={(el) => (divRefs.current[2] = el)}

          id="item-3"

          className="screen col-md-12"

          style={{

            backgroundAttachment: "fixed",

            // backgroundImage:

            //   'url("https://img.freepik.com/premium-vector/cave-vector-cartoon-background_100478-544.jpg?size=626&ext=jpg&ga=GA1.1.714599829.1686029259&semt=ais")',

            backgroundColor: "#0271B1",

            backgroundRepeat: "no-repeat",

            backgroundSize: "cover",

            backgroundPosition: "center",

          }}

        >

          <div

            id="item-3"



            className="col-md-9 float-center pt-3 pb-3 d-flex justify-content-center flex-wrap "

          >

            <div className="freeflowhtml aem-GridColumn aem-GridColumn--default--12">

              <section id="core_capabilities" className="scroll-section relative bg-topaz-medium home_promo_banner">

              </section>

              <div className="background fullCoverImg-left agile-digital-bg-img dnf-digital-operating-lead" style={{ backgroundImage: 'url("/Images/digital-operating-models-lead.png")' }}></div>



              <article className="container" >



                <div className="row ">



                  <div className="content home-third-heading-top ">

                    <div className="col-lg-6 col-md-6 col-sm-6 col-md-offset-6 col-xs-12  " data-wow-delay="0.3s" style={{ float: "right" }}>

                      <div id="what">

                        <h2 className="home-second-heading white-color  mb-xs-10" >Vision  </h2>

                        <p className="fullpage-desc white-color">

                          <em> We're leading the way in changing the world with technology
</em>

                        </p>

                        <p className="mb20 mb-xs-10 mt50 mt-xs-0 animateThird">

                          {/* <a href="navigate-your-next/digital-operating-models.html" title="Explore" className="btn btn-shutter-more text-uppercase fontweight600">Explore</a> */}

                        </p>

                        <div className="row hidden-xs">

                          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-xs-20" style={{ paddingLeft: "0" }}>

                            <h3 className="fullpage-second-desc white-color">We want to be the leaders in changing how businesses use technology. We imagine a future where all companies use tech really well, making things easier & more successful in the digital world.
</h3>



                          </div>

                        </div>

                      </div>

                    </div>

                  </div>

                </div>



              </article>

              {/* </section> */}

            </div>

          </div>



        </div>









        <div

          ref={(el) => (divRefs.current[3] = el)}

          id="item-4"

          className="screen col-md-12"

          style={{

            position: "relative",

            // backgroundImage:

            //   'url("https://images.unsplash.com/photo-1472289065668-ce650ac443d2?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1169&q=80")',

            backgroundColor: "#963596",

            backgroundRepeat: "no-repeat",

            backgroundSize: "cover",

          }}

        >

          <div

            id="item-4"



            className="col-md-9 float-center pt-3 pb-3 d-flex justify-content-center flex-wrap "

          >




            <div className="freeflowhtml aem-GridColumn aem-GridColumn--default--12">

              <section id="talent_transformations" className="scroll-section relative bg-amethyst-medium home_promo_banner">

                <article className="container">

                  <div className="row">

                    <div className="content home-second-heading-top">

                      <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 wow fadeInUp animateFirst" data-wow-delay="0.3s">

                        <div id="How">

                          <h2 className="home-second-heading white-color mb20 mb-xs-10" style={{ marginBlockEnd: "0" }}>Mission </h2>

                          <p className="fullpage-desc white-color">

                            <em>We help the world succeed by using Latest Technology
 </em>

                          </p>

                          <p className="mb20 mb-xs-10 mt50 mt-xs-0 animateThird">

                            {/* <a href="navigate-your-next/talent-transformations.html" title="Explore" className="btn btn-shutter-more text-uppercase fontweight600">Explore</a> */}

                          </p>

                          <div className="row hidden-xs">

                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-xs-20" style={{ paddingLeft: "0" }}>

                              <h3 className="fullpage-second-desc white-color" >At DigiTechub Inc., we want to help your business succeed. We work with companies all over the world to find new and personalized solutions to the problems of today's digital world. We use our knowledge and the latest tech to help our clients grow a lot.
</h3>

                              <p className="fullpage-second-more text-uppercase black-color mt20">

                                {/* <a href="iki/insights/digital-field-workforce-management.html" className="view-more white-color" title="View">View</a> */}

                              </p>

                            </div>

                          </div>

                        </div>

                      </div>

                    </div>

                  </div>

                  <div className="background fullCoverImg learn-bg-img dnf-talent-transformations-lead" style={{ backgroundImage: 'url("/Images/talent-transformations-lead.png")' }}></div>

                </article>

              </section>

            </div>



          </div>

        </div>


{/* new adding for digitechhub id-5*/}



<div

ref={(el) => (divRefs.current[2] = el)}

id="item-5"

className="screen col-md-12"

style={{

  backgroundAttachment: "fixed",

  // backgroundImage:

  //   'url("https://img.freepik.com/premium-vector/cave-vector-cartoon-background_100478-544.jpg?size=626&ext=jpg&ga=GA1.1.714599829.1686029259&semt=ais")',

  backgroundColor: "#0F9FFA",

  backgroundRepeat: "no-repeat",

  backgroundSize: "cover",

  backgroundPosition: "center",

}}

>

<div

  id="item-3"



  className="col-md-9 float-center pt-3 pb-3 d-flex justify-content-center flex-wrap "

>

  <div className="freeflowhtml aem-GridColumn aem-GridColumn--default--12">

    <section id="core_capabilities" className="scroll-section relative bg-topaz-medium home_promo_banner">

    </section>

    <div className="background fullCoverImg-left agile-digital-bg-img dnf-digital-operating-lead" ></div>



    <article className="container" >



      <div className="row ">



        <div className="content home-third-heading-top ">

          <div className="col-lg-6 col-md-6 col-sm-6 col-md-offset-6 col-xs-12  " data-wow-delay="0.3s" style={{ float: "right" }}>

            <div id="what">

              <h2 className="home-second-heading white-color  mb-xs-10" >Customer Value Creation  </h2>

              <p className="fullpage-desc white-color">

                <em> Your Success, Our Priority</em>

              </p>

              <p className="mb20 mb-xs-10 mt50 mt-xs-0 animateThird">

                {/* <a href="navigate-your-next/digital-operating-models.html" title="Explore" className="btn btn-shutter-more text-uppercase fontweight600">Explore</a> */}

              </p>

              <div className="row hidden-xs">

                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-xs-20" style={{ paddingLeft: "0" }}>

                  <h3 className="fullpage-second-desc white-color">We think we're doing well when our clients do well. Everything we do is to help your business in a real, long-lasting way. Your goals are our goals, & we'll work together to turn problems into chances to do better. Let's team up to make your business grow & succeed.
</h3>



                </div>

              </div>

            </div>

          </div>

        </div>

      </div>



    </article>

    {/* </section> */}

  </div>

</div>



</div>

      

{/* end new add id-5 for digitechhub */}

















 {/*  add id-6 for digitechhub */}

<div

          ref={(el) => (divRefs.current[1] = el)}

          id="item-6"

          className="screen col-md-12"

          style={{

            position: "relative",

            backgroundColor: "#005792",

            
            backgroundRepeat: "no-repeat",

            backgroundSize: "cover",

          }}

        >



          <div

            id="item-2"



            className="col-md-9 float-center pt-3 pb-3 d-flex justify-content-center flex-wrap "



          >

            <div className="freeflowhtml aem-GridColumn aem-GridColumn--default--12">

              <section id="core_capabilities" className="scroll-section relative bg-topaz-medium home_promo_banner">

                <article className="container">

                  <div className="row">

                    <div className="content home-second-heading-top wow fadeInUp" data-wow-delay="0.3s">

                      <div className="col-lg-6 col-md-6 col-sm-6 col-md-offset-6 col-xs-12">

                        <div id="why">

                          <h2 className="home-second-heading white-color  mb-xs-10" >Approach: Transformative Digital Solutions </h2>

                          <p className="fullpage-desc white-color">

                            <em > </em>

                          </p>

                          {/* <p className="mb20 mb-xs-10 mt50 mt-xs-0 animateThird">
 
                  <a href="navigate-your-next/digital-capabilities.html" title="Explore" className="btn btn-shutter-more text-uppercase fontweight600">Explore</a>
 
                </p> */}

                          <div className="row hidden-xs">

                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-xs-20" style={{ paddingLeft: "0" }}>

                              <h3 className="fullpage-second-desc white-color">Welcome to DigiTechub Inc. We're all about making new ideas happen. We make cool digital stuff & put it to work for you. Find out how we do things differently to help you in the digital world.
</h3>



                            </div>

                          </div>

                        </div>

                      </div>

                    </div>

                  </div>

                  {/* <div className="background fullCoverImg ai-powered-core-img dnf-digital-capabilities-lead" id="whyimg" style={{ backgroundImage: 'url("/Images/digital-capabilities-lead-tab.png")' }}></div> */}

                </article>

              </section>

            </div>



          </div>



        </div>


 {/* end add id-6 for digitechhub */}













{/* new add id-7 for digitechhub */}

<div

ref={(el) => (divRefs.current[2] = el)}

id="item-7"

className="screen col-md-12"

style={{

  backgroundAttachment: "fixed",

  // backgroundImage:

  //   'url("https://img.freepik.com/premium-vector/cave-vector-cartoon-background_100478-544.jpg?size=626&ext=jpg&ga=GA1.1.714599829.1686029259&semt=ais")',

  backgroundColor: " #272343",

  backgroundRepeat: "no-repeat",

  backgroundSize: "cover",

  backgroundPosition: "center",

}}

>

<div

  id="item-3"



  className="col-md-9 float-center pt-3 pb-3 d-flex justify-content-center flex-wrap "

>

  <div className="freeflowhtml aem-GridColumn aem-GridColumn--default--12">

    <section id="core_capabilities" className="scroll-section relative bg-topaz-medium home_promo_banner">

    </section>

    {/* <div className="background fullCoverImg-left agile-digital-bg-img dnf-digital-operating-lead" style={{ backgroundImage: 'url("/Images/digital-operating-models-lead.png")' }}></div> */}



    <article className="container" >



      <div className="row ">



        <div className="content home-third-heading-top ">

          <div className="col-lg-6 col-md-6 col-sm-6 col-md-offset-6 col-xs-12  " data-wow-delay="0.3s" style={{ float: "right" }}>

            <div id="what">

              <h2 className="home-second-heading white-color  mb-xs-10" >Innovation at the Core </h2>

              <p className="fullpage-desc white-color">

                <em> </em>

              </p>

              <p className="mb20 mb-xs-10 mt50 mt-xs-0 animateThird">

                {/* <a href="navigate-your-next/digital-operating-models.html" title="Explore" className="btn btn-shutter-more text-uppercase fontweight600">Explore</a> */}

              </p>

              <div className="row hidden-xs">

                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-xs-20" style={{ paddingLeft: "0" }}>

                  <h3 className="fullpage-second-desc white-color">Innovation at the Core
We think new ideas are really important at DigiTechub Inc. We use creative thinking & the latest tech in everything we do. We're always looking at what's new, trying out cool tech, & working with smart people to make sure our clients are ahead in the changing digital world.
</h3>



                </div>

              </div>

            </div>

          </div>

        </div>

      </div>



    </article>

    {/* </section> */}

  </div>

</div>



</div>


{/*end  new add id-7 for digitechhub */}






{/* new adding for digitechhub id-8*/}



<div

ref={(el) => (divRefs.current[3] = el)}

id="item-8"

className="screen col-md-12"

style={{

  position: "relative",

  
  backgroundColor: "#964320",

  backgroundRepeat: "no-repeat",

  backgroundSize: "cover",

}}

>

<div

  id="item-3"



  className="col-md-9 float-center pt-3 pb-3 d-flex justify-content-center flex-wrap "

>




  <div className="freeflowhtml aem-GridColumn aem-GridColumn--default--12">

    <section id="talent_transformations" className="scroll-section relative bg-amethyst-medium home_promo_banner">

      <article className="container">

        <div className="row">

          <div className="content home-second-heading-top">

            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 wow fadeInUp animateFirst" data-wow-delay="0.3s">

              <div id="How">

                <h2 className="home-second-heading white-color mb20 mb-xs-10" style={{ marginBlockEnd: "0" }}>Client-Centric Collaboration </h2>

                <p className="fullpage-desc white-color">

                  <em> </em>

                </p>

                <p className="mb20 mb-xs-10 mt50 mt-xs-0 animateThird">

                  {/* <a href="navigate-your-next/talent-transformations.html" title="Explore" className="btn btn-shutter-more text-uppercase fontweight600">Explore</a> */}

                </p>

                <div className="row hidden-xs">

                  <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-xs-20" style={{ paddingLeft: "0" }}>

                    <h3 className="fullpage-second-desc white-color" >Working together is how we get things done right. We don't just do stuff for our clients; we do it with them. We talk a lot & team up to understand what each business needs. By working together closely, we make digital solutions that fit our clients' goals perfectly.
</h3>

                    <p className="fullpage-second-more text-uppercase black-color mt20">

                      {/* <a href="iki/insights/digital-field-workforce-management.html" className="view-more white-color" title="View">View</a> */}

                    </p>

                  </div>

                </div>

              </div>

            </div>

          </div>

        </div>

        {/* <div className="background fullCoverImg learn-bg-img dnf-talent-transformations-lead" style={{ backgroundImage: 'url("/Images/talent-transformations-lead.png")' }}></div> */}

      </article>

    </section>

  </div>



</div>

</div>


{/* end new add id-8 for digitechhub */}

















{/* new add id-9 for digitechhub */}

<div

ref={(el) => (divRefs.current[2] = el)}

id="item-99"

className="screen col-md-12"

style={{

  backgroundAttachment: "fixed",

  // backgroundImage:

  //   'url("https://img.freepik.com/premium-vector/cave-vector-cartoon-background_100478-544.jpg?size=626&ext=jpg&ga=GA1.1.714599829.1686029259&semt=ais")',

  backgroundColor: " #272343",

  backgroundRepeat: "no-repeat",

  backgroundSize: "cover",

  backgroundPosition: "center",

}}

>

<div

  id="item-3"



  className="col-md-9 float-center pt-3 pb-3 d-flex justify-content-center flex-wrap "

>

  <div className="freeflowhtml aem-GridColumn aem-GridColumn--default--12">

    <section id="core_capabilities" className="scroll-section relative bg-topaz-medium home_promo_banner">

    </section>

    {/* <div className="background fullCoverImg-left agile-digital-bg-img dnf-digital-operating-lead" style={{ backgroundImage: 'url("/Images/digital-operating-models-lead.png")' }}></div> */}



    <article className="container" >



      <div className="row ">



        <div className="content home-third-heading-top ">

          <div className="col-lg-6 col-md-6 col-sm-6 col-md-offset-6 col-xs-12  " data-wow-delay="0.3s" style={{ float: "right" }}>

            <div id="what" style={{}}>

              <h2 className="home-second-heading white-color  mb-xs-10" >Strategic Planning & Execution  </h2>

              <p className="fullpage-desc white-color">

                <em>  </em>

              </p>

              <p className="mb20 mb-xs-10 mt50 mt-xs-0 animateThird">

                {/* <a href="navigate-your-next/digital-operating-models.html" title="Explore" className="btn btn-shutter-more text-uppercase fontweight600">Explore</a> */}

              </p>

              <div className="row hidden-xs">

                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-xs-20" style={{ paddingLeft: "0" }}>

                  <h3 className="fullpage-second-desc white-color">To succeed online, you always need a good plan. We talk with our clients to learn about their goals & problems. Then, we make a step-by-step plan to put things into action. This careful planning makes sure our digital solutions fit in well & actually make a difference.
</h3>



                </div>

              </div>

            </div>

          </div>

        </div>

      </div>



    </article>

    {/* </section> */}

  </div>

</div>



</div>


{/*end  new add id-9 for digitechhub */}









{/* new adding for digitechhub id-10*/}



<div

ref={(el) => (divRefs.current[3] = el)}

id="item-10"

className="screen col-md-12"

style={{

  position: "relative",

  
  backgroundColor: "#76508e",

  backgroundRepeat: "no-repeat",

  backgroundSize: "cover",

}}

>

<div

  id="item-3"



  className="col-md-9 float-center pt-3 pb-3 d-flex justify-content-center flex-wrap "

>




  <div className="freeflowhtml aem-GridColumn aem-GridColumn--default--12">

    <section id="talent_transformations" className="scroll-section relative bg-amethyst-medium home_promo_banner">

      <article className="container">

        <div className="row">

          <div className="content home-second-heading-top">

            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 wow fadeInUp animateFirst" data-wow-delay="0.3s">

              <div id="How">

                <h2 className="home-second-heading white-color mb20 mb-xs-10" style={{ marginBlockEnd: "0" }}>User-Centric Design
</h2>

                <p className="fullpage-desc white-color">

                  <em></em>

                </p>

                <p className="mb20 mb-xs-10 mt50 mt-xs-0 animateThird">

                  {/* <a href="navigate-your-next/talent-transformations.html" title="Explore" className="btn btn-shutter-more text-uppercase fontweight600">Explore</a> */}

                </p>

                <div className="row hidden-xs">

                  <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-xs-20" style={{ paddingLeft: "0" }}>

                    <h3 className="fullpage-second-desc white-color" >User experience is at the forefront of our design philosophy. We place a strong emphasis on user-centric design principles to ensure that our digital solutions are not only technologically advanced but also intuitive and enjoyable for end-users. This focus enhances user adoption, satisfaction, & overall success of the implemented solutions.
 </h3>

                    <p className="fullpage-second-more text-uppercase black-color mt20">

                      {/* <a href="iki/insights/digital-field-workforce-management.html" className="view-more white-color" title="View">View</a> */}

                    </p>

                  </div>

                </div>

              </div>

            </div>

          </div>

        </div>

        {/* <div className="background fullCoverImg learn-bg-img dnf-talent-transformations-lead" style={{ backgroundImage: 'url("/Images/talent-transformations-lead.png")' }}></div> */}

      </article>

    </section>

  </div>



</div>

</div>


{/* end new add id-10 for digitechhub */}








{/* new add id-11 for digitechhub */}

<div

ref={(el) => (divRefs.current[2] = el)}

id="item-11"

className="screen col-md-12"

style={{

  backgroundAttachment: "fixed",

  // backgroundImage:

  //   'url("https://img.freepik.com/premium-vector/cave-vector-cartoon-background_100478-544.jpg?size=626&ext=jpg&ga=GA1.1.714599829.1686029259&semt=ais")',

  backgroundColor: " #272343",

  backgroundRepeat: "no-repeat",

  backgroundSize: "cover",

  backgroundPosition: "center",

}}

>

<div

  id="item-3"



  className="col-md-9 float-center pt-3 pb-3 d-flex justify-content-center flex-wrap "

>

  <div className="freeflowhtml aem-GridColumn aem-GridColumn--default--12">

    <section id="core_capabilities" className="scroll-section relative bg-topaz-medium home_promo_banner">

    </section>

    {/* <div className="background fullCoverImg-left agile-digital-bg-img dnf-digital-operating-lead" style={{ backgroundImage: 'url("/Images/digital-operating-models-lead.png")' }}></div> */}



    <article className="container" >



      <div className="row ">



        <div className="content home-third-heading-top ">

          <div className="col-lg-6 col-md-6 col-sm-6 col-md-offset-6 col-xs-12  " data-wow-delay="0.3s" style={{ float: "right" }}>

            <div id="what">

              <h2 className="home-second-heading white-color mb-xs-10" >Continuous Innovation &and Optimization
</h2>

              <p className="fullpage-desc white-color">

                <em>  </em>

              </p>

              <p className="mb20 mb-xs-10 mt50 mt-xs-0 animateThird">

                {/* <a href="navigate-your-next/digital-operating-models.html" title="Explore" className="btn btn-shutter-more text-uppercase fontweight600">Explore</a> */}

              </p>

              <div className="row hidden-xs">

                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12  mb-xs-20" style={{ paddingLeft: "0" }}>

                  <h3 className="fullpage-second-desc white-color">At DigiTechub Inc., We're not just about giving stuff to you; we're about making big changes that help your business succeed for a long time. Come with us on a trip where we make new things, work together, & do really well in the digital world.
</h3>



                </div>

              </div>

            </div>

          </div>

        </div>

      </div>



    </article>

    {/* </section> */}

  </div>

</div>



</div>


{/*end  new add id-11 for digitechhub */}




















{/* <div

          id="item-5"

          className="screen col-md-12 "

          style={{

            backgroundAttachment: "fixed",


            backgroundColor: "white",

            backgroundRepeat: "no-repeat",

            backgroundSize: "cover",

          }}

        >

          <div

            id="item-5"

            className="col-md-9 float-center pt-3 pb-3 d-flex justify-content-center flex-wrap "

          >

            <h1 id="productserviceheading" className=" pq heading text-center">

              Products

            </h1>

            <div id="Productchat">

              <div className="w-100 d-flex justify-content-center">

                <div className="container p-0 w-100">

                  <div className="row">

                    <div id="productimg" className="col-md-6 p-0">

                      <div id="productimg1" className="  p-0 hover-zoom" onClick={() => window.scrollTo(0, 0)}>



                        <Link to="/product/Chat">

                          <figure>

                            <img

                              className=""

                              src="/Images/experience.jpg"

                              alt="Experience"

                            />



                            <figcaption>

                              <h1 className="title">Chat Bot's</h1>



                              <p className="description">

                                <strong>

                                  Professional communication channel for

                                  professional use “to the point at a click”,

                                  Chatting, Video call, Audio call

                                </strong>

                              </p>

                            </figcaption>

                          </figure>

                        </Link>

                      </div>

                    </div>





                    <div className="col-md-6 p-0" id="okedit101">

                      <div className="row col-md-12 p-0">

                        <div id="figcaption-img" className="col col-md-6 p-0" onClick={() => window.scrollTo(0, 0)}>



                          <Link to="/product/Online">

                            <figure className="fig-small">

                              <img src="/Images/insight.jpg"></img>



                              <figcaption id="small-figcaption">

                                <h6 id="imgtitle" className="title">

                                  Online Tutor Systems

                                </h6>



                                <p id="smallimg-desc" className="description">

                                  <br />



                                  <strong>

                                    Mobile Application for Students, Web Account

                                    for Tutors, Youtube /private hosting of

                                    videos, Tutor/Virtual classroom, Free

                                    Consulting

                                  </strong>

                                </p>

                              </figcaption>

                            </figure>

                          </Link>

                        </div>





                        <div className="col col-md-6 p-0" onClick={() => window.scrollTo(0, 0)}>



                          <Link to="/product/Gps">

                            <figure className="fig-small">

                              <img src="/Images/accelerate.jpg"></img>



                              <figcaption id="small-figcaption">

                                <h6 id="imgtitle" className="">

                                  GPS

                                </h6>



                                <p id="smallimg-desc" className="description">

                                  <br />



                                  <strong>

                                    Powerful Vehicle Tracking, Rich Interface,

                                    Full Reporting fuel tracking, History track,

                                    Scalable service and Pay-as-you-Go & many more

                                  </strong>

                                </p>

                              </figcaption>

                            </figure>

                          </Link>

                        </div>

                      </div>





                      <div className="row col-md-12 p-0">

                        <div className="col col-md-6 p-0" onClick={() => window.scrollTo(0, 0)}>

                          <Link to="/service/advanced-toll-survey">

                            <figure className="fig-small">

                              <img src="/Images/innovate.jpg"></img>



                              <figcaption id="small-figcaption">

                                <h6 id="imgtitle" className="">

                                  Advanced Toll Survey

                                </h6>



                                <p id="smallimg-desc" className="description">

                                  <br />



                                  <strong>

                                    Video Based Automatic Traffic Counting &

                                    Classification, traffic Counting and

                                    Classification (ATCC), detect and classify the

                                    vehicles into different classes

                                  </strong>

                                </p>

                              </figcaption>

                            </figure>

                          </Link>

                        </div>





                        <div className="col col-md-6 p-0" onClick={() => window.scrollTo(0, 0)}>

                          <Link to="/product/Project">

                            <figure className="fig-small">

                              <img src="/Images/assure.jpg"></img>



                              <figcaption id="small-figcaption">

                                <h6 id="imgtitle" className="">

                                  Project Management

                                </h6>



                                <p id="smallimg-desc" className="description">

                                  <br />



                                  <strong>

                                    Management of Access Control, Client & Third

                                    Parties & Quotation , also include Project

                                    Manager, Phase, Milestone, Activity Planning

                                  </strong>

                                </p>

                              </figcaption>

                            </figure>

                          </Link>

                        </div>

                      </div>

                    </div>

                  </div>

                </div>

              </div>

            </div>





          </div>

        </div> */}






{/* 
        <div

          id="item-6"

          className="screen col-md-12 "

          style={

            {

            }

          }

        >





          <div className="honeycomb-container " style={{ marginLeft: "auto", marginRight: "auto" }}>

            <h1 id="productserviceheading" className=" pq heading text-center">Services</h1>

            {honeycombData.map((row, rowIndex) => (

              <div

                key={`row-${rowIndex}`}

                className={`honeycomb-row ${rowIndex % 2 === 0 ? "even-row" : "odd-row"

                  }`}

              >

                {row.map((hexagon, colIndex) => (

                  <Hexagon

                    key={`hexagon-${rowIndex}-${colIndex}`}

                    imageUrl={hexagon.image}

                    text={hexagon.text}

                    url={hexagon.url}

                  />

                ))}

              </div>

            ))}

          </div>

        </div> */}

        {/* <div

          id="item-7"

          className="screen col-md-12"

          style={{

            backgroundAttachment: "fixed",

            
            backgroundColor: "#FFFFFF",

          }}

        >

          <div

            id="item-7-box"

            className="container d-flex justify-content-center align-items-center"

          >

            <div

              id="bharatmake-initiative"

              className="col-lg-9 col-md-9 col-sm-9 col-xs-9 float-center text-center"

            >

              <div className="our-purpose">

                <h2 id="Bharatmake-title" className="title-sml mb20">BharatMake Initiative</h2>

                <p id="our-purpose-bharatmake-para" className="para-ovr pt0 fontweight600">

                  At DigitechHub Data Informatics Compnay, We Have a Strategic Plan

                  To Launch Our Products As a Series Of Independent Startups For

                  the Indian Ecosystem.

                </p>

                <hr className="linee" /> 

                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center wow fadeInUp">

                  <h2 id="The-pharmacist-store-heading" className="h2-heading">

                    The Pharmacist Store

                  </h2>

                  <div className="d-flex justify-content-center">

                    <p

                      id="The-pharmacist-store"

                      className="col-lg-8 col-md-8 col-md-offset-2 para-txt light-gray mb50 text-center"

                    >

                      With This Initiative, We Are Glad To Launch Our First

                      PAN India Product.

                    </p>

                  </div>

                </div>

              </div>





            </div>

          </div>



          <div className="freeflowhtml aem-GridColumn aem-GridColumn--default--12">

            <section id="about_us_index" className="scroll-section pt75">

              <article className="container">

                <div className="row">

                  
                </div>

                <br></br>

                <div className="w-100 d-flex justify-content-center">

                  <div className="row1 initiate-counter wow fadeInUp">

                    <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12 mb50">

                      <div className="aboutus-grid text-center">

                        <p className="aboutus-grid-title"> 12+</p>

                        <p className="aboutus-grid-desc">

                          States where we have trusting clients

                        </p>

                      </div>

                    </div>

                    <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12 mb50">

                      <div className="aboutus-grid text-center">

                        <p id="bharatmake-card-title" className="aboutus-grid-title">1214+</p>

                        <p id="" className="aboutus-grid-desc">

                          Subscriptions Sold & Counting

                        </p>

                      </div>

                    </div>

                    <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12 mb50">

                      <div className="aboutus-grid text-center">

                        <p className="aboutus-grid-title"> 115+</p>

                        <p className="aboutus-grid-desc">

                          Sales Agents Thriving Hard To Serve Maximum Pharma

                          Stores In Current Year.

                        </p>

                      </div>

                    </div>

                  </div>

                </div>

              </article>

            </section>



          </div>

        </div>

 */}


        {/* <div

          id="item-8"

          className="screen col-md-12"

          style={{

            
            backgroundColor: "white",

          }}

        >

          <div

            id="item-8"

            className="col-md-9 float-center pt-3 pb-3 d-flex justify-content-center flex-wrap "

          >

            <h1 id="ourclient" className=" pq heading text-center">

              Our Clients

            </h1>

            <div className="w-100 d-flex justify-content-center">

              <div

                id="item-8-box"

                className="container p-0 w-100"



              >

                <div className="row no-gutters clients-wrap clearfix wow fadeInUp">

                  <div className="col-lg-3 col-md-4 col-xs-6">

                    <div className="client-logo">

                      <img

                        src="/Images/client_list_img/Fujitsu-logo.png"

                        className="img-fluid"

                        alt=""

                      />

                    </div>

                  </div>



                  <div className="col-lg-3 col-md-4 col-xs-6">

                    <div className="client-logo" >

                      <img

                        src="/Images/client_list_img/persistent logo.jpg"

                        className="img-fluid"

                        alt=""

                        style={{ maxHeight: "125%", maxWidth: "120%" }}

                      />

                    </div>

                  </div>



                  <div className="col-lg-3 col-md-4 col-xs-6">

                    <div className="client-logo">

                      <img

                        src="/Images/client_list_img/tata.png"

                        className="img-fluid"

                        alt=""

                      />

                    </div>

                  </div>



                  <div className="col-lg-3 col-md-4 col-xs-6">

                    <div className="client-logo">

                      <img

                        src="/Images/client_list_img/newt_global.png"

                        className="img-fluid"

                        alt=""

                      />

                    </div>

                  </div>

                  <div className="col-lg-3 col-md-4 col-xs-6">

                    <div className="client-logo">

                      <img

                        src="/Images/client_list_img/Goalogo.jpg"

                        className="img-fluid"

                        alt=""

                      />

                    </div>

                  </div>

                  <div className="col-lg-3 col-md-4 col-xs-6">

                    <div className="client-logo">

                      <img

                        src="/Images/client_list_img/Electrical.png"

                        className="img-fluid"

                        alt=""

                      />

                    </div>

                  </div>



                  <div className="col-lg-3 col-md-4 col-xs-6">

                    <div id="ouicksupplierlogo" className="client-logo">

                      <img

                        src="/Images/client_list_img/QS LOGO.png"

                        className="img-fluid"

                        alt=""

                      />

                    </div>

                  </div>



                  <div className="col-lg-3 col-md-4 col-xs-6">

                    <div className="client-logo">

                      <img

                        src="/Images/client_list_img/Swastik.png"

                        className="img-fluid"

                        alt=""

                      />

                    </div>

                  </div>




                  <div className="col-lg-3 col-md-4 col-xs-6">

                    <div className="client-logo">

                      <img

                        src="/Images/client_list_img/alphanso-mango.jpg"

                        className="img-fluid"

                        alt=""

                      />

                    </div>

                  </div>

                  <div className="col-lg-3 col-md-4 col-xs-6">

                    <div className="client-logo">

                      <img

                        src="/Images/client_list_img/Tech_Logical.png"

                        className="img-fluid"

                        alt=""

                      />

                    </div>

                  </div>

                  <div className="col-lg-3 col-md-4 col-xs-6">

                    <div className="client-logo">

                      <img

                        src="/Images/client_list_img/Ies.png"

                        className="img-fluid"

                        alt=""

                      />

                    </div>

                  </div>

                  <div className="col-lg-3 col-md-4 col-xs-6">

                    <div className="client-logo">

                      <img

                        src="/Images/client_list_img/AIS.png"

                        className="img-fluid"

                        alt=""

                      />

                    </div>

                  </div>

                  <div className="col-lg-3 col-md-4 col-xs-6">

                    <div className="client-logo">

                      <img

                        src="/Images/client_list_img/Softcon-Logo_small-1.png"

                        className="img-fluid"

                        alt=""

                      />

                    </div>

                  </div>

                </div>

              </div>

            </div>

          </div>

        </div> */}










        {/* <div

          id="item-9"

          className="screen col-md-12"

          style={{

            backgroundColor: "#933593",

          }}

        >

          <div

            id="item-5"

            className="col-md-9 float-center pt-3 pb-3 d-flex justify-content-center flex-wrap "

          >

            <div className="w-100 d-flex justify-content-center">

              <div className="contactus aem-GridColumn aem-GridColumn--default--12">

                <section id="contact_us" className="scroll-section">

                  <article className="container">

                    <div className="row">

                      <div className="col-md-6">

                        <div className="square-grid" id="okedit102">

                          <div className="square">India</div>

                          <div className="square">Thailand</div>

                          <div className="square">Middle East</div>

                          <div className="square">USA</div>

                        </div>

                      </div>

                      <div className="col-md-6">

                        <div className="column-content" onClick={() => window.scrollTo(0, 0)}>

                          <p className="contact-text">

                            Let's Help You Navigate Your Next

                          </p>

                          <Link to="/Contact" className="contactbtn  ">

                            <span>Contact us</span>

                          </Link>

                        </div>

                      </div>

                    </div>

                  </article>

                </section>

              </div>

            </div>

          </div>

        </div>

 */}




      </div>

    </div>

  );

};



export default Home;

