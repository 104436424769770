import React from 'react'
import './API_BasedPlatform.css';

function API_BasedPlatform() {
  return (
    <div>
             <img src="https://img.freepik.com/free-photo/smooth-gray-background-with-high-quality_53876-124606.jpg" className="d-block w-100" alt="slider second"/>
    <div className="carousel-caption d-none d-md-block">
      <h1 className='apibased' style={{color:'black',paddingBottom:'30px',marginTop:'-80px'}}>NEO BANKING API-BASED PLATFORM</h1>
     
      <div className="containerl">
  <div className="row">
    <div className="col-md-3">
      <div className="columnq">
        <div className="icon">
          <i className="fas fa-cogs"></i>
        </div>
        <h3>
          <a href="/service/BankingPlatform/KYC" style={{ color: 'black', textDecoration: 'none' }}>KYC</a>
        </h3>
      </div>
    </div>

    <div className="col-md-3">
      <div className="columnq">
        <div className="icon">
          <i className="fas fa-user"></i>
        </div>
        <h3>
          <a href="/service/BankingPlatform/Customer" style={{ color: 'black', textDecoration: 'none' }}>CUSTOMER</a>
        </h3>
      </div>
    </div>

    <div className="col-md-3">
      <div className="columnq">
        <div className="icon">
          <i className="fas fa-university"></i>
        </div>
        <h3>
          <a href="/service/Bankingplatform/Account" style={{ color: 'black', textDecoration: 'none' }}>ACCOUNT</a>
        </h3>
      </div>
    </div>

    <div className="col-md-3">
      <div className="columnq">
        <div className="icon">
          <i className="fas fa-money-bill-wave"></i>
        </div>
        <h3>
          <a href="/service/BankingPlatform/Loan" style={{ color: 'black', textDecoration: 'none' }}>LOAN</a>
        </h3>
      </div>
    </div>
  </div>

  <div className="row">
    <div className="col-md-3">
      <div className="columnq">
        <div className="icon">
          <i className="fas fa-credit-card"></i>
        </div>
        <h3>
          <a href="/service/BankingPlatform/Cards" style={{ color: 'black', textDecoration: 'none' }}>CARDS</a>
        </h3>
      </div>
    </div>

    <div className="col-md-3">
      <div className="columnq">
        <div className="icon">
          <i className="fas fa-piggy-bank"></i>
        </div>
        <h3>
          <a href="/service/Bankingplatform/Deposite" style={{ color: 'black', textDecoration: 'none' }}>DEPOSITS</a>
        </h3>
      </div>
    </div>

    <div className="col-md-3">
      <div className="columnq">
        <div className="icon">
          <i className="fas fa-money-check"></i>
        </div>
        <h3>
          <a href="/service/BankingPlatform/Payment" style={{ color: 'black', textDecoration: 'none' }}>PAYMENTS</a>
        </h3>
      </div>
    </div>

    <div className="col-md-3">
      <div className="columnq">
        <div className="icon">
          <i className="fas fa-shield-alt"></i>
        </div>
        <h3>
          <a href="/service/BankingPlatform/Insurance" style={{ color: 'black', textDecoration: 'none' }}>INSURANCE</a>
        </h3>
      </div>
    </div>
  </div>
</div>
</div>
    </div>
  )
}

export default API_BasedPlatform
