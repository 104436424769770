import React from 'react'

function PayrollWithIot() {
  return ( <div>
  <div className="Smartcloud-container">Payroll With IOT</div>

  {/* card effect */}

  <div class="Smart-card-grid">
    {/* <!-- Card 1 --> */}
    <div class="Smart-card ">
      <div class="Smart-card-content">
        <h4 class="Smart-card-title">1. Multi-Office Centralized Attendance: </h4>
        <p class="Smart-card-description">
        - Unified System: Attendance from multiple office locations is consolidated into one central system. 
        </p>
        <p class="Smart-card-description">
        - Real-Time Tracking: Monitor real-time attendance from all locations for better workforce management. 
        </p>

      </div>
    </div>

    {/* <!-- Card 2 (Copy and paste more cards as needed) --> */}
    <div class="Smart-card ">
      <div class="Smart-card-content">
        <h4 class="Smart-card-title">2.Staff Recruitment Solution: </h4>
        <p class="Smart-card-description">
        - Job Listings: Post, manage, and track job openings across departments. 
        </p>

        <p class="Smart-card-description">
        - Candidate Portal: Applicants can submit their resumes, cover letters, and other relevant documents. 
        </p>

        <p class="Smart-card-description">
        - Interview Scheduling: Coordinate and manage interview schedules for potential hires. 
        </p>

        <p class="Smart-card-description">
        - Feedback & Assessment: Seamless integration of interview feedback, skill assessments, and other evaluation metrics.  
        </p>


      </div>
    </div>

    {/* <!-- Card 3 (Copy and paste more cards as needed) --> */}
    <div class="Smart-card ">
      <div class="Smart-card-content">
        <h4 class="Smart-card-title">3.IT Outsourcing Solution:</h4>
        <p class="Smart-card-description">
        - Resource Management: Track and manage IT resources being outsourced to clients. 
        </p>

        <p class="Smart-card-description">
        - Project Tracking: Monitor the progress of outsourced projects and ensure timely deliveries.  
        </p>

        <p class="Smart-card-description">
        - Client Collaboration Tools: Integrated tools to facilitate seamless communication and collaboration with clients. 
        </p>
      </div>
    </div>

    {/* <!-- Card 4 (Copy and paste more cards as needed) --> */}
    <div class="Smart-card ">
      <div class="Smart-card-content">
        <h4 class="Smart-card-title">4.Hourly Based Invoicing Feature: </h4>
        <p class="Smart-card-description">- Time Tracking: Log billable hours for each task or project. </p>
        <p class="Smart-card-description">- Dynamic Invoicing: Generate invoices based on the recorded billable hours. </p>
        <p class="Smart-card-description">- Integrated Payments: Accept payments directly through the system and mark invoices as paid. </p>
      </div>
    </div>

    {/* <!-- Card 5 (Copy and paste more cards as needed) --> */}
    <div class="Smart-card ">
      <div class="Smart-card-content">
        <h4 class="Smart-card-title">5. Distributed and Mobile Phone-Based Attendance System: </h4>
        <p class="Smart-card-description">
       - Mobile Attendance: Employees can mark their attendance using a mobile app. 
        </p>

        <p class="Smart-card-description">
       - Biometric Integration: Multiple biometric systems like fingerprint and facial recognition can be used for attendance verification.  
        </p>

        <p class="Smart-card-description">
       - Geo-Tagging: Ensure the employees are marking attendance from the correct location.  
        </p>
      </div>
    </div>

    {/* <!-- Card 6 (Copy and paste more cards as needed) --> */}
    <div class="Smart-card ">
      <div class="Smart-card-content">
        <h4 class="Smart-card-title">6. Shift Handling: </h4>
        <p class="Smart-card-description">
       - Shift Creation: Define and create various shift patterns. 
        </p>

        <p class="Smart-card-description">
       - Roster Management: Assign employees to specific shifts and manage any shift swaps or changes. 
        </p>

        <p class="Smart-card-description">
       - Notification System: Notify employees of their shifts, and any changes made, through SMS, email, or app notifications. 
        </p>

      </div>
    </div>

    {/* <!-- Card 7 (Copy and paste more cards as needed) --> */}
    <div class="Smart-card ">
      <div class="Smart-card-content">
        <h4 class="Smart-card-title">7. Single Click Payroll Generation: </h4>
        <p class="Smart-card-description">
       - Quick Process: Generate payroll for all employees with a single click, reducing manual intervention. 
        </p>

        <p class="Smart-card-description">
       - Integrated Calculations: Automatically calculate salaries based on attendance, shifts, overtime, and other variables. 
        </p>

      </div>
    </div>

    {/* <!-- Card 8 (Copy and paste more cards as needed) --> */}
    <div class="Smart-card ">
      <div class="Smart-card-content">
        <h4 class="Smart-card-title">8.Stepper Form Payroll Generation System: </h4>
        <p class="Smart-card-description">- Step-by-Step Guidance: Takes the HR through a systematic process for payroll generation, ensuring no steps are missed.</p>
        <p class="Smart-card-description">- Preview and Corrections: Review payroll details at each step and make necessary corrections before finalizing. </p>
        <p class="Smart-card-description">- Audit Trail: Keep track of changes made during the payroll generation process. </p>
        
      </div>
    </div>

    
  </div>

  {/* card effect end */}



  <div className="Smartcloud-main-description transperant">
    <div className="SMV"></div>

    <section className="SMV1">
      <div className="container SMV1_container">
        <div className="SVM2">
         
          <p className="SVM2para">
          With these features, your Centralized Payroll and HR Management System offers a comprehensive solution for modern businesses, providing streamlined operations, enhanced accuracy, and optimal efficiency. It allows companies to manage their HR and payroll needs seamlessly, whether they have a local presence or operate across multiple locations. 
          </p>
        </div>
      </div>
    </section>

    
   
    {/* <!-- end smart-faqs --> */}

    <div className="SMV"></div>

    
  </div>
</div>
)
}

export default PayrollWithIot
