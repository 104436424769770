import React from 'react'
import './CustomerOnboarding.css';
import { FaVideo, FaUser, FaMobileAlt } from 'react-icons/fa'; // Import the icons you need from React Icons

function CustomerOnboarding() {
  return (
    <div className='cu'>
        
      <div className='customeron-heading'>CUSTOMER ONBOARDING
      <img src='https://blogimages.softwaresuggest.com/blog/wp-content/uploads/2022/12/06125506/How-to-Make-the-Client-Onboarding-Process-the-Best-for-You-and-Your-Customers.jpg' className='customeronboradingimges' alt='imagecustomerkyc'></img>
      </div>
     
      <div class="two-column-container1">
  <div class="column2">
  <div class="kyc-item">
  <FaVideo className="kyc-icon" />
  <h3 className='customerkyc-heading'>Video-based KYC</h3>
  <p className='customerkyc-para'>Complete KYC and customer onboarding in a few seconds with reduced cost & increased completions</p>
</div>

    <img src='https://www.ameyo.com/wp-content/uploads/2021/01/video-kyc-for-mutual-fund.png' className='customerkycimg' alt='imagecustomerkyc' />
  </div>

  <div class="column2">
    <div class="kyc-item">
      <FaMobileAlt className="kyc-icon" />
      <h3 className='customerkyc-heading'>Omnichannel Approach</h3>
      <p className='customerkyc-para'>Transform all customer touchpoints and offer a uniform user experience across all devices optimized for each channel</p>
    </div>
    <img src='https://blogimages.softwaresuggest.com/blog/wp-content/uploads/2019/09/21123332/Omnichannel-Marketing-Strategy-How-to-Leverage-For-Better-Retail-CX.jpg' className='customerkycimg' alt='imagecustomerkyc' />
  </div>
</div>



    </div>
  )
}

export default CustomerOnboarding
