import React from 'react'
import './Deposite.css';

function Deposite() {
  return (
    <div>
      <div className='deposite-heading'>DEPOSITE API</div>
      <p className='deposite-para'>Create and manage recurring and fixed deposits efficiently offering high-tech security features on this scalable platform</p>
      <img src='https://img.freepik.com/premium-vector/online-banking-concept-flat-design-illustration-people-characters-landing-page_9209-4970.jpg'className="imagcustomer"alt='customerimages'></img>
      <div className="feature-table">
  <div className="feature-box">
    <div className="box">
    <i className="fas fa-piggy-bank"></i>

      <p>Create Fixed Deposit</p>
    </div>
  </div>
  <div className="feature-box">
    <div className="box">
    <i className="fas fa-times"></i>
      <p>Close Fixed Deposit</p>
    </div>
  </div>
  <div className="feature-box">
    <div className="box">
    <i className="fas fa-redo"></i>
      <p>Create Recurring Deposit</p>
    </div>
  </div>
  <div className="feature-box">
    <div className="box">
    <i className="fas fa-times"></i>

      <p>Close Recurring Deposit</p>
    </div>
  </div>
    </div>
    </div>
  )
}

export default Deposite
