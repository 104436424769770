import React from 'react'
import './Kyc.css';

function kyc() {
  return (
    <div className='k'>
  <h1 className='kyc-heading'>KYC API</h1>
  <p className='kyc-para'>Simplify & accelerate the onboarding process for your customers with AI-powered KYC features.​</p>
  <img src='https://www.reliancesmartmoney.com/images/default-source/default-album/blog_image-2a.png?sfvrsn=0' className="imagkyc" alt='kycimages'/>
  <div class="feature-table">
    <div class="feature-box">
      <div class="box">
        <i class="fas fa-file-alt"></i>
        <p>OCR Extraction</p>
      </div>
    </div>
    <div class="feature-box">
      <div class="box">
        <i class="fas fa-check-circle"></i>
        <p>ID Validation</p>
      </div>
    </div>
    <div class="feature-box">
      <div class="box">
        <i class="fas fa-user"></i>
        <p>Face ID Recognition</p>
      </div>
    </div>
    <div class="feature-box">
      <div class="box">
        <i class="fas fa-video"></i>
        <p>Liveliness Check</p>
      </div>
    </div>
  </div>
</div>

  )
}

export default kyc
