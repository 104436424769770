import React from "react";

import  "./Privacy.css";
import { Link } from "react-router-dom";




export default function VM() {

    return (

        <>

       {/* <div><NavBar/></div>  */}

        <div className="banner parbase industries-gradient aem-GridColumn aem-GridColumn--default--12">





        <section id="sml_ht_home_banner">

            <article className="container">

            <div className="row">

              <div className="col-md-9 col-sm-12 col-xs-12 ">

                    <h1 className="h2-heading white-color l-top"  id="termsedit1s">

                    Privacy Statement

                    </h1>

                  </div>

            </div>

          </article>

        </section>

       

</div>
<section className="privacy" style={{}}>
    <article className="container">
      <div className="row row-margin"style={{textAlign:'left'}}>
      <div  class="col-md-12 col-sm12 col-xs-12 wow fadeInLeft" data-wow-delay="0.3s" >
        
        <div className="termspara"> 
        
          <div className="termsheadings">
          Last updated on
          </div>
          <div className="termsubpoint">Digitechub Limited, its subsidiaries, and branch operations, hereinafter referred to as ‘Digitechub,’ ‘we,’ ‘us,’ or ‘our,’ are committed to respecting your privacy and choices. The privacy statement highlights our privacy practices regarding Personal Information that we collect and process depending on your association with Digitechub and the nature of processing activity in compliance with applicable data privacy regulations.</div>
        
          <div className="termsheadings">
          Objective:
          </div>
          <div className="termsubpoint">Through this privacy statement, we intend to provide a synopsis of our privacy practices regarding Personal Information that we collect and process about you through various sources. Although the primary focus of this statement is on the data collected and processed through our websites that link to this Privacy Statement, which include www.Digitechub.com, www.Digitechubbpm.com, and their sub-domains such as blogs.Digitechub.com, abm.Digitechub.com, etc. (Collectively referred to as “Digitechub Websites”), our adherence to the below-mentioned principles remains consistent across the organization in personal data processing. Depending on the purposes of processing and your relationship with us as a data subject, we may provide additional customized privacy statements, more information on which can be found below.</div>
          <div className="termsubpoint"><br/>
            <div>The scope of this Statement covers the categories of personal data collected, how we use or process such data, who the recipients of such data are, and your associated rights under applicable laws, including how to exercise them. The applicability of the Privacy Statement is global; however, certain additional information may be relevant to you depending on the country where you reside. Such additional terms, based on these particular countries or regions, are called out in region-specific statements below.</div><br/>
            <div>Wherever we have provided the translation of our Statement into local languages, as per applicability, in case of a conflict between the local language and the English version, the English version shall prevail.</div>
          </div>
        

          <div className="termsheadings">
          Personal Information we collect and process and how we use it
          </div>
          <div className="privacysub">  -Personal Information that we process:</div>
          <div className="termsubpoint">For the purposes of this privacy statement, 'Personal Information' is any data which relates to an individual who may be identified from that data, or from a combination of a set of data, and other information which is in possession of Digitechub.</div>
          <br/><div className="termsubpoint">We collect the following categories of Personal Information:</div>
          <ul  id="terms-list">
            <li>Information collected as part of your visit and usage of our websites, such as Internet Protocol (IP) address, demographics, your computer's operating system, device information, telemetry data, and browser type, as well as information collected via cookies or similar tracking technologies. As a website visitor who merely browses our websites (including the hosted content), without submitting any additional data, this is the most relevant paragraph for applicable data processing.</li>
            <li>Personal and Contact details, such as Name, title, email address, phone numbers, address, designation, Company/Organization, Industry, Location (in terms of City/Country), LinkedIn profiles/URLs (or similar professional dataset).</li>
            <li>Login credentials, such as Usernames and Passwords, when you register for any of our applicable services.</li>
            <li>Audio-Visual information such as photographs or images captured, video recordings (if enabled), when attending any of our events, webinars, etc.</li>
            <li>Queries, comments, or feedback as submitted by you, including any correspondence you have made with us.</li>
            <li>Preferences relating to marketing communications, interest areas, subscription details, dietary preferences/allergies (in relation to events we may organize).</li>
            <li>Additionally, we may collect certain data points based on the categories of data subjects and their relationship with us, as detailed in additional Privacy Statements for relevant categories of data subjects at the end of this Statement.</li>
          </ul>



          <div className="privacysub"> - Use of your Personal Information</div>
        
          <div className="termsubpoint">We use your Personal Information for the following purposes:</div>
          <ul  id="terms-list">
            <li>to provide better usability, troubleshooting and site maintenance;</li>
            <li>to understand which parts of the website are visited and how frequently;</li>
            <li>to create your user ID;</li>
            <li>to identify you once you register on our website;</li>
            <li>to contact you and respond to your questions or requests; and</li>
            <li>to provide access to desirable content based on your preferences.</li>
            <li>To process job applications and alumni-related requests, more details about which are available on corresponding portals of this website.</li>
            <li>To understand your interest in participating in ideathons or other competitions.</li>
            <li>As a participant of the competition, to publish your name and corresponding details to make them available to a larger audience on the Internet.</li>
          </ul>

        
          <div className="privacysub"> - Legal basis of the processing:</div>
          
          <div className="termsubpoint">The applicable privacy and data protection laws provide certain justifiable grounds for the collection and processing of personal data, commonly referred to as the legal basis of processing. We primarily rely on the following legal bases:</div>
          <ul  id="terms-list">
            <li>We process your Personal Information when it is necessary for the performance of a contract to which you are a party or in order to take steps at your request prior to entering into a contract. For example, when you engage with us to receive our services and offerings, or when managing the data of our employees to ensure the performance of their employment contract.</li>
            <li>We process your Personal Information when it is necessary for the purposes of a legitimate interest pursued by us or a third party (when these interests are not overridden by your data protection rights). For instance, when we need to understand your usage of our website and interaction with the same, for generating your secure login credentials, or to optimize our processes.</li>
            <li>We process your Personal Information with your consent. Where we process Personal Data based on consent, your consent is revocable at any time. For example, when registering and inviting you to events organized by us, or for sharing our marketing-related communications with you.</li>
            <li>We may process your Personal Information to comply with any legal obligations on us, including applicable laws and protecting our legal rights, seeking remedies, and defending against claims.</li>
           
          </ul>




          <div className="privacysub"> -Consequences of not providing Personal Information</div>
          <div className="termsubpoint">If you choose not to provide your Personal Information that is mandatory to process your request, we may not be able to provide the corresponding service.</div>
             
        
          <div className="privacysub">  - Data recipients, transfer, and disclosure of Personal Information:</div>
          <div className="termsubpoint">Digitechub does not share your Personal Information with third parties for their direct marketing purposes.</div>
          <div className="termsubpoint">We share your Personal Information: </div>
          <ul  id="terms-list">
            <li>Digitechub or with any of its subsidiaries;</li>
            <li>Business partners;</li>
            <li>Service vendors;</li>
            <li>Authorized third-party agents; or</li>
            <li>Contractors.</li>
           
          </ul>

          <div className="termsubpoint">Digitechub Limited does not sell your Personal Information to third parties.</div>

          <div className="termsubpoint">In general, Digitechub Limited, domiciled in Bangalore, is the data controller processing your Personal Information. The following applies only where the data controller processing your Personal Information is domiciled in the European Economic Area (“EEA”):</div>

          <div className="termsubpoint">We transfer Personal Information to countries outside the EEA to third parties, including to countries which have different data protection standards to those which apply in the EEA. The locations of Digitechub group companies is set out here. Our service providers are primarily located in USA, Canada, Australia, Singapore, Hong Kong, India and UK. Where service providers process your Personal Information in countries deemed adequate by the European Commission, we rely on the European Commission's decision to protect your Personal Information.</div>

          <div className="termsubpoint">For transfers to Digitechub group companies and service providers outside the EEA, we use standard contractual clauses or rely on a service provider's (EU data protection authority approved) corporate rules that are in place to protect your Personal Information. Please contact us as set out below if you would like to obtain a copy of the methods used. When required, Digitechub discloses Personal Information to external law enforcement bodies or regulatory authorities, in order to comply with legal obligations.</div>

          <div className="privacysub"> -Use of cookies:</div>
         <div className="termsubpoint">Please refer the <a href="/Cookie">Cookie Policy</a></div>



         <div className="privacysub"> -Do Not Track:</div>
          <div className="termsubpoint">Do Not Track (DNT) is a privacy preference that users can set in some web browsers, allowing users to opt out of tracking by websites and online services. At the present time, the World Wide Web Consortium (W3C) has not yet established universal standards for recognizable DNT signals and therefore, Digitechub Limited and the Site do not recognize DNT.</div>
             
        
          <div className="privacysub"> -Your Rights:</div>
          <div className="termsubpoint">
          <div>Subject to the laws of your country, You have the right to access, correct, delete or transfer your Personal Information that we hold, including your profile and preferences. You also have the right to object to certain processing and, where we have asked for your consent to process your Personal Information, to withdraw this consent. Where we process your Personal Information because we have a legitimate interest in doing so (as explained above), you also have a right to object to this. These rights may be limited in some situations – for example, where we can demonstrate that we have a legal requirement to process your Personal Information.</div>
          <br/><div>If you are Brazilian user, you have additional rights to take confirmation on existence of your personal information, portability of your personal information to another service provider if applicable, anonymization or blocking of unnecessary information, information about public and private entities with which we have shared your data.</div>
          <br/><div>You can assert your rights in the corresponding sections where such information was provided or by contacting us at  <a href="mailto:privacy@Digitechub.com" class="blue-email" style={{color:'blue'}}>privacy@Digitechub.com</a></div>
         </div>
          <div className="privacysub"> -Data security:</div>
          <div className="termsubpoint">Digitechub adopts reasonable and appropriate security practices and procedures including administrative, physical security, and technical controls in order to safeguard your Personal Information.</div>
             
          <div className="privacysub"> -Data retention:</div>
          <div className="termsubpoint">Personal Information will not be retained for a period more than necessary to fulfill the purposes outlined in this privacy statement, unless a longer retention period is required by law or for directly related legitimate business purposes.</div>
             
          <div className="privacysub"> -Linked websites:</div>
          <div className="termsubpoint">Our privacy practices regarding Personal Information that we collect and store about you through our portals such as Recruitment and Global Alumni will be as per the privacy policy of those portals.</div>
        <br/>  <div className="termsubpoint">Digitechub provides links to third-party websites and services. However, Digitechub is not responsible for the privacy statements, practices, or the contents of such third-party websites.</div>


          <div className="privacysub"> -Children’s Privacy:</div>
          <div className="termsubpoint">The Site does not knowingly solicit or collect personally identifiable information online from children under the age of 13 without prior verifiable parental consent. If Digitechub Limited learns that a child under the age of 13 has submitted personally identifiable information online without parental consent, it will take all reasonable measures to delete such information from its databases and to not use such information for any purpose (except where necessary to protect the safety of the child or others as required or allowed by law). If you become aware of any personally identifiable information we have collected from children under 13, please call us at +91 80 2852 0261 or email us at <a href="mailto:privacy@Digitechub.com" class="blue-email" style={{color:'blue'}}>privacy@Digitechub.com</a></div>
          <br/><div className="termsubpoint">Minors under 18 years of age may have the personal information that they provide to us deleted by sending an email to <a href="mailto:privacy@Digitechub.com" class="blue-email" style={{color:'blue'}}>privacy@Digitechub.com</a>requesting deletion. Please note that, while we make reasonable efforts to comply with such requests, deletion of your personal information does not ensure complete and comprehensive removal of that data from all systems.</div>
        

          <div className="privacysub"> -How to contact us:</div>
          <div className="termsubpoint">If you have any questions regarding our privacy practices or this privacy statement, or to request this privacy statement in another format, please contact us at:</div>
             
          <ul  id="terms-list">
            <li>Contact person:</li>
            <li>Contact address: Digitechub Data Informatics</li>
            <li>Phone:</li>
            <li>Email:<a href="mailto:privacy@Digitechub.com" class="blue-email" style={{color:'blue'}}>privacy@Digitechub.com</a></li>
           
          </ul>
      
      
          <div className="privacysub"> -Updates to this privacy statement:</div>
          <div className="termsubpoint">Digitechub may change the data privacy practices and update this privacy statement as and when the need arises, and the same will be made available on the website. But our commitment to protect the privacy of website users will continue to remain.</div>
             
      
        </div>
        </div> 
        
        </div>
    
    </article>
  </section>
  
        

        

        </>
        

    )

  }
