import "./template.css";

import { Link } from "react-router-dom";
import ReadModal2 from "./readMore.js";
import React, { useState, useRef, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CarouselSlider from "./Carousel.js";
import CaseStudyCard from "./CaseStudyCard.js";
function KpoAndBpo() {
  const [activeModal, setActiveModal] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);
  const sliderRef = useRef(null);
  const modalRef = useRef(null);
  const [selectedSlide, setSelectedSlide] = useState(null);
  const settings = {
    // dots: true,
    // infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },

      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },

      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 3000,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const slideData = [
    {
      id: 1,
      heading: "Specialized Expertise: ",
      text: "Our team comprises experts in various domains, ensuring high-quality service delivery. ",
      buttonLabel: "Read More",
      modalContent: {
        // modalHeading: 'Modal Heading 1',
        modalText:
          "CloudCipher seamlessly integrates with existing workflows and software ecosystems, minimizing disruption and streamlining the adoption process. Whether migrating from another platform or implementing new security measures, CloudCipher ensures a smooth transition with minimal downtime.",
      },
    },
    {
      id: 2,
      heading: "Customized Solutions:",
      text: " Tailoring our services to meet the specific requirements of your business. ",
      buttonLabel: "Read More",
      modalContent: {
        modalHeading: "Modal Heading 2",
        modalText: "Modal Text 2",
      },
    },

    {
      id: 3,
      heading: "Technology Integration:",
      text: "Leveraging cutting-edge technology to enhance efficiency and accuracy. ",
      buttonLabel: "Read More",
      modalContent: {
        modalHeading: "Modal Heading 3",
        modalText: "Modal Text 3",
      },
    },

    {
      id: 4,
      heading: "Cost-Effective:",
      text: "Our services are designed to be cost-effective, reducing your operational overhead. ",
      buttonLabel: "Read More",
      modalContent: {
        modalHeading: "Modal Heading 4",
        modalText: "Modal Text 4",
      },
    },

    {
      id: 5,
      heading: "Scalable Operations: ",
      text: "Capability to scale services up or down based on your evolving business needs. ",
      buttonLabel: "Read More",
      modalContent: {
        modalHeading: "Modal Heading 5",
        modalText: "Modal Text 5",
      },
    },

    // {
    //   id: 6,
    //   heading: 'Sixth Slide',
    //   text: 'A different text for the second slide.',
    //   buttonLabel: 'Read More',
    //   modalContent: {
    //     modalHeading: 'Modal Heading 6',
    //     modalText: 'Modal Text 6',
    //   },
    // },

    // {
    //   id: 7,
    //   heading: 'Seventh Slide',
    //   text: 'A different text for the second slide.',
    //   buttonLabel: 'Read More',
    //   modalContent: {
    //     modalHeading: 'Modal Heading 7',
    //     modalText: 'Modal Text 7',
    //   },
    // },

    // {
    //   id: 8,
    //   heading: 'Eighth Slide',
    //   text: 'A different text for the second slide.',
    //   buttonLabel: 'Read More',
    //   modalContent: {
    //     modalHeading: 'Modal Heading 8',
    //     modalText: 'Modal Text 8',
    //   },
    // },
    // ... (more slides)
  ];

  

  const openModal = (slide) => {
    setSelectedSlide(slide);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  // Handle clicks outside the modal to close it
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        closeModal();
      }
    };

    window.addEventListener("mousedown", handleOutsideClick);

    return () => {
      window.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const modalContent = (
    <>
      <p className="modal-np">
      The Purpose Behind Our KPO and BPO Services{" "}
      </p>
      
  
      At Digitec Hub, our KPO & BPO Services are tailored to empower businesses
      by handling complex, operational, and specialist tasks. By outsourcing
      these functions to our expert team, your business can focus on core
      activities, drive growth, and achieve operational excellence.

      <hr/>
           <br/> <strong>Scalability</strong> 
 
           Outsourcing enables rapid adjustment of operations to meet fluctuating demands without the burden of hiring and training internal staff.
         <br/><br/>
        <strong>Access to Global Talent</strong>
        Outsourcing grants access to diverse talent worldwide, leveraging specialized skills and knowledge not easily found locally.
 
          <br/><br/><strong>Focus on Core Business Activities</strong> 
          Delegating non-core functions allows organizations to dedicate resources to core activities, enhancing competitiveness and facilitating effective pursuit of growth opportunities.
 
          

      {/* Your dynamic content here */}
      {/* You can pass any JSX content you want */}
    </>
  );

  return (
    <div id="maindivs">
      <div id="carouselExampleCaptions" className="carousel slide">
        <div className="carousel-inner">
          <div className="carousel-item active">
            <img
              src="/Images/firstcomponant.jpg"
              className="d-block w-100"
              style={{ height: "700px" }}
              alt="slider first"
            />
            <div className="carousel-caption  d-md-block">
              <div className="component-1">KPO & BPO Services </div>
              
              <div
                className="component-paras"
                style={{ color: "white", margin: "50px" }}
              >
                "Empowering Your Business Operations – Expertise Meets Efficiency"
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-np2">
        {/* <!-- section2 title --> */}
        <div className="cont2-titles">
          <div class="cont2-titletext"> What is KPO & BPO Service ? </div>
        </div>

        {/* <!-- section part --> */}
        <div class="container21">
          {/* <!-- left part --> */}

          <div className="cont2-left cont2-text">
            <div
              className="left-text"
              style={{
                // fontFamily: "arial",
                fontSize: "25px",
                fontFamily: "system-ui",
              }}
            >
              Knowledge Process Outsourcing (KPO) and Business Process
              Outsourcing (BPO) Services involve delegating core and non-core
              business activities to external providers. KPO focuses on
              knowledge-intensive areas requiring specialized expertise, while
              BPO encompasses broader, process-oriented tasks. Our services are
              designed to optimize these critical functions, ensuring they are
              executed efficiently and effectively.
            </div>
          </div>

          {/* right part */}
          <div className="cont2-right cont2-text">
            <div className="right-text">
              <img
                class="content2img"
                src="/Images/KPOandBPO.png"
                alt="Description of the image"
              />
            </div>
          </div>
        </div>
        <div class="readmore">
          <ReadModal2 buttonText="Read More" modalContent={modalContent} />
        </div>
      </div>

      {/* section3 */}
      <section class="section3">
        <div class="cont2-title title ">Our Features</div>

        <div class="section3parts">
          <div class="content-np item31">
            <div>
              <strong
                style={{
                  paddingLeft:'15px'
                }}
              >
                {" "}
                Accounting and Bookkeeping Services
              </strong>
              <p class="aligned-paragraph item3text">
                Comprehensive financial record management, from day-to-day
                bookkeeping to advanced accounting practices.
              </p>
            </div>
          </div>

          <div class="content-np item322">
            <strong
              class="item32-heading item31-conent"
              style={{
                paddingLeft:'15px'
              }}
            >
              Tax Compliance Services
            </strong>
            <div class=" item31-conent-img  "></div>
            <p class="aligned-paragraph item3text" style={{color:'black'}}>
              Expert handling of tax-related matters, ensuring compliance with
              regulations.
            </p>
          </div>

          <div class="content-np item33">
            <strong
              class="item32-headings"
              style={{
                paddingLeft:'15px'
              }}
            >
              {" "}
              Financial Analysis and Reporting
            </strong>
            <p class="aligned-paragraph item3text">
              Detailed financial analysis and reporting for informed
              decision-making.
            </p>
            {/* <div class="item32-button"><button class="item32-button">Read more</button></div> */}
          </div>

          <div class="content-np item34">
            <strong
              style={{
                paddingLeft:'15px'
              }}
            >
              Freight and Supply Chain Management
            </strong>
            <p class="aligned-paragraph item3text" style={{color:'black'}}>
              Efficient management of freight operations and supply chain
              logistics.
            </p>
          </div>
          <div class="content-np item35">
            <strong
              style={{
                paddingLeft:'15px'
              }}
            >
              {" "}
              E-commerce Solutions
            </strong>
            <p class="aligned-paragraph item3text">
              Tailored services for e-commerce businesses, including customer
              support, order processing, and inventory management.
            </p>
          </div>

          <div class="content-np item36">
            <strong
              style={{
                paddingLeft:'15px'
              }}
            >
              Customized KPO Solutions
            </strong>
            <p class="aligned-paragraph item3text">
              Specialized knowledge services in areas requiring specific
              expertise.
            </p>
          </div>

          <div class="content-np item37">
            <strong
              style={{
                paddingLeft:'15px'
              }}
            >
              Technology-Enabled Platforms
            </strong>
            <p class="aligned-paragraph item3text" style={{color:'black'}}>
              Utilizing advanced technology platforms for streamlined service
              delivery.
            </p>
          </div>
        </div>
        {/* <div class="readmore">
            <button class="read1">Read More</button>
          </div> */}
      </section>

      <div class="section4">
        <div className="sliders">
          {/* Previous and Next buttons */}
          <button
            className="nav-button-slider prev"
            onClick={() => sliderRef.current?.slickPrev()}
          >
            &lt;
          </button>
          <button
            className="nav-button-slider next"
            onClick={() => sliderRef.current?.slickNext()}
          >
            &gt;
          </button>
          {/* Modal */}
          {/* {isModalOpen && selectedSlide && (
    <div className="modal-overlay" ref={modalRef}>
      <div className="modal-content-m" style={{ position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
       
        <div id="close-m" onClick={closeModal}>
          <i className="fas fa-times closeicon"></i>
        </div>
        <h2>{selectedSlide. modalContent.modalHeading}</h2>
        <p>{selectedSlide. modalContent.modalText}</p>
      </div>
    </div>
  )} */}

          <div className="slidercontainer">
            <Slider ref={sliderRef} {...settings}>
              {slideData.map((slide) => (
                <div key={slide.id} className="slider-item">
                  <div className="slidecontents">
                    <h2 className="slideheading h2-np slidecontent">
                      {slide.heading}
                    </h2>
                    <p className="slidetext p-np slidecontent">{slide.text}</p>
                    {/* <button className='slidebutton read1' onClick={() => openModal(slide)}>
              {slide.buttonLabel}
            </button> */}
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </div>

        {/* <CarouselSlider/> */}
      </div>

      <div className="section5">
        <h1 className="section5heading">How We are Handling</h1>
        <div className="section5jsxcard">
          <CaseStudyCard
            imageUrl="/Images/casestudyimg1.jpg"
            title="Thorough Analysis of Requirements"
            description="Understanding your specific needs and challenges in depth. "
            // linkUrl="/platform/E-cart/casestudy-vitualwallet"
          />
          <CaseStudyCard
            imageUrl="/Images/casestudyimg2.jpg"
            title="Strategic Implementation"
            description="Implementing services in a way that aligns with your business objectives.   "
            linkUrl=""
          />
          <CaseStudyCard
            imageUrl="/Images/casestudyimg3.jpg"
            title="Continuous Monitoring and Optimization"
            description="Regularly reviewing processes to ensure optimal performance and efficiency. "
            linkUrl=""
          />

          <CaseStudyCard
            imageUrl="/Images/casestudyimg4.jpg"
            title="Responsive Client Support"
            description="Providing dedicated support to address any issues or changes in requirements."
            // title="Quality Assurance"
            // description="Maintaining high standards of quality and accuracy in all services. "
            linkUrl=""
          />

          {/* <div class="readmore">
  <button class="read">show More</button>
  </div> */}

          {/* section 6 */}
        </div>
      </div>
      {/* <div class="readmore">
      <button class="read">show More</button>
      </div> */}

      {/* section 6 */}
    </div>
  );
}

export default KpoAndBpo;
