import "./template.css";
 
 import { Link } from "react-router-dom";
import ReadModal2 from "./readMore.js";
 import React, { useState, useRef, useEffect} from 'react';
 import Slider from 'react-slick';
 import 'slick-carousel/slick/slick.css';
 import 'slick-carousel/slick/slick-theme.css';
import CarouselSlider from "./Carousel.js";
import CaseStudyCard from "./CaseStudyCard.js";


import Accordion from "./Accordion";
import "./Accordion.css";


import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

function HealthcareAndPharma() {



    const [activeModal, setActiveModal] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);
  const sliderRef = useRef(null);
  const modalRef = useRef(null);
  const [selectedSlide, setSelectedSlide] = useState(null);
  const settings = {
    // dots: true,
    // infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },

      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },

      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 3000,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
     
    ],
  };
  const slideData = [
    {
      id: 1,
      heading: 'Customized Solutions',
      text: 'Tailoring our offerings to meet the unique challenges and requirements of each sector we serve.',
      buttonLabel: 'Read More',
      modalContent: {
        // modalHeading: 'Modal Heading 1',
        modalText: 'CloudCipher seamlessly integrates with existing workflows and software ecosystems, minimizing disruption and streamlining the adoption process. Whether migrating from another platform or implementing new security measures, CloudCipher ensures a smooth transition with minimal downtime.',
      },
    },
    {
      id: 2,
      heading: 'Technology Partnerships',
      text: 'Partnering with industry leaders like Siemens and Rockwell to deliver state-of-the-art solutions. ',
      buttonLabel: 'Read More',
      modalContent: {
        modalHeading: 'Modal Heading 2',
        modalText: 'Modal Text 2',
      },
    },
 
    {
      id: 3,
      heading: 'Comprehensive Expertise',
      text: 'Our team’s deep expertise across a range of industries enables us to provide innovative, effective solutions.',
      buttonLabel: 'Read More',
      modalContent: {
        modalHeading: 'Modal Heading 3',
        modalText: 'Modal Text 3',
      },
    },
 
    {
      id: 4,
      heading: 'Focus on Efficiency and Growth',
      text: 'Our solutions are designed to streamline operations, enhance productivity, and drive business growth. ',
      buttonLabel: 'Read More',
      modalContent: {
        modalHeading: 'Modal Heading 4',
        modalText: 'Modal Text 4',
      },
    },
 
    {
      id: 5,
      heading: 'Advanced Analytics and Insights',
      text: 'Leveraging data and behavior tracking to provide actionable insights for strategic decision-making. ',
      buttonLabel: 'Read More',
      modalContent: {
        modalHeading: 'Modal Heading 5',
        modalText: 'Modal Text 5',
      },
    },
 
    // {
    //   id: 6,
    //   heading: 'Sixth Slide',
    //   text: 'A different text for the second slide.',
    //   buttonLabel: 'Read More',
    //   modalContent: {
    //     modalHeading: 'Modal Heading 6',
    //     modalText: 'Modal Text 6',
    //   },
    // },
 
    // {
    //   id: 7,
    //   heading: 'Seventh Slide',
    //   text: 'A different text for the second slide.',
    //   buttonLabel: 'Read More',
    //   modalContent: {
    //     modalHeading: 'Modal Heading 7',
    //     modalText: 'Modal Text 7',
    //   },
    // },
 
    // {
    //   id: 8,
    //   heading: 'Eighth Slide',
    //   text: 'A different text for the second slide.',
    //   buttonLabel: 'Read More',
    //   modalContent: {
    //     modalHeading: 'Modal Heading 8',
    //     modalText: 'Modal Text 8',
    //   },
    // },
    // ... (more slides)
  ];
 

  let items = [
    {
      title: "Q: How can your Medical Store Solutions improve our pharmacy operations? ",
      content:
        "A: Our solutions automate key processes, enhance inventory management, and improve patient engagement, leading to increased efficiency and customer satisfaction.   ",
    },
    {
      title: "Q: What makes your B2B Commerce Platforms stand out? ",
      content:
        "A: We focus on creating user-friendly, scalable platforms that support complex B2B transactions, personalized buying experiences, and integrated analytics for smarter business operations.  ",
    },
    {
      title: "Q: Can your Industrial Automation solutions be customized for our specific manufacturing needs?",
      content:
        "A: Absolutely. We specialize in tailoring automation solutions that fit your unique manufacturing requirements, leveraging our partnerships with Siemens and Rockwell.  ",
    },
  
  ];
 
 
  const openModal = (slide) => {
    setSelectedSlide(slide);
    setModalOpen(true);
  };
 
  const closeModal = () => {
    setModalOpen(false);
  };
 
  // Handle clicks outside the modal to close it
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        closeModal();
      }
    };
 
    window.addEventListener('mousedown', handleOutsideClick);
 
    return () => {
      window.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);



  const modalContent = (
    <>
      <p className="modal-np" >The Purpose Behind Our Healthcare & Pharma Solutions</p>
      Digitec Hub is at the forefront of providing advanced, customized solutions across various sectors. From revolutionizing retail pharmacy operations and B2B commerce to pioneering industrial automation and insightful data analytics, our goal is to empower businesses with the tools they need for success in a rapidly evolving landscape.  
          <hr/>
           <br/> <strong>B2B Commerce Platforms</strong> 
 
           The pharmaceutical industry often involves complex supply chains and B2B transactions between manufacturers, distributors, and retailers. By providing B2B commerce platforms tailored to the needs of pharmaceutical companies, Digitec Hub facilitates smoother transactions, enhances collaboration among stakeholders, and improves the efficiency of the pharmaceutical supply chain.
         <br/><br/>
        <strong> Industrial Automation with Partnerships</strong>
          Partnering with industry giants like Siemens and Rockwell for industrial automation signifies Digitec Hub's capability to integrate advanced automation technologies into healthcare and pharmaceutical manufacturing processes. This collaboration aims to optimize production processes, ensure compliance with regulatory standards, and enhance product quality and safety in pharmaceutical manufacturing.
 
          <br/><br/><strong>Comprehensive Reporting and Data Collection Utilities</strong> 
          Reporting and data collection are essential components of healthcare and pharmaceutical operations for tracking performance, monitoring compliance, and making informed decisions. By offering comprehensive reporting and data collection utilities, Digitec Hub enables healthcare organizations and pharmaceutical companies to gather, analyze, and utilize data effectively to improve operational efficiency and outcomes.
 
 
          
  
 
      {/* Your dynamic content here */}
      {/* You can pass any JSX content you want */}
    </>
 );


  return (

    <div>

<div id="maindivs">
        <div id="carouselExampleCaptions" className="carousel slide">
          <div className="carousel-inner">
            <div className="carousel-item active">
              <img
                src="/Images/healthfinalbg.png"
                className="d-block w-100"
                style={{ height: "auto" ,opacity:"0.4"}}
                alt="slider first"
              />
            <div className="carousel-caption d-md-block">
  <div className="component-1 text-dark">Healthcare & Pharma Solutions </div>
  <div className="component-paras" style={{ color: "black", marginTop: "-20px", fontStyle: 'italic',fontWeight:"700" }}>
        "Innovating Across Industries – Tailored Solutions for Tomorrow’s Challenges" 
    
  </div>
</div>

            </div>
    
        </div>
        </div>
        
    
        <div className="container-np2">
          {/* <!-- section2 title --> */}
          <div className="cont2-titles">
            {/* <div class="cont2-titletext">What is Healthcare & Pharma Solutions?</div> */}
            
          </div>
    
          {/* <!-- section part --> */}
          <div class="container21">
            {/* <!-- left part --> */}
    
            <div className="cont2-left cont2-text">
              <div
                className="left-text"
                style={{
                  // fontFamily: "arial",
               
                  fontFamily: "system-ui",
                }}
              >
           Our suite of solutions spans critical areas of modern business needs, including Medical Store management, B2B commerce platforms, Industrial Automation partnering with giants, comprehensive Reporting and Data Collection utilities, and Behavior Tracking with our proprietary SmartVision technology. At Digitec Hub, we leverage cutting-edge technologies and deep industry insights to deliver solutions that enhance efficiency, drive growth, and transform operational capabilities. 
              </div>
            </div>
    
            {/* right part */}
            <div className="cont2-right cont2-text">
              <div className="right-text">
                <img
                  class="content2img"
                  src="/Images/medical-insurance.gif"
                  alt="Description of the image"
                />
              </div>
            </div>
          </div>
          <div class="readmore">
            <ReadModal2 buttonText="Read More" modalContent={modalContent} />

            
          </div>
        </div>
    
        {/* section3 */}
        <section class="section3">
          <div class="cont2-title title ">Our Features</div>
    
          <div class="section3parts">
            <div class="content-np item31">
              <div>
                <strong style={{ paddingLeft: '20px' }}> 
                Medical Store Solutions
    </strong>
                <p class="aligned-paragraph item3text">
                Implementing technology-driven solutions for pharmacy management, inventory control, and digital prescriptions to enhance patient care and store efficiency. 
                </p>
              </div>
            </div>
    
            <div class="content-np item322">
              <strong class="item32-heading item31-conent"
              style={{
                paddingLeft:'15px',color:'black'
              }} >
              B2B Commerce Platforms
    
              </strong>
              <div class=" item31-conent-img  "></div>
              <p class="aligned-paragraph item3text" style={{color:'black'}}>
              Developing robust B2B commerce solutions that streamline operations, enhance buyer experiences, and drive sales. 
              </p>
            </div>
    
            <div class="content-np item33">
              <strong class="item32-heading"
              style={{
                paddingLeft:'15px'
              }}
                > Industrial Automation with Siemens and Rockwell
    </strong>
              <p class="aligned-paragraph item3text">
              Offering advanced industrial automation solutions, integrating the latest technologies from Siemens and Rockwell to optimize manufacturing and production processes. 
              </p>
              {/* <div class="item32-button"><button class="item32-button">Read more</button></div> */}
            </div>
    
            <div class="content-np item34" >
              <strong 
              style={{
                paddingLeft:'15px'
               }}
                >Reporting and Data Collection Utility
     </strong>
              <p class="aligned-paragraph item3text" style={{color:'black'}}>
              Providing powerful tools for data collection, analysis, and reporting to inform business decisions and improve operational insights. 
              </p>
            </div>
            <div class="content-np item35">
              <strong 
              style={{
                paddingLeft:'15px'
             }}
                > Behavior Tracking with SmartVision
     </strong>
              <p class="aligned-paragraph item3text">
              Utilizing our SmartVision technology for advanced behavior tracking applications, improving security, customer insights, and operational efficiency. 
              </p>
            </div>
    
            <div class="content-np item36">
              <strong 
              style={{
                paddingLeft:'15px'
             }}
                >
                    User-Friendly Interface
    </strong>
              <p class="aligned-paragraph item3text">
              Intuitive interfaces and user-friendly design elements make the solutions easy to use and navigate, minimizing training requirements and ensuring widespread adoption among staff members.
              </p>
            </div>
    
            <div class="content-np item37">
              <strong 
              style={{
                paddingLeft:'15px'
             }}
                >
                    Supply Chain Visibility and Traceability
    </strong>
              <p class="aligned-paragraph item3text" style={{color:'black'}}>
              To ensure transparency in healthcare and pharmaceutical supply chains, allowing stakeholders to track products from manufacturing to delivery, ensuring product integrity, compliance, and efficiency.
              </p>
            </div>
          </div>
          {/* <div class="readmore">
            <button class="read1">Read More</button>
          </div> */}
        </section>

        
    
        <div class="section4">
          
          <div className="main-cont">
            <div className="Cardcomp">
              <div className="card-container">
                <div className="row-ca">
                  <div className="col-ca">
                    <div className="card-head text-center">
                      <h2 className="head-t ">USP of HealthCare & Pharma Solutions</h2>
                     
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card-container">
              <div className="row-ck">
                <div className="col-ck">
                  <div className="card-wrapper">
                    <div className="main-card">
                      <div className="innercon">
                        <div className="card-content1">
                          <h2>
                          Customized Solutions{" "}
                            <FontAwesomeIcon icon={faArrowRight} />
                          </h2>
                          {/* <p>vbhf</p> */}
                        </div>
                        <img src="/Images/hand.gif" alt="" />
                      </div>
                    </div>
                    <div className="hover-card">
                      <div className="hover-content">
                        <h2> Customized Solutions</h2>
                        <p>
                        Tailoring our offerings to meet the unique challenges and requirements of each sector we serve. {" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-ck">
                  <div className="card-wrapper">
                    <div className="main-card">
                      <div className="innercon">
                        <div className="card-content1">
                          <h2>
                          Technology Partnerships
                            <FontAwesomeIcon icon={faArrowRight} />{" "}
                          </h2>
                          {/* <p>vbhf</p> */}
                        </div>
                        <img src="/Images/diagram.gif" alt="" />
                      </div>
                    </div>
                    <div className="hover-card">
                      <div className="hover-content">
                        <h2>Technology Partnerships</h2>
                        <p>
                          {" "}
                          Partnering with industry leaders like Siemens and Rockwell to deliver state-of-the-art solutions. {" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-ck">
                  <div className="card-wrapper">
                    <div className="main-card">
                      <div className="innercon">
                        <div className="card-content1">
                          <h2>
                          Comprehensive Expertise{" "}
                            <FontAwesomeIcon icon={faArrowRight} />
                          </h2>
                          {/* <p>vbhf</p> */}
                        </div>
                        <img src="/Images/Seamless_Multi-Platform_Integration.gif" alt="" />
                      </div>
                    </div>
                    <div className="hover-card">
                      <div className="hover-content">
                        <h2>Comprehensive Expertise</h2>
                        <p>
                        Our team’s deep expertise across a range of industries enables us to provide innovative, effective solutions.{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-ck">
                  <div className="card-wrapper">
                    <div className="main-card">
                      <div className="innercon">
                        <div className="card-content1">
                          <h2>
                          Focus on Efficiency and Growth<FontAwesomeIcon icon={faArrowRight} />
                          </h2>
                          {/* <p>vbhf</p> */}
                        </div>
                        <img src="/Images/implementation.gif" alt="" />
                      </div>
                    </div>
                    <div className="hover-card">
                      <div className="hover-content">
                        <h2>Focus on Efficiency and Growth</h2>
                        <p>
                        Our solutions are designed to streamline operations, enhance productivity, and drive business growth.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-ck">
                  <div className="card-wrapper">
                    <div className="main-card">
                      <div className="innercon">
                        <div className="card-content1">
                          <h2>
                          Advanced Analytics and Insights
                            <FontAwesomeIcon icon={faArrowRight} />
                          </h2>
                          {/* <p>vbhf</p> */}
                        </div>
                        <img src="/Images/Performance_Optimization.gif" alt="" />
                      </div>
                    </div>
                    <div className="hover-card">
                      <div className="hover-content">
                        <h2>Advanced Analytics and Insights</h2>
                        <p>
                        Leveraging data and behavior tracking to provide actionable insights for strategic decision-making.{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-ck">
                  <div className="card-wrapper">
                    <div className="main-card">
                      <div className="innercon">
                        <div className="card-content1">
                          <h2>
                          Regulatory Compliance{" "}
                            <FontAwesomeIcon icon={faArrowRight} />
                          </h2>
                          {/* <p>vbhf</p> */}
                        </div>
                        <img src="/Images/24_Customer_Support.gif" alt="" />
                      </div>
                    </div>
                    <div className="hover-card">
                      <div className="hover-content">
                        <h2>Regulatory Compliance</h2>
                        <p>
                          {" "}
                          We ensure that our solutions comply with all relevant regulations and standards, giving you peace of mind knowing that you are meeting your legal obligations.{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

               
                
              </div>
            </div>
          </div>




          {/* <CarouselSlider/> */}
        </div>
    
        <div className="section5" >
    <h1 className="section5heading">How We are Handling</h1>
      <div className="section5jsxcard">
      <CaseStudyCard
        imageUrl="/Images/casestudyimg1.jpg"
        title="Solution Development and Deployment"
        description="Custom-developing solutions that align with your business goals and seamlessly integrating them into your operations. "
        // linkUrl="/platform/E-cart/casestudy-vitualwallet"
      />
      <CaseStudyCard
        imageUrl="/Images/casestudyimg2.jpg"
        title="Continuous Support and Optimization"
        description="Providing ongoing support and continually optimizing solutions to ensure they deliver maximum value."
        linkUrl=""
      />
      <CaseStudyCard
        imageUrl="/Images/casestudyimg3.jpg"
        title="Training and Empowerment"
        description="Offering comprehensive training to ensure your team can fully leverage the new technologies and processes."
        linkUrl=""
      />
     
      <CaseStudyCard
        imageUrl="/Images/casestudyimg4.jpg"
        title="Future-Ready Focus"
        description="Keeping our solutions agile and adaptable to ensure they remain effective as your business and the industry evolve."
        linkUrl=""
      />
      
     
    {/* <div class="readmore">
  <button class="read">show More</button>
  </div> */}


{/* section 6 */}


  
    </div>
    </div> 
    

    <section className="FAQ">
        <div className="FAQbody">
          <h1 className="section5heading text-dark" >FAQ's for this service</h1>
          <Accordion items={items} />
        </div>
      </section>

        
      </div>
      
    </div>
  )
}

export default HealthcareAndPharma
