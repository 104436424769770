import React from 'react'
import './StaffingOutsource.css';

function StaffingOutsource() {


  
  return (
    <div>
             <div className='staffing'> Staffing/Consulting Service</div>
             <p className='staffingpara'>Welcome to DigitechHub, your trusted partner in staff outsourcing and consultant outsourcing services across the globe. With an extensive presence in India, USA, GCC countries, and South East Asia, we offer a diverse range of skilled professionals to elevate your projects and drive success. </p>
             <h1 className='staffingh1' style={{paddingLeft:'13.4%',fontSize:'26px'}}>Our Outsourcing Services </h1>
             <div className='out' style={{paddingLeft:'13.4%',fontSize:'23px'}}><strong>Staff Outsourcing:</strong> </div>
             <div className='em'style={{paddingLeft:'13.4%'}}>Empower your projects with our highly skilled and experienced professionals, including: </div>
            
<section id="pt75" className="pt75">
        <article className="container">
          <div className="row">
            <div
              className="col-md-6 col-sm-12 col-xs-12 text-center-xs wow fadeInLeft"
              data-wow-delay="0.6s"
            >
              <img src="https://i.pinimg.com/474x/1f/c0/60/1fc060880055e618e7453804318a6702.jpg" style={{marginLeft:'20px'}}></img>
            </div>
            <div
              className="col-md-6 col-sm-12 col-xs-12 mb-sm-20 wow fadeInRight"
              data-wow-delay="0.6s"
            >
              <h3 className="mb10 mt0" style={{paddingLeft:'13.4%',}}>Software Developers and Engineers</h3>
              <p style={{paddingLeft:'15px'}}>
              Expertise in various programming languages and frameworks.
                <br></br>
              
              </p>

              
            </div>
          </div>
        </article>
      </section>
      <section id="pt75" className="pt75">
        <article className="container">
          <div className="row">
            <div
              className="col-md-6 col-sm-12 col-xs-12 mb-sm-20 wow fadeInLeft"
              data-wow-delay="0.6s"
            >
              <h3 className="mb10 mt0"style={{paddingLeft:'6.4%'}}>Testers</h3>
             <p style={{paddingLeft:'6.4%'}}>Ensuring the quality and reliability of your software applications. </p>
            </div>
            <div id="col2"
              className="col-md-6 col-sm-12 col-xs-12 text-center-xs wow fadeInRight"
              data-wow-delay="0.6s"
            >
              <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSaPzKKHxAIGPyFwMUL8UX_Pg7lTTwZW6jRNg&usqp=CAU" style={{paddingLeft:'8.4%'}}></img>
            </div>
          </div>
        </article>
      </section>
      <section id="pt75" className="pt75">
        <article className="container">
          <div className="row">
            <div
              className="col-md-6 col-sm-12 col-xs-12 text-center-xs wow fadeInLeft"
              data-wow-delay="0.6s"
            >
              <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS5oXO7_ZfqPi5roNfY6zPa7GTlgh2KLpu8cg&usqp=CAU.jpg"style={{paddingLeft:'8.4%'}} ></img>
            </div>
            <div
              className="col-md-6 col-sm-12 col-xs-12 mb-sm-20 wow fadeInRight"
              data-wow-delay="0.6s"
            >
              <h3 className="mb10 mt0" style={{paddingLeft:'8.4%'}}>SAP Engineers and Functional Consultants: –</h3>
              <p style={{paddingLeft:'15px'}}>
              Implementing and optimizing SAP solutions. 
                <br></br>
              
              </p>

              
            </div>
          </div>
        </article>
      </section>
      <section id="pt75" className="pt75">
        <article className="container">
          <div className="row">
            <div
              className="col-md-6 col-sm-12 col-xs-12 mb-sm-20 wow fadeInLeft"
              data-wow-delay="0.6s"
            >
              <h3 className="mb10 mt0"  style={{marginTop:'65px',paddingLeft:'6.4%'}}>Solution Architects and Enterprise Architects –</h3>
             <p style={{paddingLeft:'6.4%'}}>Designing robust and scalable solutions. </p>
            </div>
            <div id="col2"
              className="col-md-6 col-sm-12 col-xs-12 text-center-xs wow fadeInRight"
              data-wow-delay="0.6s"
            >
              <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQyQKBw1fxCQdImYVvq3ZacKmZRZx9wwJTfOw&usqp=CAU" style={{marginTop:'65px',paddingLeft:'6.4%'}}></img>
            </div>
          </div>
        </article>
      </section>
      <section id="pt75" className="pt75">
        <article className="container">
          <div className="row">
            <div
              className="col-md-6 col-sm-12 col-xs-12 text-center-xs wow fadeInLeft"
              data-wow-delay="0.6s"
            >
              <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQdM4QsUSExiOkyDMfi1dgH0z5BLBZg7AgeDg&usqp=CAU" style={{marginLeft:'6.4%',marginTop:'65px'}}></img>
            </div>
            <div
              className="col-md-6 col-sm-12 col-xs-12 mb-sm-20 wow fadeInRight"
              data-wow-delay="0.6s"
            >
              <h3 className="mb10 mt0" style={{paddingLeft:'15px',marginTop:'65px'}}>Project Managers and Delivery Managers:</h3>
              <p style={{paddingLeft:'15px'}}>
              Ensuring successful project execution and timely delivery. 
                <br></br>
              
              </p>

              
            </div>
          </div>
        </article>
      </section>
      <section id="pt75" className="pt75">
        <article className="container">
          <div className="row">
            <div
              className="col-md-6 col-sm-12 col-xs-12 mb-sm-20 wow fadeInLeft"
              data-wow-delay="0.6s"
            >
              <h3 className="mb10 mt0"style={{marginTop:'65px',paddingLeft:'6.4%'}}>Front-End and Full-Stack Developers: –</h3>
             <p style={{paddingLeft:'6.4%'}}>Crafting engaging user experiences and functional solutions.  </p>
            </div>
            <div id="col2"
              className="col-md-6 col-sm-12 col-xs-12 text-center-xs wow fadeInRight"
              data-wow-delay="0.6s"
            >
              <img src="https://profoundedutech.com/blog/wp-content/uploads/2022/05/Full-Stack-Developer.png"></img>
            </div>
          </div>
        </article>
      </section>  

       <div className='out' style={{paddingLeft:'13.4%',fontSize:'23px',marginTop:'70px'}}><strong>Consultant Outsourcing:</strong> </div>           
     <div style={{paddingLeft:'13.4%'}}>Leverage our consultant outsourcing services for expert insights and guidance across various domains, including IT, finance, management, and more. Our consultants offer strategic thinking and solutions tailored to your business goals. </div>
    
     <div className="why-choose-us"  style={{marginLeft:'13.4%',marginRight:'10.4%',marginTop:'3%'}}>
      <h2>Why Choose DigitechHub for SaaS Development Support?</h2>
      <ul>
        <li>
          <strong>Vast Talent Pool:</strong> We have an extensive network of skilled professionals across various domains. 
        </li>
        <li>
          <strong>Custom Solutions:</strong>We understand your unique requirements and provide tailored solutions. 
        </li>
        <li>
          <strong>Global Reach:</strong> Our international presence enables us to cater to your needs worldwide. 
        </li>
        <li>
          <strong>Quality Assurance:</strong> Our professionals undergo rigorous screening to ensure top-notch expertise. 
        </li>
        <li>
          <strong>Flexible Engagement Models:</strong> Choose from various engagement models to fit your business needs. 
        </li>
      </ul>
    </div>
    <div>
  <div style={{ textAlign: 'center', fontSize: '30px', fontFamily: 'Oswald', fontWeight: 'bold',marginTop:'3%' }}>Industries We Serve </div>
  <div style={{ textAlign:'center',marginTop:'3%' }}>Our outsourcing services cater to a wide range of industries, including but not limited to: </div>
  <ul style={{ listStyleType: 'none', padding: 0,marginTop:'3%',textAlign:'center',marginBottom:'3%' }}>
    <li style={{ display: 'inline', marginRight: '10px' }}>
      <i className="fas fa-laptop"></i> Information Technology
    </li>
    <li style={{ display: 'inline', marginRight: '10px' }}>
      <i className="fas fa-money-bill"></i> Finance and Banking
    </li>
    <li style={{ display: 'inline', marginRight: '10px' }}>
      <i className="fas fa-heart"></i> Healthcare
    </li>
    <li style={{ display: 'inline', marginRight: '10px' }}>
      <i className="fas fa-shopping-cart"></i> Retail and E-commerce
    </li>
  </ul>
</div>

<div>
  <div style={{marginLeft:'13.4%',fontSize:'20px',fontWeight:'bold'}}>Enhance Your Team with Outsourcing: </div>
 <p style={{paddingLeft:'13.4%',paddingBottom:'20px'}}>Elevate your projects and business operations by partnering with [Your Company Name] for staff outsourcing and consultant outsourcing. Contact us today to discuss your staffing needs and how we can bring exceptional talent to your organization, no matter where you are in the world. </p>
</div>

    
    
    
    </div>
  )
}

export default StaffingOutsource
