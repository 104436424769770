import React from "react";

import "./product_common.css";

export default function VM() {
  return (
    <>
      {/* <div><NavBar/></div>  */}

      <div className="banner parbase industries-gradient aem-GridColumn aem-GridColumn--default--12">
        <section id="sml_ht_home_banner">
          <article className="container">
            <div className="row">
              <div className="col-md-9 col-sm-12 col-xs-12 ">
                <h3 className="h2-heading white-color l-top">Gps</h3>
              </div>
            </div>
          </article>
        </section>
      </div>

      <section id="pt75" className="pt75">
        <article className="container">
          <div className="row">
            <div
              className="col-md-6 col-sm-12 col-xs-12 text-center-xs wow fadeInLeft"
              data-wow-delay="0.6s"
            >
              <div className="d-flex justify-content-center align-items-center">

              <img src="/Images/gps-mobile.jpg"></img>
              </div>
            </div>

            <div id="col2"
              className="col-md-6 col-sm-12 col-xs-12 mb-sm-20 wow fadeInRight"
              data-wow-delay="0.6s"
            >
              <div className="d-flex justify-content-center align-items-center">
              <h3 className="mb10 mt0">GPS Features</h3>
              <ul>
                <li>
                  Live tracking and live info (driver, location, speed,
                  direction, connectivity)
                </li>
                <li>Customized display of vehicles on map</li>
                <li>Customized display of vehicles on map</li>
                <li>
                  Easy search for vehicles and Follow-Me feature to track from
                  close
                </li>
                <li>History track with full trip details</li>
                <li>
                  Identify all vehicles passing by certain location (BY
                  GEOZONES)
                </li>
              </ul>
              </div>
            </div>
          </div>
        </article>
      </section>

      <section id="pt75" className="pt75">
        <article className="container">
          <div className="row">
            <div
              className="col-md-6 col-sm-12 col-xs-12 mb-sm-20 wow fadeInLeft"
              data-wow-delay="0.6s"
            >
              <div className="d-flex justify-content-center align-items-center">
              <h3 className="mb10 mt0">Rich Interface</h3>
              <ul>
                <li>
                  Locate vehicles and browse Here Maps in Normal & Satellite
                  modes
                </li>
                <li>Search for any address or place over the territory</li>
                <li>Identify Point of Interest nearby a moving vehicle</li>
                <li>Calculate best routes and get directions</li>
                <li>Measure distance(s) between two or more locations</li>
                <li>Mobile App for any-time tracking (iOS/ Android)</li>
              </ul>
              </div>
            </div>

            <div id="col2"
              className="col-md-6 col-sm-12 col-xs-12 text-center-xs wow fadeInRight"
              data-wow-delay="0.6s"
            >
              <div className="d-flex justify-content-center align-items-center">
              <img src="/Images/rich-interface.jpg"></img>
              </div>
            </div>
          </div>
        </article>
      </section>
      <section id="pt75" className="pt75">
        <article className="container">
          <div className="row">
            <div
              className="col-md-6 col-sm-12 col-xs-12 text-center-xs wow fadeInLeft"
              data-wow-delay="0.6s"
            >
              <div className="d-flex justify-content-center align-items-center">

              <img src="/Images/gps-report.jpg" style={{padding: "20px"}}></img>
              </div>
            </div>

            <div id="col2"
              className="col-md-6 col-sm-12 col-xs-12 mb-sm-20 wow fadeInRight"
              data-wow-delay="0.6s"
            >
              <div className="d-flex justify-content-center align-items-center">
              <h3 className="mb10 mt0">Full Reporting</h3>
              <ul>
                <li>
                  Powerful reporting capabilities (distance traveled, trip
                  duration,stops, number & duration, average/min/max speed)
                </li>
                <li>
                  Generate most-common reports (over-speeding, track summary,
                  stops & general alert reports)
                </li>
                <li>Create customized reports per driver</li>
                <li>
                  Schedule reports to be sent automatically on daily/weekly
                  basis
                </li>
                <li>Full Logs for up to 3 months backward</li>
              </ul>
              </div>
            </div>
          </div>
        </article>
      </section>
      <section id="pt75" className="pt75">
        <article className="container">
          <div className="row">
            <div
              className="col-md-6 col-sm-12 col-xs-12 mb-sm-20 wow fadeInLeft"
              data-wow-delay="0.6s"
            >
              <div className="d-flex justify-content-center align-items-center">
              <h3 className="mb10 mt0">Fuel Tracking</h3>
              <ul>
                <li>
                  Detect real-time location of your vehicles & monitor their
                  actual movement
                </li>
                <li>
                  Monitor driver’s behavior & eliminate unauthorized stops,
                  over-speeding and after-hours use
                </li>
                <li>
                  Prevent abusive driving and fuel waste, and save lot of money
                </li>
                <li>Enhance drivers’ safety and reduce accidents risk</li>
                <li>Locate important places nearby vehicles for emergency</li>
                <li>Full Logs for up to 1 month backward</li>
              </ul>
              </div>
            </div>

            <div id="col2"
              className="col-md-6 col-sm-12 col-xs-12 text-center-xs wow fadeInRight"
              data-wow-delay="0.6s"
            >
              <div className="d-flex justify-content-center align-items-center">
              <img src="/Images/fuel1.jpg" style={{paddingTop: "80px"}}></img>
              </div>
            </div>
          </div>
        </article>
      </section>
      <section id="pt75" className="pt75">
        <article className="container">
          <div className="row">
            <div
              className="col-md-6 col-sm-12 col-xs-12 text-center-xs wow fadeInLeft"
              data-wow-delay="0.6s"
            >
              <div className="d-flex justify-content-center align-items-center">
              

              <img src="/Images/gps-benefit.jpg" style={{maxWidth:"75%"}}></img>
              </div>
            </div>

            <div id="col2"
              className="col-md-6 col-sm-12 col-xs-12 mb-sm-20 wow fadeInRight"
              data-wow-delay="0.6s"
            >
              <div className="d-flex justify-content-center align-items-center">
              <h3 className="mb10 mt0">Solution Benefits</h3>
              <ul>
                <li>
                  Detect real-time location of your vehicles & monitor their
                  actual movement
                </li>
                <li>
                  Monitor driver’s behavior & eliminate unauthorized
                  stops,over-speeding & after-hours use
                </li>
                <li>
                  Prevent abusive driving & fuel waste, & save lot of money
                </li>
                <li>
                  Locate important places (gas station, car service,
                  hospitals,chemical transportations) nearby vehicles for
                  emergency
                </li>
                <li>Enhance drivers’ safety and reduce accidents risk</li>
                <li>
                  Analyze vehicle usage eﬃciency with powerful reporting
                  capabilities
                </li>
                <li>
                  Ensure on-schedule transport and improve your customer service
                </li>
              </ul>
              </div>
            </div>
          </div>
        </article>
      </section>

      <section id="pt75" className="pt75">
        <article className="container">
          <div className="row">
            <div
              className="col-md-6 col-sm-12 col-xs-12 mb-sm-20 wow fadeInLeft"
              data-wow-delay="0.6s"
            >
              <div className="d-flex justify-content-center align-items-center">
              <h3 className="mb10 mt0">Why Our Solution?</h3>
              <ul>
                <li>Fully hosted on GIS Guide Secure Cloud</li>
                <li>
                  Web-based GUI interface, no need for any additional computer
                  hardware or soft ware
                </li>
                <li>
                  Able to connect several types of trackers, sensors, cameras
                  etc.
                </li>
                <li>
                  Full service package including hardware & software support, no
                  hidden costs
                </li>
                <li>Scalable service and Pay-as-you-Go</li>
                <li>
                  Based on GPS tracking devices known for their high reliability
                  under sever circumstances (temperature, shock, etc.)
                </li>
                <li>
                  Solution also available on-premises (Enterprise Edition)
                </li>
              </ul>
              </div>
            </div>

            <div id="col2"
              className="col-md-6 col-sm-12 col-xs-12 text-center-xs wow fadeInRight"
              data-wow-delay="0.6s"
            >
              <div className="d-flex justify-content-center align-items-center">
              <img src="/Images/gps-screen.jpg" style={{paddingTop: "100px",paddingLeft:"20px"}}></img>
              </div>
            </div>
          </div>
        </article>
      </section>
    </>
  );
}
