import React, { useState, useEffect, useRef } from 'react';
import './BankingPlatform.css';


import '@fortawesome/fontawesome-free/css/all.css';



function BankingPlatform(props) {

  const [showAccountDetails, setShowAccountDetails] = useState(false);

  const toggleAccountDetails = () => {
    setShowAccountDetails(!showAccountDetails);
  };



  const elementsToObserve = useRef([]);


  const handleIntersection = (entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        entry.target.classList.add('active'); // Add the .active class when element is in view
      } else {
        entry.target.classList.remove('active'); // Remove the .active class when element is out of view
      }
    });
  };

  useEffect(() => {
    elementsToObserve.current = document.querySelectorAll('.fade-in');
    // Rest of your IntersectionObserver code...
  }, []);
  

  useEffect(() => {
    const observer = new IntersectionObserver(handleIntersection, {
      threshold: 0.5, // You can adjust this threshold as needed
    });
  
    elementsToObserve.current.forEach((element) => {
      observer.observe(element);
    });
  
    return () => {
      observer.disconnect();
    };
  }, []);

  useEffect(() => {
    const creatingAccount = document.querySelector('.creatingaccount');
    const creatingAccountContainer = document.querySelector('.creatingaccount-container');

    window.addEventListener('scroll', () => {
      const scrollY = window.scrollY;
      const triggerPoint = creatingAccountContainer.offsetTop - window.innerHeight + 100; // Adjust the trigger point as needed

      if (scrollY >= triggerPoint) {
        creatingAccount.style.top = '0';
        creatingAccount.style.opacity = '1';
      }
    });

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('scroll', () => {});
    };
  }, []);


  return (
   
    <div>
      <div>
      <div className='Banking-heading'>Banking Platform</div>
      <p className='banking-p'>Neo Banking is a new-age banking solution aimed at helping Cooperative Banks and Credit Societies to offer an impressive digital experience to their customers with market-ready products</p>
     
      <div className="banking-features-container">
      <div className="banking-features-list">
        <ul>
          <li className='banking-listed'>
            <i className="fas fa-plug"></i> Readily available agile, plug & play platform
          </li>
          <li className='banking-listed'>
            <i className="fas fa-check-circle"></i> Covers acquisition, underwriting, onboarding, servicing, termination & renewal
          </li>
          <li className='banking-listed'>
            <i className="fas fa-cogs"></i> Provides seamless configurability, adaptability, & scalability
          </li>
          <li className='banking-listed'>
            <i className="fas fa-university"></i> Provides infrastructure for banking & financial institutions
          </li>
        </ul>
      </div>
      <div className="banking-features-image">
        <img src="https://ugem-production.s3.amazonaws.com/uploads/articleimage/file/327/ugem_dis_con_1x.png" alt="Image Description" />
      </div>
   </div>

   
   
   <div className="insights aem-GridColumn aem-GridColumn--default--12">
      <div className="row" ref={(el) => (elementsToObserve.current = el)}>
        <a
          href="/service/BankingPlatform/AI-Transformed-Customer-Journey"
          target="_self"
          className="col-md-6 fade-in"
        >
          <div className="report-box">
            <div className="insight-box">
              <div className="container">
                <div className="customerjourney">AI CUSTOMER JOURNEY</div>
              </div>
            </div>
          </div>
        </a>
        <a
          href="/service/BankingPlatform/Platform-And-Benefits"
          target="_self"
          className="col-md-6 fade-in"
        >
          <div className="report-box">
            <div className="insight-box">
              <div className="container">
                <div className="insight-title pb0">PLATFORMS AND BENEFITS</div>
              </div>
            </div>
          </div>
        </a>
        <a
          href="/service/BankingPlatform/CORE BANKING & PACSMODULES"
          target="_self"
          className="col-md-6 fade-in"
        >
          <div className="report-box">
            <div className="insight-box">
              <div className="container">
                <div className="insight-title pb0">CORE BANKING & PACSMODULES</div>
              </div>
            </div>
          </div>
        </a>
        {/* Add more <a> elements as needed */}
      </div>
    </div>

    <div class="creatingaccount-container">
    <a href="/service/BankingPlatform/CreatingSavingAccount" class="creatingaccount-link">
        <div class="creatingaccount-card">
            <div class="creatingaccount">Create Account Details</div>
        </div>
    </a>
    <img
        src="https://img.freepik.com/free-vector/placeholder-concept-illustration_114360-4983.jpg"
        class="imagesmove fade-in"
        alt="move image"
    />
</div>


<div class='paltformai'>PLATFORM's AI CAPABILITIES</div>
<div class="capabilities-container">
  <div class="capabilities-column">
    <div class="capability-card">
      <h3 class="predictions">Data Management</h3>
      <ul>
        <li><i class="fas fa-check-circle"></i> Secure document storage & customer stamping for future reference</li>
        <li><i class="fas fa-check-circle"></i> Real-time data extraction enabling AI at scale</li>
        <li><i class="fas fa-check-circle"></i> Engineer accurate & actionable insights from a mountain of data</li>
      </ul>
    </div>
  </div>
  <div class="capabilities-column">
    <div class="capability-card">
      <h3 class="predictions">Predictions</h3>
      <ul>
        <li><i class="fas fa-dollar-sign"></i> Predict cash flow user accounts by monitoring transactional behavior</li>
        <li><i class="fas fa-gift"></i> Offer best-suited products to your customers based on customer patterns</li>
        <li><i class="fas fa-money-bill"></i> Determine loan amount & period based on AI-generated credit score</li>
      </ul>
    </div>
  </div>
  <div class="capabilities-column">
    <div class="capability-card">
      <h3 class="predictions">Engage</h3>
      <ul>
        <li><i class="fas fa-chart-pie"></i> Leverage insightful analytics to better serve customers</li>
        <li><i class="fas fa-comment-dots"></i> Enable SMS & other notifications to customers</li>
        <li><i class="fas fa-broadcast-tower"></i> Create & execute real-time customer marketing campaigns</li>
      </ul>
    </div>
  </div>
</div>



<div className='core-Modules'>CORE MODULES</div>
<div className="popup-container" >
  <div className="popup-item">
    <h4 >MASTERS MODULE</h4>
  </div>
  <div className="popup-item">
    <h4>LOAN MODULE</h4>
  </div>
  <div className="popup-item">
    <h4>ADMIN MODULE</h4>
  </div>
  <div className="popup-item">
    <h4>MEMBER/ASSOCIATE MODULE</h4>
  </div>
  <div className="popup-item">
    <h4>PAYMENT MODULE</h4>
  </div>
  <div className="popup-item">
    <h4>GENERAL FEATURES</h4>
  </div>
  <div className="popup-item">
    <h4>REPORT MODULES</h4>
  </div>
</div>



</div>

</div>



    
  )
}


export default BankingPlatform
