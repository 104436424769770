import React from 'react'
import './Payment.css'; 

function Payment() {
  return (
    <div>
      <div className='payment-heading'>PAYMENT API</div>
      <p className='payment-para'>Take total control on all transactions and payment authorizations ensuring smooth offerings in real time</p>
      <img src='https://www.cisit.in/images/2021/04/06/pgi01.png'className="imagcustomer"alt='customerimages'></img>
      <div className="feature-table">
  <div className="feature-box">
    <div className="box">
    <i className="fas fa-exchange-alt"></i>

      <p>Fund Transfer</p>
    </div>
  </div>
  <div className="feature-box">
    <div className="box">
    <i className="fas fa-info-circle"></i>

      <p>Account Details</p>
    </div>
  </div>
  <div className="feature-box">
    <div className="box">
    <i className="fas fa-history"></i>

      <p>Create Standing Instructions</p>
    </div>
  </div>

  <div className="feature-box">
  <div className="box">
    <i className="fas fa-envelope"></i>
    <p>Payment Contact Information</p>
  </div>
</div>

  
  </div>
    </div>
  )
}

export default Payment
