import React from 'react'
import "./BusinessProcessManagement.css"
import { faHospital, faIndustry, faLaptop, faShoppingCart, faUniversity } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export default function BusinessProcessManagement() {
  return (
    <>
    <div className="banner parbase industries-gradient aem-GridColumn aem-GridColumn--default--12">
    <section id="sml_ht_home_banner">
      <article className="container">
        <div className="row">
          <div className="col-md-9 col-sm-12 col-xs-12 ">
            <h1 className="h2-heading white-color l-top">
              Business Process Management
            </h1>
          </div>
        </div>
      </article>
    </section>
  </div>
  <div id='box1' className="box">
  <div className='container'>
    <p>Welcome to Expert IT, your partner in Business Process Management (BPM) services that enhance efficiency, reduce costs, and drive growth. With a focus on Accounting and CFA Services, as well as Freight and Logistics Services, we offer tailored solutions that optimize your business processes. </p>

  </div>
  </div>
  {/* <div className='row' style={{ marginTop: '30px',marginLeft:'40px' }}>
  <h2 className='text-center'>Our BPM Services: Accounting and CFA</h2>
  <div className='card' data-aos="ease-in" data-aos-duration="2000" style={{ backgroundColor: '#fcf7a1', padding: '20px', borderRadius: '10px', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)' }}>
    <h2>Accounting Services</h2>
    <p>
      Efficient financial management is the backbone of every successful business. Our Accounting BPM services cover:
      Bookkeeping: Accurate and up-to-date bookkeeping to maintain financial records.
      Accounts Payable and Receivable: Streamlined management of payables and receivables.
      Financial Reporting: Comprehensive financial reports for informed decision-making.
      Tax Preparation: Expert tax preparation services for compliance and optimization.
    </p>
  </div>
  <div className='card' data-aos="flip-right" data-aos-duration="2000" style={{ backgroundColor: '#fcf9b8', padding: '20px', borderRadius: '10px', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', marginTop: '40px' }}>
    <h2>Chartered Financial Analyst (CFA) Services</h2>
    <h5>Elevate your investment decisions with our CFA services, including:</h5>
    <p>
      Financial Analysis: In-depth analysis of investment opportunities and risks.
      Portfolio Management: Tailored strategies to optimize investment portfolios.
      Risk Assessment: Identifying and mitigating potential financial risks.
      Investment Strategy: Developing investment strategies aligned with your goals.
    </p>
  </div>
</div>

  <div className='col-md-12 float-center d-flex justify-content-center'>
  <div className='card' data-aos="flip-left" data-aos-duration="2000" style={{ backgroundColor: '#A8B5C6', padding: '20px', borderRadius: '10px', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)' }}>
    <h2 className='text-center'>Our BPM Services: Freight and Logistics</h2>
    <h2>Freight and Logistics Services</h2>
    <h4>Efficient freight and logistics operations are essential for supply chain success. Our services include:</h4>
    <p>Route Optimization: Optimizing routes for cost-effective and timely deliveries. 
    Inventory Management: Efficient tracking and management of inventory levels. 
    Order Fulfillment: Seamless order processing and delivery execution. 
    Warehousing Solutions: Customized warehousing solutions for streamlined operations.</p>
  </div>
</div> */}
<div className="row">
<h2 className='text-center'>Our BPM Services: Accounting and CFA</h2>
  <div className="col-6 card">
  <h2>Accounting Services</h2>
    <p>
      Efficient financial management is the backbone of every successful business. Our Accounting BPM services cover:
      Bookkeeping: Accurate and up-to-date bookkeeping to maintain financial records.
      Accounts Payable and Receivable: Streamlined management of payables and receivables.
      Financial Reporting: Comprehensive financial reports for informed decision-making.
      Tax Preparation: Expert tax preparation services for compliance and optimization.
    </p>

  </div>
  <div className="col-6 card"><h2>Chartered Financial Analyst (CFA) Services</h2>
    <h5>Elevate your investment decisions with our CFA services, including:</h5>
    <p>
      Financial Analysis: In-depth analysis of investment opportunities and risks.
      Portfolio Management: Tailored strategies to optimize investment portfolios.
      Risk Assessment: Identifying and mitigating potential financial risks.
      Investment Strategy: Developing investment strategies aligned with your goals.
    </p></div>
</div>
<div className="box">
<div className="row">
  <div className="col-12 "> <h2 className='text-center'>Our BPM Services: Freight and Logistics</h2>
    <h2>Freight and Logistics Services</h2>
    <h4>Efficient freight and logistics operations are essential for supply chain success. Our services include:</h4>
    <p>Route Optimization: Optimizing routes for cost-effective and timely deliveries. 
    Inventory Management: Efficient tracking and management of inventory levels. 
    Order Fulfillment: Seamless order processing and delivery execution. 
    Warehousing Solutions: Customized warehousing solutions for streamlined operations.</p></div>
</div>
</div>




  <div className='d'
    
  >
    <h4 className='heading-h4'>Why Choose Expert It for BPM Services?</h4>
    <ul>
      <li className='li text-start'>Expertise: Our team comprises experienced professionals with industry-specific knowledge.</li>
      <li className='li text-start'>Tailored Solutions: We understand your unique needs and tailor solutions accordingly.</li>
      <li className='li text-start'>Technology Integration: We leverage cutting-edge technology to enhance efficiency.</li>
      <li className='li text-start'>Process Improvement: We identify areas for improvement and implement streamlined processes.</li>
      <li className='li text-start'>Scalable Solutions: Our solutions grow with your business, adapting to changing needs.</li>
    </ul>
  </div>

{/* <div className='row'>
<div className='col-md-6 d-flex align-items-center justify-content-center'>
  <img src='/Images/industry-we-serve.jpg' alt='Industry We Serve' style={{ maxWidth: '100%', height: 'auto' }} />
</div>

<div className='col-md-6'>
  <h1>Industries We Serve</h1>
  <h2>Our BPM services cater to a diverse range of industries, including but not limited to: </h2>
  <ul>
      <li className='li text-start'>Finance and Banking </li>
      <li className='li text-start'>Manufacturing </li>
      <li className='li text-start'>E-commerce and Retail </li>
      <li className='li text-start'>Healthcare </li>
      <li className='li text-start'>Logistics and Supply Chain</li>
    </ul>
</div>

</div> */}
   <h4 className='r'>Industries We Serve</h4>
   <ul className="fa-icon-list-horizontal">
  <li>
    <FontAwesomeIcon icon={faUniversity} className="section-icon1" />
    Finance and Banking
  </li>
  <li>
    <FontAwesomeIcon icon={faHospital} className="section-icon1" />
    Healthcare
  </li>
  <li>
    <FontAwesomeIcon icon={faShoppingCart} className="section-icon1" />
    E-commerce and Retail
  </li>
  <li>
    <FontAwesomeIcon icon={faIndustry} className="section-icon1" />
    Manufacturing
  </li>
  <li>
    <FontAwesomeIcon icon={faLaptop} className="section-icon1" />
    Technology and IT Services
  </li>
  <li>
    <FontAwesomeIcon icon={faUniversity} className="section-icon11" />
    Government and Public Sector
  </li>
</ul>
{/* <div className='row'>
<div className='col-md-6' style={{ marginLeft: '10px' }}>
  <h2>Elevate Your Operations with BPM</h2>
  <p>Optimize your operations, enhance efficiency, and drive growth with our Business Process Management services. Contact us today to discuss your specific needs in Accounting and CFA, as well as Freight and Logistics, and learn how we can empower your business for success.</p>
</div>



</div> */}
<div className='s'>

      <p className='p1'><b>Elevate Your Operations with BPM</b> <br></br>Optimize your operations, enhance efficiency, and drive growth with our Business Process Management services. Contact us today to discuss your specific needs in Accounting and CFA, as well as Freight and Logistics, and learn how we can empower your business for success.</p>
    </div>
    



  </>
  
     
    )
}
