import React from 'react'
import "./SmartAccounting.css";

function SmartAccounting() {
  return (
    <div>
        <div className='Smartcloud-container'>
             Smart Accounting
            </div> 
   <div className="banner parbase industries-gradient aem-GridColumn aem-GridColumn--default--12">
    <section id="sml_ht_home_banner">
      <article className="container">
        <div className="row">
          <div className="col-md-9 col-sm-12 col-xs-12 ">
            
          </div>
        </div>
      </article>
    </section>
  </div>
  
      

      <div class="container">
    
  <div class="cards-container">
    <div data-aos="flip-left" data-aos-duration="2000" class="card">
      <h3>1. Invoicing: </h3>
     <ul>
        <li>Create and send professional invoices. </li>
        <li>Set up recurring billing. </li>
        <li>Create and send professional invoices. </li>

     </ul>
    </div>
    
    <div data-aos="flip-right" data-aos-duration="2000" class="card">
      <h3>2. Bill & Expense Management: </h3>
      <ul>
        <li>Manage supplier bills and expenses </li>
        <li>Automate expense management </li>
        <li>Batch payments  </li>

     </ul>
    </div>
    <div data-aos="flip-left" data-aos-duration="2000" class="card">
      <h3>3. Bank Synchronization: </h3>
      <ul>
        <li>Automatically import bank transactions </li>
        <li>Smart reconciliation tool for matching and processing  </li>

     </ul>
    </div>
    <div data-aos="flip-right" data-aos-duration="2000" class="card">
      <h3>4. Payments: </h3>
      <ul>
        <li>Handle multiple payment methods including checks, credit cards, and bank transfers </li>
        <li>Integrate with various payment gateways </li>
       
     </ul>
    </div>
    <div data-aos="flip-left" data-aos-duration="2000" class="card">
      <h3>5. Reports: </h3>
      <ul>
        <li>Profit and Loss report </li>
        <li>Balance Sheet  </li>
        <li>Cash Flow Statement  </li>

     </ul>
    </div>
    <div data-aos="flip-right" data-aos-duration="2000" class="card">
      <h3>6. Multi-Currency: </h3>
      <ul>
        <li>Real-time currency rates  </li>
        <li>Work in multiple currencies  </li>
    
     </ul>
    </div>
    <div data-aos="flip-left" data-aos-duration="2000" class="card">
      <h3>7. Asset Management: </h3>
      <ul>
        <li>Track assets, depreciation, and manage amortization  </li>

     </ul>
    </div>
    <div data-aos="flip-right" data-aos-duration="2000" class="card">
      <h3>8. Taxes: </h3>
      <ul>
        <li>Automated tax calculations  </li>
        <li>Support for multiple tax structures </li>

     </ul>
    </div>
    <div data-aos="flip-left" data-aos-duration="2000" class="card">
      <h3>9. Budgets: </h3>
      <ul>
        <li>Set up budgetary positions and budgetary controls.  </li>
    
     </ul>
    </div>
    <div data-aos="flip-right" data-aos-duration="2000" class="card">
      <h3>10. Analytic Accounting: </h3>
      <ul>
        <li>Track costs & revenues by project, department, etc.  </li>

     </ul>
    </div>
    {/* <div data-aos="flip-left" data-aos-duration="2000" className="card">
      <h3>11. Import & Export Data: </h3>
      <ul>
        <li>Easily import/export data in CSV or Excel format </li>
        
     </ul>
    </div> */}
    
   
  </div>
   </div>
 <div id='additional ' style={{marginLeft:'7%'}}>
    <div style={{fontSize:'25px',fontFamily:'arial,myriad-pro',paddingBottom:'30px',fontWeight:'bold'}}>Additional Features: </div>
    <h5>1. Mobile Platforms: </h5>
    <ul>
        <li>Access from mobile devices ensures flexibility and allows users to manage accounts on the go. </li>
    </ul>

    <h5>2. Chatbot Integration: </h5>
    <ul>
        <li>Chatbots facilitate easy reporting, making it simpler for users to pull reports or get quick insights without delving deep into the UI.  </li>
        <li>Provides instant responses and can potentially automate certain tasks. </li>
    </ul>

    <h5>3. Scheduled Reporting:  </h5>
    <ul>
        <li>Automated reports at scheduled intervals ensure stakeholders get timely information.</li>
        <li>Customizable report schedules to suit business needs. </li>
    </ul>

    <h5>4. Updates: </h5>
    <ul>
        <li>Regular updates ensure that the platform is up to date with the latest features, security patches, and improvements.  </li>
    </ul>
    <p>With such comprehensive features, Smart Accounting Packages's /module is suitable for both SMEs and larger enterprises. However, always make sure to analyze specific business needs and consider potential customization before implementing the solution. 

</p>
 </div>

   </div>
  )
}
export default SmartAccounting
