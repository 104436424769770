import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';

const TitlePreview = () => {
  const { id } = useParams();
  const [post, setPost] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch the specific blog post from Strapi API
        const response = await axios.get(`https://demo.expertit.in/api/contents/${id}`);
        setPost(response.data.data);
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setIsLoading(false);
      }
    };

    fetchData();
  }, [id]);

  if (isLoading) {
    return <p>Loading...</p>;
  }

  return (
    <div>
      <h1>{post.attributes.Title}</h1>
      <p>{post.attributes.Content}</p>
      {/* Render other blog post information if necessary */}
    </div>
  );
};

export default TitlePreview;