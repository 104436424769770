import React from 'react'
import { Link } from 'react-router-dom'
import "./ProductModal.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBriefcase, faMicrochip, faIndustry, faShoppingCart, faDatabase, faComments, faLightbulb,faCogs } from "@fortawesome/free-solid-svg-icons";

export default function 
() {
  
  return (
    <>
    <div className="col-md-12 col-sm-12 col-xs-12">
    <h2 className="submenu-h2-heading ">Platform</h2>
  </div>
    <div className="card-Product">
     
      <div className="Product-Product">

      <div className="service-row">
          <div className="service-column">
            {/* <div className="icon-and-text">
              <FontAwesomeIcon icon={faCogs} className="section-icon" />

              <div className="heading-h6"> All Platform </div>
            </div> */}
          </div>

          <div className="service-column">
            <ul className="list-inline mb0">
              {/* <li className="experience-list-item">
                <Link to="/platforms/erp-rpa-implementaion">
                  ERP/RPA Implementation
                </Link>
              </li>

              <li className="experience-list-item">
                <Link to="/platforms/SaasDevelopment">
                 SAAS Development Support
                </Link>
              </li>

              <li className="experience-list-item">
                <Link to="/platforms/reporting">Reporting and Analytics</Link>
              </li> */}
              
                <li className="Product-list-item">
                <Link to="/platforms/ecart">
              E-Cart
                </Link>
              </li>

               <li className="Product-list-item">
                <Link to="/platforms/OnlineTutor">
              Online Tutor
                </Link>
              </li>

              <li className="Product-list-item">
                <Link to="/platform/cloudecipher">
             Cloudcipiher
                </Link>
              </li>

              <li className="experience-list-item">
                <Link to="/platforms/OnlineAnalytics" >Online Analytics</Link>
              </li>

              <li className="experience-list-item">
                <Link to="/platform/ISPMonitoring" >ISP Monitoring</Link>
              </li>

              <li className="experience-list-item">
                <Link to="/platforms/Smartvision" >AI SmartVision</Link>
              </li>
            </ul>
          </div>
        </div>

       

        {/* ERP */}

       
      </div>
      </div>
      </>
  )
}
