import "./template.css";

import { Link } from "react-router-dom";
import ReadModal2 from "./readMore.js";
import React, { useState, useRef, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CarouselSlider from "./Carousel.js";

// import "./cyberCard.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

function CyberSecurity1() {
  const [activeModal, setActiveModal] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);
  const sliderRef = useRef(null);
  const modalRef = useRef(null);
  const [selectedSlide, setSelectedSlide] = useState(null);
  const settings = {
    // dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 2000,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 508,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  //
  // {
  //   id: 6,
  //   heading: 'Sixth Slide',
  //   text: 'A different text for the second slide.',
  //   buttonLabel: 'Read More',
  //   modalContent: {
  //     modalHeading: 'Modal Heading 6',
  //     modalText: 'Modal Text 6',
  //   },
  // },

  // {
  //   id: 7,
  //   heading: 'Seventh Slide',
  //   text: 'A different text for the second slide.',
  //   buttonLabel: 'Read More',
  //   modalContent: {
  //     modalHeading: 'Modal Heading 7',
  //     modalText: 'Modal Text 7',
  //   },
  // },

  // {
  //   id: 8,
  //   heading: 'Eighth Slide',
  //   text: 'A different text for the second slide.',
  //   buttonLabel: 'Read More',
  //   modalContent: {
  //     modalHeading: 'Modal Heading 8',
  //     modalText: 'Modal Text 8',
  //   },
  // },
  // ... (more slides)

  const openModal = (slide) => {
    setSelectedSlide(slide);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  // Handle clicks outside the modal to close it
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        closeModal();
      }
    };

    window.addEventListener("mousedown", handleOutsideClick);

    return () => {
      window.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const modalContent = (
    <>
      <p className="modal-np">The Purpose Behind Our Cyber Security Service </p>
      Welcome to DigiTecHub, your trusted partner in cyber security audits and
      solutions. We offer a comprehensive range of services designed to assess,
      enhance, and protect your organization's digital ecosystem against cyber
      threats and vulnerabilities.
      <hr />
      <strong>Protection from digital attacks</strong>
 
      Cybersecurity aims to defend against various types of digital attacks, including malware infections, phishing attempts, denial-of-service attacks, and ransomware incidents. By implementing appropriate security measures, organizations can mitigate the risk of these attacks and protect their digital assets from unauthorized access or manipulation.
      
      <br/><br/>  <strong>Prevention of damage</strong>
        Cybersecurity measures are put in place to prevent damage to computer systems, networks, and data. This includes implementing firewalls, antivirus software, and intrusion detection systems to detect and block malicious activities before they cause harm. By proactively identifying and addressing vulnerabilities, organizations can minimize the potential impact of cyber threats.
       
          <br/><br/><strong>Protection against theft</strong>
          Cybersecurity helps protect digital assets from theft or unauthorized copying. This includes safeguarding sensitive data such as customer information, intellectual property, and financial records from being stolen or compromised. Encryption and data loss prevention techniques are often used to prevent unauthorized access to confidential information.
 
          {/* <br/><br/><strong>Platform Upgrades</strong>
          Keeping applications up-to-date with the latest technologies and standards improves features, security, and performance.
           */}
      {/* Your dynamic content here */}
      {/* You can pass any JSX content you want */}
    </>
  );

  return (
    <div id="maindivs">
      <div id="carouselExampleCaptions" className="carousel slide">
        <div className="carousel-inner">
          <div className="carousel-item active">
            <img
              src="/Images/cybersecuritybgimages.jpg"
              className="d-block w-100"
              style={{ height: "auto",opacity:"0.5" }}
              alt="slider first"
            />
            <div className="carousel-caption  d-md-block">
              <div className="component-1 text-dark">Cyber Security </div>

              <div
                className="component-paras"
                style={{ color: "black", marginTop: "-20px",fontStyle:"italic" ,fontWeight:"700"}}
              >
                "Safeguarding Your Digital Assets from Threats "
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-np2">
        {/* <!-- section2 title --> */}
        <div className="cont2-titles">
          {/* <div class="cont2-titletext">What is Cyber Security </div> */}
        </div>

        {/* <!-- section part --> */}
        <div class="container21">
          {/* <!-- left part --> */}

          <div className="cont2-left cont2-text">
            <div
              className="left-text"
              style={{
                // fontFamily: "arial",

                fontFamily: "system-ui",
              
              }}
            >
              Cybersecurity is the practice of protecting computer systems,
              networks, programs, and data from digital attacks, damage,
              unauthorized access, or theft. It encompasses a broad range of
              techniques, technologies, and processes designed to safeguard
              information and infrastructure in the digital realm.
            </div>
          </div>

          {/* right part */}
          <div className="cont2-right cont2-text">
            <div className="right-text">
              <img
                class="content2img"
                src="/Images/pattern-lock.gif"
                alt="Description of the image"
              />
            </div>
          </div>
        </div>
        <div class="readmore">
          <ReadModal2 buttonText="Read More" modalContent={modalContent} />
        </div>
      </div>

      {/* section3 */}
      <section class="section3">
        <div class="cont2-title title ">Our Offerings</div>

        <div class="section3parts">
          <div
            class="content-np item31"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-end",
            }}
          >
            <div>
              <strong style={{ paddingLeft: "20px" }}>
                Application Level Security
              </strong>
              <p
                class="aligned-paragraph item3text"
                style={{ marginBottom: "30px" }}
              >
                Our experts conduct thorough assessments of your applications to
                identify vulnerabilities and weaknesses that hackers could
                exploit. We provide actionable recommendations to fortify your
                software against attacks.
              </p>
            </div>
          </div>

          <div class="content-np item322">
            <strong
              class="item32-heading item31-conent"
              style={{
                paddingLeft: "15px",
                color: "black",
              }}
            >
              Data Protection and Security
            </strong>
            <div class=" item31-conent-img  "></div>
            <p
              class="aligned-paragraph item3text mt-2"
              style={{ color: "black" }}
            >
            User is interested in data protection measures and practices to prevent unauthorized access and breaches.
            </p>
          </div>

          <div class="content-np item33">
            <strong
              class="item32-heading"
              style={{
                paddingLeft: "15px",
              }}
            >
              Cyber Infrastructure Security and Audit
            </strong>
            <p class="aligned-paragraph item3text">
              We evaluate your IT infrastructure to uncover potential entry
              points for cyber threats. Our audit covers network configurations,
              firewalls, routers, and other critical components to ensure robust
              protection.
            </p>
            {/* <div class="item32-button"><button class="item32-button">Read more</button></div> */}
          </div>

          <div class="content-np item34">
            <strong
              style={{
                paddingLeft: "15px",
              }}
            >
              Penetration Testing
            </strong>
            <p class="aligned-paragraph item3text" style={{ color: "black" }}>
              Our penetration testing simulates real-world attacks to identify
              vulnerabilities and weaknesses in your systems, applications, and
              networks. We provide detailed insights to help you patch any gaps.
            </p>
          </div>
          <div class="content-np item35">
            <strong
              style={{
                paddingLeft: "15px",
              }}
            >
              {" "}
              Vulnerability Assessment
            </strong>
            <p class="aligned-paragraph item3text">
              Our team conducts vulnerability assessments to identify weaknesses
              in your systems before they can be exploited. We prioritize
              vulnerabilities and recommend steps to mitigate risks.
            </p>
          </div>

          <div class="content-np item36">
            <strong
              style={{
                paddingLeft: "15px",
              }}
            >
              Code Review and Secure Development
            </strong>
            <p class="aligned-paragraph item3text">
              We conduct comprehensive code reviews to identify security flaws
              and vulnerabilities in your software applications.
            </p>
          </div>

          <div class="content-np item37">
            <strong
              style={{
                paddingLeft: "15px",
              }}
            >
              Security Awareness Training
            </strong>
            <p class="aligned-paragraph item3text" style={{ color: "black" }}>
            We offer customized security awareness training programs to educate employees on cyber threats, phishing, social engineering, and best practices for a secure work environment.
            </p>
          </div>
        </div>
        {/* <div class="readmore">
            <button class="read1">Read More</button>
          </div> */}
      </section>

      <div class="section4">
        <div className="main-cont">
          <div className="Cardcomp">
            <div className="card-container">
              <div className="row-ca">
                <div className="col-ca">
                  <div className="card-head text-center">
                    <h2 className="head-t ">Why Choose Us</h2>
                    {/* <p className="t-color">
                    Delivering domain expertise and technology-driven offerings
                    to help you turn digital challenges into opportunities.
                  </p> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="card-container">
            <div className="row-ck">
              <div className="col-ck">
                <div className="card-wrapper">
                  <div className="main-card">
                    <div className="innercon">
                      <div className="card-content1">
                        <h2>
                          Expertise <FontAwesomeIcon icon={faArrowRight} />
                        </h2>
                        {/* <p>vbhf</p> */}
                      </div>
                      <img src="/Images/organigram.gif" alt="" />
                    </div>
                  </div>
                  <div className="hover-card">
                    <div className="hover-content">
                      <h2>Expertise</h2>
                      <p>
                        Our certified cyber security professionals possess
                        extensive knowledge and experience in identifying and
                        mitigating security risks.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-ck">
                <div className="card-wrapper">
                  <div className="main-card">
                    <div className="innercon">
                      <div className="card-content1">
                        <h2>
                          Comprehensive Approach{" "}
                          <FontAwesomeIcon icon={faArrowRight} />{" "}
                        </h2>
                        {/* <p>vbhf</p> */}
                      </div>
                      <img src="/Images/approch.gif" alt="" />
                    </div>
                  </div>
                  <div className="hover-card">
                    <div className="hover-content">
                      <h2>Comprehensive Approach</h2>
                      <p>
                        We examine every layer of your digital infrastructure to
                        provide holistic security solutions.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-ck">
                <div className="card-wrapper">
                  <div className="main-card">
                    <div className="innercon">
                      <div className="card-content1">
                        <h2>
                          Cutting-Edge Tools{" "}
                          <FontAwesomeIcon icon={faArrowRight} />
                        </h2>
                        {/* <p>vbhf</p> */}
                      </div>
                      <img src="/Images/labor-day.gif" alt="" />
                    </div>
                  </div>
                  <div className="hover-card">
                    <div className="hover-content">
                      <h2>Cutting-Edge Tools</h2>
                      <p>
                        We leverage the latest security tools and methodologies
                        to ensure accurate assessments.{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-ck">
                <div className="card-wrapper">
                  <div className="main-card">
                    <div className="innercon">
                      <div className="card-content1">
                        <h2>
                          Custom Solutions{" "}
                          <FontAwesomeIcon icon={faArrowRight} />
                        </h2>
                        {/* <p>vbhf</p> */}
                      </div>
                      <img src="/Images/hand.gif" alt="" />
                    </div>
                  </div>
                  <div className="hover-card">
                    <div className="hover-content">
                      <h2>Custom Solutions</h2>
                      <p>
                        We tailor our recommendations to your organization's
                        specific needs and industry requirements.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-ck">
                <div className="card-wrapper">
                  <div className="main-card">
                    <div className="innercon">
                      <div className="card-content1">
                        <h2>
                          Regulatory Compliance
                          <FontAwesomeIcon icon={faArrowRight} />
                        </h2>
                        {/* <p>vbhf</p> */}
                      </div>
                      <img src="/Images/steps.gif" alt="" />
                    </div>
                  </div>
                  <div className="hover-card">
                    <div className="hover-content">
                      <h2>Regulatory Compliance</h2>
                      <p>
                        We help you meet industry standards and compliance
                        regulations related to data protection and cyber
                        security.{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-ck">
                <div className="card-wrapper">
                  <div className="main-card">
                    <div className="innercon">
                      <div className="card-content1">
                        <h2>
                          Continuous Monitoring{" "}
                          <FontAwesomeIcon icon={faArrowRight} />
                        </h2>
                        {/* <p>vbhf</p> */}
                      </div>
                      <img src="/Images/interact.gif" alt="" />
                    </div>
                  </div>
                  <div className="hover-card">
                    <div className="hover-content">
                      <h2>Continuous Monitoring</h2>
                      <p>
                        We offer continuous monitoring services to keep abreast
                        of evolving cyber threats and vulnerabilities
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              {/* <div className='col-ck'>
        <div className="card-wrapper">
            <div className="main-card">
            <div className="innercon">
                  <div className="card-content1">
                    <h2>cvgd <FontAwesomeIcon icon={faArrowRight} /></h2>
                   
                  </div>
                  <img src="/Images/cybercardico.svg" alt="" />
                </div>
            </div>
            <div className="hover-card">
                <div className="hover-content">
                <h2>bhhgjfx</h2>
                    <p>vndxhdmnhit bnxilhk cvbnrildh</p>
                   
                </div>
            </div>
        </div>
        </div>
        <div className='col-ck'>
        <div className="card-wrapper">
        <div className="main-card">
        <div className="innercon">
                  <div className="card-content1">
                    <h2>cvgd <FontAwesomeIcon icon={faArrowRight} /></h2>
                   
                  </div>
                  <img src="/Images/cybercardico.svg" alt="" />
                </div>
        </div>
        <div className="hover-card">
            <div className="hover-content">
            <h2>bhhgjfx</h2>
            <p>vndxhdmnhit bnxilhk cvbnrildh</p>
                   
            </div>
        </div>
    </div>
    </div> */}
              {/* <div className='col-ck'>
    <div className="card-wrapper">
        <div className="main-card">
        <div className="innercon">
                  <div className="card-content1">
                    <h2>cvgd <FontAwesomeIcon icon={faArrowRight} /></h2>
                   
                  </div>
                  <img src="/Images/cybercardico.svg" alt="" />
                </div>
        </div>
        <div className="hover-card">
            <div className="hover-content">
              <h2>bhhgjfx</h2>
                   
              <p>vndxhdmnhit bnxilhk cvbnrildh</p>
                   
            </div>
        </div>
    </div>
    </div> */}
            </div>
          </div>
        </div>

        {/* <CarouselSlider/> */}
      </div>

      {/* <div class="section5">
          <div class="section5heading">
            <div class="section5heading1">Our Case Studies</div>
         
          </div>
   
          <div class="section5cards">
            <div class="item51 item55">
              <div class="item5content">
                <div class="item51img">Expert Team</div>
   
                <div class="item51text item5text">
                  <a href="" class="full-width-link a-np">
                    Our team comprises experienced web designers, developers, and
                    UX/UI experts who excel at translating ideas into exceptional
                    websites
                  </a>
                </div>
              </div>
            </div>
   
            <div class="item52 item55">
              <div class="item5content">
                <div class="item52img">Scalability</div>
   
                <div class="item52text item5text">
                  <a href="" class="full-width-link a-np">
                    We build websites that are scalable and adaptable to
                    accommodate your business growth and changing requirements.
                  </a>
                </div>
              </div>
            </div>
   
            <div class="item53 item55">
              <div class="item5content">
                <div class="item53img">Responsive Design</div>
   
                <div class="item53text item5text">
                  <a href="" class="full-width-link a-np">
                    We ensure that your website looks and functions flawlessly on
                    desktops, tablets, and smartphones, providing a consistent
                    experience to users.
                  </a>
                </div>
              </div>
            </div>
   
            <div class="item54 item55">
              <div class="item5content">
                <div class="item54img">User-Centric Approach</div>
   
                <div class="item54text item5text">
                  <a href="" class="full-width-link a-np">
                    Our design and development process centers around the needs
                    and preferences of your target audience, ensuring a
                    user-focused website.
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div> */}

      {/* <div class="readmore">
      <button class="read">show More</button>
      </div> */}

      {/* section 6 */}

      <section className="section7">
        <div class="section7headding ">
          <h1 className="head-t" style={{color:'black'}}>Industries We Serve </h1>
          <h3 className="headt-7p" >
            Our cyber security audit services cater to a wide range of
            industries, including but not limited to
          </h3>
        </div>
       
        <section className="section7slider">
          <Slider {...settings} className="cards7np">
            <section class="cards-np7">
              <article className="card-np7">
                <div className="card__img-np7 card__img-np71 "></div>
                <a href="#" className="card_link-np7">
                  <div className="card__img--hover-np7 card__img--hover-np71"></div>
                </a>
                <div className="card__info-np7">
                  <h5 className="card__title-np7">Finance and Banking </h5>
                </div>
              </article>
            </section>
            {/* card2 */}

            <section class="cards-np7">
              <article className="card-np7">
                <div className="card__img-np7 card__img-np72"></div>
                <a href="#" className="card_link-np7">
                  <div className="card__img--hover-np7 card__img--hover-np72"></div>
                </a>
                <div className="card__info-np7">
                  <h5 className="card__title-np7">Healthcare </h5>
                </div>
              </article>
            </section>
            {/* card3 */}
            <section class="cards-np7">
              <article className="card-np7">
                <div className="card__img-np7 card__img-np73"></div>
                <a href="#" className="card_link-np7">
                  <div className="card__img--hover-np7 card__img--hover-np73"></div>
                </a>
                <div className="card__info-np7">
                  <h5 className="card__title-np7">E-commerce and Retail </h5>
                </div>
              </article>
            </section>
            {/* card4 */}
            <section class="cards-np7">
              <article className="card-np7">
                <div className="card__img-np7 card__img-np74"></div>
                <a href="#" className="card_link-np7">
                  <div className="card__img--hover-np7 card__img--hover-np74"></div>
                </a>
                <div className="card__info-np7">
                  <h5 className="card__title-np7">Manufacturing </h5>
                </div>
              </article>
            </section>
            {/* card5 */}
            <section class="cards-np7">
              <article className="card-np7">
                <div className="card__img-np7 card__img-np75"></div>
                <a href="#" className="card_link-np7">
                  <div className="card__img--hover-np7 card__img--hover-np75"></div>
                </a>
                <div className="card__info-np7">
                  <h5 className="card__title-np7">
                    Technology and IT Services{" "}
                  </h5>
                </div>
              </article>
            </section>
            {/* card6 */}
            <section class="cards-np7">
              <article className="card-np7">
                <div className="card__img-np7 card__img-np76"></div>
                <a href="#" className="card_link-np7">
                  <div className="card__img--hover-np7 card__img--hover-np76"></div>
                </a>
                <div className="card__info-np7">
                  <h5 className="card__title-np7">
                    Government and Public Sector{" "}
                  </h5>
                </div>
              </article>
            </section>
          </Slider>
        </section>

        <div className="sec7">
          <div > Secure Your Digital Future</div><br/>
          <p style={{fontFamily:'system-ui',fontWeight:'500',marginBottom:'25px',fontSize:'20px'}}>
            Don't let cyber threats compromise your business. Partner with
              Expertit to fortify your digital assets against
              potential risks. 
              <p style={{fontFamily:'system-ui',fontWeight:'500',marginBottom:'25px',fontSize:'20px'}}>Contact us today to discuss your cyber security
              needs and how we can safeguard your organization from evolving
              cyber threats.</p>
              </p>

        </div>

        {/* <div className="section7bottom">
          <div className="bottom7text">
            <h2 className="bottom7texth">Secure Your Digital Future </h2>
            <p style={{fontFamily:' system-ui',fontSize:'20px'}}>
              Don't let cyber threats compromise your business. Partner with
              Expertit to fortify your digital assets against
              potential risks. Contact us today to discuss your cyber security
              needs and how we can safeguard your organization from evolving
              cyber threats.
            </p>
          </div>
        </div> */}
      </section>
    </div>
  );
}

export default CyberSecurity1;
