import "./template.css";

import { Link } from "react-router-dom";
import ReadModal2 from "./readMore.js";
import React, { useState, useRef, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CarouselSlider from "./Carousel.js";
import CaseStudyCard from "./CaseStudyCard.js";

import Accordion from "./Accordion";
import "./Accordion.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

function FinancialS() {
  const [activeModal, setActiveModal] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);
  const sliderRef = useRef(null);
  const modalRef = useRef(null);
  const [selectedSlide, setSelectedSlide] = useState(null);
  const settings = {
    // dots: true,
    // infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },

      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },

      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 3000,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const slideData = [
    {
      id: 1,
      heading: "Holistic Financial Ecosystem Support",
      text: "From banking to digital payments, we offer a comprehensive suite of solutions for the entire financial ecosystem. ",
      buttonLabel: "Read More",
      modalContent: {
        // modalHeading: 'Modal Heading 1',
        modalText:
          "CloudCipher seamlessly integrates with existing workflows and software ecosystems, minimizing disruption and streamlining the adoption process. Whether migrating from another platform or implementing new security measures, CloudCipher ensures a smooth transition with minimal downtime.",
      },
    },
    {
      id: 2,
      heading: "Customization and Integration",
      text: "Tailoring solutions to perfectly fit your operational needs and seamlessly integrating them into your existing systems. ",
      buttonLabel: "Read More",
      modalContent: {
        modalHeading: "Modal Heading 2",
        modalText: "Modal Text 2",
      },
    },

    {
      id: 3,
      heading: "Technological Leadership",
      text: "Utilizing the forefront of financial technology innovations to deliver cutting-edge solutions. ",
      buttonLabel: "Read More",
      modalContent: {
        modalHeading: "Modal Heading 3",
        modalText: "Modal Text 3",
      },
    },

    {
      id: 4,
      heading: "Security and Compliance Focus",
      text: "Ensuring the highest levels of data security and regulatory compliance across all solutions.",
      buttonLabel: "Read More",
      modalContent: {
        modalHeading: "Modal Heading 4",
        modalText: "Modal Text 4",
      },
    },

    {
      id: 5,
      heading: "Expertise and Experience",
      text: "Leveraging years of industry expertise to deliver solutions that meet the highest standards of quality and efficiency. ",
      buttonLabel: "Read More",
      modalContent: {
        modalHeading: "Modal Heading 5",
        modalText: "Modal Text 5",
      },
    },

    // {
    //   id: 6,
    //   heading: 'Sixth Slide',
    //   text: 'A different text for the second slide.',
    //   buttonLabel: 'Read More',
    //   modalContent: {
    //     modalHeading: 'Modal Heading 6',
    //     modalText: 'Modal Text 6',
    //   },
    // },

    // {
    //   id: 7,
    //   heading: 'Seventh Slide',
    //   text: 'A different text for the second slide.',
    //   buttonLabel: 'Read More',
    //   modalContent: {
    //     modalHeading: 'Modal Heading 7',
    //     modalText: 'Modal Text 7',
    //   },
    // },

    // {
    //   id: 8,
    //   heading: 'Eighth Slide',
    //   text: 'A different text for the second slide.',
    //   buttonLabel: 'Read More',
    //   modalContent: {
    //     modalHeading: 'Modal Heading 8',
    //     modalText: 'Modal Text 8',
    //   },
    // },
    // ... (more slides)
  ];


  let items = [
    {
      title: "Q: How can integrating your banking system solutions benefit our institution?",
      content:
        "A: Our banking solutions offer modern, scalable platforms that enhance operational efficiency, customer satisfaction, and digital service offerings.  ",
    },
    {
      title: "Q: What advantages do your tax collection solutions offer?  ",
      content:
        "A: Our solutions automate and streamline tax processes, reduce errors, improve compliance, and enhance the taxpayer experience.  ",
    },
    {
      title: "Q: How secure are your e-wallet solutions?",
      content:
        "A: We prioritize security in our e-wallet solutions, employing advanced encryption, fraud detection, and compliance measures to protect users and transactions.  ",
    },
  
  ];

  const openModal = (slide) => {
    setSelectedSlide(slide);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  // Handle clicks outside the modal to close it
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        closeModal();
      }
    };

    window.addEventListener("mousedown", handleOutsideClick);

    return () => {
      window.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const modalContent = (
    <>
      <p className="modal-np"> The Purpose Behind Our Finance Services </p>
      At Digitec Hub, we're redefining financial services by integrating
      advanced Banking Systems, efficient Tax Collection solutions, and
      versatile E-Wallet technologies. Our goal is to empower your financial
      operations with seamless, secure, and innovative solutions tailored for
      growth, efficiency, and compliance in the digital age.
      <hr />
      <br /> <strong>Comprehensive Offerings</strong>
      Digitec Hub offers financial services covering Banking, Taxation, and
      Digital Payments.
      <br />
      <br />
      <strong>Expertise and Approach</strong>
      The company focuses on implementing modern solutions, such as e-wallets,
      to meet evolving financial needs.
      <br />
      <br />
      <strong>Emphasis on Innovation</strong>
      The company focuses on implementing modern solutions, such as e-wallets,
      to meet evolving financial needs.
      <br />
      <br />
      <strong>Partnerships and Quality</strong>They partner with leading
      platforms and technologies, ensuring access to the latest tools and best
      practices.
      <br />
      <br />
      <strong>Flexibility and Support</strong>Digitec Hub provides a mix of
      standard and custom solutions, along with comprehensive managed services,
      catering to diverse client needs with ongoing support.
      <br />
      <br />
      {/* Your dynamic content here */}
      {/* You can pass any JSX content you want */}
    </>
  );

  return (
    <div>
      <div id="maindivs">
        <div id="carouselExampleCaptions" className="carousel slide">
          <div className="carousel-inner">
            <div className="carousel-item active">
              <img
                src="/Images/finalbg.png"
                className="d-block w-100"
                style={{ height: "auto",opacity:"0.4" }}
                alt="slider first"
              />
              <div className="carousel-caption d-md-block">
                <div className="component-1 text-dark"> Financial Services </div>
                <div
                  className="component-paras"
                  style={{
                    color: "black",
                    marginTop: "-20px",
                    fontStyle: "italic",
                    fontWeight:"700"
                  }}
                >
                  "Financing the Future – Comprehensive Solutions for Today's
                  Financial Ecosystem"
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container-np2">
          {/* <!-- section2 title --> */}
          <div className="cont2-titles">
            {/* <div class="cont2-titletext">What is Finance Services?</div> */}
          </div>

          {/* <!-- section part --> */}
          <div class="container21">
            {/* <!-- left part --> */}

            <div className="cont2-left cont2-text">
              <div
                className="left-text"
                style={{
                  // fontFamily: "arial",
                  
                  fontFamily: "system-ui",
                }}
              >
                Our enhanced suite of Financial Services at Digitec Hub now
                spans critical sectors of the financial ecosystem, including
                Banking, Taxation, and Digital Payments. From deploying robust
                banking systems and streamlining tax collection processes to
                implementing cutting-edge e-wallet solutions, we leverage our
                deep industry expertise and technology-driven approach to solve
                complex financial challenges. Partnering with leading platforms
                and technologies, we offer a blend of standard and custom
                solutions, supported by comprehensive managed services.
              </div>
            </div>

            {/* right part */}
            <div className="cont2-right cont2-text">
              <div className="right-text">
                <img
                  class="content2img"
                  src="/Images/analytics.gif"
                  alt="Description of the image"
                />
              </div>
            </div>
          </div>
          <div class="readmore">
            <ReadModal2 buttonText="Read More" modalContent={modalContent} />
          </div>
        </div>

        {/* section3 */}
        <section class="section3">
          <div class="cont2-title title ">Our Features</div>

          <div class="section3parts">
            <div class="content-np item31">
              <div>
                <strong
                  style={{
                    paddingLeft: "15px",
                  }}
                >
                  {" "}
                  Banking Systems Implementation
                </strong>
                <p class="aligned-paragraph item3text">
                  Deploying advanced banking solutions for retail and corporate
                  banking, integrating core banking functionalities with digital
                  innovation.
                </p>
              </div>
            </div>

            <div class="content-np item322">
              <strong
                class="item32-heading item31-conent"
                style={{
                  paddingLeft: "15px",
                }}
              >
                E-Wallet Solutions
              </strong>
              <div class=" item31-conent-img  "></div>
              <p class="aligned-paragraph item3text" style={{ color: "black" }}>
                Offering versatile digital wallet platforms for secure,
                convenient digital transactions for consumers and businesses
                alike.
              </p>
            </div>

            <div class="content-np item33">
              <strong
                class=""
                style={{
                  paddingLeft: "15px",
                }}
              >
                {" "}
                Tax Collection Solutions
              </strong>
              <p class="aligned-paragraph item3text">
                Automating and optimizing tax collection processes for
                governmental and financial institutions, enhancing accuracy and
                compliance.
              </p>
              {/* <div class="item32-button"><button class="item32-button">Read more</button></div> */}
            </div>

            <div class="content-np item34">
              <strong
                style={{
                  paddingLeft: "15px",
                }}
              >
                Fintech Innovations
              </strong>
              <p class="aligned-paragraph item3text" style={{ color: "black" }}>
                Streamlining financial operations with the latest fintech
                solutions to enhance customer experiences and operational
                efficiency.
              </p>
            </div>
            <div class="content-np item35">
              <strong
                style={{
                  paddingLeft: "15px",
                }}
              >
                {" "}
                Wealth Management and Advisory Services
              </strong>
              <p class="aligned-paragraph item3text">
                Providing strategic wealth management and financial advisory for
                optimal investment and financial planning.
              </p>
            </div>

            <div class="content-np item36">
              <strong
                style={{
                  paddingLeft: "15px",
                }}
              >
                Custom Financial Applications Development
              </strong>
              <p class="aligned-paragraph item3text">
                Tailoring bespoke financial applications to meet unique business
                needs across sectors.
              </p>
            </div>

            <div class="content-np item37">
              <strong
                style={{
                  paddingLeft: "15px",
                }}
              >
                Regulatory Compliance and Risk Management
              </strong>
              <p class="aligned-paragraph item3text" style={{ color: "black" }}>
                Ensuring adherence to financial regulations and implementing
                strategies to mitigate financial risks.
              </p>
            </div>
          </div>
          {/* <div class="readmore">
            <button class="read1">Read More</button>
          </div> */}
        </section>

        <div class="section4">
          
          <div className="main-cont">
            <div className="Cardcomp">
              <div className="card-container">
                <div className="row-ca">
                  <div className="col-ca">
                    <div className="card-head text-center">
                      <h2 className="head-t ">USP of Finacial Services</h2>
                     
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card-container">
              <div className="row-ck">
                <div className="col-ck">
                  <div className="card-wrapper">
                    <div className="main-card">
                      <div className="innercon">
                        <div className="card-content1">
                          <h2>
                          Holistic Financial Ecosystem Support{" "}
                            <FontAwesomeIcon icon={faArrowRight} />
                          </h2>
                          {/* <p>vbhf</p> */}
                        </div>
                        <img src="/Images/social-care.gif" alt="" />
                      </div>
                    </div>
                    <div className="hover-card">
                      <div className="hover-content">
                        <h2> Holistic Financial Ecosystem Support</h2>
                        <p>
                        From banking to digital payments, we offer a comprehensive suite of solutions for the entire financial ecosystem.{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-ck">
                  <div className="card-wrapper">
                    <div className="main-card">
                      <div className="innercon">
                        <div className="card-content1">
                          <h2>
                          Customization and Integration
                            <FontAwesomeIcon icon={faArrowRight} />{" "}
                          </h2>
                          {/* <p>vbhf</p> */}
                        </div>
                        <img src="/Images/hand.gif" alt="" />
                      </div>
                    </div>
                    <div className="hover-card">
                      <div className="hover-content">
                        <h2>Customization and Integration</h2>
                        <p>
                          {" "}
                          Tailoring solutions to perfectly fit your operational needs and seamlessly integrating them into your existing systems.{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-ck">
                  <div className="card-wrapper">
                    <div className="main-card">
                      <div className="innercon">
                        <div className="card-content1">
                          <h2>
                          Technological Leadership{" "}
                            <FontAwesomeIcon icon={faArrowRight} />
                          </h2>
                          {/* <p>vbhf</p> */}
                        </div>
                        <img src="/Images/diagram.gif" alt="" />
                      </div>
                    </div>
                    <div className="hover-card">
                      <div className="hover-content">
                        <h2>Technological Leadership</h2>
                        <p>
                        Utilizing the forefront of financial technology innovations to deliver cutting-edge solutions.{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-ck">
                  <div className="card-wrapper">
                    <div className="main-card">
                      <div className="innercon">
                        <div className="card-content1">
                          <h2>
                          Security and Compliance Focus<FontAwesomeIcon icon={faArrowRight} />
                          </h2>
                          {/* <p>vbhf</p> */}
                        </div>
                        <img src="/Images/Secure_Payment_Processing.gif" alt="" />
                      </div>
                    </div>
                    <div className="hover-card">
                      <div className="hover-content">
                        <h2>Security and Compliance Focus</h2>
                        <p>
                        Ensuring the highest levels of data security and regulatory compliance across all solutions.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-ck">
                  <div className="card-wrapper">
                    <div className="main-card">
                      <div className="innercon">
                        <div className="card-content1">
                          <h2>
                          Expertise and Experience
                            <FontAwesomeIcon icon={faArrowRight} />
                          </h2>
                          {/* <p>vbhf</p> */}
                        </div>
                        <img
                        className="img-ico"
                        src="/Images/organigram.gif"
                        alt=""
                      />
                      </div>
                    </div>
                    <div className="hover-card">
                      <div className="hover-content">
                        <h2> Expertise and Experience</h2>
                        <p>
                        Leveraging years of industry expertise to deliver solutions that meet the highest standards of quality and efficiency.{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-ck">
                  <div className="card-wrapper">
                    <div className="main-card">
                      <div className="innercon">
                        <div className="card-content1">
                          <h2>
                          Transparency{" "}
                            <FontAwesomeIcon icon={faArrowRight} />
                          </h2>
                          {/* <p>vbhf</p> */}
                        </div>
                        <img src="/Images/hourglass.gif" alt="" />
                      </div>
                    </div>
                    <div className="hover-card">
                      <div className="hover-content">
                        <h2>Transparency</h2>
                        <p>
                          {" "}
                          We believe in transparency and honesty in our dealings with clients, ensuring that you always have a clear understanding of our recommendations and the fees associated with our services.{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

               
                
              </div>
            </div>
          </div>




          {/* <CarouselSlider/> */}
        </div>


        <div className="section5">
          <h1 className="section5heading">How We are Handling</h1>
          <div className="section5jsxcard">
            <CaseStudyCard
              imageUrl="/Images/casestudyimg1.jpg"
              title="Comprehensive Needs Analysis"
              description="Conducting thorough evaluations to understand your specific financial operations and challenges. "
              // linkUrl="/platform/E-cart/casestudy-vitualwallet"
            />
            <CaseStudyCard
              imageUrl="/Images/casestudyimg2.jpg"
              title="Strategic Solution Development and Implementation"
              description=" Crafting and deploying strategic solutions tailored to address your needs and objectives efficiently. "
              linkUrl=""
            />
            <CaseStudyCard
              imageUrl="/Images/casestudyimg3.jpg"
              title="System Integration and Optimization"
              description=" Ensuring seamless integration with your current operations and continuous optimization for peak performance.  "
              linkUrl=""
            />

            <CaseStudyCard
              imageUrl="/Images/casestudyimg4.jpg"
              title="Robust Training and Support"
              description=" Providing extensive training and ongoing support to ensure you maximize the benefits of our financial solutions.  "
              linkUrl=""
            />

            {/* <div class="readmore">
  <button class="read">show More</button>
  </div> */}

            {/* section 6 */}
          </div>
        </div>


        <section className="FAQ">
        <div className="FAQbody">
          <h1 className="section5heading text-dark" >FAQ's for this service</h1>
          <Accordion items={items} />
        </div>
      </section>

      </div>
    </div>
  );
}

export default FinancialS;
