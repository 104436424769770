import React from 'react'
import './Cards.css';

function cards() {
  return (
    <div>
      <div className='cards-heading'>CARDS API</div>
      <p className='cards-para'>Explore the opportunities of providing custom card facilities as per your customer requirements</p>
      <img src='https://blog.bevatel.com/wp-content/uploads/2021/03/What-are-the-API-integration-services.jpeg'className="imagcustomer"alt='customerimages'></img>
      <div className="feature-table">
  <div className="feature-box">
    <div className="box">
    <i className="fas fa-credit-card"></i>
      <p>Create Card</p>
    </div>
  </div>

  <div className="feature-box">
    <div className="box">
    <i className="fas fa-ban"></i>
      <p>Block Card</p>
    </div>
  </div>
  <div className="feature-box">
    <div className="box">
    <i className="fas fa-chart-line"></i>

      <p>Enhance Card Limit</p>
    </div>
  </div>

  <div className="feature-box">
    <div className="box">
    
    <i className="fas fa-lock"></i>


      <p>Privacy and Security</p>
    </div>
  </div>
  </div>
    </div>
  )
}

export default cards
