import React from 'react'
import './FinanceVertical.css';
function FinanceVertical() {
  return (
    <div>
            <img src="https://img.freepik.com/premium-photo/white-watercolor-papar-texture-background-cover-card-design-overlay-paint-art-background_1962-2474.jpg" className="d-block w-100" alt="slider third"/>
    <div className="carousel-caption d-none d-md-block">
      <h1 className='circle-h11'>WE SERVE ALL FINANCE VERTICALS</h1>
      <div class="circle">
  <div className="center-text">End-to-End Offering</div>
  <div className="section retail">RETAIL / CORPORATE BANKING</div>
  <div className="section loans">LOANS</div>
  <div className="section insurance">INSURANCE</div>
  <div className="section wealth">WEALTH MANAGEMENT</div>
</div>
    </div>
    </div>
  )
}

export default FinanceVertical
