import React from "react";

import  "./Foundar.css";
import { Link } from "react-router-dom";




export default function VM() {

    return (

        <>

       {/* <div><NavBar/></div>  */}

        <div className="banner parbase industries-gradient aem-GridColumn aem-GridColumn--default--12">





        <section id="sml_ht_home_banner">

            <article className="container">

            <div className="row">

              <div className="col-md-9 col-sm-12 col-xs-12 ">

                    <h1 className="h2-heading white-color l-top">

                    Founder

                    </h1>

                  </div>

            </div>

          </article>

        </section>

    </div>
    <div className="freeflowhtml aem-GridColumn aem-GridColumn--default--12">
     <section id="main-text-body">
  <article className="container">
    <div className="row">
      <div className="col-md-10 col-sm-12 col-xs-12 wow fadeInUp" data-wow-delay="0.6s">
        <div className="row">
          <div className="col-md-10 col-sm-12 col-xs-12">
            {/* <h1 className="k h1-heading">Kiran B M</h1> */}
            <p className="mngmt-profile"></p>
            <img src="/Images/kiran.jpg" alt="Kiran B M" title="Kiran B M" className="img-responsive"></img>
                        <h1 className="k h1-heading">Kiran B M</h1>
             <div  className=" col-md-12 float-center">
              <p id="founder">Engg Graduate from Pune University, operating business since 2013, He is an Enterprise Architect designed several government and private systems,those are contributing in great extent to the organizations till date. CEO from Technological background makes us unique in creating value with our client.</p>
          </div>
          </div>
        </div>
      </div>
    </div>
  </article>
</section>

</div>


        

        

        </>
        

    )

  }
