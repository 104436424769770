
 
import React, { useEffect } from "react";
import "./template.css";
 
 

 
 
 
const ReadModal2 = ({ buttonText, modalContent }) => {

  

  useEffect(() => {
    // Get the modal
    const modal = document.getElementById("myModal");
 
    // Get the button that opens the modal
    const btn = document.getElementById("myBtn");
 
    // Get the <div> element that contains the close icon
    const closeDiv = document.getElementById("close");
    // const body = document.body;
    // When the user clicks the button, open the modal
    const openModal = () => {
      modal.style.display = "block";
      // body.style.overflow = "hidden";
    };
 
    // When the user clicks on the close icon, close the modal
    const closeModal = () => {
      modal.style.display = "none";
 
    };
 
    // When the user clicks anywhere outside of the modal, close it
    const handleOutsideClick = (event) => {
      if (event.target === modal) {
        closeModal();
      }
    };
 
    btn.addEventListener("click", openModal);
    closeDiv.addEventListener("click", closeModal);
    window.addEventListener("click", handleOutsideClick);
 
    // Cleanup event listeners on component unmount
    return () => {
      btn.removeEventListener("click", openModal);
      closeDiv.removeEventListener("click", closeModal);
      window.removeEventListener("click", handleOutsideClick);
    };
  }, []); // Empty dependency array to run the effect once after the initial render
 
  return (
    <div>
    <button id="myBtn" className="read1">
      {buttonText}
    </button>
 
    <div id="myModal" className="modal">
      <div className="modal-content">
        <div id="close">
          <i className="fas fa-times closeicon"></i>
        </div>
        {modalContent}
      </div>
    </div>
  </div>
  );
};
 
export default ReadModal2;