// CarouselSlider.jsx
import React, { useRef } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './template.css'; // Import your custom styles
 
const CarouselSlider = () => {
  const sliderRef = useRef(null);
 
  const settings = {
    // dots: true,
    // infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 3000,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
     
    ],
  };
 
 
 
  const slideData = [
    {
      id: 1,
      heading: 'Seamless Integration with Cloud Platforms:',
      // text: 'This is the first slide with unique content.',
      buttonLabel: 'READ MORE',
    },
    {
      id: 2,
      heading: 'Scalability and Performance:',
      // text: 'A different text for the second slide.',
      buttonLabel: 'READ MORE',
    },
    {
      id: 3,
      heading: 'Comprehensive Compliance and Certification:',
      // text: 'Content specific to the third slide.',
      buttonLabel: 'READ MORE',
    },
    {
      id: 4,
      heading: 'User-Friendly Interface:',
      // text: 'Content specific to the third slide.',
      buttonLabel: 'READ MORE',
    },
    {
      id: 5,
      heading: 'Real-Time Threat Monitoring and Alerts:',
      // text: 'Content specific to the third slide.',
      buttonLabel: 'READ MORE',
    },
    {
      id: 6,
      heading: '24/7 Customer Support:',
      // text: 'Content specific to the third slide.',
      buttonLabel: 'READ MORE',
    },
    // ... Continue with unique content for each slide
  ];
 
  return (
 
   
    <div className='sliders' >
       <button className=" nav-button-slider prev" onClick={() => sliderRef.current.slickPrev()}>
      &lt;
      </button>
      <button className=" nav-button-slider next" onClick={() => sliderRef.current.slickNext()}>
      &gt;
      </button>
     
       <div class="slidercontainer">
      <Slider ref={sliderRef} {...settings} >
 
       
        {slideData.map((slide) => (
          <div key={slide.id} className="slider-item">
            <div class="slidecontents">
            <h2 className='slideheading h2-np slidecontent'>{slide.heading}</h2>
            <p className='slidetext p-np  slidecontent'>{slide.text}</p>
            <button className='slidebutton read1'>{slide.buttonLabel}</button>
            </div>
          </div>
        ))}
 
      </Slider>
      </div>
     
     
      {/* Arrow buttons */}
     
    </div>
 
  );
};
 
export default CarouselSlider;
