import React from 'react';
import './PlatformVariants.css';

function BankingPlatformVariant() {
  const modules = [
    {
      name: 'Neo Banking',
      iconClass: 'fas fa-university',
    },
    {
      name: 'Digital Banking',
      iconClass: 'fas fa-mobile',
    },
    {
      name: 'Masters Module',
      iconClass: 'fas fa-book',
    },
    {
      name: 'Loan Module',
      iconClass: 'fas fa-money-bill-wave',
    },
  ];

  const modulesSet1 = [
    {
      name: 'Admin Module',
      iconClass: 'fas fa-cogs',
    },
    {
      name: 'Accounts Module',
      iconClass: 'fas fa-chart-line',
    },
    {
      name: 'Voucher Module',
      iconClass: 'fas fa-file-invoice-dollar',
    },
    {
      name: 'Payment Module',
      iconClass: 'fas fa-credit-card',
    },
  ];

  return (
    <div>
      <img
        src="https://img.freepik.com/free-photo/cardboard-texture_1194-5301.jpg"
        className="d-block w-100"
        alt="slider second"
      />
      <div className="carousel-caption d-none d-md-block">
        <h1 className='bakingh1' style={{color:'black'}}>BANKING PLATFORM VARIANTS</h1>
        <div className="containerq">
          <div className="row">
            {/* Create 4 columns for the first module array */}
            {modules.map((module, index) => (
              <div className="col-md-3" key={index}>
                <div className="columnqs">
                  <div className="icon">
                    <i className={module.iconClass}></i>
                  </div>
                  <h3>{module.name}</h3>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="containerq">
          <div className="row">
            {/* Create 4 columns for the second module array */}
            {modulesSet1.map((module, index) => (
              <div className="col-md-3" key={index}>
                <div className="columnqs">
                  <div className="icon">
                    <i className={module.iconClass}></i>
                  </div>
                  <h3>{module.name}</h3>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default BankingPlatformVariant;
