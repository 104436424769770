import React from "react";

import { Link } from "react-router-dom";

import "./service.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBriefcase,
  faBug,
  faBullhorn,
  faChartBar,
  faCode,
  faCogs,
  faFileAlt,
  faLightbulb,
  faIndustry,
  faDatabase
} from "@fortawesome/free-solid-svg-icons";

export default function ServiceModal() {
  
  return (<>

    <div className="col-md-12 col-sm-12 col-xs-12">
    <h2 className="submenu-h2-heading ">Services</h2>
  </div>
    <div className="card1">
      
      <div className="service-section">
        {/* Digital Marketing/Strategy */}

        <div className="service-row">
          <div className="service-column">
            {/* <div className="icon-and-text">
              <FontAwesomeIcon icon={faBullhorn} className="section-icon" />

              <div className="heading-h6">Application Development & Managed (ADM) Services</div>
            </div> */}
          </div>

          <div className="service-column">
            <ul className="list-inline mb0">
              

              <li className="experience-list-item">
                <Link to="/service/StafAugmentation">
                  Staff Augumentation
                </Link>
              </li>


              


              <li className="experience-list-item">
                <Link to="/service/ManagedServices">
                Managed Services
                </Link>
              </li>

              <li className="experience-list-item">
                <Link to="/service/AI">
                Artificial Intelligence
                </Link>
              </li>

              
            </ul>
          </div>
        </div>

        <hr />

        {/* <div className="menu-seprator"></div> */}

        {/* Software and Web Development */}

        <div className="service-row">
          <div className="service-column">
            {/* <div className="icon-and-text">
              <FontAwesomeIcon icon={faCode} className="section-icon" />

              <div className="heading-h6"> Development</div>
            </div> */}
          </div>

          <div className="service-column">
            <ul className="list-inline mb0">
              <li className="experience-list-item">
                <Link to="/solution/DroneSolution">Drones</Link>
              </li>

              <li className="experience-list-item">
                <Link to="/service/blockchain">Blockchain</Link>
              </li>

              <li className="experience-list-item">
                <Link to="/service/CyberSecurity1">
                Cyber Security
                </Link>
              </li>

              {/* <li className="experience-list-item">
                <Link to="/service/KpoAndBpo">KPO And BPO Services</Link>
              </li> */}
            </ul>
          </div>
        </div>

        <hr />

       

        {/* LetUs Tech */}

        <div className="service-row">
          <div className="service-column">
            {/* <div className="icon-and-text">
              <FontAwesomeIcon icon={faLightbulb} className="section-icon" />

              <div className="heading-h6">Latest Tech</div>
            </div> */}
          </div>

          <div className="service-column">
            <ul className="list-inline mb0">
              

            <li className="experience-list-item">
                <Link to="/service/IOTServices">
                IOT
                </Link>
              </li>


        
              {/* <li className="experience-list-item">
                <Link to="/service/Consulting-services">
              Consulting Services
                </Link>
              </li> */}

              {/* <li className="experience-list-item">
                <Link to="/service/Cloudservices">
              Cloud Services
                </Link>
              </li> */}

              {/* <li className="experience-list-item">
                <Link to="/service/Industrial-automation-service">
                Industrial Automations Services
                </Link>
              </li> */}

              {/* <li className="experience-list-item">
                <Link to="/service/GenAI">
                 GenAI
                </Link>
              </li> */}

            </ul>
          </div>
        </div>

        {/* <hr /> */}

        {/* <div className="menu-seprator"></div> */}

        {/* SAAS Platforms / ERP Services */}

        {/* <div className="service-row">
          <div className="service-column">
            <div className="icon-and-text">
              <FontAwesomeIcon icon={faCogs} className="section-icon" />

              <div className="heading-h6">Platform Service </div>
            </div>
          </div>

          <div className="service-column">
            <ul className="list-inline mb0">
              <li className="experience-list-item">
                <Link to="/service/erp-rpa-implementaion">
                  ERP/RPA Implementation
                </Link>
              </li>

              <li className="experience-list-item">
                <Link to="/service/SaasDevelopment">
                 SAAS Development Support
                </Link>
              </li>

              <li className="experience-list-item">
                <Link to="/services/reporting" >Reporting and Analytics</Link>
              </li>

              <li className="experience-list-item">
                <Link to="/service/BankingPlatform" >Banking Platfrom</Link>
              </li>

              <li className="experience-list-item">
                <Link to="/service/VoIP" >VoIP platform</Link>
              </li>

              <li className="experience-list-item">
                <Link to="service/ECommerce" >E commerce platform</Link>
              </li>
            </ul>
          </div>
        </div>

        <div className="menu-seprator"></div> */}

        {/* Software Testing */}

        <div className="service-row">
          <div className="service-column">
            {/* <div className="icon-and-text">
              <FontAwesomeIcon icon={faBug} className="section-icon" />

              <div className="heading-h6"> Testing</div>
            </div> */}
          </div>

          <div className="service-column">
            <ul className="list-inline mb0">
              {/* <li className="experience-list-item">
                <Link to="/service/OCRservice">OCR/Object Recognitation</Link>
              </li> */}

              {/* <li className="experience-list-item">
                <Link to="/service/MobileApplication">
                 Integration Services
                </Link>
              </li> */}

              {/* <li className="experience-list-item">
                <Link to="/service/CyberSecurity">Cyber Security
                </Link>
              </li> */}
            </ul>
          </div>
        </div>

      
{/* 
        <div className="Product-row"  style={{ justifyContent: 'flex-start' }}>
          <div className="Product-column">
            <div className="icon-and-text">
            <FontAwesomeIcon icon={faIndustry} className="Product-icon" />
             <div className="heading-h6">Industrial Automations</div>

            </div>
          </div>

          <div className="Product-column">
            <ul className="list-inline">
              <li className="Product-list-item">
                <Link to="/Products/Data Warehouse and Data Mining/Analytics.html">
                Licence Implementation
                </Link>
              </li>

              <li className="Product-list-item">
                <Link to="/Products/Integration with Machine (INDUSTRY 4.0).html">
                Integration with Machine (INDUSTRY 4.0)
                </Link>
              </li>

              <li className="Product-list-item">
                <Link to="/product/Gps">
                GPS based vehicle tracking
                </Link>
              </li>
            </ul>
          </div>
        </div> */}

        {/* <div className="menu-seprator"></div> */}

        {/* Outsorcing */}

        

                 {/* bigdata analytics */}
        {/* <div className="Product-row"  style={{ justifyContent: 'flex-start' }}>
          <div className="Product-column">
            <div className="icon-and-text">
            <FontAwesomeIcon icon={faDatabase} className="Product-icon" />
                <div className="heading-h6">BO/BI Managed Services</div>

            </div>
          </div>

          <div className="Product-column">
            <ul className="list-inline">
              <li className="Product-list-item">
                <Link to="/Products/Big data management (Hadoop, HPC, Apache Sparks, BO, BI).html">Big data management (Hadoop, HPC, Apache Sparks, BO, BI)</Link>
              </li>

              <li className="Product-list-item">
                <Link to="/Products/Toll Collections and Survey System.html">
                Business Process Outsourcing
                </Link>Toll Collections and Survey System
              </li>
            </ul>
          </div>
        </div>

        <div className="menu-seprator"></div> */}


        {/* Application Development and Maintenance */}

        {/* <div className="service-row">
          
          <div className="service-column">
            <div className="icon-and-text">
              <FontAwesomeIcon icon={faChartBar} className="section-icon" />
              <Link to="/services/business-managment-process">
                <h3 className="hear-txt-sub">Business Process Management</h3>
              </Link>
            </div>
          </div>

          <div className="service-column">
            <a
              href="/service/Staffing/Consulting"
              aria-label="Go to Consulting Service Page"
            >
              <div className="icon-and-text">
                <FontAwesomeIcon icon={faBriefcase} className="section-icon" />

                <h3 className="hear-txt-sub">Staffing / Consulting Service</h3>
              </div>
            </a>
          </div>

          <div className="service-column">
            <a
              href="/service/KnowledgeProcessOutsourcing"
              aria-label="Go to Incubating Emerging Offering Page"
            >
              <div className="icon-and-text">
                <FontAwesomeIcon icon={faFileAlt} className="section-icon" />{" "}
              
                <h3 className="hear-txt-sub">Knowledge Process Outsourcing</h3>
              </div>
            </a>
          </div>
        </div> */}
      </div>
    </div>
    </>
  );
}
