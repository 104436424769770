import "./template.css";

import { Link } from "react-router-dom";
import ReadModal2 from "./readMore.js";
import React, { useState, useRef, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CarouselSlider from "./Carousel.js";
import CaseStudyCard from "./CaseStudyCard.js";

import Accordion from "./Accordion";
import "./Accordion.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faArrowRight } from "@fortawesome/free-solid-svg-icons";



function IOTServices() {
  const [activeModal, setActiveModal] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);
  const sliderRef = useRef(null);
  const modalRef = useRef(null);
  const [selectedSlide, setSelectedSlide] = useState(null);
  const settings = {
    // dots: true,
    // infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 3000,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const slideData = [
    {
      id: 1,
      heading: "Expertise in IoT and Industry 4.0",
      text: "Deep knowledge and experience in the latest IoT and Industry 4.0 technologies.",
      buttonLabel: "Read More",
      modalContent: {
        // modalHeading: 'Modal Heading 1',
        modalText:
          "CloudCipher seamlessly integrates with existing workflows and software ecosystems, minimizing disruption and streamlining the adoption process. Whether migrating from another platform or implementing new security measures, CloudCipher ensures a smooth transition with minimal downtime.",
      },
    },
    {
      id: 2,
      heading: "Tailored Solutions",
      text: "Customizing solutions to align perfectly with your business requirements.  ",
      buttonLabel: "Read More",
      modalContent: {
        modalHeading: "Modal Heading 2",
        modalText: "Modal Text 2",
      },
    },

    {
      id: 3,
      heading: "Scalability and Flexibility",
      text: "Offering scalable and flexible solutions to grow with your business. ",
      buttonLabel: "Read More",
      modalContent: {
        modalHeading: "Modal Heading 3",
        modalText: "Modal Text 3",
      },
    },

    {
      id: 4,
      heading: "Security and Reliability",
      text: "Prioritizing the security and reliability of your IoT infrastructure.",
      buttonLabel: "Read More",
      modalContent: {
        modalHeading: "Modal Heading 4",
        modalText: "Modal Text 4",
      },
    },

    {
      id: 5,
      heading: "End-to-End Service",
      text: 'From consultation to implementation and support, providing comprehensive IoT integration services."',
      buttonLabel: "Read More",
      modalContent: {
        modalHeading: "Modal Heading 5",
        modalText: "Modal Text 5",
      },
    },

    // {
    //   id: 6,
    //   heading: 'Sixth Slide',
    //   text: 'A different text for the second slide.',
    //   buttonLabel: 'Read More',
    //   modalContent: {
    //     modalHeading: 'Modal Heading 6',
    //     modalText: 'Modal Text 6',
    //   },
    // },

    // {
    //   id: 7,
    //   heading: 'Seventh Slide',
    //   text: 'A different text for the second slide.',
    //   buttonLabel: 'Read More',
    //   modalContent: {
    //     modalHeading: 'Modal Heading 7',
    //     modalText: 'Modal Text 7',
    //   },
    // },

    // {
    //   id: 8,
    //   heading: 'Eighth Slide',
    //   text: 'A different text for the second slide.',
    //   buttonLabel: 'Read More',
    //   modalContent: {
    //     modalHeading: 'Modal Heading 8',
    //     modalText: 'Modal Text 8',
    //   },
    // },
    // ... (more slides)
  ];


  let items = [
    {
      title: "Q: How do IoT and Industry 4.0 technologies benefit businesses? ",
      content:
        "A: These technologies enhance operational efficiency, provide real-time data insights, improve safety, and enable automation, leading to better decision-making and reduced costs.  ",
    },
    {
      title: "Q: Can IoT solutions be scaled to fit different business sizes? ",
      content:
        " A: Yes, our IoT solutions are highly scalable and can be customized to suit businesses of all sizes and sectors.   ",
    },
    {
      title: "Q: How do you ensure the security of IoT systems? ",
      content:
        "A: We implement robust security protocols, including encryption and secure data handling, to protect your IoT infrastructure.   ",
    },
   
    
   
  ];
 
 


  const openModal = (slide) => {
    setSelectedSlide(slide);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  // Handle clicks outside the modal to close it
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        closeModal();
      }
    };

    window.addEventListener("mousedown", handleOutsideClick);

    return () => {
      window.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const modalContent = (
    <>
      <p className="modal-np">The Purpose Behind Our IOT Services </p>
     
      At Digitec Hub, we specialize in integrating cutting-edge IoT technologies
      to propel your business into the future. Our services are aimed at
      harnessing the power of IoT and Industry 4.0 innovations to transform your
      operations, ensuring efficiency, accuracy, and real-time intelligence.

      <hr />
         
 
 
          
  
       <br/> <strong>Remote Monitoring and Control</strong> 
 
          IoT services enable real-time monitoring and control of connected devices and systems from anywhere with an internet connection. This allows businesses to remotely manage equipment, facilities, and processes, improving operational efficiency and reducing downtime.
         <br/><br/>
        <strong> Predictive Maintenance</strong>
        IoT services enable predictive maintenance by monitoring equipment and machinery for signs of potential failures or malfunctions. By analyzing sensor data and performance metrics in real-time, businesses can detect issues early, schedule proactive maintenance, and prevent costly downtime and repairs.
 
          <br/><br/><strong>Asset Tracking and Management</strong> 
          IoT services track and manage physical assets, such as vehicles, equipment, inventory, and goods, throughout their lifecycle. This enhances visibility, traceability, and security of assets, enabling better inventory management, supply chain optimization, and loss prevention.
    
    
      {/* You can pass any JSX content you want */}
    </>
  );

  return (
    <div>
      <div id="maindivs">
        <div id="carouselExampleCaptions" className="carousel slide">
          <div className="carousel-inner">
            <div className="carousel-item active">
              <img
                src="/Images/iotmainbg.png"
                className="d-block w-100"
                style={{ height: "auto" ,opacity:"0.4"}}
                alt="slider first"
              />
              <div className="carousel-caption  d-md-block">
                <div className="component-1 text-dark">IOT Services </div>

                <div
                  className="component-paras"
                  style={{
                    color: "black",
                    marginTop: "-20px",
                    fontStyle: "italic",
                    fontWeight:"700"
                  }}
                >
                  "Revolutionizing Operations with Smart Technology – Embrace IoT in the Industry 4.0 Era" 
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container-np2">
          {/* <!-- section2 title --> */}
          <div className="cont2-titles">
            {/* <div class="cont2-titletext">What is IOT Services?</div> */}
            
            
            
           
          </div>

          {/* <!-- section part --> */}
          <div class="container21">
            {/* <!-- left part --> */}

            <div className="cont2-left cont2-text">
              <div
                className="left-text"
                style={{
                  // fontFamily: "arial",

                  fontFamily: "system-ui",
                  fontSize: "22px",
                }}
              >
                IoT and Industry 4.0 Integration Services refer to the
                incorporation of Internet of Things (IoT) technologies into
                various business operations and processes. This service involves
                implementing smart devices and systems, such as biometrics, NFC,
                RFID, CCTV-based AI, and GPS tracking, to streamline operations
                in line with Industry 4.0 standards. Our focus is to enhance
                automation, monitoring, and data-driven decision-making in your
                business environment.
              </div>
            </div>

            {/* right part */}
            <div className="cont2-right cont2-text">
              <div className="right-text">
                <img
                  class="content2img"
                  src="/Images/internet.gif"
                  alt="Description of the image"
                />
              </div>
            </div>
          </div>
          <div class="readmore">
            <ReadModal2 buttonText="Read More" modalContent={modalContent} />
          </div>
        </div>

        {/* section3 */}
        <section class="section3">
          <div class="cont2-title title ">Our Features</div>

          <div class="section3parts6">
            <div class="content-np item316" >
              <div>
                <strong
                  style={{ paddingLeft: '20px' }}
                >
                  {" "}
                  Payroll Management with Biometrics
                </strong>
                <p class="aligned-paragraph item3text" style={{ marginBottom: "30px" }}>
                Implementing biometric systems for enhanced payroll 

accuracy and security. 
                </p>
              </div>
            </div>

            <div class="content-np item322">
              <strong
                class="item32-heading item31-conent"
                style={{
                  paddingLeft:'15px',color:'black'
                }}
              >
               Advanced Warehouse Solutions with NFC and RFID
              </strong>
              <div class=" item31-conent-img  "></div>
              <p class="aligned-paragraph item3text " style={{color:'black'}}>
              Utilizing NFC and RFID technology for 

efficient warehouse and inventory management. 
              </p>
            </div>

            <div class="content-np item33">
              <strong
                class=""
                style={{
                  paddingLeft:'15px'
                }}
              >
                {" "}
                Industrial Automation with AI and CCTV
              </strong>
              <p class="aligned-paragraph item3text">
              Deploying AI-driven CCTV systems for automated 

monitoring and enhanced operational safety. 
              </p>
              {/* <div class="item32-button"><button class="item32-button">Read more</button></div> */}
            </div>

            <div class="content-np item34">
              <strong
                style={{
                  paddingLeft:'15px'
                 }}
              >
               Real-Time Monitoring Solutions
              </strong>
              <p class="aligned-paragraph item3text" style={{color:'black'}}>
              Providing solutions for continuous, real-time monitoring of 

various business processes. 
              </p>
            </div>
            <div class="content-np item35">
              <strong
                style={{
                  paddingLeft:'15px'
               }}
              >
                {" "}
                GPS Tracking for Logistics and Supply Chain
              </strong>
              <p class="aligned-paragraph item3text">
              Implementing GPS systems to optimize logistics and supply chain management with real-time tracking. 
              </p>
            </div>

            <div class="content-np item36">
              <strong
                style={{
                  paddingLeft:'15px'
               }}
              >
                 Custom IoT Integration
              </strong>
              <p class="aligned-paragraph item3text">
              Tailoring IoT solutions to meet the specific needs of your business in 

the context of Industry 4.0. 
              </p>
            </div>

            {/* <div class="content-np item37">
              <strong
                style={{
                  paddingLeft:'15px'
               }}
              >
                Interoperability and Standards
              </strong>
              <p class="aligned-paragraph item3text" style={{color:'black'}}>
              Ensuring compatibility and seamless communication among diverse IoT devices and platforms require the adoption of interoperability standards and protocols.
              </p>
            </div> */}
          </div>
          {/* <div class="readmore">
            <button class="read1">Read More</button>
          </div> */}
        </section>

        <div class="section4">
          
          <div className="main-cont">
            <div className="Cardcomp">
              <div className="card-container">
                <div className="row-ca">
                  <div className="col-ca">
                    <div className="card-head text-center">
                      <h2 className="head-t ">USP of IOT Services</h2>
                     
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card-container">
              <div className="row-ck">
                <div className="col-ck">
                  <div className="card-wrapper">
                    <div className="main-card">
                      <div className="innercon">
                        <div className="card-content1">
                          <h2>
                          Expertise in IoT and Industry 4.0{" "}
                            <FontAwesomeIcon icon={faArrowRight} />
                          </h2>
                          {/* <p>vbhf</p> */}
                        </div>
                        <img src="/Images/organigram.gif" alt="" />
                      </div>
                    </div>
                    <div className="hover-card">
                      <div className="hover-content">
                        <h2>Expertise in IoT and Industry 4.0</h2>
                        <p>
                        Deep knowledge and experience in the latest IoT and Industry 4.0 technologies.{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-ck">
                  <div className="card-wrapper">
                    <div className="main-card">
                      <div className="innercon">
                        <div className="card-content1">
                          <h2>
                          Tailored Solutions
                            <FontAwesomeIcon icon={faArrowRight} />{" "}
                          </h2>
                          {/* <p>vbhf</p> */}
                        </div>
                        <img src="/Images/hand.gif" alt="" />
                      </div>
                    </div>
                    <div className="hover-card">
                      <div className="hover-content">
                        <h2>Tailored Solutions</h2>
                        <p>
                          {" "}
                          Customizing solutions to align perfectly with your business requirements. {" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-ck">
                  <div className="card-wrapper">
                    <div className="main-card">
                      <div className="innercon">
                        <div className="card-content1">
                          <h2>
                          Scalability and Flexibility{" "}
                            <FontAwesomeIcon icon={faArrowRight} />
                          </h2>
                          {/* <p>vbhf</p> */}
                        </div>
                        <img src="/Images/scalability.gif" alt=""/>
                        
                      </div>
                    </div>
                    <div className="hover-card">
                      <div className="hover-content">
                        <h2>Scalability and Flexibility</h2>
                        <p>
                        Offering scalable and flexible solutions to grow with your business. {" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-ck">
                  <div className="card-wrapper">
                    <div className="main-card">
                      <div className="innercon">
                        <div className="card-content1">
                          <h2>
                          Security and Reliability<FontAwesomeIcon icon={faArrowRight} />
                          </h2>
                          {/* <p>vbhf</p> */}
                        </div>
                        <img src="/Images/Secure_Payment_Processing.gif" alt="" />
                      </div>
                    </div>
                    <div className="hover-card">
                      <div className="hover-content">
                        <h2>Security and Reliability</h2>
                        <p>
                        Prioritizing the security and reliability of your IoT infrastructure.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-ck">
                  <div className="card-wrapper">
                    <div className="main-card">
                      <div className="innercon">
                        <div className="card-content1">
                          <h2>
                          End-to-End Service
                            <FontAwesomeIcon icon={faArrowRight} />
                          </h2>
                          {/* <p>vbhf</p> */}
                        </div>
                        <img src="/Images/route.gif" alt="" />
                      </div>
                    </div>
                    <div className="hover-card">
                      <div className="hover-content">
                        <h2> End-to-End Service</h2>
                        <p>
                        From consultation to implementation and support, providing comprehensive IoT integration services.{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>


                {/* <div className="col-ck">
                  <div className="card-wrapper">
                    <div className="main-card">
                      <div className="innercon">
                        <div className="card-content1">
                          <h2>
                          ROI Driven{" "}
                            <FontAwesomeIcon icon={faArrowRight} />
                          </h2>
                        </div>
                        <img src="/Images/24_Customer_Support.gif" alt="" />
                      </div>
                    </div>
                    
                    <div className="hover-card">
                      <div className="hover-content">
                        <h2>ROI Driven</h2>
                        <p>
                          {" "}
                          We focus on delivering measurable results and a strong return on investment (ROI) for our clients, helping you achieve your business objectives and drive growth.{" "}
                        </p>
                      </div>
                    </div>
    </div>
                </div> */}





               
                
              </div>
            </div>
          </div>




          {/* <CarouselSlider/> */}
        </div>

        <div div className="section5">
          <h1 className="section5heading">How We are Handling</h1>
          <div className="section5jsxcard">
            <CaseStudyCard
              imageUrl="/Images/casestudyimg1.jpg"
              title="In-depth Consultation"
              description="  Understanding your business needs and identifying areas for IoT 

              integration.  "
              linkUrl="#"
            />
            <CaseStudyCard
              imageUrl="/Images/casestudyimg2.jpg"
              title=" Customized Implementation Strategy"
              description="Developing a tailored strategy for deploying IoT 

              solutions in your operations. "
              linkUrl=""
            />
            <CaseStudyCard
              imageUrl="/Images/casestudyimg3.jpg"
              title="Seamless Integration"
              description=" Ensuring smooth integration of IoT technologies with existing business 

              processes. "
              linkUrl=""
            />

            <CaseStudyCard
              imageUrl="/Images/casestudyimg4.jpg"
              title="Continuous Support and Optimization"
              description=" Providing ongoing support and continually optimizing 

              the solutions for maximum efficiency. "
              linkUrl=""
            />

            {/* <div class="readmore">
  <button class="read">show More</button>
  </div> */}

            {/* section 6 */}
          </div>
        </div>


        <section className="FAQ">
        <div className="FAQbody">
          <h1 className="section5heading text-dark" >FAQ's for this service</h1>
          <Accordion items={items} />
        </div>
      </section>

      </div>
    </div>
  );
}

export default IOTServices;
