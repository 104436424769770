import React from 'react'

export default function NavigateYourNext() {
  return (
    <div className='container'>
    <div className="card mt-4">
        <div className="card-body">
            <h2>Navigate your Next</h2>
        </div>
    </div>
  
</div>
  )
}
