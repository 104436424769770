import "./template.css";

import { Link } from "react-router-dom";
import ReadModal2 from "./readMore.js";
import React, { useState, useRef, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CarouselSlider from "./Carousel.js";
import CaseStudyCard from "./CaseStudyCard.js";
import Accordion from "./Accordion";
import "./Accordion.css";


import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faArrowRight } from "@fortawesome/free-solid-svg-icons";


function ERPservice() {
  const [activeModal, setActiveModal] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);
  const sliderRef = useRef(null);
  const modalRef = useRef(null);
  const [selectedSlide, setSelectedSlide] = useState(null);
  const settings = {
    // dots: true,
    // infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 3000,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const slideData = [
    {
      id: 1,
      heading: "Expertise Across Platforms",
      text: "Proficient in SAP, MS Dynamics, and Odoo, offering you a range of options to best fit your needs.",
      buttonLabel: "Read More",
      modalContent: {
        // modalHeading: 'Modal Heading 1',
        modalText:
          "CloudCipher seamlessly integrates with existing workflows and software ecosystems, minimizing disruption and streamlining the adoption process. Whether migrating from another platform or implementing new security measures, CloudCipher ensures a smooth transition with minimal downtime.",
      },
    },
    {
      id: 2,
      heading: "Customized Solutions",
      text: "Tailoring ERP solutions to align perfectly with your business processes.  ",
      buttonLabel: "Read More",
      modalContent: {
        modalHeading: "Modal Heading 2",
        modalText: "Modal Text 2",
      },
    },

    {
      id: 3,
      heading: "Comprehensive Support",
      text: "Ensuring not just implementation but also providing continuous support and maintenance. ",
      buttonLabel: "Read More",
      modalContent: {
        modalHeading: "Modal Heading 3",
        modalText: "Modal Text 3",
      },
    },

    {
      id: 4,
      heading: "Focus on User Experience",
      text: "Prioritizing user adoption and ease of use in our ERP implementations.  ",
      buttonLabel: "Read More",
      modalContent: {
        modalHeading: "Modal Heading 4",
        modalText: "Modal Text 4",
      },
    },

    {
      id: 5,
      heading: "Strategic Approach",
      text: "Adopting a strategic approach to maximize the efficiency and effectiveness of your ERP system. ",
      buttonLabel: "Read More",
      modalContent: {
        modalHeading: "Modal Heading 5",
        modalText: "Modal Text 5",
      },
    },

    // {
    //   id: 6,
    //   heading: 'Sixth Slide',
    //   text: 'A different text for the second slide.',
    //   buttonLabel: 'Read More',
    //   modalContent: {
    //     modalHeading: 'Modal Heading 6',
    //     modalText: 'Modal Text 6',
    //   },
    // },

    // {
    //   id: 7,
    //   heading: 'Seventh Slide',
    //   text: 'A different text for the second slide.',
    //   buttonLabel: 'Read More',
    //   modalContent: {
    //     modalHeading: 'Modal Heading 7',
    //     modalText: 'Modal Text 7',
    //   },
    // },

    // {
    //   id: 8,
    //   heading: 'Eighth Slide',
    //   text: 'A different text for the second slide.',
    //   buttonLabel: 'Read More',
    //   modalContent: {
    //     modalHeading: 'Modal Heading 8',
    //     modalText: 'Modal Text 8',
    //   },
    // },
    // ... (more slides)
  ];

  let items = [
    {
      title: "Q: What makes ERP implementation crucial for modern businesses? ",
      content:
        "A: ERP systems centralize business operations, streamline processes, and provide real-time data, crucial for informed decision-making and operational efficiency.  ",
    },
    {
      title: "Q: Can ERP solutions be customized according to our business needs? ",
      content:
        "A: Yes, we specialize in customizing ERP solutions like SAP, MS Dynamics, and Odoo to fit your specific business requirements.  ",
    },
    {
      title: "Q: How do you ensure a smooth transition during ERP implementation?",
      content:
        "A: We follow a structured implementation process, provide comprehensive training, and offer ongoing support to ensure a smooth transition.   ",
    },
   
    
   
  ];
 

  const openModal = (slide) => {
    setSelectedSlide(slide);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  // Handle clicks outside the modal to close it
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        closeModal();
      }
    };

    window.addEventListener("mousedown", handleOutsideClick);

    return () => {
      window.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const modalContent = (
    <>
      <p className="modal-np">The Purpose Behind Our ERP Services </p>
      At Digitec Hub, our ERP Services are dedicated to transforming your
      business operations. Leveraging the power of leading ERP platforms like
      SAP, MS Dynamics, and Odoo, we offer solutions that streamline processes,
      enhance efficiency, and drive business growth.
      <hr />
      <br /> <strong>Integration of Business Processes</strong>
      ERP services integrate various business functions and processes across
      departments such as finance, human resources, supply chain management,
      manufacturing, sales, and customer service. By providing a unified
      platform, ERP eliminates data silos and enables seamless flow of
      information across the organization.
      <br />
      <br />
      <strong> Centralized Data Management</strong>
       ERP systems centralize data from different departments into a
      single database, ensuring data consistency, accuracy, and reliability.
      This centralized data repository serves as a single source of truth,
      enabling users to access real-time information for decision-making and
      analysis.
      <br />
      <br />
      <strong>Improved Collaboration and Communication</strong> ERP systems
      facilitate collaboration and communication among employees, departments,
      and stakeholders by providing tools for sharing information, coordinating
      activities, and managing workflows. This enhances teamwork, promotes
      transparency, and accelerates decision-making processes.
      <br />
      <br />
     
      {/* Your dynamic content here */}
      {/* You can pass any JSX content you want */}
    </>
  );
  return (
    <div id="maindivs">
      <div id="carouselExampleCaptions" className="carousel slidey">
        <div className="carousel-inner">
          <div className="carousel-item active">
            <img
              src="/Images/erpservicesbgimages.jpg"
              className="d-block w-100"
              style={{ height: "auto",opacity:"0.3"  }}
              alt="slider first"
            />
            <div className="carousel-caption  d-md-block">
              <div className="component-1 text-dark">ERP Services </div>

              <div
                className="component-paras"
                style={{ color: "black", marginTop: "-20px", fontStyle: "italic" ,fontWeight:"700"}}
              >
                "Streamlining Your Business Processes – ERP Solutions Tailored
                for Excellence"
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-np2">
        {/* <!-- section2 title --> */}
        <div className="cont2-titles">
          {/* <div class="cont2-titletext">What is ERP Services?</div> */}
        </div>

        {/* <!-- section part --> */}
        <div class="container21">
          {/* <!-- left part --> */}

          <div className="cont2-left cont2-text">
            <div
              className="left-text"
              style={{
                // fontFamily: "arial",
                
                fontFamily: "system-ui",
              }}
            >
              ERP (Enterprise Resource Planning) Services encompass the
              comprehensive management and implementation of ERP software
              systems. These services include the customization, deployment, and
              ongoing support of ERP solutions like SAP, MS Dynamics, and Odoo.
              Our aim is to integrate all facets of your business operations
              into a unified and efficient system, enhancing decision-making and
              operational efficiency.
            </div>
          </div>

          {/* right part */}
          <div className="cont2-right cont2-text">
            <div className="right-text">
              <img
                class="content2img"
                src="/Images/planning.gif"
                alt="Description of the image"
              />
            </div>
          </div>
        </div>
        <div class="readmore">
          <ReadModal2 buttonText="Read More" modalContent={modalContent} />
        </div>
      </div>

      {/* section3 */}
      <section class="section3">
        <div class="cont2-title title ">Our Features</div>

        <div class="section3parts">
          <div class="content-np item31" >
            <strong
              // style={{
              //   fontSize: "20px",
              //   marginLeft: "20px",
              // }}
              class="section3heading" style={{ paddingLeft: '20px' }}
            >
              {" "}
              SAP Implementation and Management
            </strong>
            <p
              class="aligned-paragraph item3text"
              style={{ marginBottom: "30px" }}
            >
              Comprehensive SAP solutions, from initial setup to ongoing
              management and optimization.
            </p>
          </div>

          <div class="content-np item322">
            <strong
              section3heading
              class="item32-heading item31-conent "
              style={{
                paddingLeft:'15px',color:'black'
              }}
            >
              MS Dynamics Solutions
            </strong>
            <div class=" item31-conent-img  "></div>
            <p
              class="aligned-paragraph item3text"
              style={{color:'black'}}
            >
              Customized MS Dynamics implementations, tailored to fit unique
              business processes and requirements.
            </p>
          </div>

          <div class="content-np item33">
            <strong
              
              className=" "
              style={{
                paddingLeft:'15px'
              }}
            >
              {" "}
              Odoo-Based Systems
            </strong>
            <p
              class="aligned-paragraph item3text"
              
            >
              Implementing and managing flexible, cost-effective ERP solutions
              with Odoo.
            </p>
            {/* <div class="item32-button"><button class="item32-button">Read more</button></div> */}
          </div>

          <div class="content-np item34">
            <strong
              style={{
                paddingLeft:'15px'
               }}
              className=" section3heading"
            >
              Customization and Integration
            </strong>
            <p
              class="aligned-paragraph item3text"
              style={{color:'black'}}
            >
              Tailoring ERP systems to meet specific business needs and ensuring
              seamless integration with existing processes.
            </p>
          </div>
          <div class="content-np item35">
            <strong
              className="item32-heading"
              style={{
                paddingLeft:'15px'
             }}
            >
              {" "}
              Continuous Support and Maintenance
            </strong>
            <p
              class="aligned-paragraph item3text"
              style={{
                paddingLeft:'15px'
             }}
            >
              Offering ongoing support and maintenance to ensure ERP systems are
              up-to-date and running smoothly.
            </p>
          </div>

          <div class="content-np item36">
            <strong
              style={{
                paddingLeft:'15px'
             }}
              className="item32-heading"
            >
              Training and User Adoption
            </strong>
            <p
              class="aligned-paragraph item3text"
              
            >
              Providing training sessions to ensure your team can fully leverage
              the new ERP system.
            </p>
          </div>

          <div class="content-np item37">
            <strong
              className="item32-heading"
              style={{
                paddingLeft:'15px'
             }}
            >
              Customer Relationship Management (CRM)
            </strong>
            <p class="aligned-paragraph item3text" style={{color:'black'}}>
              Tracks leads, opportunities, quotes, and orders. Manages marketing
              campaigns, lead generation, and customer engagement.
            </p>
          </div>
        </div>
        {/* <div class="readmore">
        <button class="read1">Read More</button>
      </div> */}
      </section>

      <div class="section4">
          
          <div className="main-cont">
            <div className="Cardcomp">
              <div className="card-container">
                <div className="row-ca">
                  <div className="col-ca">
                    <div className="card-head text-center">
                      <h2 className="head-t ">USP of ERP Services</h2>
                     
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card-container">
              <div className="row-ck">
                <div className="col-ck">
                  <div className="card-wrapper">
                    <div className="main-card">
                      <div className="innercon">
                        <div className="card-content1">
                          <h2>
                          Expertise Across Platforms{" "}
                            <FontAwesomeIcon icon={faArrowRight} />
                          </h2>
                          {/* <p>vbhf</p> */}
                        </div>
                        <img
                        className="img-ico"
                        src="/Images/organigram.gif"
                        alt=""
                      />
                      </div>
                    </div>
                    <div className="hover-card">
                      <div className="hover-content">
                        <h2> Expertise Across Platforms </h2>
                        <p>
                        Proficient in SAP, MS Dynamics, and Odoo, offering you a range of options to best fit your needs.{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-ck">
                  <div className="card-wrapper">
                    <div className="main-card">
                      <div className="innercon">
                        <div className="card-content1">
                          <h2>
                          Customized Solutions
                            <FontAwesomeIcon icon={faArrowRight} />{" "}
                          </h2>
                          {/* <p>vbhf</p> */}
                        </div>
                        <img src="/Images/hand.gif" alt="" />
                      </div>
                    </div>
                    <div className="hover-card">
                      <div className="hover-content">
                        <h2>Customized Solutions</h2>
                        <p>
                          {" "}
                          Tailoring ERP solutions to align perfectly with your business processes.{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-ck">
                  <div className="card-wrapper">
                    <div className="main-card">
                      <div className="innercon">
                        <div className="card-content1">
                          <h2>
                          Comprehensive Support{" "}
                            <FontAwesomeIcon icon={faArrowRight} />
                          </h2>
                          {/* <p>vbhf</p> */}
                        </div>
                        <img src="/Images/Seamless_Multi-Platform_Integration.gif" alt="" />
                      </div>
                    </div>
                    <div className="hover-card">
                      <div className="hover-content">
                        <h2>Comprehensive Support</h2>
                        <p>
                        Ensuring not just implementation but also providing continuous support and maintenance.{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-ck">
                  <div className="card-wrapper">
                    <div className="main-card">
                      <div className="innercon">
                        <div className="card-content1">
                          <h2>
                          Focus on User Experience<FontAwesomeIcon icon={faArrowRight} />
                          </h2>
                          {/* <p>vbhf</p> */}
                        </div>
                        <img src="/Images/implementation.gif" alt="" />
                      </div>
                    </div>
                    <div className="hover-card">
                      <div className="hover-content">
                        <h2>Focus on User Experience</h2>
                        <p>
                        Prioritizing user adoption and ease of use in our ERP implementations.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-ck">
                  <div className="card-wrapper">
                    <div className="main-card">
                      <div className="innercon">
                        <div className="card-content1">
                          <h2>
                          Strategic Approach
                            <FontAwesomeIcon icon={faArrowRight} />
                          </h2>
                          {/* <p>vbhf</p> */}
                        </div>
                        <img src="/Images/Social_Shopping_Features.gif" alt="" />
                      </div>
                    </div>
                    <div className="hover-card">
                      <div className="hover-content">
                        <h2>Strategic Approach</h2>
                        <p>
                        Adopting a strategic approach to maximize the efficiency and effectiveness of your ERP system.{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-ck">
                  <div className="card-wrapper">
                    <div className="main-card">
                      <div className="innercon">
                        <div className="card-content1">
                          <h2>
                          Cost-Effectiveness{" "}
                            <FontAwesomeIcon icon={faArrowRight} />
                          </h2>
                          {/* <p>vbhf</p> */}
                        </div>
                        <img src="/Images/hand.gif" alt="" />
                      </div>
                    </div>
                    <div className="hover-card">
                      <div className="hover-content">
                        <h2>Cost-Effectiveness </h2>
                        <p>
                          {" "}
                          We offer cost-effective ERP solutions that deliver high ROI, ensuring that you get the most value from your ERP investment.{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

               
                
              </div>
            </div>
          </div>




          {/* <CarouselSlider/> */}
        </div>




      <div className="section5">
        <h1 className="section5heading">How We are Handling</h1>
        <div className="section5jsxcard">
          <CaseStudyCard
            imageUrl="/Images/casestudyimg1.jpg"
            title="Needs Assessment"
            description=" Conducting a thorough analysis of your business requirements and processes."
            linkUrl="#"
          />
          <CaseStudyCard
            imageUrl="/Images/casestudyimg2.jpg"
            title="Strategic Planning and Implementation"
            description="Developing a strategic plan for ERP implementation and executing it in alignment with your business goals."
            linkUrl=""
          />
          <CaseStudyCard
            imageUrl="/Images/casestudyimg3.jpg"
            title="Data Migration and Integration"
            description="Ensuring safe and seamless migration of existing data and integration with current systems."
            linkUrl=""
          />

          <CaseStudyCard
            imageUrl="/Images/casestudyimg4.jpg"
            title="Training and Knowledge Transfer"
            description="Providing extensive training to your team for effective utilization of the ERP system. "
            linkUrl=""
          />

          {/* <div class="readmore">
  <button class="read">show More</button>
  </div> */}

          {/* section 6 */}
        </div>
      </div>
      {/* <div class="readmore">
<button class="read">show More</button>
</div> */}

      {/* section 6 */}

      <section className="FAQ">
        <div className="FAQbody">
          <h1 className="section5heading text-dark" >FAQ's for this service</h1>
          <Accordion items={items} />
        </div>
      </section>
    </div>
  );
}

export default ERPservice;
