import React from 'react';
import "./TechnicalDocument.css";

export default function TechicalDocument() {
  return (
    <>
      <div className="banner parbase industries-gradient aem-GridColumn aem-GridColumn--default--12">
        <section id="sml_ht_home_banner">
          <article className="container">
            <div className="rows">
             
                <h1
                  id="technicala"
                  className="h2-heading white-color l-top"
                 
                >
                  Technical Documents and Content Writing Services
                </h1>
              
            </div>
          </article>
        </section>
      </div>

      <h2
        className="technicalcontent"
        style={{
          fontWeight: 'normal',
          fontSize: '18px', // Reduce font size for mobile
          paddingLeft: '7%', // Adjust the padding for mobile
          paddingTop: '20px', // Reduce top padding for mobile
        }}
      >
        Welcome to DigitechHub Data Informatics Company, your partner in delivering clear, concise, and comprehensive technical
        documents and content. Our team of skilled writers specializes in translating complex concepts into accessible
        content that engages, educates, and informs your target audience.
      </h2>

      <section class="pt75">
        <article class="container">
          <div class="row">
            <div class="col-md-6 col-sm-12 col-xs-12 " id='technicalwht'>
              <h1 style={{marginLeft:'-5%'}}>Why Choose Our Technical Writing Services?</h1>
              <div style={{fontSize:'17px'}}>
                Crafting technical documents and content requires a unique blend of expertise, precision, and communication
                skills. At DigitechHub Data Informatics Company, we offer a range of benefits that set us apart:
              </div>
              <ul>
                <li>1. Subject Matter Experts: Our writers are well-versed in various industries and technologies, ensuring accurate and in-depth coverage of your technical topics.</li>
                <li>2. Clarity and Simplicity: We excel at breaking down complex information into easy-to-understand language, ensuring that even intricate subjects are accessible to your readers.</li>
                <li>3. Custom Solutions: Whether you need user manuals, guides, documentation, whitepapers, or other technical content, we tailor our services to meet your specific needs.</li>
                <li>4. Attention to Detail: We pay meticulous attention to detail, ensuring that every technical term, diagram, and explanation is accurate and consistent.</li>
              </ul>
            </div>
            <div className="col-md-6 col-sm-12 col-xs-12 mb-sm-20">
              <img
                src="/Images/technical-writing.jpg"
                alt="Technical Writing Services"
                className="small-image"
                style={{
                  maxWidth: '100%',
                  height: 'auto',
                  maxHeight: '500px', // Reduce max height for mobile
                  marginLeft: '10%',
                  marginRight:'10%' // Adjust the margin for mobile
                }}
              />
            </div>
          </div>
        </article>
      </section>

      <br></br>
      <br></br>

      <div>
        <h1 className="text-center">Our Technical Writing Services</h1>
      </div>
      <div class="cards-container" style={{marginLeft:'7%',marginRight:'5%'}}>
        <div className="card" >
          <h3>1. User Manuals and Guides:</h3>
          <p>Our technical writers create user-friendly manuals and guides that provide step-by-step instructions, troubleshooting tips, and usage guidelines for your products and services.</p>
        </div>
        <div className="card">
          <h3>2. Product Documentation:</h3>
          <p>We produce comprehensive product documentation that covers technical specifications, features, installation processes, and best practices, ensuring users have all the information they need.</p>
        </div>
        <div className="card">
          <h3>3. Whitepapers and Case Studies:</h3>
          <p>Our team crafts well-researched whitepapers and case studies that delve into industry trends, insights, and real-world applications, establishing your authority in your field.</p>
        </div>
        <div className="card">
          <h3>4. Online Help and FAQs:</h3>
          <p>We create clear and concise online help documentation and FAQs that guide users through your digital platforms, apps, and software interfaces.</p>
        </div>
        <div className="card">
          <h3>5. Technical Blog Posts:</h3>
          <p>Our writers develop informative and engaging blog posts that explore technical topics, trends, and solutions, showcasing your expertise to a broader audience.</p>
        </div>
        <div className="card">
          <h3>6. API Documentation:</h3>
          <p>We produce API documentation that provides developers with detailed information on how to interact with your software's APIs, facilitating seamless integration.</p>
        </div>
      </div>
      <div className="d">
        <h4 className="heading-h4">Why Collaborate with DigitechHub Data Informatics Company?</h4>
        <ul id="technical why" style={{ color: 'black' }}>
          <li className="li text-start">Industry Experience: Our writers have diverse industry backgrounds and are well-equipped to handle various technical subjects.</li>
          <li className="li text-start">Accuracy and Consistency: We ensure that technical terminology and concepts are accurately portrayed and consistently used throughout the document.</li>
          <li className="li text-start">Timely Delivery: We understand the importance of deadlines and commit to delivering high-quality technical content on time.</li>
          <li className="li text-start">Client-Centric Approach: We work closely with you to understand your requirements and goals, ensuring that our writing aligns with your vision.</li>
          <li className="li text-start">Confidentiality: We handle sensitive technical information with the utmost confidentiality and adhere to strict data security practices.</li>
        </ul>
      </div>

      <p className="p1">Let us transform your complex ideas into compelling technical content that resonates with your audience. Contact us today to discuss your technical writing needs and how we can assist you in creating impactful documents.</p>
    </>
  );
}
