import "./template.css";

import { Link } from "react-router-dom";
import ReadModal2 from "./readMore.js";
import React, { useState, useRef, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CarouselSlider from "./Carousel.js";
import CaseStudyCard from "./CaseStudyCard.js";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

function ML() {
  const [activeModal, setActiveModal] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);
  const sliderRef = useRef(null);
  const modalRef = useRef(null);
  const [selectedSlide, setSelectedSlide] = useState(null);
  const settings = {
    // dots: true,
    // infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },

      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },

      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 3000,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const slideData = [
    {
      id: 1,
      heading: "Advanced Technology Integration",
      text: " Leveraging the latest advancements in OCR and object recognition to provide state-of-the-art solutions.  ",
      buttonLabel: "Read More",
      modalContent: {
        // modalHeading: 'Modal Heading 1',
        modalText:
          "CloudCipher seamlessly integrates with existing workflows and software ecosystems, minimizing disruption and streamlining the adoption process. Whether migrating from another platform or implementing new security measures, CloudCipher ensures a smooth transition with minimal downtime.",
      },
    },
    {
      id: 2,
      heading: "Customized Solutions on SmartVision",
      text: "Tailoring our services to maximize the potential of the SmartVision Platform.",
      buttonLabel: "Read More",
      modalContent: {
        modalHeading: "Modal Heading 2",
        modalText: "Modal Text 2",
      },
    },

    {
      id: 3,
      heading: "Expert Team",
      text: "Our team comprises experts in machine learning, data annotation, and visual data analysis. ",
      buttonLabel: "Read More",
      modalContent: {
        modalHeading: "Modal Heading 3",
        modalText: "Modal Text 3",
      },
    },

    {
      id: 4,
      heading: "High Accuracy and Efficiency",
      text: "Focusing on delivering solutions with high accuracy and operational efficiency. ",
      buttonLabel: "Read More",
      modalContent: {
        modalHeading: "Modal Heading 4",
        modalText: "Modal Text 4",
      },
    },

    {
      id: 5,
      heading: "Scalable Solutions",
      text: "Offering scalable solutions that can grow and adapt with your business needs.",
      buttonLabel: "Read More",
      modalContent: {
        modalHeading: "Modal Heading 5",
        modalText: "Modal Text 5",
      },
    },

    // {
    //   id: 6,
    //   heading: 'Sixth Slide',
    //   text: 'A different text for the second slide.',
    //   buttonLabel: 'Read More',
    //   modalContent: {
    //     modalHeading: 'Modal Heading 6',
    //     modalText: 'Modal Text 6',
    //   },
    // },

    // {
    //   id: 7,
    //   heading: 'Seventh Slide',
    //   text: 'A different text for the second slide.',
    //   buttonLabel: 'Read More',
    //   modalContent: {
    //     modalHeading: 'Modal Heading 7',
    //     modalText: 'Modal Text 7',
    //   },
    // },

    // {
    //   id: 8,
    //   heading: 'Eighth Slide',
    //   text: 'A different text for the second slide.',
    //   buttonLabel: 'Read More',
    //   modalContent: {
    //     modalHeading: 'Modal Heading 8',
    //     modalText: 'Modal Text 8',
    //   },
    // },
    // ... (more slides)
  ];

  const openModal = (slide) => {
    setSelectedSlide(slide);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  // Handle clicks outside the modal to close it
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        closeModal();
      }
    };

    window.addEventListener("mousedown", handleOutsideClick);

    return () => {
      window.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const modalContent = (
    <>
      <p className="modal-np"> The Purpose Behind Our Machine Learning Managed Services </p>
  
      At Digitec Hub, we specialize in OCR (Optical Character Recognition) & Object Recognition Development, turning visual data into actionable insights. Empowering businesses with precise OCR & object recognition for smarter decision-making.


      <hr />
      <br /> <strong>Empowering Businesses</strong>
      The primary purpose is to empower businesses by providing them with end-to-end machine learning solutions. This enables businesses to harness the power of advanced analytics and AI technologies without needing to build and manage the infrastructure and expertise in-house.
      <br />
      <br />

      <strong> Developing Tailored Solutions</strong>
      The services are tailored to meet the specific needs of each business. This involves understanding the unique challenges and requirements of the client and developing customized machine learning models and solutions to address them effectively.
      <br />
      <br />

      <strong>Expertise and Specialization</strong> 
      Digitec Hub's expert team specializes in developing, training, and managing machine learning models. Their expertise covers various domains such as OCR, image recognition, and more. This specialization ensures that clients receive high-quality solutions backed by domain knowledge and technical proficiency.
      <br />
      <br />

      <strong>Delivering Actionable Insights</strong> 
      The focus is on delivering actionable insights derived from data analysis and machine learning models. These insights enable businesses to make informed decisions, identify opportunities for improvement, and stay ahead of the competition in a rapidly evolving market landscape.
      <br />
      <br />
      {/* Your dynamic content here */}
      {/* You can pass any JSX content you want */}
    </>
  );

  return (
    <div>
      <div id="maindivs">
        <div id="carouselExampleCaptions" className="carousel slide">
          <div className="carousel-inner">
            <div className="carousel-item active">
              <img
                src="/Images/mlandmsbgimgaes.png"
                className="d-block w-100"
                style={{ height: "auto",opacity:"0.5" }}
                alt="slider first"
              />
              <div className="carousel-caption d-md-block">
                <div className="component-1 text-dark"> Machine Learning Managed Services  </div>
                <div
                  className="component-paras"
                  style={{
                    color: "black",
                    marginTop: "-20px",
                    fontStyle: "italic",
                    fontWeight:"700",
                  }}
                >
                  "Unlocking Potential, Driving Innovation – Comprehensive Machine Learning Managed 

Services"
"
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container-np2">
          {/* <!-- section2 title --> */}
          <div className="cont2-titles">
            {/* <div class="cont2-titletext">What is OCR & Object Recognition?</div> */}
          </div>

          {/* <!-- section part --> */}
          <div class="container21">
            {/* <!-- left part --> */}

            <div className="cont2-left cont2-text">
              <div
                className="left-text"
                style={{
                  // fontFamily: "arial",
                  fontSize: "22px",
                  fontFamily: "system-ui",
                }}
              >
             At Digitec Hub offers end-to-end Machine Learning Managed Services tailored to empower 

businesses with the ability to harness advanced analytics and AI capabilities. Our expert team 

specializes in developing, training, and managing machine learning models, including OCR, 

image recognition, and more, to solve complex problems and optimize operations. With a focus 

on delivering actionable insights and enhancing decision-making, our comprehensive services 

cover every aspect of the machine learning project ecosystem. 

              </div>
            </div>

            {/* right part */}
            <div className="cont2-right cont2-text">
              <div className="right-text">
                <img
                  class="content2img"
                  src="/Images/ML.gif"
                  alt="Description of the image"
                />
              </div>
            </div>
          </div>
          <div class="readmore">
            <ReadModal2 buttonText="Read More" modalContent={modalContent} />
          </div>
        </div>

          {/* section3 */}
          <section class="section3">
          <div class="cont2-title title ">Our Features</div>
 
          <div class="section3parts6">
            <div class="content-np item316" >
              <div>
                <strong
                  style={{ paddingLeft: '20px' }}
                >
                  {" "}
                  Model Training and Development
                </strong>
                <p class="aligned-paragraph item3text" style={{ marginBottom: "30px" }}>
                Specializing in training machine learning models for OCR, 

image recognition, and other data-intensive applications. 
                </p>
              </div>
            </div>
 
            <div class="content-np item322">
              <strong
                class="item32-heading item31-conent"
                style={{
                  paddingLeft:'15px',color:'black'
                }}
              >
                Dataset Preparation Services
              </strong>
              <div class=" item31-conent-img  "></div>
              <p class="aligned-paragraph item3text " style={{color:'black'}}>
              Expertise in preparing and curating high-quality datasets, 

including data collection, cleansing, labeling, and augmentation. 
              </p>
            </div>
 
            <div class="content-np item33">
              <strong
                class=""
                style={{
                  paddingLeft:'15px'
                }}
              >
                {" "}
                Machine Learning Strategy
              </strong>
              <p class="aligned-paragraph item3text">
              Developing a strategic approach to machine learning 

implementation, ensuring alignment with business objectives. 
              </p>
              {/* <div class="item32-button"><button class="item32-button">Read more</button></div> */}
            </div>
 
            <div class="content-np item34">
              <strong
                style={{
                  paddingLeft:'15px'
                 }}
              >
                 Model Deployment and Integration
              </strong>
              <p class="aligned-paragraph item3text" style={{color:'black'}}>
              Seamlessly deploying and integrating machine learning 

models into existing business systems for real-time analytics and insights. 
              </p>
            </div>
            <div class="content-np item35">
              <strong
                style={{
                  paddingLeft:'15px'
               }}
              >
                {" "}
                Continuous Learning and Optimization
              </strong>
              <p class="aligned-paragraph item3text">
              Implementing mechanisms for models to continuously 

learn and improve from new data, ensuring they remain effective and accurate over time. 
              </p>
            </div>
 
            <div class="content-np item36">
              <strong
                style={{
                  paddingLeft:'15px'
               }}
              >
              Managed Services for ML Ecosystem
              </strong>
              <p class="aligned-paragraph item3text">
              Providing comprehensive managed services covering 

the entire machine learning project lifecycle, from conception to deployment and beyond. 

 
              </p>
            </div>
 
            {/* <div class="content-np item37">
              <strong
                style={{
                  paddingLeft:'15px'
               }}
              >
                Interoperability and Standards
              </strong>
              <p class="aligned-paragraph item3text" style={{color:'black'}}>
              Ensuring compatibility and seamless communication among diverse IoT devices and platforms require the adoption of interoperability standards and protocols.
              </p>
            </div> */}
          </div>
          {/* <div class="readmore">
            <button class="read1">Read More</button>
          </div> */}
        </section>


        <div class="section4">
          
          <div className="main-cont">
            <div className="Cardcomp">
              <div className="card-container">
                <div className="row-ca">
                  <div className="col-ca">
                    <div className="card-head text-center">
                      <h2 className="head-t ">USP of ML Services</h2>
                     
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card-container">
              <div className="row-ck">
                <div className="col-ck">
                  <div className="card-wrapper">
                    <div className="main-card">
                      <div className="innercon">
                        <div className="card-content1">
                          <h2>
                          Expertise in Advanced ML Technologies{" "}
                            <FontAwesomeIcon icon={faArrowRight} />
                          </h2>
                          {/* <p>vbhf</p> */}
                        </div>
                        <img
                        className="img-ico"
                        src="/Images/organigram.gif"
                        alt=""
                      />
                      </div>
                    </div>
                    <div className="hover-card">
                      <div className="hover-content">
                        <h2> Expertise in Advanced ML Technologies</h2>
                        <p>
                        Our deep expertise in machine learning technologies ensures state-of-the-art solutions.


                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-ck">
                  <div className="card-wrapper">
                    <div className="main-card">
                      <div className="innercon">
                        <div className="card-content1">
                          <h2>
                          Customized Machine Learning Strategies
                            <FontAwesomeIcon icon={faArrowRight} />{" "}
                          </h2>
                          {/* <p>vbhf</p> */}
                        </div>
                        <img src="/Images/hand.gif" alt="" />
                      </div>
                    </div>
                    <div className="hover-card">
                      <div className="hover-content">
                        <h2>Customized Machine Learning Strategies</h2>
                        <p>
                          {" "}
                          Tailoring machine learning strategies to fit your 

specific business needs and goals. 
 {" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-ck">
                  <div className="card-wrapper">
                    <div className="main-card">
                      <div className="innercon">
                        <div className="card-content1">
                          <h2>
                          Comprehensive Managed Services{" "}
                            <FontAwesomeIcon icon={faArrowRight} />
                          </h2>
                          {/* <p>vbhf</p> */}
                        </div>
                        <img src="/Images/Seamless_Multi-Platform_Integration.gif" alt="" />
                      </div>
                    </div>
                    <div className="hover-card">
                      <div className="hover-content">
                        <h2>Comprehensive Managed Services</h2>
                        <p>
                        Offering a full spectrum of services that cover the entire 

lifecycle of a machine learning project. 

                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-ck">
                  <div className="card-wrapper">
                    <div className="main-card">
                      <div className="innercon">
                        <div className="card-content1">
                          <h2>
                          Focus on Actionable Insights<FontAwesomeIcon icon={faArrowRight} />
                          </h2>
                          {/* <p>vbhf</p> */}
                        </div>
                        <img src="/Images/implementation.gif" alt="" />
                      </div>
                    </div>
                    <div className="hover-card">
                      <div className="hover-content">
                        <h2> Focus on Actionable Insights</h2>
                        <p>
                        Dedicated to turning data into actionable insights that can 

significantly impact your business outcomes. 

                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-ck">
                  <div className="card-wrapper">
                    <div className="main-card">
                      <div className="innercon">
                        <div className="card-content1">
                          <h2>
                          Continuous Improvement
                            <FontAwesomeIcon icon={faArrowRight} />
                          </h2>
                          {/* <p>vbhf</p> */}
                        </div>
                        <img src="/Images/Performance_Optimization.gif" alt="" />
                      </div>
                    </div>
                    <div className="hover-card">
                      <div className="hover-content">
                        <h2>  Continuous Improvement</h2>
                        <p>
                        Commitment to continuous learning and model optimization to keep 

pace with evolving data and business needs. 
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="col-ck">
                  <div className="card-wrapper">
                    <div className="main-card">
                      <div className="innercon">
                        <div className="card-content1">
                          <h2>
                          Speed{" "}
                            <FontAwesomeIcon icon={faArrowRight} />
                          </h2>
                         
                        </div>
                        <img src="/Images/24_Customer_Support.gif" alt="" />
                      </div>
                    </div>
                    <div className="hover-card">
                      <div className="hover-content">
                        <h2>Speed</h2>
                        <p>
                          {" "}
                          We deliver fast turnaround times, allowing you to digitize large volumes of documents quickly and efficiently, saving you time and resources.

{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

               
                 */}
              </div>
            </div>
          </div>




          {/* <CarouselSlider/> */}
        </div>

        <div className="section5">
          <h1 className="section5heading">How We are Handling</h1>
          <div className="section5jsxcard">
            <CaseStudyCard
              imageUrl="/Images/casestudyimg1.jpg"
              title=" Initial Assessment and Strategy Formulation"
              description="  Beginning with an in-depth analysis of your 

              needs and formulating a customized machine learning strategy. 
              "
              // linkUrl="/platform/E-cart/casestudy-vitualwallet"
            />
            <CaseStudyCard
              imageUrl="/Images/casestudyimg2.jpg"
              title="Data Preparation and Model Training"
              description="  Meticulously preparing datasets and training models to 

              meet specified objectives. 

              "
              linkUrl=""
            />
            <CaseStudyCard
              imageUrl="/Images/casestudyimg3.jpg"
              title="Model Deployment and Integration"
              description="  Ensuring smooth deployment and integration of machine 

              learning models into your business operations. 
              "
              linkUrl=""
            />

            <CaseStudyCard
              imageUrl="/Images/casestudyimg4.jpg"
              title="Ongoing Management and Optimization"
              description=" roviding continuous management and optimization 

              of machine learning models and datasets. 
              "
              linkUrl=""
            />

            {/* <div class="readmore">
  <button class="read">show More</button>
  </div> */}

            {/* section 6 */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ML;
