import React from 'react'
import './WebsiteDevelopment.css';

function WebsiteDevelopment() {

    const servicesData = [
        {
          title: "Custom Website Design",
          description: "Our designers create unique and visually appealing website layouts that align with your brand identity and resonate with your audience.",
        },
        {
          title: "Responsive Development",
          description: "We develop websites with responsive design principles, ensuring optimal performance and appearance across various devices and screen sizes.",
        },
        {
          title: "E-Commerce Solutions",
          description: "Our team specializes in creating e-commerce platforms that offer seamless shopping experiences, secure payment gateways, and robust inventory management.",
        },
        {
          title: "UX/UI Design",
          description: "We prioritize user experience (UX) and user interface (UI) design, ensuring intuitive navigation, engaging interactions, and aesthetically pleasing visuals.",
        },
        {
          title: "International Standards Adherence",
          description: "We follow international design and development standards, including accessibility guidelines, to ensure your website reaches a global audience.",
        },
        {
          title: "SEO Optimization",
          description: "Our websites are optimized for search engines, making it easier for users to find your content and services.",
        },
        {
          title: "Speed and Performance",
          description: "We optimize websites for speed and performance, ensuring fast loading times and smooth interactions.",
        },
      ];

      
  return (
    <div>
      <div className='Website-Development-heading'>Website Design and Development</div>
      <div className='WebsiteDevelopment-point'>Welcome to DigitechHub, where we specialize in creating visually stunning and highly functional websites that adhere to international standards. Our team of skilled designers and developers is committed to delivering websites that not only captivate users but also provide seamless user experiences across all devices. </div>
      <div className='WebsiteD'>Why Choose Our Web Development and Design Services? </div>
       <p className='py'>Building a website that stands out and meets international standards requires a blend of creativity, technical expertise, and attention to detail. At DigitechHub, we offer a range of benefits that make us your ideal partner: </p>
       
       {/* <div className="team-info">
      <div className="column">
        <h2 className='hi'>Expert Team</h2>
        <p className='pari'>
          Our team comprises experienced web designers, developers, and UX/UI experts who excel at translating ideas into exceptional websites.
        </p>

        <h2 className='hi'>Responsive Design</h2>
        <p className='pari'>
          We ensure that your website looks and functions flawlessly on desktops, tablets, and smartphones, providing a consistent experience to users.
        </p>
      </div>

      <div className="column">
        <h2 className='hi'>User-Centric Approach</h2>
        <p className='pari'>
          Our design and development process centers around the needs and preferences of your target audience, ensuring a user-focused website.
        </p>

        <h2 className='hi'>Scalability</h2>
        <p className='pari'>
          We build websites that are scalable and adaptable to accommodate your business growth and changing requirements.
        </p>
      </div>
    </div> */}


<div class="websitetesing-columns-containers1">
    {/* <!-- Column 1 --> */}
    <div class="websitetesing-column">
      <h4 class="websitetesing-holder">Expert Team</h4>
      <p> Our team comprises experienced web designers, developers, and UX/UI experts who excel at translating ideas into exceptional websites.</p>
    </div>

    {/* <!-- Column 2 --> */}
    <div class="websitetesing-column">
      <h4 class="websitetesing-holder">Responsive Design</h4>
      <p> We ensure that your website looks and functions flawlessly on desktops, tablets, and smartphones, providing a consistent experience to users.</p>
    </div>


    {/* <!-- Column 3 --> */}
    <div class="websitetesing-column">
      <h4 class="websitetesing-holder">User-Centric Approach</h4>
      <p> Our design and development process centers around the needs and preferences of your target audience, ensuring a user-focused website.</p>
    </div>


    {/* <!-- Column 4 --> */}
    <div class="websitetesing-column">
      <h4 class="websitetesing-holder">Scalability</h4>
      <p> We build websites that are scalable and adaptable to accommodate your business growth and changing requirements.</p>
    </div>


 


 

  </div>





  
  <div className='headi'>Our Web Development and Design Services: </div>
    <div className="servicese-container">
      {servicesData.map((service, index) => (
        <div className="servicee" key={index}>
          <h3 className='hq'style={{paddingLeft:'2%'}}>{service.title}</h3>
          <p className='po'>{service.description}</p>
          
        </div>
      ))}
    </div>
    <div className='whyecontainer'>
      <div className='whye'>Why Collaborate with DigitechHub?  </div>
      <ul className="whyee-choose-us-list">
        <li>
          <strong>Design Excellence:</strong>Our designers blend creativity with functionality, resulting in visually appealing websites that deliver superior user experiences. 
        </li>
        <li>
          <strong>Technical Expertise:</strong> Our developers stay updated with the latest technologies and coding practices to ensure high-quality, secure, and future-proof websites. 
        </li>
        <li>
          <strong>Client Collaboration:</strong>We work closely with you to understand your goals and preferences, ensuring that the final product reflects your vision.
        </li>
        <li>
          <strong>Quality Assurance:</strong> Our rigorous testing and quality assurance processes ensure that every aspect of your website works seamlessly. 
        </li>
        <li>
          <strong>Global Reach:</strong> We create websites that align with international design and development standards, making your brand accessible to a global audience. 
        </li>
      </ul>
      <p style={{paddingLeft:'3.5%'}}>Elevate your online presence with a high-quality website that adheres to international standards. Contact us today to discuss your web development and design needs and how we can create a website that stands out in the digital landscape. </p>
      </div>
    </div>
  )
}

export default WebsiteDevelopment
