import React from 'react'
import './Loan.css';

function Loan() {
  return (
    <div>
      <div className='loan-heading'>LOAN API</div>
      <p className='loan-para'>With our API, extend lending to every customer in real-time and offer faster transaction processing</p>
      <img src='https://img.freepik.com/premium-vector/applying-loan-bank_701961-7583.jpg'className="imagcustomer"alt='customerimages'></img>
      <div className="feature-table">
  <div className="feature-box">
    <div className="box">
    <i className="fas fa-hand-holding-usd"></i>
      <p>Create Loan</p>
    </div>
  </div>

  <div className="feature-box">
    <div className="box">
    <i className="fas fa-file-alt"></i>
      <p>Get Loan Details</p>
    </div>
  </div>

  <div className="feature-box">
    <div className="box">
    <i className="fas fa-calculator"></i>
      <p>Check Loan Eligibility</p>
    </div>
  </div>
  <div className="feature-box">
    <div className="box">
    <i className="fas fa-clock"></i>

      <p>24/7 Availability</p>
    </div>
  </div>

  
      </div>
      </div>
  )
}

export default Loan
