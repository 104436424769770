import React from 'react'
import { Link } from 'react-router-dom';
import './AITransformedCustomerjourney.css';

function AITransformerCustomerJourney() {
  return (
    <div>
        <div>
             <div className='aijourney'>AI Transformed Customer Journey</div> 

             <p className='po' style={{paddingLeft:'13.4%',fontSize:'19px'}}>Platform can transform the complete customer journey with your financial enterprise</p>
        
             <div className="containerwa">
  <div className="row">
    <div className="col-md-4">
      <div className="column-box">
        <ul className="list-unstyled">
          <li className="columna">
            <i className="fas fa-video"></i>
            <a href="/srevice/BankingPlatform/CustomerOnboarding"><b>CUSTOMER ONBOARDING</b></a>
            <ul className="list-unstyled numbered-list">
              <li>video KYC</li>
              <li>Omnichannel approach</li>
              <li>  .</li>
              <li>.</li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
    <div className="col-md-4">
      <div className="column-box">
        <ul className="list-unstyled">
          <li className="columna">
            <i className="fas fa-cog"></i>
            <a href="/service/BankingPlatform/CustomerService"><b>CUSTOMER SERVICES</b></a>
            <ul className="list-unstyled numbered-list">
              <li>OCR Extraction</li>
              <li>Virtual Chatbot</li>
              <li>Intuitive user experience</li>
              <li>Smart notification system</li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
    <div className="col-md-4">
      <div className="column-box">
        <ul className="list-unstyled">
          <li className="columna">
            <i className="fas fa-heart"></i>
            <a href="/service/BankingPlatform/CustomerNurturing"><b>CUSTOMER NURTURING</b></a>
            <ul className="list-unstyled numbered-list">
              <li>Personalized Offerings</li>
              <li>Smart Analytics</li>
              <li>Customizable rules & search engine</li>
              <li>Role-based Screen access</li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>

<p className='pl' style={{paddingLeft:'13%'}}>For regions with slow digital adoption, This Platform can offer capabilities to partially automate processes, thereby swiftly completing tasks that require a human touch.</p>


     
    </div>
   
    </div>
  )
}

export default AITransformerCustomerJourney
