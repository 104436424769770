import "./template.css";

import { Link } from "react-router-dom";
import ReadModal2 from "./readMore.js";
import React, { useState, useRef, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CarouselSlider from "./Carousel.js";
import CaseStudyCard from "./CaseStudyCard.js";

import Accordion from "./Accordion";
import "./Accordion.css";


import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

function GenAI() {
  const [activeModal, setActiveModal] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);
  const sliderRef = useRef(null);
  const modalRef = useRef(null);
  const [selectedSlide, setSelectedSlide] = useState(null);
  const settings = {
    // dots: true,
    // infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },

      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },

      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 3000,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const slideData = [
    {
      id: 1,
      heading: "Innovative Applications",
      text: " We are at the forefront of applying Generative AI in innovative ways across various sectors.  ",
      buttonLabel: "Read More",
      modalContent: {
        // modalHeading: 'Modal Heading 1',
        modalText:
          "CloudCipher seamlessly integrates with existing workflows and software ecosystems, minimizing disruption and streamlining the adoption process. Whether migrating from another platform or implementing new security measures, CloudCipher ensures a smooth transition with minimal downtime.",
      },
    },
    {
      id: 2,
      heading: "Customization and Flexibility",
      text: " Our solutions are highly customizable to meet the unique needs of each industry. ",
      buttonLabel: "Read More",
      modalContent: {
        modalHeading: "Modal Heading 2",
        modalText: "Modal Text 2",
      },
    },

    {
      id: 3,
      heading: "Expert Team",
      text: " Our team comprises AI specialists who are skilled in the latest advancements in Generative AI technology.  ",
      buttonLabel: "Read More",
      modalContent: {
        modalHeading: "Modal Heading 3",
        modalText: "Modal Text 3",
      },
    },

    {
      id: 4,
      heading: "Ethical AI Practices",
      text: " We commit to the ethical and responsible use of AI in all our solutions.  ",
      buttonLabel: "Read More",
      modalContent: {
        modalHeading: "Modal Heading 4",
        modalText: "Modal Text 4",
      },
    },

    {
      id: 5,
      heading: "Integrated Solutions",
      text: "Our Generative AI services are designed to integrate seamlessly with existing business systems and processes. ",
      buttonLabel: "Read More",
      modalContent: {
        modalHeading: "Modal Heading 5",
        modalText: "Modal Text 5",
      },
    },

    // {
    //   id: 6,
    //   heading: 'Sixth Slide',
    //   text: 'A different text for the second slide.',
    //   buttonLabel: 'Read More',
    //   modalContent: {
    //     modalHeading: 'Modal Heading 6',
    //     modalText: 'Modal Text 6',
    //   },
    // },

    // {
    //   id: 7,
    //   heading: 'Seventh Slide',
    //   text: 'A different text for the second slide.',
    //   buttonLabel: 'Read More',
    //   modalContent: {
    //     modalHeading: 'Modal Heading 7',
    //     modalText: 'Modal Text 7',
    //   },
    // },

    // {
    //   id: 8,
    //   heading: 'Eighth Slide',
    //   text: 'A different text for the second slide.',
    //   buttonLabel: 'Read More',
    //   modalContent: {
    //     modalHeading: 'Modal Heading 8',
    //     modalText: 'Modal Text 8',
    //   },
    // },
    // ... (more slides)
  ];



  let items = [
    {
      title: "Q: How can Generative AI benefit my business?",
      content:
        "A: Smart AI can make things more creative, write stuff automatically, give customers special experiences, & understand data really well. ",
    },
    {
      title: "Q: Is Generative AI applicable across different industries? ",
      content:
        "A: Yes, our smart AI can work for lots of different industries like online selling, watching things, & making content. ",
    },
    {
      title: "Q: How do you ensure the ethical use of Generative AI?",
      content:
        "A: We follow strict rules to make sure our AI is fair, responsible, & good for people and society. ",
    },
  
  ];
 






  const openModal = (slide) => {
    setSelectedSlide(slide);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  // Handle clicks outside the modal to close it
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        closeModal();
      }
    };

    window.addEventListener("mousedown", handleOutsideClick);

    return () => {
      window.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const modalContent = (
    <>
      <p className="modal-np">The Purpose Behind Our Generative AI Services </p>

     At Digitec Hub, We use smart AI to change things in different areas like our SmartVision platform & helping with online E-commerce selling. Our Technology solutions make your business more creative, efficient, & better at solving problems.

      <hr />
      <br /> <strong>Cross-Domain Applications</strong>
      GenAI might aim to create AI technologies that can seamlessly integrate
      and adapt to various domains, such as healthcare, finance, education,
      transportation, and entertainment, unlocking new possibilities for
      innovation and societal impact.
      <br />
      <br />
      <strong> Collaborative Intelligence</strong>
      GenAI could foster the collaboration between humans and AI systems,
      leveraging the strengths of both to solve complex problems, enhance
      creativity, and drive scientific and technological advancements
      <br />
      <br />
      <strong>Sustainability and Global Challenges</strong> The purpose of GenAI
      might include addressing global challenges such as climate change,
      healthcare disparities, poverty, and cybersecurity by leveraging AI
      technologies to develop innovative solutions and inform evidence-based
      decision-making.
      <br />
      <br />
      <strong>Ethical and Responsible AI</strong>
      GenAI could prioritize the development of AI systems that adhere to
      ethical principles, promote fairness, transparency, and accountability,
      and mitigate potential risks and biases associated with AI technologies.
      {/* Your dynamic content here */}
      {/* You can pass any JSX content you want */}
    </>
  );

  return (
    <div>
      <div id="maindivs">
        <div id="carouselExampleCaptions" className="carousel slide">
          <div className="carousel-inner">
            <div className="carousel-item active">
              <img
                src="/Images/genaimainbg.png"
                className="d-block w-100"
                style={{ height: "auto",opacity:"0.4" }}
                alt="slider first"
              />
              <div className="carousel-caption d-md-block">
                <div className="component-1 text-dark">Generative AI Services </div>
                <div
                  className="component-paras"
                  style={{
                    color: "black",
                    marginTop: "-20px",
                    fontStyle: "italic",
                    fontWeight:"700"
                  }}
                >
                  We're Allowing Artificial Intelligence (AI) to be Creative – Changing industries using smart AI ( Generative AI) "
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container-np2">
          {/* <!-- section2 title --> */}
          <div className="cont2-titles">
            {/* <div class="cont2-titletext">What is Generative AI Services </div> */}
          </div>

          {/* <!-- section part --> */}
          <div class="container21">
            {/* <!-- left part --> */}

            <div className="cont2-left cont2-text">
              <div
                className="left-text"
                style={{
                  // fontFamily: "arial",
              
                  fontFamily: "system-ui",
                }}
              >
                Generative AI services at Digitec Hub involve leveraging artificial intelligence to generate new & unique data, ideas, solutions, & content. Our services extend across various domains, from enhancing visual surveillance capabilities with SmartVision to powering e-commerce industries with AI-driven chatbots. We aim to facilitate the adoption of AI in a way that is both transformative & seamlessly integrated with your business processes.

              </div>
            </div>

            {/* right part */}
            <div className="cont2-right cont2-text">
              <div className="right-text">
                <img
                  class="content2img"
                  src="/Images/Genartificial-intelligence.gif"
                  alt="Description of the image"
                />
              </div>
            </div>
          </div>
          <div class="readmore">
            <ReadModal2 buttonText="Read More" modalContent={modalContent} />
          </div>
        </div>

        {/* section3 */}
        <section class="section3">
          <div class="cont2-title title ">Our Features</div>

          <div class="section3partsss" >
            <div class="content-np item31">
              <div>
                <strong
                  style={{
                    paddingLeft: "15px",
                  }}
                >
                  {" "}
                  SmartVision AI Integration
                </strong>
                <p class="aligned-paragraph item3text">
                Smart Generative AI in our SmartVision platform to do advanced surveillance & analyze data.

                </p>
              </div>
            </div>

            {/* <div class="content-np item322">
              <strong
                class="item32-heading item31-conent"
                style={{
                  paddingLeft: "15px",
                }}
              >
              AI-Enabled Chatbots for E-Commerce
              </strong>
              <div class=" item31-conent-img  "></div>
              <p class="aligned-paragraph item3text" style={{ color: "black" }}>
              We make smart chatbots that help customers, make service faster, & give personalized experiences in online shopping.

              </p>
            </div> */}

            <div class="content-np item33" >
              <strong
                class=""
                style={{
                  paddingLeft: "15px",
                }}
              >
                {" "}
                AI-Enabled Chatbots for E-Commerce
              </strong>
              <p class="aligned-paragraph item3text">
              We make smart chatbots that help customers, make service faster, & give personalized experiences in online shopping.
              </p>
            
            </div>

            {/* <div class="content-np item34">
              <strong
                style={{
                  paddingLeft: "15px",
                }}
              >
                Shipping and Logistics Management
              </strong>
              <p class="aligned-paragraph item3text" style={{ color: "black" }}>
                Comprehensive management solutions that streamline shipping
                processes, reduce costs, and improve delivery times.
              </p>
            </div> */}

            <div class="content-np item35">
              <strong
                style={{
                  paddingLeft: "15px",
                }}
              >
                {" "}
                ERP System Implementations
              </strong>
              <p class="aligned-paragraph item3text">
                Expert implementation of ERP solutions from MS Dynamics, SAP,
                NetSuite, and Odoo, tailored to your supply chain needs.
              </p>
            </div>

            <div class="content-np item36">
              <strong
                style={{
                  paddingLeft: "15px",
                }}
              >
                Managed Services
              </strong>
              <p class="aligned-paragraph item3text">
                Ongoing support and management services to ensure your supply
                chain systems operate smoothly and efficiently.
              </p>
            </div>

            <div class="content-np item37">
              <strong
                style={{
                  paddingLeft: "15px",
                }}
              >
                End-to-End Supply Chain Solutions
              </strong>
              <p class="aligned-paragraph item3text" style={{ color: "black" }}>
                From procurement to delivery, providing complete solutions that
                optimize every stage of your supply chain.
              </p>
            </div>
          </div>
          {/* <div class="readmore">
            <button class="read1">Read More</button>
          </div> */}
        </section>

        <div class="section4">
          
          <div className="main-cont">
            <div className="Cardcomp">
              <div className="card-container">
                <div className="row-ca">
                  <div className="col-ca">
                    <div className="card-head text-center">
                      <h2 className="head-t ">USP of GenAI Services</h2>
                     
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card-container">
              <div className="row-ck">
                <div className="col-ck">
                  <div className="card-wrapper">
                    <div className="main-card">
                      <div className="innercon">
                        <div className="card-content1">
                          <h2>
                          Innovative Applications{" "}
                            <FontAwesomeIcon icon={faArrowRight} />
                          </h2>
                          {/* <p>vbhf</p> */}
                        </div>
                        <img src="/Images/implementation.gif" alt="" />
                      </div>
                    </div>
                    <div className="hover-card">
                      <div className="hover-content">
                        <h2>Innovative Applications</h2>
                        <p>
                        We're leading the way in using smart AI in new & cool ways in different areas.{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-ck">
                  <div className="card-wrapper">
                    <div className="main-card">
                      <div className="innercon">
                        <div className="card-content1">
                          <h2>
                          Customization & Flexibility
                            <FontAwesomeIcon icon={faArrowRight} />{" "}
                          </h2>
                          {/* <p>vbhf</p> */}
                        </div>
                        <img src="/Images/direction.gif" alt="" />
                      </div>
                    </div>
                    <div className="hover-card">
                      <div className="hover-content">
                        <h2>Customization & Flexibility</h2>
                        <p>
                          {" "}
                          We can change our solutions a lot to fit what each industry wants. {" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-ck">
                  <div className="card-wrapper">
                    <div className="main-card">
                      <div className="innercon">
                        <div className="card-content1">
                          <h2>
                          Expert Team{" "}
                            <FontAwesomeIcon icon={faArrowRight} />
                          </h2>
                          {/* <p>vbhf</p> */}
                        </div>
                        <img
                        className="img-ico"
                        src="/Images/organigram.gif"
                        alt=""
                      />
                      </div>
                    </div>
                    <div className="hover-card">
                      <div className="hover-content">
                        <h2>Expert Team</h2>
                        <p>
                        Our team has smart people who know a lot about the newest AI technology. {" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-ck">
                  <div className="card-wrapper">
                    <div className="main-card">
                      <div className="innercon">
                        <div className="card-content1">
                          <h2>
                          Ethical AI Practices<FontAwesomeIcon icon={faArrowRight} />
                          </h2>
                          {/* <p>vbhf</p> */}
                        </div>
                        <img src="/Images/Ethical.gif" alt="" />
                      </div>
                    </div>
                    <div className="hover-card">
                      <div className="hover-content">
                        <h2>Ethical AI Practices</h2>
                        <p>
                        We promise to use AI in a fair and responsible way in everything we do.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-ck">
                  <div className="card-wrapper">
                    <div className="main-card">
                      <div className="innercon">
                        <div className="card-content1">
                          <h2>
                          Integrated Solutions
                            <FontAwesomeIcon icon={faArrowRight} />
                          </h2>
                          {/* <p>vbhf</p> */}
                        </div>
                        <img src="/Images/Performance_Optimization.gif" alt="" />
                      </div>
                    </div>
                    <div className="hover-card">
                      <div className="hover-content">
                        <h2> Integrated Solutions</h2>
                        <p>
                        Our smart AI services fit in really well with how your business already works.{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-ck">
                  <div className="card-wrapper">
                    <div className="main-card">
                      <div className="innercon">
                        <div className="card-content1">
                          <h2>
                          Personalization{" "}
                            <FontAwesomeIcon icon={faArrowRight} />
                          </h2>
                          {/* <p>vbhf</p> */}
                        </div>
                        <img src="/Images/Personalization.gif" alt="" />
                      </div>
                    </div>
                    <div className="hover-card">
                      <div className="hover-content">
                        <h2>Personalization</h2>
                        <p>
                          {" "}
                          We can provide individualized content for every user thanks to our generative AI technology, making their experience more relevant and interesting.{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

               
                
              </div>
            </div>
          </div>




          {/* <CarouselSlider/> */}
        </div>
        <div className="section5">
          <h1 className="section5heading">How We are Handling</h1>
          <div className="section5jsxcard">
            <CaseStudyCard
              imageUrl="/Images/casestudyimg1.jpg"
              title="Needs Assessment and Consultation"
              description=" We start by understanding your business needs & the potential applications of Generative AI.
              "
              // linkUrl="/platform/E-cart/casestudy-vitualwallet"
            />
            <CaseStudyCard
              imageUrl="/Images/casestudyimg2.jpg"
              title="Strategic Development and Implementation"
              description=" We make smart plan for using AI & make sure it matches what your business wants to achieve.
              "
              linkUrl=""
            />
            <CaseStudyCard
              imageUrl="/Images/casestudyimg3.jpg"
              title="Continuous Monitoring & Optimization"
              description=" We keep an eye on the AI stuff, solutions & make it work even better.
              "
              linkUrl=""
            />

            <CaseStudyCard
              imageUrl="/Images/casestudyimg4.jpg"
              title="Training & Support"
              description="We teach & help your team use the smart AI solutions well.
              "
              linkUrl=""
            />

            {/* <div class="readmore">
  <button class="read">show More</button>
  </div> */}

            {/* section 6 */}
          </div>
        </div>

        <section className="FAQ">
        <div className="FAQbody">
          <h1 className="section5heading text-dark" >FAQ's for this service</h1>
          <Accordion items={items} />
        </div>
      </section>

      </div>
    </div>
  );
}

export default GenAI;
