import "./template.css";

import { Link } from "react-router-dom";
import ReadModal2 from "./readMore.js";
import React, { useState, useRef, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CarouselSlider from "./Carousel.js";
import CaseStudyCard from "./CaseStudyCard.js";
import Accordion from "./Accordion";
import "./Accordion.css";


import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

function OCRservice() {
  const [activeModal, setActiveModal] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);
  const sliderRef = useRef(null);
  const modalRef = useRef(null);
  const [selectedSlide, setSelectedSlide] = useState(null);
  const settings = {
    // dots: true,
    // infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },

      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },

      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 3000,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const slideData = [
    {
      id: 1,
      heading: "Advanced Technology Integration",
      text: " Leveraging the latest advancements in OCR and object recognition to provide state-of-the-art solutions.  ",
      buttonLabel: "Read More",
      modalContent: {
        // modalHeading: 'Modal Heading 1',
        modalText:
          "CloudCipher seamlessly integrates with existing workflows and software ecosystems, minimizing disruption and streamlining the adoption process. Whether migrating from another platform or implementing new security measures, CloudCipher ensures a smooth transition with minimal downtime.",
      },
    },
    {
      id: 2,
      heading: "Customized Solutions on SmartVision",
      text: "Tailoring our services to maximize the potential of the SmartVision Platform.",
      buttonLabel: "Read More",
      modalContent: {
        modalHeading: "Modal Heading 2",
        modalText: "Modal Text 2",
      },
    },

    {
      id: 3,
      heading: "Expert Team",
      text: "Our team comprises experts in machine learning, data annotation, and visual data analysis. ",
      buttonLabel: "Read More",
      modalContent: {
        modalHeading: "Modal Heading 3",
        modalText: "Modal Text 3",
      },
    },

    {
      id: 4,
      heading: "High Accuracy and Efficiency",
      text: "Focusing on delivering solutions with high accuracy and operational efficiency. ",
      buttonLabel: "Read More",
      modalContent: {
        modalHeading: "Modal Heading 4",
        modalText: "Modal Text 4",
      },
    },

    {
      id: 5,
      heading: "Scalable Solutions",
      text: "Offering scalable solutions that can grow and adapt with your business needs.",
      buttonLabel: "Read More",
      modalContent: {
        modalHeading: "Modal Heading 5",
        modalText: "Modal Text 5",
      },
    },

    // {
    //   id: 6,
    //   heading: 'Sixth Slide',
    //   text: 'A different text for the second slide.',
    //   buttonLabel: 'Read More',
    //   modalContent: {
    //     modalHeading: 'Modal Heading 6',
    //     modalText: 'Modal Text 6',
    //   },
    // },

    // {
    //   id: 7,
    //   heading: 'Seventh Slide',
    //   text: 'A different text for the second slide.',
    //   buttonLabel: 'Read More',
    //   modalContent: {
    //     modalHeading: 'Modal Heading 7',
    //     modalText: 'Modal Text 7',
    //   },
    // },

    // {
    //   id: 8,
    //   heading: 'Eighth Slide',
    //   text: 'A different text for the second slide.',
    //   buttonLabel: 'Read More',
    //   modalContent: {
    //     modalHeading: 'Modal Heading 8',
    //     modalText: 'Modal Text 8',
    //   },
    // },
    // ... (more slides)
  ];

  let items = [
    {
      title: "Q.What is OCR (Optical Character Recognition) technology?",
      content:
        "A. OCR technology is process of converting different types of documents, such as scanned paper documents, PDF files, or images captured by digital camera, into editable & searchable data. ",
    },
    {
      title: "Q.How does OCR differ from object recognition? ",
      content:
        "A.OCR focuses on extracting and recognizing text from images or documents, while object recognition involves identifying & categorizing objects or patterns within images, such as identifying faces, vehicles, or other specific objects. ",
    },
    {
      title: "Q.What are the main applications of OCR technology?",
      content:
        "A.OCR technology is widely used in various applications, including document digitization, automated data entry, invoice processing, text extraction from images for translation or analysis, & accessibility enhancement for visually impaired individuals.  ",
    },
   
    {
      title: "Q. Can OCR accurately recognize text from handwritten documents?",
      content:
        "A. While OCR technology has advanced significantly & can recognize printed text with high accuracy, recognizing handwritten text accurately can be more challenging & may vary depending on the handwriting style & quality of the input. ",
    },
    {
      title: "Q. How does object recognition benefit businesses?",
      content:
        "A.Object recognition technology offers numerous benefits to businesses, including improved security through surveillance systems, enhanced customer experiences through augmented reality applications, optimized inventory management through automated object tracking, & increased efficiency in manufacturing processes through defect detection & quality control.",
    },
    {
      title: "Q.What factors affect the accuracy of OCR & object recognition?",
      content:
        "A.The accuracy of OCR & object recognition depends on various factors, including the quality & resolution of the input images or documents, the complexity of the text or objects being recognized, the training data used to develop recognition models, & the effectiveness of preprocessing techniques applied to enhance image clarity & remove noise. ",
    },
    {
      title: "Q.How can I integrate OCR & object recognition into my existing systems?",
      content:
        "A.Integrating OCR & object recognition capabilities into existing systems can be achieved through API-based solutions or by deploying standalone software applications that communicate with your systems through standard interfaces. ",
    },
   
  ];
 

  const openModal = (slide) => {
    setSelectedSlide(slide);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  // Handle clicks outside the modal to close it
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        closeModal();
      }
    };

    window.addEventListener("mousedown", handleOutsideClick);

    return () => {
      window.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const modalContent = (
    <>
      <p className="modal-np"> The Purpose Behind Our OCR & Object Recognition </p>
  
      At Digitec Hub, we specialize in OCR (Optical Character Recognition) & Object Recognition Development, turning visual data into actionable insights. Empowering businesses with precise OCR & object recognition for smarter decision-making.


      <hr />
      <br /> <strong>Text Extraction</strong>
      OCR technology is primarily used to extract text from scanned documents,
      images, or PDF files. It converts the text within these documents into
      machine-readable format, allowing for indexing, searching, and
      manipulation of the content.
      <br />
      <br />
      <strong> Document Digitization</strong>
      OCR facilitates the digitization of paper-based documents by converting
      them into electronic format. This enables organizations to store, manage,
      and retrieve documents more efficiently, leading to reduced storage costs,
      improved accessibility, and streamlined document workflows.
      <br />
      <br />
      <strong>Data Entry Automation</strong> OCR automates the process of data
      entry by extracting text from scanned forms, invoices, receipts, and other
      types of documents. This eliminates the need for manual data entry,
      reduces errors, and accelerates data processing workflows.
      <br />
      <br />
      {/* Your dynamic content here */}
      {/* You can pass any JSX content you want */}
    </>
  );

  return (
    <div>
      <div id="maindivs">
        <div id="carouselExampleCaptions" className="carousel slide">
          <div className="carousel-inner">
            <div className="carousel-item active">
              <img
                src="/Images/ocrimgbgfinal.png"
                className="d-block w-100"
                style={{height: "550px" ,opacity:"0.4"}}
                alt="slider first"
              />
              <div className="carousel-caption d-md-block">
                <div className="component-1 text-dark"> OCR & Object Recognition </div>
                <div
                  className="component-paras"
                  style={{
                    color: "black",
                    marginTop: "-20px",
                    fontStyle: "italic",
                    fontWeight:"700",
                  }}
                >
                  "To enable seamless extraction of text & identification of objects from images, enhancing efficiency & accuracy in various industries and applications.


"
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container-np2">
          {/* <!-- section2 title --> */}
          <div className="cont2-titles">
            {/* <div class="cont2-titletext">What is OCR & Object Recognition?</div> */}
          </div>

          {/* <!-- section part --> */}
          <div class="container21">
            {/* <!-- left part --> */}

            <div className="cont2-left cont2-text">
              <div
                className="left-text"
                style={{
                  // fontFamily: "arial",
                  fontSize: "22px",
                  fontFamily: "system-ui",
                }}
              >
           At DigiTecHub Inc, we offer highest value of services to our customers. A service is an intangible offering provided by one party to another, typically involving some form of performance, expertise, or assistance, rather than the transfer of a tangible product. Services can range from professional advice & consulting to maintenance, repair, & various other forms of assistance tailored to meet the needs of individuals, businesses, or organizations.


              </div>
            </div>

            {/* right part */}
            <div className="cont2-right cont2-text">
              <div className="right-text">
                <img
                  class="content2img"
                  src="/Images/OCRFA.png"
                  alt="Description of the image"
                />
              </div>
            </div>
          </div>
          <div class="readmore">
            <ReadModal2 buttonText="Read More" modalContent={modalContent} />
          </div>
        </div>

        {/* section3 */}
        <section class="section3">
          <div class="cont2-title title ">Our Features</div>

          <div class="section3parts">
            <div class="content-np item31">
              <div>
                <strong
                  style={{ paddingLeft: '20px' }}
                >
                  {" "}
                  Advanced Optical Character Recognition (OCR) Technology
                </strong>
                <p class="aligned-paragraph item3text">
                We leverage state-of-the-art OCR algorithms to accurately extract text from images, scanned documents, & other media types.


                </p>
              </div>
            </div>

            <div class="content-np item322">
              <strong
                class="item32-heading item31-conent"
                style={{
                  paddingLeft:'15px',color:'black'
                }}
              >
              Customized Recognition Models
              </strong>
              <div class=" item31-conent-img  "></div>
              <p class="aligned-paragraph item3text" style={{color:'black'}}>
              We develop bespoke recognition models tailored to your specific requirements, ensuring optimal performance for diverse use cases.


              </p>
            </div>

            <div class="content-np item33">
              <strong
                class=""
                style={{
                  paddingLeft:'15px', paddingTop:'20px'
                }}
              >
                {" "}
                Multilingual Support
              </strong>
              <p class="aligned-paragraph item3text">
               Our solutions support recognition & extraction of text in multiple languages, facilitating global applicability & accessibility.

              </p>
              {/* <div class="item32-button"><button class="item32-button">Read more</button></div> */}
            </div>

            <div class="content-np item34">
              <strong
                style={{
                  paddingLeft:'15px'
                 }}
              >
              Data Verification & Validation
              </strong>
              <p class="aligned-paragraph item3text" style={{color:'black'}}>
              We incorporate validation mechanisms to ensure the accuracy & reliability of extracted data, minimizing errors and improving data quality
              </p>
            </div>
            <div class="content-np item35">
              <strong
                style={{
                  paddingLeft:'15px'
               }}
              >
                {" "}
                Integration with Existing Systems
              </strong>
              <p class="aligned-paragraph item3text">
              We seamlessly integrate OCR & recognition capabilities into your existing workflows & systems, enhancing automation & efficiency.


              </p>
            </div>

            <div class="content-np item36">
              <strong
                style={{
                  paddingLeft:'15px'
               }}
              >
                Scalable & Flexible Solutions
              </strong>
              <p class="aligned-paragraph item3text">
              Our offerings are designed to scale with your business needs & accommodate evolving requirements, providing flexibility & adaptability.

              </p>
            </div>

            <div class="content-np item37">
              <strong
                style={{
                  paddingLeft:'15px'
               }}
              >
               Cross-platform Compatibility
              </strong>
              <p class="aligned-paragraph item3text" style={{color:'black'}}>
              Our solutions are compatible with various platforms & environments, including desktop, web, mobile, & cloud-based applications.

              </p>
            </div>

           
          </div>
          {/* <div class="readmore">
            <button class="read1">Read More</button>
          </div> */}
        </section>

        <div class="section4">
          
          <div className="main-cont">
            <div className="Cardcomp">
              <div className="card-container">
                <div className="row-ca">
                  <div className="col-ca">
                    <div className="card-head text-center">
                      <h2 className="head-t ">USP of OCR & Object Recognition Services</h2>
                     
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card-container">
              <div className="row-ck">
                <div className="col-ck">
                  <div className="card-wrapper">
                    <div className="main-card">
                      <div className="innercon">
                        <div className="card-content1">
                          <h2>
                           Comprehensive Solution{" "}
                            <FontAwesomeIcon icon={faArrowRight} />
                          </h2>
                          {/* <p>vbhf</p> */}
                        </div>
                        <img src="/Images/Seamless_Multi-Platform_Integration.gif" alt="" />
                      </div>
                    </div>
                    <div className="hover-card">
                      <div className="hover-content">
                        <h2>Comprehensive Solution</h2>
                        <p>
                        We offer a comprehensive suite of OCR & object recognition services, catering to diverse needs across industries & applications.


                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-ck">
                  <div className="card-wrapper">
                    <div className="main-card">
                      <div className="innercon">
                        <div className="card-content1">
                          <h2>
                          High Accuracy & Reliability
                            <FontAwesomeIcon icon={faArrowRight} />{" "}
                          </h2>
                          {/* <p>vbhf</p> */}
                        </div>
                        <img src="/Images/artificial-intelligence-sa.gif" alt="" />
                      </div>
                    </div>
                    <div className="hover-card">
                      <div className="hover-content">
                        <h2>High Accuracy & Reliability</h2>
                        <p>
                          {" "}
                           Our technologies are renowned for their exceptional accuracy & reliability, ensuring precise results even in challenging conditions.
 {" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-ck">
                  <div className="card-wrapper">
                    <div className="main-card">
                      <div className="innercon">
                        <div className="card-content1">
                          <h2>
                          Scalability & Flexibility{" "}
                            <FontAwesomeIcon icon={faArrowRight} />
                          </h2>
                          {/* <p>vbhf</p> */}
                        </div>
                        <img src="/Images/scalability.gif" alt="" />
                      </div>
                    </div>
                    <div className="hover-card">
                      <div className="hover-content">
                        <h2>Scalability & Flexibility</h2>
                        <p>
                        Whether you're a small business or a large enterprise, our services are scalable & flexible, accommodating varying volumes & requirements with ease.

                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-ck">
                  <div className="card-wrapper">
                    <div className="main-card">
                      <div className="innercon">
                        <div className="card-content1">
                          <h2>
                          Advanced Technologies<FontAwesomeIcon icon={faArrowRight} />
                          </h2>
                          {/* <p>vbhf</p> */}
                        </div>
                        <img src="/Images/Engagement_and_Interaction.gif" alt="" />
                      </div>
                    </div>
                    <div className="hover-card">
                      <div className="hover-content">
                        <h2>Advanced Technologies</h2>
                        <p>
                        We stay at the forefront of innovation, continuously enhancing our OCR & object recognition capabilities with the latest advancements in artificial intelligence & computer vision.

                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-ck">
                  <div className="card-wrapper">
                    <div className="main-card">
                      <div className="innercon">
                        <div className="card-content1">
                          <h2>
                          Expert Support & Customization
                            <FontAwesomeIcon icon={faArrowRight} />
                          </h2>
                          {/* <p>vbhf</p> */}
                        </div>
                        <img
                        className="img-ico"
                        src="/Images/organigram.gif"
                        alt=""
                      />
                      </div>
                    </div>
                    <div className="hover-card">
                      <div className="hover-content">
                        <h2> Expert Support & Customization</h2>
                        <p>
                         Our team of experts provides dedicated support & customization options, ensuring that our solutions are tailored to meet your specific needs & deliver maximum value.

                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="col-ck">
                  <div className="card-wrapper">
                    <div className="main-card">
                      <div className="innercon">
                        <div className="card-content1">
                          <h2>
                          Speed{" "}
                            <FontAwesomeIcon icon={faArrowRight} />
                          </h2>
                         
                        </div>
                        <img src="/Images/24_Customer_Support.gif" alt="" />
                      </div>
                    </div>
                    <div className="hover-card">
                      <div className="hover-content">
                        <h2>Speed</h2>
                        <p>
                          {" "}
                          We deliver fast turnaround times, allowing you to digitize large volumes of documents quickly and efficiently, saving you time and resources.

{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

               
                 */}
              </div>
            </div>
          </div>




          {/* <CarouselSlider/> */}
        </div>

        <div className="section5">
          <h1 className="section5heading">How We are Handling</h1>
          <div className="section5jsxcard">
            <CaseStudyCard
              imageUrl="/Images/casestudyimg1.jpg"
              title="Robust Algorithms"
              description=" : We employ state-of-the-art algorithms for both OCR & object recognition, ensuring robust performance across different types of documents & images, as well as varying environmental conditions.
              "
              // linkUrl="/platform/E-cart/casestudy-vitualwallet"
            />
            <CaseStudyCard
              imageUrl="/Images/casestudyimg2.jpg"
              title="Data Augmentation"
              description=" To enhance the robustness & generalization capabilities of our OCR & object recognition models, we employ data augmentation techniques such as rotation, scaling, flipping, & adding noise to diversify the training dataset.

              "
              linkUrl=""
            />
            <CaseStudyCard
              imageUrl="/Images/casestudyimg3.jpg"
              title="Continuous Training and Improvement"
              description=" We continuously train & fine-tune our OCR & object recognition models using feedback data & annotated datasets, incorporating new samples & adapting to evolving patterns & trends to maintain high accuracy & reliability.
              "
              linkUrl=""
            />

            <CaseStudyCard
              imageUrl="/Images/casestudyimg4.jpg"
              title="Scalable Infrastructure"
              description=": Our infrastructure is designed to handle large volumes of data & processing tasks efficiently, leveraging parallel processing, distributed computing, & cloud-based resources to scale dynamically according to demand.
              "
              linkUrl=""
            />

            {/* <div class="readmore">
  <button class="read">show More</button>
  </div> */}

            {/* section 6 */}
          </div>
        </div>

        <section className="FAQ">
        <div className="FAQbody">
          <h1 className="section5heading text-dark" >FAQ's for this service</h1>
          <Accordion items={items} />
        </div>
      </section>


      </div>
    </div>
  );
}

export default OCRservice;
