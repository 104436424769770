import "./template.css";

import { Link } from "react-router-dom";
import ReadModal2 from "./readMore.js";
import React, { useState, useRef, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CarouselSlider from "./Carousel.js";
import CaseStudyCard from "./CaseStudyCard.js";


import Accordion from "./Accordion";
import "./Accordion.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

function SolECommerce() {
  const [activeModal, setActiveModal] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);
  const sliderRef = useRef(null);
  const modalRef = useRef(null);
  const [selectedSlide, setSelectedSlide] = useState(null);
  const settings = {
    // dots: true,
    // infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },

      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },

      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 3000,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const slideData = [
    {
      id: 1,
      heading: "End-to-End Services",
      text: "From conceptualization to implementation and beyond, we offer comprehensive solutions covering all facets of e-commerce.",
      buttonLabel: "Read More",
      modalContent: {
        // modalHeading: 'Modal Heading 1',
        modalText:
          "CloudCipher seamlessly integrates with existing workflows and software ecosystems, minimizing disruption and streamlining the adoption process. Whether migrating from another platform or implementing new security measures, CloudCipher ensures a smooth transition with minimal downtime.",
      },
    },
    {
      id: 2,
      heading: "Cross-Platform Expertise",
      text: "Our applications are designed to provide seamless experiences across all devices, maximizing user engagement.  ",
      buttonLabel: "Read More",
      modalContent: {
        modalHeading: "Modal Heading 2",
        modalText: "Modal Text 2",
      },
    },

    {
      id: 3,
      heading: "Strategic Marketing Edge",
      text: "With our deep understanding of digital and affiliate marketing, we position your brand for maximum visibility and growth. ",
      buttonLabel: "Read More",
      modalContent: {
        modalHeading: "Modal Heading 3",
        modalText: "Modal Text 3",
      },
    },

    {
      id: 4,
      heading: "Customized Solutions",
      text: "We believe in tailor-made solutions that align perfectly with your business goals and market demands. ",
      buttonLabel: "Read More",
      modalContent: {
        modalHeading: "Modal Heading 4",
        modalText: "Modal Text 4",
      },
    },

    {
      id: 5,
      heading: "Reliable Managed Services",
      text: "Our managed services ensure that your e-commerce operations run smoothly, allowing you to focus on strategic business growth. ",
      buttonLabel: "Read More",
      modalContent: {
        modalHeading: "Modal Heading 5",
        modalText: "Modal Text 5",
      },
    },

    // {
    //   id: 6,
    //   heading: 'Sixth Slide',
    //   text: 'A different text for the second slide.',
    //   buttonLabel: 'Read More',
    //   modalContent: {
    //     modalHeading: 'Modal Heading 6',
    //     modalText: 'Modal Text 6',
    //   },
    // },

    // {
    //   id: 7,
    //   heading: 'Seventh Slide',
    //   text: 'A different text for the second slide.',
    //   buttonLabel: 'Read More',
    //   modalContent: {
    //     modalHeading: 'Modal Heading 7',
    //     modalText: 'Modal Text 7',
    //   },
    // },

    // {
    //   id: 8,
    //   heading: 'Eighth Slide',
    //   text: 'A different text for the second slide.',
    //   buttonLabel: 'Read More',
    //   modalContent: {
    //     modalHeading: 'Modal Heading 8',
    //     modalText: 'Modal Text 8',
    //   },
    // },
    // ... (more slides)
  ];


  let items = [
    {
      title: "Q: How do your e-commerce solutions stand out in the competitive market?",
      content:
        "A: Our holistic approach, combining cutting-edge technology with strategic marketing and comprehensive managed services, ensures our clients not only launch but also grow and sustain their online presence effectively.  ",
    },
    {
      title: "Q: Can you tailor e-commerce solutions to fit niche markets? ",
      content:
        "A: Absolutely. Our expertise allows us to customize solutions to cater to specific market segments, ensuring relevance and effectiveness.  ",
    },
    {
      title: "Q: How do you ensure the success of digital marketing strategies? ",
      content:
        "A: By leveraging data analytics and insights, we continuously optimize our strategies to ensure they are targeted, effective, and deliver measurable results. ",
    },
  
  ];
 

  const openModal = (slide) => {
    setSelectedSlide(slide);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  // Handle clicks outside the modal to close it
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        closeModal();
      }
    };

    window.addEventListener("mousedown", handleOutsideClick);

    return () => {
      window.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const modalContent = (
    <>
      <p className="modal-np">The Purpose Behind Our E Commerce Solutions</p>
      At Digitec Hub, we're dedicated to redefining the e-commerce landscape
      with our end-to-end solutions. From cross-platform application development
      to affiliate marketing and digital strategy implementation, our services
      are designed to empower your business, ensuring it thrives in the
      competitive digital marketplace.
      <hr />
      <br /> <strong>Global Reach</strong>
      E-commerce breaks down geographical barriers, allowing businesses to reach
      customers around the world. This expands market reach and opens up new
      opportunities for growth.
      <br />
      <br />
      <strong>Accessibility</strong>
      E-commerce platforms are accessible 24/7, allowing customers to shop at
      any time that is convenient for them. This flexibility accommodates
      varying schedules and time zones.
      <br />
      <br />
      <strong>Cost-effectiveness</strong>
      For businesses, e-commerce can be a cost-effective alternative to
      traditional brick-and-mortar stores. It reduces overhead costs associated
      with maintaining physical locations and allows for efficient inventory
      management.
      <br />
      <br />
      <strong>Increased Sales Potential</strong>
      E-commerce enables businesses to reach a larger audience and target
      specific customer segments through personalized marketing efforts. This
      can lead to increased sales and revenue.
      {/* Your dynamic content here */}
      {/* You can pass any JSX content you want */}
    </>
  );

  return (
    <div>
      <div id="maindivs">
        <div id="carouselExampleCaptions" className="carousel slide">
          <div className="carousel-inner">
            <div className="carousel-item active">
              <img
                src="/Images/ecommerecefinal.png"
                className="d-block w-100"
                style={{height: "auto",opacity:"0.4" }}
                alt="slider first"
              />
              <div className="carousel-caption d-md-block">
                <div className="component-1 text-dark">E-Commerce Solutions </div>
                <div
                  className="component-paras"
                  style={{
                    color: "black",
                    marginTop: "-20px",
                    fontStyle: "italic",
                    fontWeight:"700"
                  }}
                >
                  "Transforming Digital Commerce – Comprehensive E-commerce
                  Solutions for Tomorrow's Market"
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container-np2">
          {/* <!-- section2 title --> */}
          <div className="cont2-titles">
            {/* <div class="cont2-titletext">What is E Commerce Solutions?</div> */}
          </div>

          {/* <!-- section part --> */}
          <div class="container21">
            {/* <!-- left part --> */}

            <div className="cont2-left cont2-text">
              <div
                className="left-text"
                style={{
                  // fontFamily: "arial",
                
                  fontFamily: "system-ui",
                }}
              >
                Digitec Hub offers a wide range of E-commerce Solutions tailored
                to meet the dynamic needs of the digital commerce industry. Our
                expertise spans across creating robust e-commerce applications,
                executing impactful affiliate and digital marketing strategies,
                and providing managed services to ensure seamless operation.
                Leveraging the latest technologies and marketing insights, we
                help businesses of all sizes to build, grow, and sustain a
                successful online presence.
              </div>
            </div>

            {/* right part */}
            <div className="cont2-right cont2-text">
              <div className="right-text">
                <img
                  class="content2img"
                  src="/Images/application-store.gif"
                  alt="Description of the image"
                />
              </div>
            </div>
          </div>
          <div class="readmore">
            <ReadModal2 buttonText="Read More" modalContent={modalContent} />
          </div>
        </div>

        {/* section3 */}
        <section class="section3">
          <div class="cont2-title title ">Our Features</div>

          <div class="section3parts">
            <div class="content-np item31">
              <div>
                <strong style={{ paddingLeft: "20px" }}>
                  Cross-Platform E-commerce Application Development
                </strong>
                <p class="aligned-paragraph item3text">
                  Developing responsive, user-friendly e-commerce applications
                  accessible across various devices and platforms.
                </p>
              </div>
            </div>

            <div class="content-np item322">
              <strong
                class="item32-heading item31-conent"
                style={{
                  paddingLeft: "15px",
                  color: "black",
                }}
              >
                Affiliate Marketing Solutions
              </strong>
              <div class=" item31-conent-img  "></div>
              <p class="aligned-paragraph item3text" style={{ color: "black" }}>
                Crafting and managing affiliate programs to expand market reach
                and drive sales through strategic partnerships.
              </p>
            </div>

            <div class="content-np item33">
              <strong
                class=""
                style={{
                  paddingLeft: "15px",
                }}
              >
                {" "}
                Analytics and Insight
              </strong>
              <p class="aligned-paragraph item3text">
                Utilizing advanced analytics to gather insights, optimize
                performance, and drive informed decision-making.
              </p>
              {/* <div class="item32-button"><button class="item32-button">Read more</button></div> */}
            </div>

            <div class="content-np item34">
              <strong
                style={{
                  paddingLeft: "15px",
                }}
              >
                Managed Services
              </strong>
              <p class="aligned-paragraph item3text" style={{ color: "black" }}>
                Offering a suite of managed services covering every aspect of
                the e-commerce industry, from inventory management to customer
                support, ensuring smooth operations.
              </p>
            </div>
            <div class="content-np item35">
              <strong
                style={{
                  paddingLeft: "15px",
                }}
              >
                {" "}
                Digital Marketing and Strategy Implementation
              </strong>
              <p class="aligned-paragraph item3text">
                Implementing comprehensive digital marketing strategies,
                including SEO, SEM, content marketing, and social media
                marketing to enhance online visibility and engagement.
              </p>
            </div>

            <div class="content-np item36">
              <strong
                style={{
                  paddingLeft: "15px",
                }}
              >
                Customization and Integration
              </strong>
              <p class="aligned-paragraph item3text">
                Tailoring solutions to fit your specific business model and
                seamlessly integrating with existing systems for enhanced
                functionality.
              </p>
            </div>

            <div class="content-np item37">
              <strong
                style={{
                  paddingLeft: "15px",
                }}
              >
                Customer Relationship Management
              </strong>
              <p class="aligned-paragraph item3text" style={{ color: "black" }}>
                Implementing CRM systems to manage customer interactions, track
                leads, and streamline communication processes, fostering
                stronger customer relationships and improving retention rates.
              </p>
            </div>
          </div>
          {/* <div class="readmore">
            <button class="read1">Read More</button>
          </div> */}
        </section>

        <div class="section4">
          
          <div className="main-cont">
            <div className="Cardcomp">
              <div className="card-container">
                <div className="row-ca">
                  <div className="col-ca">
                    <div className="card-head text-center">
                      <h2 className="head-t ">USP of E-Commerce Solutions</h2>
                     
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card-container">
              <div className="row-ck">
                <div className="col-ck">
                  <div className="card-wrapper">
                    <div className="main-card">
                      <div className="innercon">
                        <div className="card-content1">
                          <h2>
                          End-to-End Services{" "}
                            <FontAwesomeIcon icon={faArrowRight} />
                          </h2>
                          {/* <p>vbhf</p> */}
                        </div>
                        <img src="/Images/route.gif" alt="" />
                      </div>
                    </div>
                    <div className="hover-card">
                      <div className="hover-content">
                        <h2> End-to-End Services </h2>
                        <p>
                        From conceptualization to implementation and beyond, we offer comprehensive solutions covering all facets of e-commerce. {" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-ck">
                  <div className="card-wrapper">
                    <div className="main-card">
                      <div className="innercon">
                        <div className="card-content1">
                          <h2>
                          Cross-Platform Expertise
                            <FontAwesomeIcon icon={faArrowRight} />{" "}
                          </h2>
                          {/* <p>vbhf</p> */}
                        </div>
                        <img src="/Images/Seamless_Multi-Platform_Integration.gif" alt="" />
                      </div>
                    </div>
                    <div className="hover-card">
                      <div className="hover-content">
                        <h2>Cross-Platform Expertise</h2>
                        <p>
                          {" "}
                          Our applications are designed to provide seamless experiences across all devices, maximizing user engagement.{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-ck">
                  <div className="card-wrapper">
                    <div className="main-card">
                      <div className="innercon">
                        <div className="card-content1">
                          <h2>
                          Strategic Marketing Edge{" "}
                            <FontAwesomeIcon icon={faArrowRight} />
                          </h2>
                          {/* <p>vbhf</p> */}
                        </div>
                        <img src="/Images/diagram.gif" alt="" />
                      </div>
                    </div>
                    <div className="hover-card">
                      <div className="hover-content">
                        <h2>Strategic Marketing Edge</h2>
                        <p>
                        With our deep understanding of digital and affiliate marketing, we position your brand for maximum visibility and growth.{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-ck">
                  <div className="card-wrapper">
                    <div className="main-card">
                      <div className="innercon">
                        <div className="card-content1">
                          <h2>
                          Customized Solutions<FontAwesomeIcon icon={faArrowRight} />
                          </h2>
                          {/* <p>vbhf</p> */}
                        </div>
                        <img src="/Images/hand.gif" alt="" />
                      </div>
                    </div>
                    <div className="hover-card">
                      <div className="hover-content">
                        <h2>Customized Solutions</h2>
                        <p>
                        We believe in tailor-made solutions that align perfectly with your business goals and market demands.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-ck">
                  <div className="card-wrapper">
                    <div className="main-card">
                      <div className="innercon">
                        <div className="card-content1">
                          <h2>
                          Reliable Managed Services
                            <FontAwesomeIcon icon={faArrowRight} />
                          </h2>
                          {/* <p>vbhf</p> */}
                        </div>
                        <img src="/Images/social-care.gif" alt="" />
                      </div>
                    </div>
                    <div className="hover-card">
                      <div className="hover-content">
                        <h2>Reliable Managed Services</h2>
                        <p>
                        Our managed services ensure that your e-commerce operations run smoothly, allowing you to focus on strategic business growth.{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-ck">
                  <div className="card-wrapper">
                    <div className="main-card">
                      <div className="innercon">
                        <div className="card-content1">
                          <h2>
                          Integration{" "}
                            <FontAwesomeIcon icon={faArrowRight} />
                          </h2>
                          {/* <p>vbhf</p> */}
                        </div>
                        <img src="/Images/copywriting.gif" alt="" />
                      </div>
                    </div>
                    <div className="hover-card">
                      <div className="hover-content">
                        <h2>Integration</h2>
                        <p>
                          {" "}
                          Our E-commerce solutions can be easily integrated with your existing systems and third-party applications, allowing for seamless operation and enhanced functionality.{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

               
                
              </div>
            </div>
          </div>




          {/* <CarouselSlider/> */}
        </div>

        <div className="section5">
          <h1 className="section5heading">How We are Handling</h1>
          <div className="section5jsxcard">
            <CaseStudyCard
              imageUrl="/Images/casestudyimg1.jpg"
              title="Solution Design and Implementation"
              description="Utilizing the latest technologies, we design and implement robust e-commerce solutions. "
              // linkUrl="/platform/E-cart/casestudy-vitualwallet"
            />
            <CaseStudyCard
              imageUrl="/Images/casestudyimg2.jpg"
              title="Consultation and Strategy Development"
              description="Starting with a deep-dive analysis to understand your business, we develop a tailored e-commerce strategy."
              linkUrl=""
            />
            <CaseStudyCard
              imageUrl="/Images/casestudyimg3.jpg"
              title="Marketing Strategy Execution"
              description="We deploy comprehensive marketing strategies, continuously optimized based on performance data. "
              linkUrl=""
            />

            <CaseStudyCard
              imageUrl="/Images/casestudyimg4.jpg"
              title="Ongoing Support and Optimization"
              description="Providing continuous support and optimization services to ensure peak performance and adaptability to market changes."
              linkUrl=""
            />

            {/* <div class="readmore">
  <button class="read">show More</button>
  </div> */}

            {/* section 6 */}
          </div>
        </div>


        <section className="FAQ">
        <div className="FAQbody">
          <h1 className="section5heading text-dark" >FAQ's for this service</h1>
          <Accordion items={items} />
        </div>
      </section>



      </div>
    </div>
  );
}

export default SolECommerce;
