import React from 'react'

function Clientlist() {
  return (
    <div>
       <div
 
 id="item-8"

 className="col-md-9 float-center pt-3 pb-3 d-flex justify-content-center flex-wrap "

>

 <h1 id="ourclient" className=" pq heading text-center">

   Our Clients

 </h1>

 <div className="w-100 d-flex justify-content-center">

   <div

     id="item-8-box"

     className="container p-0 w-100"

    

   >

     <div className="row no-gutters clients-wrap clearfix wow fadeInUp">

       <div className="col-lg-3 col-md-4 col-xs-6">

         <div className="client-logo">

           <img

             src="/Images/client_list_img/Fujitsu-logo.png"

             className="img-fluid"

             alt=""

           />

         </div>

       </div>



       <div className="col-lg-3 col-md-4 col-xs-6">

         <div  className="client-logo" >

           <img

             src="/Images/client_list_img/persistent logo.jpg"

             className="img-fluid"

             alt=""

             style={{maxHeight:"125%",maxWidth:"120%"}}

           />

         </div>

       </div>



       <div className="col-lg-3 col-md-4 col-xs-6">

         <div className="client-logo">

           <img

             src="/Images/client_list_img/tata.png"

             className="img-fluid"

             alt=""

           />

         </div>

       </div>



       <div className="col-lg-3 col-md-4 col-xs-6">

         <div className="client-logo">

           <img

             src="/Images/client_list_img/newt_global.png"

             className="img-fluid"

             alt=""

           />

         </div>

       </div>
       <div className="col-lg-3 col-md-4 col-xs-6">
 
 <div className="client-logo">

   <img

     src="/Images/client_list_img/Goalogo.jpg"

     className="img-fluid"

     alt=""

   />

 </div>

</div>   


       <div className="col-lg-3 col-md-4 col-xs-6">

         <div className="client-logo">

           <img

             src="/Images/client_list_img/Electrical.png"

             className="img-fluid"

             alt=""

           />

         </div>

       </div>



       <div className="col-lg-3 col-md-4 col-xs-6">

         <div id="ouicksupplierlogo" className="client-logo">

           <img

             src="/Images/client_list_img/QS LOGO.png"

             className="img-fluid"

             alt=""

           />

         </div>

       </div>



       <div className="col-lg-3 col-md-4 col-xs-6">

         <div className="client-logo">

           <img

             src="/Images/client_list_img/Swastik.png"

             className="img-fluid"

             alt=""

           />

         </div>

       </div>
       <div className="col-lg-3 col-md-4 col-xs-6">
 
 <div className="client-logo">

   <img

     src="/Images/client_list_img/alphanso-mango.jpg"

     className="img-fluid"

     alt=""

   />

 </div>

</div>


       <div className="col-lg-3 col-md-4 col-xs-6">

         <div className="client-logo">

           <img

             src="/Images/client_list_img/Tech_Logical.png"

             className="img-fluid"

             alt=""

           />

         </div>

       </div>

       <div className="col-lg-3 col-md-4 col-xs-6">

         <div className="client-logo">

           <img

             src="/Images/client_list_img/Ies.png"

             className="img-fluid"

             alt=""

           />

         </div>

       </div>

       <div className="col-lg-3 col-md-4 col-xs-6">

         <div className="client-logo">

           <img

             src="/Images/client_list_img/AIS.png"

             className="img-fluid"

             alt=""

           />

         </div>

       </div>

       <div className="col-lg-3 col-md-4 col-xs-6">

         <div className="client-logo">

           <img

             src="/Images/client_list_img/Softcon-Logo_small-1.png"

             className="img-fluid"

             alt=""

           />

         </div>

       </div>

     </div>

   </div>
</div>
</div>
    </div>
  )
}

export default Clientlist
