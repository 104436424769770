import ProductModal from "../Layouts/ProductModal";
import "../Layouts/ProductModal.css";


 const Products = () =>{
    return (
        <div style={{paddingTop:'100px'}}><ProductModal /></div>
    )
}

export default Products;