import React from 'react'
import { FaBrain, FaShoppingBag,FaRobot,FaUserShield} from 'react-icons/fa';
function CustomerNurturing() {
  return (
    <div>
      <div className='customerservicing-heading'>CUSTOMER NURTURING</div>
       <img src='https://res.cloudinary.com/autopilothq/image/upload/v1566872510/2019/08/27/255_fjqggu.jpg' className='customerservicingimges' alt='imagecustomerkyc'style={{marginTop:'-233px'}}></img>

       
      <div class="two-column-container1">
  <div class="column2">
  <div class="kyc-item">
  <FaShoppingBag className="kyc-icon" />
  <h3 className='customerkyc-heading'>Personalized Offerings</h3>
  <p className='customerkyc-para'>utilize AI/ML functionality to generate personalized offers or services to cross sell / up-sell</p>
</div>

    <img src='https://www.contentserv.com/hubfs/blog/EN/what-does-a-product-info-manager-do-blog-header-en.png' className='customerkycimg' alt='imagecustomerkyc' />
  </div>

  <div class="column2">
    <div class="kyc-item">
    <FaBrain  className="kyc-icon" />
      <h3 className='customerkyc-heading'>Smart Analytics</h3>
      <p className='customerkyc-para'>Leverage real-time trends & past performances in reducing dropout & improve overall RoI</p>
    </div>
    <img src='https://edutechtalks.com/wp-content/uploads/2021/11/How-the-Smart-Campus-transforms-all-from-pedagogy-thru-administration.png' className='customerkycimg' alt='imagecustomerkyc' />
  </div>
</div>


<div class="two-column-container1">
  <div class="column2">
  <div class="kyc-item">
  <FaRobot className="kyc-icon" />
  <h3 className='customerkyc-heading'>Easy & Configurable</h3>
  <p className='customerkyc-para'>Configure workflows suiting your process requirements with platform that provides high flexibility & productivity</p>
</div>

    <img src='https://media.licdn.com/dms/image/D5612AQFyDNJQnDd8sw/article-cover_image-shrink_720_1280/0/1653482895117?e=2147483647&v=beta&t=bzpobsNMQ2C78ayGt0Y1ehaIKA1CO0bpfRuwx4aRkLA' className='customerkycimg' alt='imagecustomerkyc' />
  </div>

  <div class="column2">
    <div class="kyc-item">
    <FaUserShield  className="kyc-icon" />
      <h3 className='customerkyc-heading'>Role-based Screen Access</h3>
      <p className='customerkyc-para'>Provisions to clearly define roles and access to screens and functionalities with custom-made dashboards </p>
    </div>
    <img src='https://www.bettercloud.com/wp-content/uploads/2021/07/RoleBasedAccessControl_FeatureImage.jpg' className='customerkycimg' alt='imagecustomerkyc' />
  </div>
</div>
    </div>
  )
}

export default CustomerNurturing
