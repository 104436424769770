import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

const DropdownMenu = () => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const handleLinkClick = () => {
    setIsHovered(false);
  };

  return (
    <div
      className="dropdown"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <a className="ok">Platforms</a>
      {isHovered && (
        <div className="dropdown-content">
          <h3 className="allplatforms">
            All Platforms <FontAwesomeIcon icon={faArrowRight} />
          </h3>
          <hr className="linehr" />
          <li className="dard1">
            <Link
              className="link-aj"
              to="/platfom/e-cart"
              onClick={handleLinkClick}
            >
              E-Cart
            </Link>
            {/* <Link className='link-aj' to="/service/digital-marketing" onClick={handleLinkClick}>
              DigitalMarketing
            </Link> */}
            <Link
              className="link-aj"
              to="/platforms/OnlineTutor"
              onClick={handleLinkClick}
            >
              Online Tutor
            </Link>
            <Link
              className="link-aj"
              to="/platform/cloudecipher"
              onClick={handleLinkClick}
            >
              Cloudcipher
            </Link>
            <Link
              className="link-aj"
              to="/platforms/OnlineAnalytics"
              onClick={handleLinkClick}
            >
              Online Analytics
            </Link>
            <Link
              className="link-aj"
              to="/platform/ISPMonitoring"
              onClick={handleLinkClick}
            >
              ISP Monitoring
            </Link>
            <Link
              className="link-aj"
              to="/platforms/Smartvision"
              onClick={handleLinkClick}
            >
              AI Smart Vision
            </Link>
          </li>
        </div>
      )}
    </div>
  );
};

export default DropdownMenu;
