import React, { useState } from "react";
import "./DropdownMenu.css"; // Import the CSS file for styling
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

const Dropdownmenu5 = () => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const handleLinkClick = () => {
    setIsHovered(false);
  };

  return (
    <div
      className="dropdown-5"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <a className="ok-5">Case Studies</a>
      {isHovered && (
        <div className="dropdown-content-5">
          <h3 className="allplatforms-5">
            Our Case Study <FontAwesomeIcon icon={faArrowRight} />
          </h3>

          <hr className="linehr-5" />

          <li className="dard-5">
            <li>
             
              <Link
                className="link-aj-5"
                to="/platform/E-cart/casestudt-bharatmake" onClick={handleLinkClick}
              >
                BharatMake.com
              </Link>
              <Link
                className="link-aj-5"
                to="/platform/E-cart/casestudy-digitalkeystore" onClick={handleLinkClick}
              >
                Digital Key Store
              </Link>
              <Link
                className="link-aj-5"
                to="/platform/E-cart/casestudy-vitualwallet" onClick={handleLinkClick}
              >
                Virtual Wallet
              </Link>
            </li>

            <li>
             
              <Link
                className="link-aj-5"
                to="/platform/cloudecipher/casestudy-Empowering-taxcollection" onClick={handleLinkClick}
              >
                Tax Collection Department
              </Link>
              <Link
                className="link-aj-5"
                to="/plaform/cloudcipher/casestudy-Enhancing-Data-Integrity" onClick={handleLinkClick}
              >
                Integrity in PharmaCo's R&D Division
              </Link>
              <Link
                className="link-aj-5"
                to="/platform/cloudcipher/casestudy-Securing-Operations-Bank" onClick={handleLinkClick}
              >
                Bank Security
              </Link>
            </li>
          </li>
        </div>
      )}
    </div>
  );
};

export default Dropdownmenu5;
