import React from "react";
import "./product_common.css";

export default function Hotel_Management() {
  return (

    
    // new changes in hotel mangament code

    <div>

<div className="OnlineTP-container"> Hotel Management</div>

{/* left right conetent for grid left side image right side content start */}

{/* conent image 1 */}
<div class="OnlineTP-grid-leftright">
        <div class="OnlineTP-image-left">
        <img src="/Images/hotel.jpg" alt="Hotel" />
        </div>
        <div class="OnlineTP-content-right">

        <h4 className="OTPh3">Content</h4>
              <p className="OTPp"> 1.Goal</p>
              <p className="OTPp"> 2.Mobile ordering management</p>
              <p className="OTPp"> 3.Kitchen management</p>
              <p className="OTPp"> 4.Analysis of mobile ordering</p>
              <p className="OTPp"> 5.Sales and stock Tracking with analysis</p>
              <p className="OTPp"> 6.Invoice and back office management</p>
              <p className="OTPp"> 7.Point of sale counter</p>
              <p className="OTPp"> 8.Additional</p>

                  
        </div>
    </div>


{/* conent image 2 */}
<div class="OnlineTP-grid-leftright">
        <div class="OnlineTP-image-left">
        <img src="/Images/goal.jpg"></img>
        </div>
        <div class="OnlineTP-content-right">

        <h4 className="OTPh3">How to achieve Goal?</h4>
              <p className="OTPp"> 1. The only thing that we could do to make an influence to
                     increase productivity during the period of rush time is to
                      shorten the time that is needed to take customer’s order
                     and bring it to the table.</p>
              <p className="OTPp"> 2. Beside the fast and efficient work in the kitchen and bar
                     you need to reduce your waiter’s moving.</p>
              <p className="OTPp"> 3.Also you should avoid that your waiters take 4-5 orders at
                     once.</p>
              
        </div>
    </div>


    
    {/* conent image 3 */}

    <div class="OnlineTP-grid-leftright">
        <div class="OnlineTP-image-left">
        <img src="/Images/waiter.jpg"></img>
        </div>
        <div class="OnlineTP-content-right">

        <h4 className="OTPh3">Mobile Ordering System</h4>
              <p className="OTPp"> Step 1</p>
              
              <h4 className="OTPh3">Set the Printer or Monitor in your Bar or Kitchen</h4>
              <p className="OTPp">Instead of printer, you can also set the monitor or tablet
                  where your staff in the kitchen will see all orders made by
                   your waiters.</p>
                   <p>Step 2</p>

                   <h4 className="OTPh3">Mobile Ordering System for your waiters</h4>
              <p className="OTPp"> Your waiter takes orders from guests near their table, tap it
                  in their mobile device with installed application for mobile
                   ordering & send it immediately to the kitchen / bar where your
                  staff can see it printed or displayed on kitchen’s / bar’s
                 monitor.</p>
              
              
        </div>
    </div>



    {/* conent image 4 */}


    <div class="OnlineTP-grid-leftright">
        <div class="OnlineTP-image-left">
        <img src="/Images/printer.jpg"></img>
        </div>
        <div class="OnlineTP-content-right">
        <p>Step 3</p>
        <h4 className="OTPh3">Orders are printing on bar's / kitchen’s printer</h4>
              <p className="OTPp"> 1.On the spur of the moment the order that was send by
                    waiter’s mobile device is transferred through the wireless
                     network and prints on the printer / monitor in bar or
                     kitchen.</p>
              <p className="OTPp"> 2. The restaurant staff in the kitchen/bar could immediately
                    see the orders and start with its preparation.</p>
                      
        </div>
    </div>

        {/* conent image 5 */}
        <div class="OnlineTP-grid-leftright">
        <div class="OnlineTP-image-left">
        <img src="/Images/time-pri.jpg"></img>
        </div>
        <div class="OnlineTP-content-right">

              <p className="OTPp">Step 4</p>

              <h4 className="OTPh3">Time and Priority</h4>
              
              <p className="OTPp">On the spur of the moment the order that was send by waiter’s mobile device is transferred through the wireless network and prints on the printer / monitor in bar or kitchen.</p>
                          
        </div>
    </div>

            {/* conent image 6 */}
<div class="OnlineTP-grid-leftright">

        <div class="OnlineTP-image-left">
        <img src="/Images/order.jpg"></img>
                </div>
        
                <div class="OnlineTP-content-right">
        
                <p>Step 5</p>

                <h4 className="OTPh3">Order Ready</h4>
                      
                      <p className="OTPp"> After the order is ready, staff from the kitchen inform
                    waiter by sending a notice about prepared order from kitchen
                  monitor to waiter’s mobile device.
                   </p>

                   <p>Step 6</p>

                   <h4 className="OTPh3">Notification</h4>

                   <p> After notification, the waiter gets in his mobile device
                     audible or visual signal about orders that are ready to go
                   to guest’s table.</p>
    
                      
        
                      
                </div>
        
        
            </div>
        





        {/* conent image 7 */}
<div class="OnlineTP-grid-leftright">
        
        <div class="OnlineTP-image-left">
        <img src="/Images/Time-Saver.jpg"></img>
                </div>
        
                <div class="OnlineTP-content-right">
                
             <p className="OTPp">
                In our establishment, we have reduced the time from taking of
                 an order to serving for 50% with the assistance of work with
                the tablets.
                </p>
        
                      
                </div>
        
            </div>





            {/* conent image 8 */}
<div class="OnlineTP-grid-leftright">
        
        <div class="OnlineTP-image-left">
        <img src="/Images/analysis.jpg"></img>
                </div>
        
                <div class="OnlineTP-content-right">
        
              <h4 className="OTPh3">Analysis</h4>
                      <p className="OTPp"> 1.Our analysis is generally serving order without Smartphone
                    or tab takes about 30–40 minutes to serve</p>
                      <p className="OTPp"> 2.In this system it will be around 15–20 minutes</p>
                      <p className="OTPp"> 3.Since order has been printed in kitchen on touch screen
                  display, they gives best performance</p>
                      
        
                      
                </div>
        
            </div>
        





{/* conent image 9 */}
<div class="OnlineTP-grid-leftright">
        
<div class="OnlineTP-image-left">
<img src="/Images/stocks.jpg"></img>
        </div>

        <div class="OnlineTP-content-right">


        <h4 className="OTPh3">Enter goods in stocks</h4>
              <p className="OTPp"> A precondition for efficient stocks management is everyday
                    debiting of received goods from suppliers with the
                      purchase price and correct quantity
                </p>
              
        </div>


        


    </div>





{/* conent image 10 */}
<div class="OnlineTP-grid-leftright">
        
<div class="OnlineTP-image-left">
<img src="/Images/stock_review.jpg"></img>
        </div>

        <div class="OnlineTP-content-right">


        <h4 className="OTPh3">Stock review</h4>
              <p className="OTPp"> Important part of your business operations can be done
                  through quick review of the status of goods with indicated
                   quantities and financial purchase price</p>
              

              
        </div>


        


    </div>





{/* conent image 11 */}
<div class="OnlineTP-grid-leftright">
        
<div class="OnlineTP-image-left">
<img src="/Images/stock_correction.jpg"></img>
        </div>


        <div class="OnlineTP-content-right">


        <h4 className="OTPh3">Make corrections of stocks</h4>
              <p className="OTPp"> When you determine a discrepancy between the physical
                     status of goods and goods managed by POS Sector, it is
                     necessary to make corrections of stocks or inventory of
                      goods. With POS Sector do it easily</p>
              
        </div>


        


    </div>


        
        
        
       {/* conent image 12 */}
<div class="OnlineTP-grid-leftright">
        
        <div class="OnlineTP-image-left">
        <img src="/Images/stock_withdraw.jpg"></img>
                </div>
     
                <div class="OnlineTP-content-right">
        
        
                <h4 className="OTPh3">Set the stockroom from which goods are to be withdrawn</h4>
                      <p className="OTPp"> 1.POS Sector is adapted to work with several different
                      stockrooms</p>
                      <p className="OTPp"> 2. For easier inventory control for each category of items
                     can be defined a different stockroom from which goods will
                       be withdrawn</p>
                     
        
                      
                </div>
        
        
                
        
        
            </div>





            {/* conent image 13 */}
<div class="OnlineTP-grid-leftright">
        
        <div class="OnlineTP-image-left">
        <img src="/Images/correction_unit.jpg"></img>
                </div>
        
   
        
                <div class="OnlineTP-content-right">
        
        
                <h4 className="OTPh3"> Change mistakes made during entering of measurement units</h4>
                      <p className="OTPp">  After reviewing all goods with clearly defined
                       ingredients, change the previously incorrectly entered
                     measurement units for each good individually</p>
                     
        
                      
                </div>
        
        
                
        
        
            </div>
        





{/* conent image 14 */}
<div class="OnlineTP-grid-leftright">
        
<div class="OnlineTP-image-left">
<img src="/Images/supplier.jpg"></img>
        </div>

        <div class="OnlineTP-content-right">


        <h4 className="OTPh3"> Monitor procurement of goods per suppliers</h4>
              <p className="OTPp">Monitor procurement of goods per suppliers, recognize
                       differences in prices and react in time. In this way, you
                      can save a lot of money only on differences in prices</p>
             

              
        </div>


        


    </div>





{/* conent image 15 */}
<div class="OnlineTP-grid-leftright">
        
<div class="OnlineTP-image-left">
<img src="/Images/dashboard.jpg"></img>
        </div>

        <div class="OnlineTP-content-right">


        <h4 className="OTPh3">Dashboard Mobile and Web </h4>
              <p className="OTPp"> Use reports and powerful tool of successful managers</p>
              <p className="OTPp"> 1. Overview and printing of all necessary reports such as
                      end-of-shift report or individual and cumulative reports
                       for certain periods are very articulate and simple for
                       analysis in Point Of Sales Sector</p>
              <p className="OTPp"> 2. Overview and printing of all necessary reports such as
                      end-of-shift report or individual and cumulative reports
                       for certain periods are very articulate and simple for
                      analysis in Point Of Sales Sector</p>
             

              
        </div>


        


    </div>


{/* conent image 16 */}
<div class="OnlineTP-grid-leftright">
        
<div class="OnlineTP-image-left">
<img src="/Images/data.jpg"></img>
        </div>

        <div class="OnlineTP-content-right">


        <h4 className="OTPh3">Date wise report</h4>
              <p className="OTPp"> 1.Date wise report</p>
              <p className="OTPp"> 2.  A report may contain all desired information and it is
                  suitable for fast, comprehensive reporting</p>
              <p className="OTPp"> 3.Reports are printed on A4 or POS printer, and they are
                  adjusted for export as well</p>
             

              
        </div>


        


    </div>


        


        {/* conent image 17*/}
<div class="OnlineTP-grid-leftright">
        
        <div class="OnlineTP-image-left">
        <img src="/Images/flow_chart.jpg"></img>
                </div>
        
        
        
                <div class="OnlineTP-content-right">
        
        
                <h4 className="OTPh3">Work flow charts</h4>
                      <p className="OTPp"> 1. Visualize business operations in specific period of time
                     and compare it with previous period, seven days or one
                       month</p>
                      <p className="OTPp"> 2.  Additional feature is fast review of 5 top selling menu
                     items, operators, consumed goods and taxes</p>
                      
                      
                </div>
        
        
                
        
        
            </div>




            {/* conent image 18 */}
<div class="OnlineTP-grid-leftright">
        <div class="OnlineTP-image-left">
        <img src="/Images/billing.jpg"></img>
        </div>


        <div class="OnlineTP-content-right">

        <h4 className="OTPh3">Point of sales counter - Billing</h4>
              <p className="OTPp"> 1.  Visualize business operations in specific period of time
                      and compare it with previous period, seven days or one
                       month</p>
              <p className="OTPp"> 2.  Additional feature is fast review of 5 top selling menu
                       items, operators, consumed goods and taxes</p>
             
              
        </div>
    </div>




{/* conent image 19 */}
<div class="OnlineTP-grid-leftright">
        <div class="OnlineTP-image-left">
        <img src="/Images/payment.jpg"></img>
        </div>


        <div class="OnlineTP-content-right">

        <h4 className="OTPh3">Payment Methods</h4>
              <p className="OTPp"> 1. Payment Methods</p>
              <p className="OTPp"> 2. Additional payment methods</p>
              <p className="OTPp"> 3.Issuing of invoices for legal entities</p>
              <p className="OTPp"> 4.Discount </p>
              <p className="OTPp"> 5.Delete add items in bill</p>
              <p className="OTPp"> 6.Work by table and bill by table</p>
              <p className="OTPp"> 7.All payment gateways integration would be available</p>
              
        </div>
    </div>



{/* conent image 20 */}
<div class="OnlineTP-grid-leftright">
        <div class="OnlineTP-image-left">
        <img src="/Images/idea.jpg"></img>
        </div>


        <div class="OnlineTP-content-right">

        <h4 className="OTPh3">Remaining customizations and additional ideas most welcome</h4>
              <p className="OTPp"> 1.Also can have Employee payroll module if they are on
                     payroll</p>
              <p className="OTPp"> 2.Asset management and micro tracking</p>
              <p className="OTPp"> 3. Hardware integration like RFIS, GPS or thumb reader
                      whatever possible</p>

                      <p className="OTPp"> 4.Stock management separate login</p>
              <p className="OTPp"> 5.Turn over analysis report</p>
              <p className="OTPp"> 6.  Live update on directors mobile of daily sales, current
                      activities etc</p>
              
        </div>
    </div>


        
        
         
        
        

    
{/* left right conetent for grid left side image right side content end */}


    </div>
  );
}
