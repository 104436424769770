import React from "react";
import "./template.css";
 
 import { Link } from "react-router-dom";
 
import ReadModal1 from "./readMore.js";
import CarouselSlider from "./Carousel.js";
 
export default function DigitalMarketing() {
  // section4 jsx
 
 
  let currentIndex = 0;
  const totalSlides = document.querySelectorAll(".carousel-item").length;
 
  return (
    <div id="maindivs">
      <div id="carouselExampleCaptions" className="carousel slide">
        <div className="carousel-inner">
          <div className="carousel-item active">
            <img
              src="https://www.wallpapertip.com/wmimgs/83-838362_web-developer.jpg"
              className="d-block w-100"
              style={{ height: "700px" }}
              alt="slider first"
            />
            <div className="carousel-caption  d-md-block">
              <div className="component-1">Digital Marketing</div>
              <div
                className="component-paras"
                style={{ color: "white", margin: "50px" }}
              >
                Unlocking Your Business Potential with Digital Marketing
                Services
              </div>
            </div>
          </div>
      </div>
          {/* <div className="carousel-item">
            <img
              src="https://thumbs.dreamstime.com/b/right-left-choosing-direction-right-left-choosing-direction-concept-114395400.jpg"
              className="d-block w-100"
              alt="slider second"
            />
            <div className="carousel-caption  d-md-block">
              <div className="component-1">second moving carousel </div>
              <p
                className="component-paras"
                style={{ color: "white", marginTop: "50px" }}
              >
                Welcome to ExpertIT, where we specialize in creating visually
                stunning and highly functional websites that adhere to
                international standards. Our team of skilled designers and
                developers is committed to delivering websites that not only
                captivate users but also provide seamless user experiences
                across all devices.
              </p>
            </div>
          </div>
          <div className="carousel-item">
            <img
              src="https://thumbs.dreamstime.com/b/businessman-drawing-virtual-screen-css-concept-89391760.jpg"
              className="d-block w-100"
              alt="slider third"
            />
            <div className="carousel-caption  d-md-block">
              <div className="component-1">theird moving carousel</div>
              <p
                className="component-paras"
                style={{ color: "white", marginTop: "50px" }}
              >
                Welcome to ExpertIT, where we specialize in creating visually
                stunning and highly functional websites that adhere to
                international standards. Our team of skilled designers and
                developers is committed to delivering websites that not only
                captivate users but also provide seamless user experiences
                across all devices.
              </p>
            </div>
          </div>
        </div>
  */}
        {/* <button
          className="carousel-control-prev"
          type="button"
          data-bs-target="#carouselExampleCaptions"
          data-bs-slide="prev"
        >
          <span
            className="carousel-control-prev-icon"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Previous</span>
        </button>
        <button
          className="carousel-control-next"
          type="button"
          data-bs-target="#carouselExampleCaptions"
          data-bs-slide="next"
        >
          <span
            className="carousel-control-next-icon"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Next</span>
        </button> */}
      </div>
      {/* <div className="firstComponant">
        <ResponsiveSlider/>
      </div> */}
 
      <div className="container-np2">
        {/* <!-- section2 title --> */}
        <div className="cont2-titles">
          <div class="cont2-titletext">Why Choose Our Digital Marketing?</div>
        </div>
 
        {/* <!-- section part --> */}
        <div class="container21">
          {/* <!-- left part --> */}
 
          <div className="cont2-left cont2-text">
            <div
              className="left-text"
              style={{
                // fontFamily: "arial",
 
                fontFamily: "system-ui",
              }}
            >
              In today's digital age, the path to success for businesses lies in
              effectively leveraging the power of the online world. At ExpertIT,
              we offer comprehensive digital marketing services tailored to
              elevate your brand, expand your reach, and drive tangible results.
              Our expert team is dedicated to creating dynamic strategies that
              resonate with your audience and deliver a strong return on
              investment.
            </div>
          </div>
 
          {/* right part */}
          <div className="cont2-right cont2-text">
            <div className="right-text">
              <img
                class="content2img"
                src="https://www.webconfs.com/wp-content/uploads/2017/12/website-development-process-1024x1024.jpg"
                alt="Description of the image"
              />
            </div>
          </div>
        </div>
        <div class="readmore">
          <ReadModal1 />
        </div>
      </div>
 
      {/* section3 */}
      <section class="section3">
        <div class="cont2-title title ">Our Digital Marketing Services?</div>
 
        <div class="section3parts">
          <div class="content-np item31">
            <div>
              <strong> Market Analysis</strong>
              <p class="aligned-paragraph item3text">
                We conduct in-depth market research to identify opportunities,
                challenges, and competitor insights. This helps us create
                strategies that give you a competitive edge.
              </p>
            </div>
          </div>
 
          <div class="content-np item322">
            <strong class="item32-heading item31-conent">
              Content Strategy
            </strong>
            <div class=" item31-conent-img  "></div>
            <p class="aligned-paragraph item3text">
              Content is king in the digital world. We create a content strategy
              that resonates with your audience, establishing your brand as an
              authority and driving engagement.
            </p>
          </div>
 
          <div class="content-np item33">
            <strong class="item32-heading">Campaign Planning</strong>
            <p class="aligned-paragraph item3text">
              We design result-oriented digital campaigns across various
              channels, such as social media, email marketing, SEO, and PPC,
              ensuring maximum impact and ROI.
            </p>
            {/* <div class="item32-button"><button class="item32-button">Read more</button></div> */}
          </div>
 
          <div class="content-np item34">
            <strong>Analytics Setup </strong>
            <p class="aligned-paragraph item3text">
              We implement advanced analytics tools to track website traffic,
              user behavior, and campaign performance, providing valuable data
              for optimization.
            </p>
          </div>
          <div class="content-np item35">
            <strong>User Behavior Analysis </strong>
            <p class="aligned-paragraph item3text">
              Understanding how users interact with your digital assets helps
              refine user experiences and conversion funnels for higher
              engagement and sales.
            </p>
          </div>
 
          <div class="content-np item36">
            <strong>Multi-channel Support</strong>
            <p class="aligned-paragraph item3text">
              We set up channels for customer support, including chatbots,
              email, and social media, offering seamless assistance and
              enhancing customer satisfaction.
            </p>
          </div>
 
          <div class="content-np item37">
            <strong>Social Media Engagement</strong>
            <p class="aligned-paragraph item3text">
              We manage your social media presence, engaging with customers in
              real-time, addressing queries, and fostering a sense of community
              around your brand.
            </p>
          </div>
        </div>
        <div class="readmore">
          <button class="read1">Read More</button>
        </div>
      </section>
 
      <div class="section4">
   
         
        <CarouselSlider/>
       
      </div>
 
      <div class="section5">
        <div class="section5heading">
          <div class="section5heading1">We Choose</div>
          {/* <h3 class="section5heading2">Our Data Analytics Tales - Learn how we are transforming businesses to make data do more</h3> */}
        </div>
 
        <div class="section5cards">
          <div class="item51 item55">
            <div class="item5content">
              <div class="item51img">Expert Team</div>
 
              <div class="item51text item5text">
                <a href="" class="full-width-link a-np">
                  Our team comprises experienced web designers, developers, and
                  UX/UI experts who excel at translating ideas into exceptional
                  websites
                </a>
              </div>
            </div>
          </div>
 
          <div class="item52 item55">
            <div class="item5content">
              <div class="item52img">Scalability</div>
 
              <div class="item52text item5text">
                <a href="" class="full-width-link a-np">
                  We build websites that are scalable and adaptable to
                  accommodate your business growth and changing requirements.
                </a>
              </div>
            </div>
          </div>
 
          <div class="item53 item55">
            <div class="item5content">
              <div class="item53img">Responsive Design</div>
 
              <div class="item53text item5text">
                <a href="" class="full-width-link a-np">
                  We ensure that your website looks and functions flawlessly on
                  desktops, tablets, and smartphones, providing a consistent
                  experience to users.
                </a>
              </div>
            </div>
          </div>
 
          <div class="item54 item55">
            <div class="item5content">
              <div class="item54img">User-Centric Approach</div>
 
              <div class="item54text item5text">
                <a href="" class="full-width-link a-np">
                  Our design and development process centers around the needs
                  and preferences of your target audience, ensuring a
                  user-focused website.
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div class="readmore">
    <button class="read">show More</button>
    </div> */}


{/* section 6 */}
 
      <section class="section6">
      <div class="section6title">
        <div class="section6title-np section6title-np1">Our Offering</div>
        <div class="section6title-np">
          Discover services and offerings that can unlock new possibilities for
          you
        </div>
      </div>
      {/* <!-- sectcion6 row --> */}
 
      <div class="section6row">
        <div class="section61 section666">
          {/* <!-- section6 row1 --> */}
          <div class="_6row1col1 section666col _6text">
            <div class="_6textheading">
              <h1>Autonomous Data and AI Estate</h1>
            </div>
          </div>
 
          {/* <!-- row1 col2 --> */}
          <div class="_6row1col2 section666col">
            <div class="_6text">
              <ul>
                <li>
                  <a href="" class="a-np"
                    >sdlkfmkInfosys Information Grid Solution Infosys Data
                    Wizard Solution</a
                  >
                </li>
                <li>
                  <a href="" class="a-np"
                    >sdlkfmkInfosys Information Grid Solution Infosys Data
                    Wizard Solution</a
                  >
                </li>
              </ul>
            </div>
          </div>
          {/* <!-- row1 col3 --> */}
          <div class="_6row1col3 section666col">
            <div class="_6text">
              <ul>
                <li>
                  <a href="" class="a-np"
                    >sdlkfmkInfosys Information Grid Solution Infosys Data
                    Wizard Solution</a
                  >
                </li>
                <li>
                  <a href="" class="a-np"
                    >sdlkfmkInfosys Information Grid Solution Infosys Data
                    Wizard Solution</a
                  >
                </li>
              </ul>
            </div>
          </div>
 
          {/* <!-- row1 col4 --> */}
          <div class="_6row1col4 section666col">
            <div class="_6text">
              <ul>
                <li>
                  <a href="" class="a-np"
                    >sdlkfmkInfosys Information Grid Solution Infosys Data
                    Wizard Solution</a
                  >
                </li>
                <li>
                  <a href="" class="a-np"
                    >sdlkfmkInfosys Information Grid Solution Infosys Data
                    Wizard Solution</a
                  >
                </li>
              </ul>
            </div>
          </div>
        </div>
        {/* <!-- section6 row2 --> */}
        <div class="section62 section666">
          {/* <!-- row2 col1 --> */}
          <div class="_6row2col1 section666col">
            <div class="_6textheading"><h1>AI Powered Business</h1></div>
          </div>
          {/* <!-- row2 col2 --> */}
          <div class="_6row2col2 section666col">
            <div class="_6text">
              <ul>
                <li>
                  <a href="" class="a-np"
                    >sdlkfmkInfosys Information Grid Solution Infosys Data
                    Wizard Solution</a
                  >
                </li>
                <li>
                  <a href="" class="a-np"
                    >sdlkfmkInfosys Information Grid Solution Infosys Data
                    Wizard Solution</a
                  >
                </li>
              </ul>
            </div>
          </div>
          {/* <!-- row2 col3 --> */}
          <div class="_6row2col3 section666col">
            <div class="_6text">
              <ul>
                <li>
                  <a href="" class="a-np"
                    >sdlkfmkInfosys Information Grid Solution Infosys Data
                    Wizard Solution</a
                  >
                </li>
                <li>
                  <a href="" class="a-np"
                    >sdlkfmkInfosys Information Grid Solution Infosys Data
                    Wizard Solution</a
                  >
                </li>
              </ul>
            </div>
          </div>
          {/* <!-- row2 col4 --> */}
          <div class="_6row2col4 section666col">
            <div class="_6text">
              <ul>
                <li>
                  <a href="" class="a-np"
                    >sdlkfmkInfosys Information Grid Solution Infosys Data
                    Wizard Solution</a
                  >
                </li>
                <li>
                  <a href="" class="a-np"
                    >sdlkfmkInfosys Information Grid Solution Infosys Data
                    Wizard Solution</a
                  >
                </li>
              </ul>
            </div>
          </div>
        </div>
        {/* <!-- section6 row3 --> */}
        <div class="section63 section666">
          {/* <!-- row3 col1 --> */}
          <div class="_6row3col1 section666col">
            <div class="_6row3col1 section666col">
              <div class="_6textheading"><h1>AI Powered Business</h1></div>
            </div>
          </div>
          {/* <!-- row3 col2 --> */}
          <div class="_6row3col2 section666col">
            <div class="_6text">
              <ul>
                <li>
                  <a href="" class="a-np"
                    >sdlkfmkInfosys Information Grid Solution Infosys Data
                    Wizard Solution</a
                  >
                </li>
                <li>
                  <a href="" class="a-np"
                    >sdlkfmkInfosys Information Grid Solution Infosys Data
                    Wizard Solution</a
                  >
                </li>
              </ul>
            </div>
          </div>
          {/* <!-- row3 col3 --> */}
          <div class="_6row3col3 section666col">
            <div class="_6text">
              <ul>
                <li>
                  <a href="" class="a-np"
                    >sdlkfmkInfosys Information Grid Solution Infosys Data
                    Wizard Solution</a
                  >
                </li>
                <li>
                  <a href=""  class="a-np"
                    >sdlkfmkInfosys Information Grid Solution Infosys Data
                    Wizard Solution</a
                  >
                </li>
              </ul>
            </div>
          </div>
          {/* <!-- row3 col4 --> */}
          <div class="_6row3col4 section666col">
            <div class="_6text">
              <ul>
                <li>
                  <a href="" class="a-np"
                    >sdlkfmkInfosys Information Grid Solution Infosys Data
                    Wizard Solution</a
                  >
                </li>
                <li>
                  <a href="" class="a-np"
                    >sdlkfmkInfosys Information Grid Solution Infosys Data
                    Wizard Solution</a
                  >
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
    </div>
  );
}