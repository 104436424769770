import React from 'react'
import './NeoBankingplatform.css'; // Correct the letter casing in the file name

function NeoBankingPlatform() {
  return (
    <div>
          <img src="https://i.pinimg.com/originals/9f/69/d7/9f69d74b362720ecb5716b82533169ad.jpg" className="d-block w-100" alt="slider second" style={{height:'570px'}}/>
              <div className="carousel-caption d-none d-md-block">
              <h1 className='neoheading' style={{ color: 'black', marginTop: '-70px', paddingBottom: '30px' }}>NEO BANKING PLATFORM</h1>

      <p className='neopara' style={{color:'black',fontFamily: 'myriad-pro, Arial',fontSize:'16px',paddingBottom:'10px'}}>Platform's a configurable, adaptable & scalable platform that sits on an cooperative bank's core platform and can be easily integrated with multiple templates and APIs KEY .</p>
      <div className="containeree">
  <div className="columnsn">
    <div className="columnq">
      <h3>CORE BANKING SYSTEM</h3>
      <p>Integrate existing core banking system to build products of choice.</p>
    </div>
    <div className="columnq">
      <h3>NEO BANK PLATFORM</h3>
      <p>Platform is built to enable agile products using templates.</p>
    </div>
    <div className="columnq">
      <h3>TEMPLATES</h3>
      <p>Multiple templates are created which will be available to cherry pick to form business products.</p>
    </div>
    <div className="columnq">
      <h3>PRODUCT CONFIGURATION</h3>
      <p>Ease to customize products of choice with short turnaround time.</p>
    </div>
  </div> 
</div>
</div>
    </div>
  )
}

export default NeoBankingPlatform
