import React from "react";

import "./product_common.css";

 

export default function CaneERPandAutomation() {

  return (

 

// new chnages in code of  cane erp

         

    <div>

 

<div className="OnlineTP-container">Cane ERP & Automation</div>

 

 

{/* left right conetent for grid left side image right side content start */}

 

{/* conent image 1 */}

<div class="OnlineTP-grid-leftright">

        <div class="OnlineTP-image-left">

        <img src="/Images/farmer.jpg"></img>

        </div>

 

 

        <div class="OnlineTP-content-right">

 

        <h4 className="OTPh3">Sugar Losses prevention and controls</h4>

             

              <p className="OTPp"> 1.Registration of cane farms by forming cane agreement with Sugar factory.</p>

              <p className="OTPp"> 2.Allocation of ticket to farmer when cane matures.</p>

              <p className="OTPp"> 3.Harvesting the cane and supply to the factory. </p>

                           

        </div>

    </div>

 

 

{/* left right conetent for grid left side image right side content end */}

 

 

 

{/* what we did start */}

 

<div className="OTP">What we did?</div>

    <section className="OTP1">

      <div className="container OTP1_container">

        <div className="OTP2">

 

          <p className="OTP2para">

          In this approach we have proposed GPS based real time system,

            after following this sugar recovery increases from 0.8 to 1.8 %

            as per the research paper and proven to be correct around 1.1 to 1.2

            this is large impact we were be creating in sugar sector at that time.

          </p>

         

 

        </div>

      </div>

    </section>

 

 

    {/* what we did end */}

 

 

    {/* We have developed real time system where we are able to prevent the following start */}

 

   

 

    {/* card effect */}

    <div className="OTP">We have developed real time system where we are able to prevent the following:</div>

 

<div class="OnlineTP-card-grid">

  {/* <!-- Card 1 --> */}

 

  <div class="OnlineTP-card ">

    <div class="OnlineTP-card-content">

    <h4 className="OTPh3">AUTOMATED HARVESTING</h4>

      <p class="OnlineTP-card-description">

      Prevention of Early date Entry of cane plantation - which was

             causing the less mature cane delivery to factory

           causing 1.8 to 2.4 % of less sugar than mature cane.

            We draw geo-zones over the farm using mobile application

            that will tag the registration

      </p>

     

    </div>

  </div>

 

 

{/* <!-- Card 2--> */}

 

  <div class="OnlineTP-card ">

    <div class="OnlineTP-card-content">

     

    <h4 className="OTPh3">AUTOMATED HARVESTING</h4>

     

      <p class="OnlineTP-card-description">

      Prevents political intervention in harvesting process and implemented more disciplined harvesting plans for factory.

      </p>

     

    </div>

  </div>

 

 

 

  {/* <!-- Card 3 --> */}

 

  <div class="OnlineTP-card ">

    <div class="OnlineTP-card-content">

    <h4 className="OTPh3">Cane yard level validation for harvester/contractors miss-lead</h4>

      <p class="OnlineTP-card-description">

      We create one geo-zone mapping on map, where only valid vehicle will register for the cane yard queue in dispatch, if contractor mislead the factory and takes the farm which factory permits, server identifies the contractors vehicle location & validates for valid allocated field only, of allocated field is not as same as harvested contractor will face a large fine hence we disciplined contractor with help of system.

      </p>

     

    </div>

  </div>

 

 

{/* <!-- Card 4--> */}

 

  <div class="OnlineTP-card ">

    <div class="OnlineTP-card-content">

     

    <h4 className="OTPh3">Post Harvesting Losses</h4>

     

      <p class="OnlineTP-card-description">

      Here we design and schedule the supply of cane in such a way that any vehicle carrying harvested cane will get consumed in less than 50 hours. This will helps to save the post harvesting losses using GPS & cane yard balance calculations.

      </p>

     

    </div>

  </div>

 

 

 

</div>

 

{/* card effect end */}

 

 {/* We have developed real time system where we are able to prevent the following end */}

 

 

{/* what we found start */}

 

 

<div className="OTP">What we found?</div>

    <section className="OTP1">

      <div className="container OTP1_container">

        <div className="OTP2">

 

          <p className="OTP2para">

          This product was our first experience as an Entrepreneur in 2013,

             We later came to observe that Maharashtra sugar sector got heavily

            influenced dependency on politicians. Politicians have only major

            one way to connect with the farmers and that is sugar industry hence

            they are influencing the protocols and policies of Industry for

            political benefit, hence system design was correct but we learn

            that choice of domain is also important while deciding to contribute

            in any socially influenced system. Hence in 2017 years we decided to

            shut down the projects and services on this project.

          </p>

         

 

        </div>

      </div>

    </section>

 

{/* what we found end */}

 

 

 

 

 

 

    </div>

  );

}