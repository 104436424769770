import "./template.css";
 
 import { Link } from "react-router-dom";
import ReadModal2 from "./readMore.js";
 import React, { useState, useRef, useEffect} from 'react';
 import Slider from 'react-slick';
 import 'slick-carousel/slick/slick.css';
 import 'slick-carousel/slick/slick-theme.css';
import CarouselSlider from "./Carousel.js";
import CaseStudyCard from "./CaseStudyCard.js";

function IndustrialAutomationService() {



    const [activeModal, setActiveModal] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);
  const sliderRef = useRef(null);
  const modalRef = useRef(null);
  const [selectedSlide, setSelectedSlide] = useState(null);
  const settings = {
    // dots: true,
    // infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },

      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },

      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 3000,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
     
    ],
  };
  const slideData = [
    {
      id: 1,
      heading: 'Cutting-Edge Technology',
      text: 'Utilizing the latest advancements in digital technology for industrial automation.  ',
      buttonLabel: 'Read More',
      modalContent: {
        // modalHeading: 'Modal Heading 1',
        modalText: 'CloudCipher seamlessly integrates with existing workflows and software ecosystems, minimizing disruption and streamlining the adoption process. Whether migrating from another platform or implementing new security measures, CloudCipher ensures a smooth transition with minimal downtime.',
      },
    },
    {
      id: 2,
      heading: 'Customized Solutions',
      text: 'Offering solutions that are specifically tailored to meet your industrial needs. ',
      buttonLabel: 'Read More',
      modalContent: {
        modalHeading: 'Modal Heading 2',
        modalText: 'Modal Text 2',
      },
    },
 
    {
      id: 3,
      heading: 'Enhanced Efficiency and Productivity',
      text: 'Focusing on solutions that significantly enhance operational efficiency and productivity. ',
      buttonLabel: 'Read More',
      modalContent: {
        modalHeading: 'Modal Heading 3',
        modalText: 'Modal Text 3',
      },
    },
 
    {
      id: 4,
      heading: 'Expert Team',
      text: 'Our team comprises experts in digital industrial technologies and automation. ',
      buttonLabel: 'Read More',
      modalContent: {
        modalHeading: 'Modal Heading 4',
        modalText: 'Modal Text 4',
      },
    },
 
    {
      id: 5,
      heading: 'Robust Security Measures',
      text: 'Prioritizing the security of your industrial systems in our digital solutions.',
      buttonLabel: 'Read More',
      modalContent: {
        modalHeading: 'Modal Heading 5',
        modalText: 'Modal Text 5',
      },
    },
 
    // {
    //   id: 6,
    //   heading: 'Sixth Slide',
    //   text: 'A different text for the second slide.',
    //   buttonLabel: 'Read More',
    //   modalContent: {
    //     modalHeading: 'Modal Heading 6',
    //     modalText: 'Modal Text 6',
    //   },
    // },
 
    // {
    //   id: 7,
    //   heading: 'Seventh Slide',
    //   text: 'A different text for the second slide.',
    //   buttonLabel: 'Read More',
    //   modalContent: {
    //     modalHeading: 'Modal Heading 7',
    //     modalText: 'Modal Text 7',
    //   },
    // },
 
    // {
    //   id: 8,
    //   heading: 'Eighth Slide',
    //   text: 'A different text for the second slide.',
    //   buttonLabel: 'Read More',
    //   modalContent: {
    //     modalHeading: 'Modal Heading 8',
    //     modalText: 'Modal Text 8',
    //   },
    // },
    // ... (more slides)
  ];
 
 
 
  const openModal = (slide) => {
    setSelectedSlide(slide);
    setModalOpen(true);
  };
 
  const closeModal = () => {
    setModalOpen(false);
  };
 
  // Handle clicks outside the modal to close it
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        closeModal();
      }
    };
 
    window.addEventListener('mousedown', handleOutsideClick);
 
    return () => {
      window.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);



  const modalContent = (
    <>
      <p className="modal-np" >The Purpose Behind Our Industrial Automation Services</p>
      At Digitec Hub, our Industry 4.0 and Industrial Automation services are centered around transforming traditional industrial practices. We leverage the power of digital technology to create smart, interconnected systems that elevate efficiency, productivity, and innovation in the industrial sector.
          <hr/>
           <br/> <strong>Improved Quality and Consistency</strong> 
 
           Digitization and automation enable industries to achieve higher levels of quality and consistency in their products and processes. Advanced sensors, data analytics, and machine learning algorithms help identify defects, deviations, and anomalies in real-time, allowing for proactive quality control and continuous improvement.
         <br/><br/>
        <strong> Cost Reduction</strong>
        Automation helps industries reduce operational costs by optimizing resource usage, minimizing waste, and improving energy efficiency. By automating routine tasks and processes, companies can lower labor costs, decrease downtime, and achieve economies of scale, resulting in overall cost savings and improved profitability.
 
          <br/><br/><strong>Faster Time-to-Market</strong>
          Digitizing and automating operations enable industries to accelerate product development cycles and bring new products to market faster. Automation speeds up production processes, shortens lead times, and enhances agility, allowing companies to respond quickly to changing market demands and gain a competitive edge.
 
 
          <br/><br/><strong>Enhanced Efficiency and Productivity</strong>
          By digitizing and automating operations, industries aim to improve efficiency and productivity by streamlining processes, reducing manual labor, and optimizing resource utilization. 
  
 
      {/* Your dynamic content here */}
      {/* You can pass any JSX content you want */}
    </>
 );


  return (

    <div>

<div id="maindivs">
        <div id="carouselExampleCaptions" className="carousel slide">
          <div className="carousel-inner">
            <div className="carousel-item active">
              <img
                src="/Images/firstcomponant.jpg"
                className="d-block w-100"
                style={{ height: "700px" }}
                alt="slider first"
              />
            <div className="carousel-caption d-md-block">
  <div className="component-1">Industrial Automation Services </div>
  <div className="component-paras" style={{ color: "white", marginLeft: '20px', fontStyle: 'italic' }}>
    "Revolutionizing Industry Standards – Embracing the Fourth Industrial Revolution"
    
  </div>
</div>

            </div>
    
        </div>
        </div>
        
    
        <div className="container-np2">
          {/* <!-- section2 title --> */}
          <div className="cont2-titles">
            <div class="cont2-titletext">What is Industrial Automation Services?</div>
            
          </div>
    
          {/* <!-- section part --> */}
          <div class="container21">
            {/* <!-- left part --> */}
    
            <div className="cont2-left cont2-text">
              <div
                className="left-text"
                style={{
                  // fontFamily: "arial",
                fontSize:"25px",
                  fontFamily: "system-ui",
                }}
              >
           Industry 4.0 and Industrial Automation refer to the integration of modern digital technologies into industrial processes. This encompasses advanced data analytics, robotics, artificial intelligence, the Internet of Things (IoT), and more to create highly intelligent and autonomous systems in manufacturing and production. Our services aim to digitize and automate operations, ensuring industries are future-ready.  
              </div>
            </div>
    
            {/* right part */}
            <div className="cont2-right cont2-text">
              <div className="right-text">
                <img
                  class="content2img"
                  src="/Images/industrial-automation.png"
                  alt="Description of the image"
                />
              </div>
            </div>
          </div>
          <div class="readmore">
            <ReadModal2 buttonText="Read More" modalContent={modalContent} />

            
          </div>
        </div>
    
        {/* section3 */}
        <section class="section3">
          <div class="cont2-title title ">Our Features</div>
    
          <div class="section3parts">
            <div class="content-np item31">
              <div>
                <strong style={{ paddingLeft: '20px' }}
                > Smart Factory Solutions
              </strong>
                <p class="aligned-paragraph item3text">
                Implementing IoT and AI-driven systems for smart factory operations.  
                </p>
              </div>
            </div>
    
            <div class="content-np item322">
              <strong class="item32-heading item31-conent"
              style={{
                paddingLeft:'15px',color:'black'
              }} >
              Robotics and Automation
    
              </strong>
              <div class=" item31-conent-img  "></div>
              <p class="aligned-paragraph item3text" style={{color:'black'}}>
              Integrating advanced robotics to streamline production processes.  
              </p>
            </div>
    
            <div class="content-np item33">
              <strong class="item32-headings"
              style={{
                paddingLeft:'15px'
              }} 
              >
                 Data Analytics and AI
    </strong>
              <p class="aligned-paragraph item3text">
              Leveraging big data analytics and AI for predictive maintenance and optimization. 
              </p>
              {/* <div class="item32-button"><button class="item32-button">Read more</button></div> */}
            </div>
    
            <div class="content-np item34" >
              <strong 
              style={{
                paddingLeft:'15px'
               }}
              >
                IoT Integration
     </strong>
              <p class="aligned-paragraph item3text" style={{color:'black'}}>
              Connecting various industrial devices and systems for enhanced data communication and analysis. 
              </p>
            </div>
            <div class="content-np item35">
              <strong 
              style={{
                paddingLeft:'15px'
             }}
                >
                   Customized Automation Solutions
     </strong>
              <p class="aligned-paragraph item3text">
              Tailoring automation solutions specific to your industrial requirements. 
              </p>
            </div>
    
            <div class="content-np item36">
              <strong 
              style={{
                paddingLeft:'15px'
             }}
                >
                  Cybersecurity for Industrial Systems
    </strong>
              <p class="aligned-paragraph item3text">
              Ensuring robust security protocols for your digital industrial infrastructure. 
              </p>
            </div>
    
            <div class="content-np item37">
              <strong 
              style={{
                paddingLeft:'15px'
             }}
                >
                    Continuous Monitoring and Optimization
    </strong>
              <p class="aligned-paragraph item3text" style={{color:'black'}}>
              Providing ongoing monitoring and optimization of automated systems.  

              </p>
            </div>
          </div>
          {/* <div class="readmore">
            <button class="read1">Read More</button>
          </div> */}
        </section>

        
    
        <div class="section4">


        
<div className='sliders'>
  {/* Previous and Next buttons */}
  <button className="nav-button-slider prev" onClick={() => sliderRef.current?.slickPrev()}>
    &lt;
  </button>
  <button className="nav-button-slider next" onClick={() => sliderRef.current?.slickNext()}>
    &gt;
  </button>
  {/* Modal */}
  {/* {isModalOpen && selectedSlide && (
    <div className="modal-overlay" ref={modalRef}>
      <div className="modal-content-m" style={{ position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
       
        <div id="close-m" onClick={closeModal}>
          <i className="fas fa-times closeicon"></i>
        </div>
        <h2>{selectedSlide. modalContent.modalHeading}</h2>
        <p>{selectedSlide. modalContent.modalText}</p>
      </div>
    </div>
  )} */}
 
  <div className="slidercontainer">
    <Slider ref={sliderRef} {...settings}>
      {slideData.map((slide) => (
        <div key={slide.id} className="slider-item">
          <div className="slidecontents">
            <h2 className='slideheading h2-np slidecontent'>{slide.heading}</h2>
            <p className='slidetext p-np slidecontent'>{slide.text}</p>
            {/* <button className='slidebutton read1' onClick={() => openModal(slide)}>
              {slide.buttonLabel}
            </button> */}
          </div>
        </div>
      ))}
    </Slider>
  </div>
</div>
 
     
          {/* <CarouselSlider/> */}
         
        </div>
    
        <div className="section5" >
    <h1 className="section5heading">How We are Handling</h1>
      <div className="section5jsxcard">
      <CaseStudyCard
        imageUrl="/Images/casestudyimg1.jpg"
        title="Thorough Needs Assessment"
        description="Conducting a detailed analysis of your industrial processes to identify key areas for automation.  "
        // linkUrl="/platform/E-cart/casestudy-vitualwallet"
      />
      <CaseStudyCard
        imageUrl="/Images/casestudyimg2.jpg"
        title="Strategic Implementation"
        description="Developing and implementing a strategic plan tailored to integrate Industry 4.0 technologies seamlessly. "
        linkUrl=""
      />
      <CaseStudyCard
        imageUrl="/Images/casestudyimg3.jpg"
        title="System Integration and Testing"
        description="Ensuring smooth integration of new technologies with existing systems and rigorous testing for reliability."
        linkUrl=""
      />
     
      <CaseStudyCard
        imageUrl="/Images/casestudyimg4.jpg"
        title="Training and Skill Development"
        description="Providing comprehensive training for your team to effectively manage and utilize automated systems."
        linkUrl=""
      />
      
     
    {/* <div class="readmore">
  <button class="read">show More</button>
  </div> */}


{/* section 6 */}


  
    </div>
    </div> 
    
        
      </div>
      
    </div>
  )
}

export default IndustrialAutomationService
