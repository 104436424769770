import React from 'react'
import { Link } from 'react-router-dom'
import "./ProductModal.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBriefcase, faMicrochip, faIndustry, faShoppingCart, faDatabase, faComments, faLightbulb,faCogs } from "@fortawesome/free-solid-svg-icons";

export default function 
() {
  
  return (
    <>
    <div className="col-md-12 col-sm-12 col-xs-12">
    <h2 className="submenu-h2-heading ">Solutions</h2>
  </div>
    <div className="card-Product">
     
      <div className="Product-Product">

      <div className="service-row">
          <div className="service-column">
            {/* <div className="icon-and-text">
              <FontAwesomeIcon icon={faCogs} className="section-icon" />

              <div className="heading-h6"> All Platform </div>
            </div> */}
          </div>

          <div className="service-column">
            <ul className="list-inline mb0">
              {/* <li className="experience-list-item">
                <Link to="/platforms/erp-rpa-implementaion">
                  ERP/RPA Implementation
                </Link>
              </li>

              <li className="experience-list-item">
                <Link to="/platforms/SaasDevelopment">
                 SAAS Development Support
                </Link>
              </li>

              <li className="experience-list-item">
                <Link to="/platforms/reporting">Reporting and Analytics</Link>
              </li> */}
              
                <li className="Product-list-item">
                <Link to="/solution/DroneSolution">
            Drone Solutions
                </Link>
              </li>

               <li className="Product-list-item">
                <Link to="/solution/SuplyCL">
             Supplier Chain
                </Link>
              </li>

              <li className="Product-list-item">
                <Link to="/solution/FinancialS">
          Financial Services
                </Link>
              </li>

              <li className="experience-list-item">
                <Link to="/solution/HealthcareAndPharma" >Healthcare</Link>
              </li>

              <li className="experience-list-item">
                <Link to="/solution/E-commerce" >ECommerce</Link>
              </li>

              <li className="experience-list-item">
                <Link to="/solution/Automative" >Automative</Link>
              </li>
            </ul>
          </div>
        </div>

       

        {/* ERP */}

       
      </div>
      </div>
      </>
  )
}
