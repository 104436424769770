import React from 'react'
import { FaVideo, FaMobileAlt,FaRobot,FaBell } from 'react-icons/fa'; // Import the icons you need from React Icons
import './CustomerServices.css';

function CustomerServicing() {
  return (
    <div>
       <div className='customerservicing-heading'>CUSTOMER SERVICES</div>
       <img src='https://img.freepik.com/free-vector/flat-design-illustration-customer-support_23-2148887720.jpg' className='customerservicingimges' alt='imagecustomerkyc'></img>

       
      <div class="two-column-container1">
  <div class="column2">
  <div class="kyc-item">
  <FaVideo className="kyc-icon" />
  <h3 className='customerkyc-heading'>OCR-based Data Extraction</h3>
  <p className='customerkyc-para'>This Bank statement processing to enhance effectiveness of your processes</p>
</div>

    <img src='https://cdn.mastersindia.co/website/category/OCR.png' className='customerkycimg' alt='imagecustomerkyc' />
  </div>

  <div class="column2">
    <div class="kyc-item">
      <FaMobileAlt className="kyc-icon" />
      <h3 className='customerkyc-heading'>Virtual Assistant</h3>
      <p className='customerkyc-para'>Provide a 24*7 virtual assistant to your customers that can offer improved service with its AI capabilities</p>
    </div>
    <img src='https://www.syntacticsinc.com/wp-content/uploads/2014/05/Why-Business-Owners-Need-Virtual-Assistants-Dedicated-Virtual-Assistant.jpg' className='customerkycimg' alt='imagecustomerkyc' />
  </div>
</div>


<div class="two-column-container1">
  <div class="column2">
  <div class="kyc-item">
  <FaRobot className="kyc-icon" />
  <h3 className='customerkyc-heading'>Intuitive User Experience</h3>
  <p className='customerkyc-para'>Offer differentiated & state-of-the-art user experience that can be tailored as per customer patterns to improve retention</p>
</div>

    <img src='https://abaralms.com/wp-content/uploads/2020/08/03_LMS-with-Great-User-Experience-and-User-Interface_banner.png' className='customerkycimg' alt='imagecustomerkyc' />
  </div>

  <div class="column2">
    <div class="kyc-item">
      <FaBell className="kyc-icon" />
      <h3 className='customerkyc-heading'>Smart Notification System</h3>
      <p className='customerkyc-para'>Send real-time notifications via app alerts, SMS or emails for effective service deliveries</p>
    </div>
    <img src='https://notify.events/assets/YXBwL3RoZW1lL2xhbmRpbmcvZGlzdA==/img/svg/2.5.upgrade.svg' className='customerkycimg' alt='imagecustomerkyc' />
  </div>
</div>

      

</div>
   
  )
}

export default CustomerServicing
