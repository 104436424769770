import "./template.css";

import { Link } from "react-router-dom";
import ReadModal2 from "./readMore.js";
import React, { useState, useRef, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CarouselSlider from "./Carousel.js";
import CaseStudyCard from "./CaseStudyCard.js";

import Accordion from "./Accordion";
import "./Accordion.css";


import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

function CloudServices() {
  const [activeModal, setActiveModal] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);
  const sliderRef = useRef(null);
  const modalRef = useRef(null);
  const [selectedSlide, setSelectedSlide] = useState(null);
  const settings = {
    // dots: true,
    // infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },

      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },

      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 3000,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const slideData = [
    {
      id: 1,
      heading: "Tailored for Small and Midsize Tutors",
      text: "Specifically designed to meet the unique needs of individual tutors and small tutoring businesses.  ",
      buttonLabel: "Read More",
      modalContent: {
        // modalHeading: 'Modal Heading 1',
        modalText:
          "CloudCipher seamlessly integrates with existing workflows and software ecosystems, minimizing disruption and streamlining the adoption process. Whether migrating from another platform or implementing new security measures, CloudCipher ensures a smooth transition with minimal downtime.",
      },
    },
    {
      id: 2,
      heading: "Comprehensive Yet Simple",
      text: "Offers a wide range of features in an intuitive, easy-to-use interface.  ",
      buttonLabel: "Read More",
      modalContent: {
        modalHeading: "Modal Heading 2",
        modalText: "Modal Text 2",
      },
    },

    {
      id: 3,
      heading: "Security and Privacy",
      text: "Prioritizing the protection of educational content and student data. ",
      buttonLabel: "Read More",
      modalContent: {
        modalHeading: "Modal Heading 3",
        modalText: "Modal Text 3",
      },
    },

    {
      id: 4,
      heading: "Engagement and Interaction",
      text: "Tools designed to create an engaging and interactive learning environment.  ",
      buttonLabel: "Read More",
      modalContent: {
        modalHeading: "Modal Heading 4",
        modalText: "Modal Text 4",
      },
    },

    {
      id: 5,
      heading: "Performance Optimization",
      text: 'Continuous monitoring and optimization to ensure peak performance of your cloud resources."',
      buttonLabel: "Read More",
      modalContent: {
        modalHeading: "Modal Heading 5",
        modalText: "Modal Text 5",
      },
    },

    // {
    //   id: 6,
    //   heading: 'Sixth Slide',
    //   text: 'A different text for the second slide.',
    //   buttonLabel: 'Read More',
    //   modalContent: {
    //     modalHeading: 'Modal Heading 6',
    //     modalText: 'Modal Text 6',
    //   },
    // },

    // {
    //   id: 7,
    //   heading: 'Seventh Slide',
    //   text: 'A different text for the second slide.',
    //   buttonLabel: 'Read More',
    //   modalContent: {
    //     modalHeading: 'Modal Heading 7',
    //     modalText: 'Modal Text 7',
    //   },
    // },

    // {
    //   id: 8,
    //   heading: 'Eighth Slide',
    //   text: 'A different text for the second slide.',
    //   buttonLabel: 'Read More',
    //   modalContent: {
    //     modalHeading: 'Modal Heading 8',
    //     modalText: 'Modal Text 8',
    //   },
    // },
    // ... (more slides)
  ];


  let items = [
    {
      title: "Q: How does the platform ensure the security of my teaching content? ",
      content:
        "A: We use state-of-the-art encryption and access controls to ensure your content is accessible only to authorized users.  ",
    },
    {
      title: "Q: Can I track individual student progress across different subjects?",
      content:
        "A: Yes, our comprehensive logging system allows you to monitor each student's progress, attendance, and performance by subject.  ",
    },
    {
      title: "Q: Is the platform easy to use for someone with limited technical skills?",
      content:
        "A: Absolutely. We've designed the platform with a focus on user-friendliness, ensuring that tutors can easily navigate and utilize all features without technical difficulties.  ",
    },
   
    
   
  ];
 

  const openModal = (slide) => {
    setSelectedSlide(slide);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  // Handle clicks outside the modal to close it
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        closeModal();
      }
    };

    window.addEventListener("mousedown", handleOutsideClick);

    return () => {
      window.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const modalContent = (
    <>
      <p className="modal-np">The Purpose Behind Our Online Tutor </p>
      Our Online Tutor Platform is specifically designed to empower small and
      midsize tutoring operations to expand their reach, secure their content,
      and effectively manage their tutoring business online. With a focus on
      ease of use, security, and comprehensive functionality, we're committed to
      enriching the educational experience for both tutors and students alike.
      <hr />
      <br />
      <strong>Student Progress Tracking</strong>
      Online tutor platforms enable tutors to monitor and track students'
      progress and performance, providing valuable insights that can inform
      instructional strategies and interventions.
      <br />
      <br />
      <strong>Efficient Course Management</strong>
      By centralizing course materials, communication channels, and
      administrative functions, online tutor platforms streamline course
      management tasks, saving tutors time and effort.
      <br />
      <br />
      <strong>Enhanced Digital Presence</strong> For tutors looking to establish
      or enhance their digital presence, online tutor platforms offer a
      professional platform to showcase their expertise and attract students.
      <br />
      <br /> <strong>Maximized Teaching Impact</strong> Ultimately, the purpose
      of an online tutor platform is to maximize the teaching impact of tutors
      by providing them with the tools and support they need to deliver
      high-quality instruction in a secure and effective online environment.
      {/* Your dynamic content here */}
      {/* You can pass any JSX content you want */}
    </>
  );

  return (
    <div>
      <div id="maindivs">
        <div id="carouselExampleCaptions" className="carousel slide">
          <div className="carousel-inner">
            <div className="carousel-item active">
              <img
                src="/Images/onlinetutorfinal.png"
                className="d-block w-100"
                style={{opacity:'0.5', height: "auto"}}
                alt="slider first"
              />
              <div className="carousel-caption  d-md-block">
                <div className="component-1" style={{
                    color: "black",
                    
                  }}>Online Tutor</div>

                <div
                  className="component-paras"
                  style={{
                    color: "black",
                    marginTop: "-20px",
                    fontStyle: "italic",
                    fontWeight:"700"
                  }}
                >
                  "Empowering Tutors, Enriching Minds – Your Secure,
                  Comprehensive Online Tutoring Solution"
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container-np2">
          {/* <!-- section2 title --> */}
          <div className="cont2-titles">
            {/* <div class="cont2-titletext">What is Online Tutor?</div> */}
          </div>

          {/* <!-- section part --> */}
          <div class="container21">
            {/* <!-- left part --> */}

            <div className="cont2-left cont2-text">
              <div
                className="left-text"
                style={{
                  // fontFamily: "arial",
                
                  fontFamily: "system-ui",
                }}
              >
                The Online Tutor Platform offers an all-in-one solution for
                tutors seeking to bring their teaching services online. It
                provides a secure environment for educational content, detailed
                logs of student progress and subjects, and tools to manage and
                deliver courses effectively. Whether you're looking to
                transition your tutoring services online or enhance your
                existing digital presence, our platform is tailored to meet your
                needs, ensuring your content remains protected and your teaching
                impact maximized.
              </div>
            </div>

            {/* right part */}
            <div className="cont2-right cont2-text">
              <div className="right-text">
                <img
                  class="content2img"
                  src="/Images/presentationss.gif"
                  alt="Description of the image"
                />
              </div>
            </div>
          </div>
          <div class="readmore">
            <ReadModal2 buttonText="Read More" modalContent={modalContent} />
          </div>
        </div>

        {/* section3 */}
        <section class="section3">
          <div class="cont2-title title ">Our Features</div>

          <div class="section3parts">
            {/* <div class="content-np item31">
              <div>
                <strong style={{
                  fontSize:'20px',marginLeft:'20px'
                }}> Content Security
    </strong>
                <p class="aligned-paragraph item3text">
                Advanced encryption and security measures to protect your educational materials and intellectual property. 
                </p>
              </div>
            </div> */}
            <div
              class="content-np item31"
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-end",
              }}
            >
              <div>
                <strong style={{ paddingLeft: "20px" }}>
                  Content Security
                </strong>
                <p
                  class="aligned-paragraph item3text"
                  style={{ marginBottom: "30px" }}
                >
                  Advanced encryption and security measures to protect your
                  educational materials and intellectual property.
                </p>
              </div>
            </div>

            <div class="content-np item322">
              <strong
                class="item32-heading item31-conent"
                style={{
                  paddingLeft: "15px",
                }}
              >
                Student and Subject Logging
              </strong>

              <div class=" item31-conent-img  "></div>
              <p class="aligned-paragraph item3text" style={{ color: "black" }}>
                Comprehensive logging features to track student progress,
                attendance, and subject matter coverage.
              </p>
            </div>

            <div class="content-np item33">
              <strong
                class=""
                style={{
                  paddingLeft: "15px",
                }}
              >
                {" "}
                Customizable Tutor Profiles
              </strong>
              <p class="aligned-paragraph item3text">
                Personalized tutor profiles to showcase qualifications, teaching
                styles, and course offerings.
              </p>
              {/* <div class="item32-button"><button class="item32-button">Read more</button></div> */}
            </div>

            <div class="content-np item34">
              <strong
                style={{
                  paddingLeft: "15px",
                }}
              >
                Course Management Tools
              </strong>
              <p
                class="aligned-paragraph item3texts"
                style={{ color: "black" }}
              >
                Intuitive tools for creating, managing, and delivering courses,
                including scheduling, assignments, and assessments.
              </p>
            </div>
            <div class="content-np item35">
              <strong
                style={{
                  paddingLeft: "15px",
                }}
              >
                {" "}
                Interactive Learning Environments
              </strong>
              <p class="aligned-paragraph item3text">
                Engaging, interactive tools such as live video sessions, forums,
                and quizzes to enhance the learning experience.
              </p>
            </div>

            <div class="content-np item36">
              <strong
                style={{
                  paddingLeft: "15px",
                }}
              >
                Payment Integration
              </strong>
              <p class="aligned-paragraph item3text">
                Secure payment gateways for easy processing of course fees and
                subscriptions.
              </p>
            </div>

            <div class="content-np item37">
              <strong
                style={{
                  paddingLeft: "15px",
                }}
              >
                Analytics and Reporting
              </strong>
              <p class="aligned-paragraph item3text" style={{ color: "black" }}>
                Detailed analytics and reports to monitor engagement,
                performance, and growth.
              </p>
            </div>
          </div>
          {/* <div class="readmore">
            <button class="read1">Read More</button>
          </div> */}
        </section>

        <div class="section4">
          
          <div className="main-cont">
            <div className="Cardcomp">
              <div className="card-container">
                <div className="row-ca">
                  <div className="col-ca">
                    <div className="card-head text-center">
                      <h2 className="head-t ">USP of Online Tutor Platforms</h2>
                     
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card-container">
              <div className="row-ck">
                <div className="col-ck">
                  <div className="card-wrapper">
                    <div className="main-card">
                      <div className="innercon">
                        <div className="card-content1">
                          <h2>
                          Tailored for Small and Midsize Tutors{" "}
                            <FontAwesomeIcon icon={faArrowRight} />
                          </h2>
                          {/* <p>vbhf</p> */}
                        </div>
                        <img src="/Images/Tailored_for_Small_and_Midsize_Tutors.gif" alt="" />
                      </div>
                    </div>
                    <div className="hover-card">
                      <div className="hover-content">
                        <h2> Tailored for Small and Midsize Tutors </h2>
                        <p>
                        Specifically designed to meet the unique needs of individual tutors and small tutoring businesses. {" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-ck">
                  <div className="card-wrapper">
                    <div className="main-card">
                      <div className="innercon">
                        <div className="card-content1">
                          <h2>
                          Comprehensive Yet Simple
                            <FontAwesomeIcon icon={faArrowRight} />{" "}
                          </h2>
                          {/* <p>vbhf</p> */}
                        </div>
                        <img src="/Images/Seamless_Multi-Platform_Integration.gif" alt="" />
                      </div>
                    </div>
                    <div className="hover-card">
                      <div className="hover-content">
                        <h2>Comprehensive Yet Simple</h2>
                        <p>
                          {" "}
                          Offers a wide range of features in an intuitive, easy-to-use interface. {" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-ck">
                  <div className="card-wrapper">
                    <div className="main-card">
                      <div className="innercon">
                        <div className="card-content1">
                          <h2>
                          Security and Privacy{" "}
                            <FontAwesomeIcon icon={faArrowRight} />
                          </h2>
                          {/* <p>vbhf</p> */}
                        </div>
                        <img src="/Images/Secure_Payment_Processing.gif" alt="" />
                      </div>
                    </div>
                    <div className="hover-card">
                      <div className="hover-content">
                        <h2>Security and Privacy</h2>
                        <p>
                        Prioritizing the protection of educational content and student data.{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-ck">
                  <div className="card-wrapper">
                    <div className="main-card">
                      <div className="innercon">
                        <div className="card-content1">
                          <h2>
                          Engagement and Interaction<FontAwesomeIcon icon={faArrowRight} />
                          </h2>
                          {/* <p>vbhf</p> */}
                        </div>
                        <img src="/Images/Engagement_and_Interaction.gif" alt="" />
                      </div>
                    </div>
                    <div className="hover-card">
                      <div className="hover-content">
                        <h2>Engagement and Interaction</h2>
                        <p>
                        Tools designed to create an engaging and interactive learning environment.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-ck">
                  <div className="card-wrapper">
                    <div className="main-card">
                      <div className="innercon">
                        <div className="card-content1">
                          <h2>
                          Support and Growth
                            <FontAwesomeIcon icon={faArrowRight} />
                          </h2>
                          {/* <p>vbhf</p> */}
                        </div>
                        <img src="/Images/Performance_Optimization.gif" alt="" />
                      </div>
                    </div>
                    <div className="hover-card">
                      <div className="hover-content">
                        <h2> Support and Growth</h2>
                        <p>
                        Dedicated support and tools for business growth, including marketing and student engagement analytics. 
                        </p>
                      </div>
                    </div>
                  </div>
                </div>


               
               
                
              </div>
            </div>
          </div>




          {/* <CarouselSlider/> */}
        </div>


        <div className="section5">
          <h1 className="section5heading">How we are Handling</h1>
          <div className="section5jsxcard">
            <CaseStudyCard
              imageUrl="/Images/casestudyimg1.jpg"
              title="Easy Onboarding"
              description="Guided setup process to quickly get your tutoring services online.  "
              // linkUrl="/platform/E-cart/casestudy-vitualwallet"
            />
            <CaseStudyCard
              imageUrl="/Images/casestudyimg2.jpg"
              title="Customization and Branding"
              description=" Tools to customize your platform presence, matching your branding and teaching philosophy. "
              linkUrl=""
            />
            <CaseStudyCard
              imageUrl="/Images/casestudyimg3.jpg"
              title="Continuous Support"
              description="Ongoing support from our team to assist with any questions or technical needs.  "
              linkUrl="/platform/E-cart/casestudy-vitualwallet"
            />

            <CaseStudyCard
              imageUrl="/Images/casestudyimg4.jpg"
              title="Regular Updates"
              description="Continuous updates to the platform, adding new features and enhancements based on tutor feedback. "
              linkUrl=""
            />

            {/* <div class="readmore">
  <button class="read">show More</button>
  </div> */}

            {/* section 6 */}
          </div>
        </div>

        <section className="FAQ">
        <div className="FAQbody">
          <h1 className="section5heading text-dark" >FAQ's for this service</h1>
          <Accordion items={items} />
        </div>
      </section>

      </div>
    </div>
  );
}

export default CloudServices;
