import react from 'react';
import "./MobileApplication.css";


function MobileAppDevelopment  ()  {

  return (
    <div>
        <div className='mobile-heading'>Mobile Application</div>
      <h1 style={{paddingLeft:'7%'}}>Mobile Application Development: Native & Cross-Platform</h1>
      <p className='para-heading' style={{paddingLeft:'7%'}}>
        Welcome to DigitechHub, your partner in delivering cutting-edge mobile applications that cater to diverse user needs. Whether you're looking for a native app optimized for specific platforms or a cross-platform solution to reach a wider audience, our skilled team of developers has you covered.
      </p>

 <div id="carouselExampleCaptions" className="carousel slide">

 <div className="carousel-inner">
  <div className="carousel-item active">
    <img
      src="https://intersog.com/wp-content/uploads/2021/11/Mobile-Application-Development-Statistics5-Facts.jpg"
      className="d-block w-100"
      alt="slider first"
    />
    <div className="carousel-caption">
      <h3>Native Mobile App Development</h3>
      <p>Why Native?</p>
      <p>
        Native apps are built specifically for a single platform (iOS or Android) using platform-specific programming languages (Swift/Objective-C for iOS, Java/Kotlin for Android). They offer several advantages:
      </p>
      <ul>
        <li>Performance: Native apps provide the best performance and user experience due to their close integration with the platform's capabilities.</li>
        <li>Access to Features: Native development allows direct access to device features like camera, GPS, push notifications, and hardware acceleration.</li>
        <li>UX/UI: Native apps can be designed with platform-specific UI guidelines, resulting in a consistent and familiar user interface.</li>
      </ul>
    </div>
  </div>
  <div className="carousel-item">
    <img
      src="https://www.chetu.com/img/blogs/5-things-to-consider-before-mobile/5-things-to-consider-before-mobile-app-development-banner.jpg"
      className="d-block w-100"
      alt="slider second"
    />
    <div className="carousel-caption">
      <h3>Cross-Platform Mobile App Development</h3>
      <p>Why Cross-Platform?</p>
      <p>
        Cross-platform apps are developed using frameworks like React Native, Flutter, or Xamarin, allowing you to create a single codebase that runs on multiple platforms:
      </p>
      <ul>
        <li>Cost Efficiency: Developing for multiple platforms using a single codebase significantly reduces development time and costs.</li>
        <li>Faster Deployment: Cross-platform apps can be deployed simultaneously on multiple platforms, reducing time-to-market.</li>
        <li>Maintenance: Updates and maintenance can be applied to the single codebase, saving time compared to maintaining separate native apps.</li>
      </ul>
    </div>
    </div>

    <div className="carousel-item">
    <img
      src="https://segwitz.com/wp-content/uploads/2021/12/mobile-app-development-framework-2048x1076-1.png"
      className="d-block w-100"
      alt="slider second"
    />
    <div className="carousel-caption">
      <h3>Our Mobile App Development Services </h3>
      <p>Why Cross-Platform?</p>
      <p>
        Cross-platform apps are developed using frameworks like React Native, Flutter, or Xamarin, allowing you to create a single codebase that runs on multiple platforms:
      </p>
      <ul>
        <li>Cost Efficiency: Developing for multiple platforms using a single codebase significantly reduces development time and costs.</li>
        <li>Faster Deployment: Cross-platform apps can be deployed simultaneously on multiple platforms, reducing time-to-market.</li>
        <li>Maintenance: Updates and maintenance can be applied to the single codebase, saving time compared to maintaining separate native apps.</li>
      </ul>
    </div>
    </div>
<button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
  <span className="carousel-control-prev-icon" aria-hidden="true"></span>
  <span className="visually-hidden">Previous</span>
</button>
<button className="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
  <span className="carousel-control-next-icon" aria-hidden="true"></span>
  <span className="visually-hidden">Next</span>
</button>

</div>



<div className="cart">
  <div className='div'>
    <h2 className='headings-point'>Why Choose DigitechHub?</h2>
    <ul style={{paddingLeft:'7%'}}>
      <li className='li-ul'><strong>Experience:</strong> Our developers have a proven track record in both native and cross-platform app development.</li>
      <li className='li-ul'><strong>Innovation:</strong> We stay updated with the latest trends and technologies to deliver modern, feature-rich apps.</li>
      <li className='li-ul' ><strong>Custom Solutions:</strong> We tailor our solutions to your unique requirements, ensuring your app stands out from the competition.</li>
      <li className='li-ul' ><strong>Collaboration:</strong> We work closely with you, involving you in the development process to ensure your vision is realized.</li>
      <li className='li-ul'><strong>User-Centric:</strong> Whether native or cross-platform, we prioritize creating user-friendly and engaging experiences.</li>
    </ul>
  </div>
</div>

      <p className='pare' style={{paddingLeft:'7%'}}>
        Join us in bringing your mobile app idea to life, whether through native precision or cross-platform efficiency. Contact us today to discuss your mobile app development needs and how we can help you create an app that resonates with your target audience.
      </p>

    </div>
    </div>
  );
        
};

export default MobileAppDevelopment;
