import "./template.css";
 
 import { Link } from "react-router-dom";
import ReadModal2 from "./readMore.js";
 import React, { useState, useRef, useEffect} from 'react';
 import Slider from 'react-slick';
 import 'slick-carousel/slick/slick.css';
 import 'slick-carousel/slick/slick-theme.css';
import CarouselSlider from "./Carousel.js";
import CaseStudyCard from "./CaseStudyCard.js";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Accordion from "./Accordion";
import "./Accordion.css";

import {
  faArrowRight
} from '@fortawesome/free-solid-svg-icons';


function Blockchain() {



    const [activeModal, setActiveModal] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);
  const sliderRef = useRef(null);
  const modalRef = useRef(null);
  const [selectedSlide, setSelectedSlide] = useState(null);
  const settings = {
    // dots: true,
    // infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },

      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },

      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 3000,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
     
    ],
  };
  const slideData = [
    {
      id: 1,
      heading: 'Customized Cloud Strategies',
      text: 'Tailor-made cloud solutions that align with your specific business needs and goals. ',
      buttonLabel: 'Read More',
      modalContent: {
        // modalHeading: 'Modal Heading 1',
        modalText: 'CloudCipher seamlessly integrates with existing workflows and software ecosystems, minimizing disruption and streamlining the adoption process. Whether migrating from another platform or implementing new security measures, CloudCipher ensures a smooth transition with minimal downtime.',
      },
    },
    {
      id: 2,
      heading: 'Expertise in Cloud Technology',
      text: 'A team of experts adept in the latest cloud technologies and best practices. ',
      buttonLabel: 'Read More',
      modalContent: {
        modalHeading: 'Modal Heading 2',
        modalText: 'Modal Text 2',
      },
    },
 
    {
      id: 3,
      heading: 'Proactive Management and Support',
      text: 'Ongoing management and proactive support to ensure your cloud environment runs smoothly.',
      buttonLabel: 'Read More',
      modalContent: {
        modalHeading: 'Modal Heading 3',
        modalText: 'Modal Text 3',
      },
    },
 
    {
      id: 4,
      heading: 'Security and Compliance Focus',
      text: 'A strong emphasis on maintaining a secure and compliant cloud environment. ',
      buttonLabel: 'Read More',
      modalContent: {
        modalHeading: 'Modal Heading 4',
        modalText: 'Modal Text 4',
      },
    },
 
    {
      id: 5,
      heading: 'Performance Optimization',
      text: 'Continuous monitoring and optimization to ensure peak performance of your cloud resources."',
      buttonLabel: 'Read More',
      modalContent: {
        modalHeading: 'Modal Heading 5',
        modalText: 'Modal Text 5',
      },
    },
 
    // {
    //   id: 6,
    //   heading: 'Sixth Slide',
    //   text: 'A different text for the second slide.',
    //   buttonLabel: 'Read More',
    //   modalContent: {
    //     modalHeading: 'Modal Heading 6',
    //     modalText: 'Modal Text 6',
    //   },
    // },
 
    // {
    //   id: 7,
    //   heading: 'Seventh Slide',
    //   text: 'A different text for the second slide.',
    //   buttonLabel: 'Read More',
    //   modalContent: {
    //     modalHeading: 'Modal Heading 7',
    //     modalText: 'Modal Text 7',
    //   },
    // },
 
    // {
    //   id: 8,
    //   heading: 'Eighth Slide',
    //   text: 'A different text for the second slide.',
    //   buttonLabel: 'Read More',
    //   modalContent: {
    //     modalHeading: 'Modal Heading 8',
    //     modalText: 'Modal Text 8',
    //   },
    // },
    // ... (more slides)
  ];
 
  let items = [
    {
      title: "Q. What is blockchain?",
      content:
        "A. Blockchain is a decentralized digital ledger technology that records transactions across multiple computers in a way that is immutable, transparent, & secure. ",
    },
    {
      title: "Q. How does blockchain ensure security? ",
      content:
        "A. Blockchain ensures security through cryptographic techniques, consensus mechanisms, & decentralization. Each transaction is encrypted & linked to the previous one, making it virtually impossible to alter past records without consensus from the network participants.  ",
    },
    {
      title: "Q. What are smart contracts?",
      content:
        "A. Smart contracts are self-executing contracts with the terms of the agreement directly written into code. They automatically enforce & verify the terms of an agreement, eliminating the need for intermediaries & reducing the risk of fraud.  ",
    },
   
    {
      title: "Q.What are the benefits of blockchain technology?",
      content:
        "A. Blockchain technology offers benefits such as enhanced security, transparency, immutability, reduced transaction costs, increased efficiency, & decentralized governance. ",
    },
    {
      title: "Q. Can blockchain be used for industries other than finance?",
      content:
        "A. Yes, blockchain has applications across various industries including supply chain management, healthcare, real estate, government, voting systems, identity management, & many more industries. Its decentralized & transparent nature makes it versatile for solving diverse problems.",
    },
    {
      title: "Q. What is the difference between public & private blockchains?",
      content:
        "A. Public blockchains are open to anyone to join & participate in the network, whereas private blockchains restrict access to authorized participants. Public blockchains are decentralized & permissionless, while private blockchains are centralized & permissioned. ",
    },
    {
      title: "Q. How long does it take to develop a blockchain solution?",
      content:
        "A. The development timeline for a blockchain solution depends on various factors including complexity, requirements, & integration with existing systems. It can range from a few weeks for simple projects to several months for more complex implementations. ",
    },
    {
      title: "Q. Is blockchain technology scalable?",
      content:
        "A. Yes, efforts are ongoing to improve the scalability of blockchain networks through solutions such as sharding, layer 2 protocols, & consensus algorithm enhancements. While scalability challenges exist, ongoing research & development aim to address them. ",
    },
    {
      title: "Q. How do I choose the right blockchain platform for my project?",
      content:
        "A. The choice of blockchain platform depends on factors such as use case, scalability requirements, consensus mechanism, development capabilities, & community support. It's essential to evaluate these factors carefully & choose a platform that aligns with your project goals. ",
    },
    {
      title: "Q. Can blockchain be integrated with existing systems?",
      content:
        "A. Yes, blockchain technology can be integrated with existing systems through APIs, middleware, or custom development. This integration can enhance security, transparency, & efficiency in data management & transactions. ",
    },
   
  ];
 
 
  const openModal = (slide) => {
    setSelectedSlide(slide);
    setModalOpen(true);
  };
 
  const closeModal = () => {
    setModalOpen(false);
  };
 
  // Handle clicks outside the modal to close it
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        closeModal();
      }
    };
 
    window.addEventListener('mousedown', handleOutsideClick);
 
    return () => {
      window.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);



  const modalContent = (
    <>
      <p className="modal-np" >The Purpose Behind Our Blockchain</p>
      At Digitec Hub, we are dedicated to leveraging the transformative power of blockchain technology. Revolutionizing industries through decentralized solutions, enhancing security, transparency, & efficiency. Our exclusive customized solutions & services in blockchain-based application development, support, & maintenance are designed to help empowering businesses with immutable trust, one block at a time.

         
          <hr/>
           <br/> <strong>Decentralization</strong> 
 
           Blockchain enables decentralization by distributing data across a network of computers (nodes) rather than storing it in a central location. This decentralization eliminates the need for intermediaries, such as banks or financial institutions, thereby reducing the risk of manipulation, fraud, and censorship.
         <br/><br/>
        <strong> Transparency and Immutability</strong>
           Transactions recorded on a blockchain are transparent and immutable, meaning they cannot be altered or deleted once added to the chain. This transparency and immutability foster trust among participants, as every transaction can be traced back to its origin, promoting accountability and reducing the risk of fraud.
 
          <br/><br/><strong>Security</strong>  Blockchain employs cryptographic techniques to secure transactions and ensure the integrity of the data stored on the network. Each block in the chain is linked to the previous one through cryptographic hashes, making it extremely difficult for malicious actors to tamper with the data without being detected.
 
 
          <br/><br/><strong>Trustless Transactions</strong>A large library of apps to enhance functionality, from note-taking to music streaming. The SmartCloudApp Store offers various apps to extend the platform's capabilities.
  
 
      {/* Your dynamic content here */}
      {/* You can pass any JSX content you want */}
    </>
 );


  return (

    <div>

<div id="maindivs">
        <div id="carouselExampleCaptions" className="carousel slide">
          <div className="carousel-inner">
            <div className="carousel-item active">
              <img
                src="/Images/FinalBlockChain.png"
                className="d-block w-100"
                style={{ height: "auto" }}
                alt="slider first"
              />
            <div className="carousel-caption d-md-block">
  <div className="component-1 ">Blockchain</div>
  <div className="component-paras" style={{ color: "white", marginTop: '-20px', fontStyle: 'italic',fontWeight:"700" }}>
  "Creating trust step by step – Leading the way with new blockchain ideas"

   
  </div>
</div>

            </div>
    
        </div>
        </div>
        
    
        <div className="container-np2">
          {/* <!-- section2 title --> */}
          <div className="cont2-titles">
            {/* <div class="cont2-titletext">What is Blockchain?</div> */}
            
          </div>
    
          {/* <!-- section part --> */}
          <div class="container21">
            {/* <!-- left part --> */}
    
            <div className="cont2-left cont2-text">
              <div
                className="left-text"
                style={{
                  // fontFamily: "arial",
                fontSize:"22px",
                  fontFamily: "system-ui",
                }}
              >
       Blockchain service refers to a decentralized digital ledger technology that records transactions across multiple computers in a way that is immutable, transparent, & secure. Each transaction is stored in a "block" that is linked to the previous one, forming a chain. This technology enables peer-to-peer transactions without the need for intermediaries, providing trust & accountability in various industries such as finance, supply chain, healthcare, & more.


              </div>
            </div>
    
            {/* right part */}
            <div className="cont2-right cont2-text">
              <div className="right-text">
                <img
                  class="content2img"
                  src="/Images/blockchain.gif"
                  alt="Description of the image"
                />
              </div>
            </div>
          </div>
          <div class="readmore">
            <ReadModal2 buttonText="Read More" modalContent={modalContent} />

            
          </div>
        </div>
    
          {/* section3 */}
      <section class="section3">
        <div class="cont2-title title ">Our Features</div>

        <div class="section3parts">
          <div class="content-np item31">
            <div>
              <strong
                style={{
                  paddingLeft: "15px",
                }}
              >
              Smart Contract Development
              </strong>
              <p class="aligned-paragraph item3text">
              Creating self-executing contracts that automatically enforce and verify the terms of an agreement, streamlining business processes.

              </p>
            </div>
          </div>

          <div class="content-np item322">
            <strong
              class="item32-heading item31-conent"
              style={{
                paddingLeft: "15px",
                color: "black",
              }}
            >
            Decentralized Applications (DApps) Development
            </strong>
            <div class=" item31-conent-img  "></div>
            <p
              class="aligned-paragraph item3text mt-1"
              style={{ color: "black" }}
            >
             Building decentralized applications that run on blockchain networks, ensuring transparency, security, & immutability.

            </p>
          </div>

          <div class="content-np item33">
            <strong
              class=""
              style={{
                paddingLeft: "15px",
              }}
            >
              {" "}
              Cryptocurrency Development
            </strong>
            <p class="aligned-paragraph item3text">
            Designing & developing custom cryptocurrencies or tokens for various purposes, including crowdfunding, loyalty programs, & asset digitization.

            </p>
            {/* <div class="item32-button"><button class="item32-button">Read more</button></div> */}
          </div>

          <div class="content-np item34">
            <strong
              style={{
                paddingLeft: "15px",
              }}
            >
              Blockchain Consulting
            </strong>
            <p class="aligned-paragraph item3text" style={{ color: "black" }}>
            Providing expert guidance on blockchain adoption, implementation strategies, & integration into existing systems to optimize business operations.


            </p>
          </div>
          <div class="content-np item35">
            <strong
              style={{
                paddingLeft: "15px",
              }}
            >
              {" "}
              Blockchain Integration
            </strong>
            <p class="aligned-paragraph item3text">
            Integrating blockchain technology with existing systems & platforms to enhance security, transparency, & efficiency in data management & transactions.

            </p>
          </div>

          <div class="content-np item36">
            <strong
              style={{
                paddingLeft: "15px",
              }}
            >
              Blockchain Security Audits
            </strong>
            <p class="aligned-paragraph item3text">
            Conducting thorough security audits to identify vulnerabilities & ensure the integrity & confidentiality of blockchain networks & applications.

            </p>
          </div>

          <div class="content-np item37">
            <strong
              style={{
                paddingLeft: "15px",
              }}
            >
              Permissioned Blockchain Development
            </strong>
            <p class="aligned-paragraph item3text" style={{ color: "black" }}>
            Building private or permissioned blockchain networks for enterprises, consortiums, or government agencies, ensuring controlled access & data privacy.

            </p>
          </div>
        </div>
        {/* <div class="readmore">
          <button class="read1">Read More</button>
        </div> */}
      </section>


        
    
        <div class="section4">


        
{/* <div className='sliders'> */}
  {/* Previous and Next buttons */}
  {/* <button className="nav-button-slider prev" onClick={() => sliderRef.current?.slickPrev()}>
    &lt;
  </button>
  <button className="nav-button-slider next" onClick={() => sliderRef.current?.slickNext()}>
    &gt;
  </button> */}
  {/* Modal */}
  {/* {isModalOpen && selectedSlide && (
    <div className="modal-overlay" ref={modalRef}>
      <div className="modal-content-m" style={{ position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
       
        <div id="close-m" onClick={closeModal}>
          <i className="fas fa-times closeicon"></i>
        </div>
        <h2>{selectedSlide. modalContent.modalHeading}</h2>
        <p>{selectedSlide. modalContent.modalText}</p>
      </div>
    </div>
  )} */}
 
  {/* <div className="slidercontainer">
    <Slider ref={sliderRef} {...settings}>
      {slideData.map((slide) => (
        <div key={slide.id} className="slider-item">
          <div className="slidecontents">
            <h2 className='slideheading h2-np slidecontent'>{slide.heading}</h2>
            <p className='slidetext p-np slidecontent'>{slide.text}</p> */}
            {/* <button className='slidebutton read1' onClick={() => openModal(slide)}>
              {slide.buttonLabel}
            </button> */}
          {/* </div>
        </div>
      ))}
    </Slider>
  </div>
</div> */}

<div className="main-cont">
        <div className="Cardcomp">
          <div className="card-container">
            <div className="row-ca">
              <div className="col-ca">
                <div className="card-head text-center">
                  <h2 className="head-t ">USP of Blockchain Services</h2>
                  {/* <p className="t-color">
                    Delivering domain expertise and technology-driven offerings
                    to help you turn digital challenges into opportunities.
                  </p> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='card-container'>
        <div className='row-ck'>
            <div className='col-ck'>
        <div className="card-wrapper">
            <div className="main-card">
            <div className="innercon">
                  <div className="card-content1" >
                    <h2>Tailored Solutions <FontAwesomeIcon icon={faArrowRight} /></h2>
                    {/* <p>vbhf</p> */}
                  </div>
                  <img src="/Images/Tailored_for_Small_and_Midsize_Tutors.gif" alt="" />
                </div>
            </div>
            <div className="hover-card">
                <div className="hover-content">
                    <h2>Tailored Solutions</h2>
                    <p>: We offer customized blockchain solutions tailored to the specific needs and objectives of each client, ensuring optimal performance and efficiency.
 </p>
                    
                </div>
            </div>
        </div>
        </div>
        <div className='col-ck'>
        <div className="card-wrapper">
        <div className="main-card">
        <div className="innercon">
                  <div className="card-content1">
                    <h2> Expertise & Experience<FontAwesomeIcon icon={faArrowRight} /> </h2>
                    {/* <p>vbhf</p> */}
                  </div>
                  <img
                        className="img-ico"
                        src="/Images/organigram.gif"
                        alt=""
                      />
                </div>
        </div>
        <div className="hover-card">
            <div className="hover-content">
                  <h2>Expertise & Experience</h2>
                    <p> With a team of experienced blockchain developers & consultants, we bring in-depth knowledge & expertise to deliver high-quality solutions & overcome complex challenges.
 </p>
                    
            </div>
        </div>
    </div>
    </div>
    <div className='col-ck'>
    <div className="card-wrapper">
        <div className="main-card">
        <div className="innercon">
                  <div className="card-content1">
                    <h2> Innovative Approach <FontAwesomeIcon icon={faArrowRight} /></h2>
                    {/* <p>vbhf</p> */}
                  </div>
                  <img src="/Images/implementation.gif" alt="" />
                </div>
        </div>
        <div className="hover-card">
            <div className="hover-content">
                  <h2>Innovative Approach</h2>
                    <p>We stay at the forefront of blockchain technology advancements, continuously innovating & exploring new possibilities to provide cutting-edge solutions that drive business growth & transformation. </p>
                    
            </div>
        </div>
    </div>
    </div>
    
    
        
            <div className='col-ck'>
        <div className="card-wrapper">
            <div className="main-card">
            <div className="innercon">
                  <div className="card-content1">
                    <h2> End-to-End Services<FontAwesomeIcon icon={faArrowRight} /></h2>
                    {/* <p>vbhf</p> */}
                  </div>
                  <img src="/Images/route.gif" alt="" />
                </div>
            </div>
            <div className="hover-card">
                <div className="hover-content">
                <h2>End-to-End Services</h2>
                    <p>From initial consultation & ideation to development, deployment, & maintenance, we provide comprehensive end-to-end blockchain services, ensuring a seamless experience for our clients.
</p>
                    
                </div>
            </div>
        </div>
        </div>
        <div className='col-ck'>
        <div className="card-wrapper">
        <div className="main-card">
        <div className="innercon">
                  <div className="card-content1">
                    <h2>Security & Reliability <FontAwesomeIcon icon={faArrowRight} /></h2>
                    {/* <p>vbhf</p> */}
                  </div>
                  <img src="/Images/Secure_Payment_Processing.gif" alt="" />
                </div>
        </div>
        <div className="hover-card">
            <div className="hover-content">
            <h2>Security & Reliability</h2>
                    <p>Our blockchain solutions are designed with scalability & flexibility in mind, capable of handling growing transaction volumes & evolving business requirements without compromising performance or reliability.

</p>
                    
            </div>
        </div>
    </div>
    </div>
    <div className='col-ck'>
    <div className="card-wrapper">
        <div className="main-card">
        <div className="innercon">
                  <div className="card-content1">
                    <h2>Proven Track Record<FontAwesomeIcon icon={faArrowRight} /></h2>
                    {/* <p>vbhf</p> */}
                  </div>
                  <img src="/Images/interact.gif" alt="" />
                </div>
        </div>
        <div className="hover-card">
            <div className="hover-content">
            <h2> Proven Track Record</h2>
                    <p> With a track record of successful blockchain implementations across various industries, we have earned the trust & confidence of our clients, delivering tangible results & driving business value
</p>
                    
            </div>
        </div>
    </div>
    </div>
  
    
        
            <div className='col-ck'>
        <div className="card-wrapper">
            <div className="main-card">
            <div className="innercon">
                  <div className="card-content1">
                    <h2>Continuous Support & Maintenance <FontAwesomeIcon icon={faArrowRight} /></h2>
                    
                  </div>
                  <img src="/Images/online-shopping.gif" alt="" />
                </div>
            </div>
            <div className="hover-card">
                <div className="hover-content">
                <h2>Continuous Support & Maintenance</h2>
                    <p> We provide ongoing support & maintenance services to ensure the long-term success & sustainability of our blockchain solutions, adapting to changes & addressing issues promptly.
</p>
                    
                </div>
            </div>
        </div>
        </div>
        <div className='col-ck'>
        <div className="card-wrapper">
        <div className="main-card">
        <div className="innercon">
                  <div className="card-content1">
                    <h2>Cost-Effective Solutions <FontAwesomeIcon icon={faArrowRight} /></h2>
                    
                  </div>
                  <img src="/Images/analytics.gif" alt="" />
                </div>
        </div>
        <div className="hover-card">
            <div className="hover-content">
            <h2>Cost-Effective Solutions</h2>
            <p>We offer cost-effective blockchain solutions that deliver maximum value for our clients' investment, optimizing resources & minimizing total cost of ownership over the solution's lifecycle</p>
                    
            </div>
        </div>
    </div>
    </div>
    <div className='col-ck'>
    <div className="card-wrapper">
        <div className="main-card">
        <div className="innercon">
                  <div className="card-content1">
                    <h2>Transparent Communication <FontAwesomeIcon icon={faArrowRight} /></h2>
                    
                  </div>
                  <img src="/Images/job-interview.gif" alt="" />
                </div>
        </div>
        <div className="hover-card">
            <div className="hover-content">
              <h2>Transparent Communication</h2>
                   
              <p>We maintain transparent communication throughout the project lifecycle, keeping clients informed about progress, challenges, & decisions to ensure alignment & satisfaction</p>
                    
            </div>
        </div>
    </div>
    </div>

    
    </div>
    </div>
    </div>
 
     
          {/* <CarouselSlider/> */}
         
        </div>
    
        <div className="section5" >
    <h1 className="section5heading">How We are Handling</h1>
      <div className="section5jsxcard">
      <CaseStudyCard
        imageUrl="/Images/casestudyimg1.jpg"
        title="Client Consultation"
        description="We begin by understanding the client's requirements, objectives, & challenges through comprehensive consultation sessions.
        "
        // linkUrl="/platform/E-cart/casestudy-vitualwallet"
      />
      <CaseStudyCard
        imageUrl="/Images/casestudyimg2.jpg"
        title="Use Case Analysis"
        description="Our team conducts a thorough analysis of the client's use case to determine the feasibility & potential benefits of blockchain technology.
        "
        linkUrl=""
      />
      <CaseStudyCard
        imageUrl="/Images/casestudyimg3.jpg"
        title="Solution Design"
        description="Based on the use case analysis, we design a tailored blockchain solution that aligns with the client's goals & requirements, outlining the architecture, features, & functionalities.
        "
        linkUrl=""
      />
     
      <CaseStudyCard
        imageUrl="/Images/casestudyimg4.jpg"
        title="Deployment"
        description="Once the solution passes testing & QA, we deploy it to the production environment, ensuring a smooth & seamless transition from development to live operation.
        "
        linkUrl=""
      />
      
     
    {/* <div class="readmore">
  <button class="read">show More</button>
  </div> */}


{/* section 6 */}


  
    </div>
    </div> 
    

    <section className="FAQ">
        <div className="FAQbody">
          <h1 className="section5heading text-dark" >FAQ's for this service</h1>
          <Accordion items={items} />
        </div>
      </section>
        
      </div>
      
    </div>
  )
}

export default Blockchain
