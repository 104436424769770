import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import './Blog.css';


export default function Category() {
    const[recentPosts, setRecentPosts] = useState([]);
    const[isLoading, setIsLoading] = useState(true);
    const[postsToShow, setPostsToShow] = useState(6); // Number of blogs to display initially
    const[categories, setCategories] = useState([]);
    const { title } = useParams();

    // useEffect(() => {
    //     const fetchData = async () => {
    //       try {
    //         const recentPostsResponse = await axios.get(
    //           'https://demo.expertit.in/api/blogs?populate=*'
    //         );
    //         setRecentPosts(recentPostsResponse.data.data);
    //         setIsLoading(false);
    //       } catch (error) {
    //         console.error('Error fetching data:', error);
    //         setIsLoading(false);
    //       }
    //     };
    
    //     fetchData();
    //   }, []);
     
    useEffect(() => {
        const fetchData = async () => {
          try {
            const recentPostsResponse = await axios.get(
              `https://demo.expertit.in/api/categories?filters%5Bslug%5D=${title}&populate[posts][populate][0]=CoverImg`
            );
            setRecentPosts(recentPostsResponse.data.data[0].attributes.posts.data);
            setIsLoading(false);
          } catch (error) {
            console.error('Error fetching data:', error);
            setIsLoading(false);
          }
        };
        fetchData();
    }, [title]);
    useEffect(() => {
        const fetchCategories = async () => {
          
        
        try {
          const catrgorydata = await axios.get('https://demo.expertit.in/api/categories?populate=*'
        );
        setCategories(catrgorydata.data.data);
    
          
        } catch (error) {
          
        }
      };
      fetchCategories();
      },[]);
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
      };
    
    const renderBlogCards = (blogs) => {
        return blogs.slice(0, postsToShow).map(blog => (
          <div key={blog.id} className="cardblog">
            {blog.attributes.CoverImg && (
              <img
                src={`https://demo.expertit.in${
                  Array.isArray(blog.attributes.CoverImg.data)
                    ? blog.attributes.CoverImg.data[0].attributes.url
                    : blog.attributes.CoverImg.data.attributes.url
                }`}
                alt={blog.attributes.Title}
                className="img-blogs"
              />
            )}
            <div className="card-content-blogblog">
              <Link to={`/BlogDetails/${encodeURIComponent(blog.attributes.slug)}`} className="blog-link">
                <h2>{blog.attributes.Title}</h2>
              </Link>
              {Array.isArray(blog.attributes.Subtitle) ? (
                blog.attributes.Subtitle.map((paragraph, index) => (
                  <p key={index}>{paragraph.children[0].text}</p>
                ))
              ) : (
                <p>{blog.attributes.Subtitle}</p>
              )}
              <p>{formatDate(blog.attributes.BlogDate)}</p>
            </div>
          </div>
        ));
      };
      const handleReadMore = () => {
        setPostsToShow((prevPostsToShow) => prevPostsToShow + 6);
      };
  return (
    <div>
     <div>
        <img
          src="/Images/blogs-banner.jpg"
          className="Imgblog"
        />
      </div>
      <div className="cont2-title"style={{marginBottom:'10px !important'}}>{title}</div>
     <div className="blog-container">
     
      

      {/* {categories && categories.map((category) => (
        <Link to={`/category/${category.attributes.slug}`} key={category.id} className="cont2-title title">{category.attributes.Title}</Link>
      ))} */}
      {categories && (
  <div class="btn-group" role="group" aria-label="Basic example">
    {categories.map((category) => (
        
      <Link
        to={`/category/${category.attributes.slug}`}
        key={category.id}
        className="btn btn-primary"
      >
        {category.attributes.Title}
      </Link>
    ))}
  </div>
)}
<div className="containerblog">
    {recentPosts.length > 0 ? (
        renderBlogCards(recentPosts)
    ) : (
        <div className="cont2-title"style={{marginBottom:'10px !important',maxWidth:'100vw'}}>No posts found.</div>
    )}
    {postsToShow < recentPosts.length && (
        <div className="readmore" onClick={handleReadMore}>
            <button className='Loadmore'>Load More</button>
        </div>
    )}
</div>

      </div>
     
      
    </div>
  )
}
