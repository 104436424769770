import React from 'react'
import './ECommerce.css';

function ECommerce() {
  return (
    <div>
          <div className="expert-ecommerce">
      <h1 className='Ecommerece-headings'> E-Commerce Platform</h1>
      <p style={{paddingLeft:'7%'}}>Discover a world where cutting-edge technology meets eCommerce, introducing the Expert eCommerce Platform. Built for entrepreneurs by entrepreneurs, our platform encapsulates everything you'd ever need to run a successful online store and more.</p>

      <h2 className='ecommerceh2'>Why Expert eCommerce Platform?</h2>
      <div className='listed'>
      <ul>
        <li className='Ecommerece-Listed'>All-In-One Platform: From analytics to affiliate marketing, every feature is integrated seamlessly, allowing you to focus on what you do best - sell.</li>
        <li>Proactive User Management: Monitor user activities, keep track of your best customers, and understand buying behavior – all in real-time.</li>
        <li>Data-Driven Insights: With advanced reporting, analytics, and abandoned cart analysis, make informed decisions to propel your business forward.</li>
        <li>Unparalleled Support: Stuck somewhere? Chat with our support team anytime, anywhere. We're here to assist you every step of the way.</li>
      </ul>
      </div>

      <h2 className='ecommerce-h2s' style={{paddingLeft:'7%'}}>Standout Features:</h2>
      <ol style={{paddingLeft:'4.8%'}}>
        <li>Warehouse & Inventory Control: Complete management of your warehouse, ensuring that you’re stocked up and ready to meet customer demands.</li>
        <li>Supply Chain Mastery: Integrate seamlessly with supply chain companies. Streamline processes, reduce costs, and enhance efficiency.</li>
        <li>Unified Communication: From WhatsApp-like chat options to chatbots, stay connected with your customers like never before.</li>
        <li>Simplified Accounting: Automatic sales and accounting integration ensures that you're always on top of your finances.</li>
        <li>Marketing Tools: Harness the power of email marketing, social media sign-ins, and bot-driven bookings to drive traffic and conversions.</li>
        <li>Affiliate Opportunities: Expand your reach with our in-built affiliate marketing tools, allowing you to collaborate with influencers and bloggers with ease.</li>
        <li>Automated Reporting: Scheduled broadcasting ensures that your team stays updated. Receive critical business reports right in your inbox.</li>
        <li>Chatbots & API Integration: Elevate user experience with our advanced bot APIs tailored for eCommerce, automating queries and improving response time.</li>
      </ol>

      <p className='pts'>Experience the Expert Difference:</p>
      <p>In the dynamic world of online shopping, Expert eCommerce Platform emerges as the trusted partner, bridging gaps, and providing solutions. With us, you're not just choosing a platform; you're aligning with innovation, growth, and success.</p>

      <p>Dive into the Future of eCommerce with Expert eCommerce Platform</p>
      <p>Become part of a community that's setting new standards in the online shopping realm.</p>
    </div>
    </div>
  )
}

export default ECommerce
