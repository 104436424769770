import React, { useState, useEffect, useRef } from "react";
import "./Contact-Us.css"; // Import the CSS file for Contact component
import "./Contact-Us-mobile.css"; // Mobile styles
import "./template.css";
import axios from "axios";
import Contactus2 from "./contactus2";
import AOS from "aos";
import "aos/dist/aos.css";
import ReCAPTCHA from "react-google-recaptcha";

function Contact() {
  const firstName = useRef("");
  const lastName = useRef("");
  const companyName = useRef("");
  const jobTitle = useRef("");
  const email = useRef("");
  const conNo = useRef("");
  const discription = useRef("");

  useEffect(() => {
    AOS.init({
      disable: "phone",
      duration: 700,
      easing: "ease-out-cubic",
    });
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();

    const formData = {
      data: {
        First_Name: firstName.current.value,
        Last_Name: lastName.current.value,
        Company_name: companyName.current.value,
        Job_Title: jobTitle.current.value,
        Email: email.current.value,
        Co_No: conNo.current.value,
        Description: discription.current.value,
      },
    };

    try {
      const response = await axios.post(
        "https://demo.expertit.in/api/about-uses",
        formData
      );

      console.log("Form data submitted successfully:", response.data);
      alert("Form submitted successfully!");
      // Reset form fields after successful submission
      firstName.current.value = "";
      lastName.current.value = "";
      companyName.current.value = "";
      jobTitle.current.value = "";
      email.current.value = "";
      conNo.current.value = "";
      discription.current.value = "";
    } catch (error) {
      console.error("Error submitting form data:", error);
      alert("Error submitting form data. Please try again later.");
    }
  };

  const handleCaptchaVerify = (response) => {
    console.log("Captcha verified:", response);
  };

  const handleCaptchaExpire = () => {
    console.log("Captcha expired");
  };

  return (
    <div className="cont">
      <div id="carouselExampleCaptions" className="carousel slide">
        <div className="carousel-inner">
          <div className="carousel-item active">
            <img
              src="/Images/contact.webp"
              className="d-block w-100"
              style={{ height: "auto" }}
              alt="slider first"
            />
            <div className="carousel-caption d-md-block">
              <div className="component-1 text-dark"></div>
              <div
                className="component-paras"
                style={{
                  color: "black",
                  marginTop: "-20px",
                  fontStyle: "italic",
                  fontWeight: "700",
                }}
              ></div>
            </div>
          </div>
        </div>
      </div>

      <div className="mainaddresdiv" data-aos="fade-up">
        <div className="addresdiv">
          <div className="address1outsidediv">
            <h2 className="cantacth2 contacth">Locations</h2>
            <h4 className="conatacth4 contacth">Explore our Location</h4>
          </div>
        </div>
        <div className="divforaddress-1">
          <div className="divforaddress">
            {/* America */}
            <div className="americas address1div-np">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3485.9971738105655!2d-82.2156315!3d29.105764799999996!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88e87fa6437ba5af%3A0x55b97d08c127f267!2s5790%20SW%2080th%20Pl%2C%20Ocala%2C%20FL%2034476%2C%20USA!5e0!3m2!1sen!2sin!4v1708354365378!5m2!1sen!2sin"
                width="100%"
                height="400px"
                style={{ border: "0" }}
                allowFullScreen={true}
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>
        </div>
        </div>
        
        <div className="flexcmpy">
        <div className="company-details">
          {/* Company Name and Contact Details */}
          <div >
            <h3>DigiTecHub Inc</h3>
            <p>Address: 5790 SW 80th Pl, Ocala, FL 34476, USA</p>
            <p>Email: <a href="mailto:info@digitechub.com">info@digitechub.com</a></p>
            <p>Contact: +1-786 471 8571</p>
          </div>
        </div>
        </div>
      

      {/* Contact Us Form */}
      <form className="formaind" onSubmit={handleSubmit}>
        <div className="container-yq">
          <div className="headparadiv">
            <h1 className="contactheading">Contact us</h1>
            <p className="contactp">
              {/* Contact us about anything related to our company or services.
              We'll do our best to get back to you as soon as possible. */}
            </p>
          </div>
          <div className="conparadiv">
            <div>
              <div className="rows">
                <div className="rows1">
                  <div className="columnb">
                    <input
                      type="text"
                      placeholder="*First Name"
                      name="name"
                      id="name"
                      ref={firstName}
                      required
                      className="input"
                    />
                    <input
                      type="text"
                      placeholder="*Company Name"
                      name="company"
                      id="company"
                      ref={companyName}
                      required
                      className="input"
                    />
                    <input
                      type="text"
                      placeholder="*Email"
                      name="email"
                      id="email"
                      ref={email}
                      required
                      className="input"
                    />
                  </div>
                  <div className="columnb">
                    <input
                      type="text"
                      placeholder="*Last Name"
                      name="last-name"
                      id="last-name"
                      ref={lastName}
                      required
                      className="input"
                    />
                    <input
                      type="text"
                      placeholder="*Job Title"
                      name="job-title"
                      id="job-title"
                      ref={jobTitle}
                      required
                      className="input"
                    />
                    <input
                      type="text"
                      placeholder="*Phone Number"
                      name="phone-number"
                      id="phone-number"
                      ref={conNo}
                      required
                      className="input"
                    />
                  </div>
                </div>
                <div className="columnb">
                  <textarea
                    cols="30"
                    rows="5"
                    maxLength="1500"
                    className="wpcf7-form-control wpcf7-textarea form-control"
                    id="message-mob"
                    autoComplete="global-your-message-mob"
                    aria-invalid="false"
                    ref={discription}
                    placeholder="How can we help you?"
                    name="global-your-message-mob"
                  ></textarea>
                </div>
              </div>
            </div>

            <div className="agree-policy">
              <input
                type="checkbox"
                id="agree-policy1"
                name="agree-policy"
                required
              />
              <label htmlFor="agree-policy1" style={{ paddingLeft: "10px" }}>
                I agree to the privacy policy
              </label>
            </div>
            <div className="agree-policy">
              <input
                type="checkbox"
                id="agree-policy2"
                name="agree-policy"
                required
              />
              <label htmlFor="agree-policy2" style={{ paddingLeft: "10px" }}>
                Subscribe me to Digitec's latest thought leadership, blogs and
                updates.
              </label>
            </div>
            <p className="usemailagree">
              You can also email us directly at{" "}
              <a href="mailto:info@digitechub.com">info@digitechub.com</a>.
            </p>

            <div className="form-groupcaptcha">
              <ReCAPTCHA
                sitekey="6LckvrYpAAAAAO3lwZ-kQM2Qa4EC5laGAy2Ma6HN"
                onExpired={handleCaptchaExpire}
                onVerify={handleCaptchaVerify}
              />
            </div>
            <div className="grid-container-a">
              <button type="submit" className="registerbtn">
                Submit
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

export default Contact;
